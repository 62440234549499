import { Person } from "../../interfaces/Person"

export const GET_PERSONS_LIST = 'GET_PERSONS_LIST'
export const SET_PERSONS_LIST = 'SET_PERSONS_LIST'

const initialState: {
    list: Person[];
    page: number;
    name: string;
    sort: string;
} = {
    list: [],
    page: 0,
    name: '',
    sort: ''
}

export const PersonsListReducer = ( state = initialState, action ) => {

    switch (action.type) {

        case SET_PERSONS_LIST:
            return {
                ...state,
                page: action.payload.page, 
                list: ( (action.payload.page !== 0) ? [...state.list, ...action.payload.list] : [...action.payload.list] ),
                name: action.payload.name,
                sort: action.payload.sort
            }

        default:
           
            return state
    }
}

export const getPersonsList = (payload: {page: number; name?: string; sort?: string}) => ({type: GET_PERSONS_LIST, payload})
export const setPersonsList = (payload: {
    list: Person[];
    page: number;
    name?: string;
    sort?: string
}) => ({type: SET_PERSONS_LIST, payload})