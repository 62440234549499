import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { User } from "../../../interfaces/User"
import { tryToUpdatePhoto } from "../../../store/reducers/AccountMainDataReducer"

import './styles.sass'

export const MyAccountPhoto = () => {

    const dispatch = useDispatch()
    const [fileSize, setFileSize] = useState(0);
    const accountData: User = useSelector( (state: any) => (state.accountMainData))
    const [errorSize, setErrorSize] = useState(false)
    async function fileUpload(file){
        if (file.file.size > fileSize){
            setErrorSize(true)
        } else {
            setErrorSize(false)
            const formData = new FormData();
            formData.append('image',file.file)
    
            dispatch( tryToUpdatePhoto(formData) )
        }
      
      }
    function onFormSubmit(event, phot?){
        event.preventDefault() // Stop form submit
        fileUpload(phot)
      }
    function onChange(event) {
        onFormSubmit(event, {file:event.target.files[0]})
      }
    useEffect(()=>{
        fetch(process.env.REACT_APP_API_HOST +'/static-information/max-photo-size',{
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + localStorage.token
            },
        }).then((response)=>{
            return response.json()
        }).then((data)=>{
            setFileSize(data.value)
        })
    },[])

    return (
        <div
            className="my-account-photo-wrapper"
        >
            <div>
                {
                    accountData
                    &&
                    typeof accountData.photo === 'string'
                    &&
                    accountData.photo.length > 0
                    &&
                    <img 
                        src={accountData.photo}
                    />
                }
            </div>
            <form encType="multipart/form-data" onSubmit={onFormSubmit}>
                <input type="file" onChange={onChange}/>
                <span>
                    <FormattedMessage id='my_account_change_photo_title'/>
                </span>
                {errorSize && <div style={{color: 'red',marginLeft: '24px',marginTop: '5px'}}>
                    Превышен максимальный размер файла {fileSize/1000000}мб. </div>}
            </form>

        </div>
    )
}