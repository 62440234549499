import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { routes } from '../../routes'
import { finishPasswordRestoreCreator } from "../../store/reducers/RestorePasswordReducer"

import './styles.sass'

interface PasswordRecovery {
    key: {
        value: string,
        changed: boolean
    };
    password: {
        value: string,
        changed: boolean
    };
    confirmPassword: {
        value: string,
        changed: boolean
    };
}

interface PasswordRecoveryErrors {
    key: string;
    password: string;
    confirmPassword: string;
}

export const LoginPasswordRecoverySecondStage = () => {

    const intl = useIntl()
    const dispatch = useDispatch()

    const isSuccess = useSelector( (state: any) => (state.restorePassword.success))
    const isFinishError = useSelector( (state: any) => (state.restorePassword.finishError))
    
    const [isValid, setIsValid] = useState<boolean>(false)
    const [form, setForm] = useState<PasswordRecovery>({
        key: {
            value: '',
            changed: false
        },
        password: {
            value: '',
            changed: false
        },
        confirmPassword: {
            value: '',
            changed: false
        }
    })
    const [errors, setErrors] = useState<PasswordRecoveryErrors>({
        key: '',
        password: '',
        confirmPassword: ''
    })

    const handleSubmit = (event) => {
        event.preventDefault()
        if (isValid) {
            dispatch(finishPasswordRestoreCreator({
                key: form.key.value,
                newPassword: form.password.value
            }))
        }
    }

    const validate = () => {
        let isValidTemp = true;
        let errorsTemp = {
            key: '',
            password: '',
            confirmPassword: ''
        };

        if (form.key.changed && !form.key.value) {
            isValidTemp = false;
            errorsTemp.key = "signup_text_error_must_field";
        }

        if (form.password.changed && !form.password.value) {
            isValidTemp = false;
            errorsTemp.password = "signup_text_error_must_field";
        }
        if (form.password.changed && typeof form.password.value !== "undefined") {
            let passwordPattern = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[[!@#\$%\^\&*\)\(+=._\-\A-Za-z\d]{6,}$/)
            if (!passwordPattern.test(form.password.value) ) {
                isValidTemp = false;
                errorsTemp.password = "signup_text_error_password_length";
            }
        }
        if (form.password.changed && typeof form.password.value !== "undefined") {
            if (form.password.value.length < 4) {
                isValidTemp = false;
                errorsTemp.password = "signup_text_error_password_length";
            }
        }

        if (form.confirmPassword.changed && !form.confirmPassword.value) {
            isValidTemp = false;
            errorsTemp.confirmPassword = "signup_text_error_must_field";
        }

        if (form.confirmPassword.changed && typeof form.confirmPassword.value !== "undefined") {
            if (form.confirmPassword.value !== form.password.value) {
                isValidTemp = false;
                errorsTemp.confirmPassword = "signup_text_error_passwords_not_match";
            }
        }

        setErrors(errorsTemp);
        setIsValid(isValidTemp);
    }

    const handleChange = (event) => {
        switch (event.target.name) {
            case "key":
                setForm(
                    {
                        ...form,
                        key: {
                            value: event.target.value,
                            changed: true
                        }
                    }
                );
                break;
            case "password":
                setForm(
                    {
                        ...form,
                        password: {
                            value: event.target.value,
                            changed: true
                        }
                    }
                );
                break;
            case "confirmPassword":
                setForm(
                    {
                        ...form,
                        confirmPassword: {
                            value: event.target.value,
                            changed: true
                        }
                    }
                );
                break;
        }

        if(!isValid) {
            validate()
        }
    }

    const handleFocus = (event) => {
        switch (event.target.name) {
            case "email":
                setForm(
                    {
                        ...form,
                        key: {
                            value: form.key.value,
                            changed: true
                        }
                    }
                );
                break;
            case "password":
                setForm(
                    {
                        ...form,
                        password: {
                            value: form.password.value,
                            changed: true
                        }
                    }
                );
                break;
            case "confirmPassword":
                setForm(
                    {
                        ...form,
                        confirmPassword: {
                            value: form.confirmPassword.value,
                            changed: true
                        }
                    }
                );
                break;

        }
    }

    return (
        <form className='login-recovery-second-stage-form-wrapper' onSubmit={handleSubmit}>
            <span className='login-recovery-title'>
                <FormattedMessage id='login_recovery_title'/>
            </span>

            {
                isFinishError
                &&
                <div className='login-error-panel'>
                    <span className='login-error-panel__title'>
                        <FormattedMessage id='login_recovery_second_stage_error_title'/>
                    </span>

                    <span className='login-error-panel__sub-title'>
                        <FormattedMessage id='login_recovery_second_stage_error_text'/>
                    </span>
                </div>
            }
            <div className={`text-input`}>
                <input
                    type='text'
                    placeholder={ intl.formatMessage({ id: 'login_recovery_key_placeholder' })}
                    value={form.key.value}
                    name={"key"}
                    autoComplete="new-password"
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={validate}
                    className={`${ !isValid && errors.key.length !== 0 && 'error-input'}`}
                />
                {
                    !isValid && errors.key.length !== 0
                    &&
                    <span className={"error-text"}><FormattedMessage id={errors.key}/></span>
                }
            </div>

            <div className={`text-input`}>
                <input
                    type='password'
                    placeholder={ intl.formatMessage({ id: 'login_recovery_password_placeholder' })}
                    value={form.password.value}
                    name={"password"}
                    autoComplete="new-password"
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={validate}
                    className={`${ !isValid && errors.password.length !== 0 && 'error-input'}`}
                />
                {
                    !isValid && errors.password.length !== 0
                    &&
                    <span className={"error-text"}><FormattedMessage id={errors.password}/></span>
                }
            </div>

            <div className={`text-input`}>
                <input
                    type='password'
                    placeholder={ intl.formatMessage({ id: 'login_recovery_password_confirmation_placeholder' })}
                    value={form.confirmPassword.value}
                    name={"confirmPassword"}
                    onFocus={handleFocus}
                    autoComplete="new-password"
                    onChange={handleChange}
                    onBlur={validate}
                    className={`${ !isValid && errors.confirmPassword.length !== 0 && 'error-input'}`}
                />
                {
                    !isValid && errors.confirmPassword.length !== 0
                    &&
                    <span className={"error-text"}><FormattedMessage id={errors.confirmPassword}/></span>
                }
            </div>

            <button 
                className={`green-button ${ !isValid && 'disabled-button' }`}
                disabled={!isValid}
            >
                <FormattedMessage id='login_recovery_second_stage_submit_button' />
            </button>

            {
                isSuccess
                &&
                <Navigate to={routes.login}/>
            }
        </form>
    )
}