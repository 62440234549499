export const TRY_TO_ADD_MEASUREMENT_TO_CONSULTATION = 'TRY_TO_ADD_MEASUREMENT_TO_CONSULTATION'
export const SET_IS_SUCCESS_ADDING_MEASUREMENT_TO_CONSULTATION = 'SET_IS_SUCCESS_ADDING_MEASUREMENT_TO_CONSULTATION'

const initialState: boolean = false

export const AddMeasurementToConsultationReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_IS_SUCCESS_ADDING_MEASUREMENT_TO_CONSULTATION:
            return true

        default:
            return state
    }
}

export const tryToAddMeasurementToConsultation = (payload: string) => ({type: TRY_TO_ADD_MEASUREMENT_TO_CONSULTATION, payload})
export const setIsSucccessAddingMeasurementToConsultation = () => ({type: SET_IS_SUCCESS_ADDING_MEASUREMENT_TO_CONSULTATION})