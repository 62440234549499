import { call, put, takeEvery } from "@redux-saga/core/effects"
import { apiUrl } from "../consts"
import { MeasurementType } from "../interfaces/MeasurementType"
import { routes } from "../routes"
import { setMeasurementsTypeList, TRY_TO_GET_MEASUREMENTS_TYPE_LIST } from "../store/reducers/MeasurementTypeListReducer"

const asyncGetMeasurementTypeList = async () => {
    try {

        const response = await fetch(
            apiUrl + '/measurement-types',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            }
        )

        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
            return null
        } else {
            return null
        }

    } catch(error) {}
}

function* GetMeasurementTypeListWorker() {
    const data: MeasurementType[] | null = yield call(asyncGetMeasurementTypeList)

    if (data && data.length > 0) {
        yield put(setMeasurementsTypeList(data))
    }
}

export function* GetMeasurementTypeListWatcher() {
    yield takeEvery(TRY_TO_GET_MEASUREMENTS_TYPE_LIST, GetMeasurementTypeListWorker)
}