import { Consultant } from "../../interfaces/Consultant"

export const TRY_TO_GET_CONSULTANT_INFO = 'TRY_TO_GET_CONSULTANT_INFO'
export const SET_CONSULTANT = 'SET_CONSULTANT'

const initialState: Consultant | null = null

export const ConsultantReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_CONSULTANT:
            return action.payload

        default:
            return state
    }
}

export const tryToGetConsultantInfo = (payload: string | null) => ({type: TRY_TO_GET_CONSULTANT_INFO, payload})
export const setConsultant = (payload: Consultant) => ({type: SET_CONSULTANT, payload})