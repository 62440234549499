import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import { setConsultationCommission } from "../store/reducers/ConsultationCommisionReducer";
import { TRY_TO_CANCEL_VALUE_CONSULTATION } from "../store/reducers/ConsultationsCostReducer";
import { getCurrentConsultation } from "../store/reducers/CurrentConsultationReducer";
import { closeModal } from "../store/reducers/ModalWindowsReducer";

const asyncCancelCosnultationValue = async (id: string) => {
    try {
        
        const response = await fetch(
            apiUrl + '/consultation/cancel-invoice/'+id,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            }
        )

        if (response.status === 200) {
            return true
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return false
        }

    } catch (err) {}
}

function* ConsultationsCancelValueWorker(action) {
    const data: boolean = yield call(asyncCancelCosnultationValue, action.payload)

    if (data) {
        yield put(getCurrentConsultation(action.payload))
        yield put(setConsultationCommission(0))
        yield put(closeModal())
    }
}

export function* ConsultationsCancelValueWatcher() {
    yield takeEvery(TRY_TO_CANCEL_VALUE_CONSULTATION, ConsultationsCancelValueWorker)
}