import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { User } from '../interfaces/User'
import { routes } from "../routes";
import store from "../store";
import { ConsultationRequestInterface, setConsultationsList, TRY_TO_GET_CONSULTATIONS_LIST } from "../store/reducers/ConsultationsListReducer";

const asyncTryToGetConsultationsList = async (data: {isConsultant: boolean, type: string, page: number}) => {
    try {
        let typeConsultation = '';
      
       
        if (localStorage.getItem('typeConsultation') == 'consultation'){
           
            typeConsultation='&isConsultant=false'
        }
        if (localStorage.getItem('typeConsultation') == 'appeals'){
          
            typeConsultation='&isConsultant=true'
        }
        const response = await fetch(
            apiUrl + `/consultation/search?category=${data.type}&page=${data.page}&category=${data.type}&page=${data.page}`+typeConsultation,
            {
                method: 'GET',
                headers: {
                    //'X-Total-Count': '28',
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            }
        )

        if (response.status === 200) {
            const result: ConsultationRequestInterface = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch(error) {}
}

function* GetConsultationsListWorker(action) {
    let use: User = store.getState().user

    if (use && use.id) {
        const isUserConsultant = use.authorities ? use.authorities.includes('CONSULTANT') : false

        const data: ConsultationRequestInterface | null = yield call(asyncTryToGetConsultationsList, {isConsultant: isUserConsultant, type: action.payload.type, page: action.payload.page })
        
        if (data && data.size) {
            yield put( setConsultationsList({...data, page: action.payload.page}) )
        }
    }
}

export function* GetConsultationsListWatcher() {
    yield takeEvery(TRY_TO_GET_CONSULTATIONS_LIST, GetConsultationsListWorker)
}