import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentMeasurementStatisticPerson } from "../../../store/reducers/CurrentMeasurementStatisticPersonReducer";
import { Consultation } from "../../../interfaces/Consultation";
import "./styles.sass";
import { User } from "../../../interfaces/User";
const relationTypes = {
  ["Родственники"]: "Р",
  ["Родственник"]: "Р",
  ["Он мной руководит"]: "Н",
  ["Я им руковожу"]: "Н",
  ["Он равный мне"]: "C",
  ["Иной мне человек (малозначимый)"]: "И",
};

export const PersonListStatistic = () => {
  const dispatch = useDispatch();
  const consultation: Consultation | null = useSelector(
    (state: any) => state.currentConsultation
  );
  const accountData: User = useSelector((state: any) => state.user);
  console.log(accountData);
  const data = useSelector(
    (state: any) => state.measurementStatistic
  )?.personRates;
  let fufich = 0;
  const currentPerson = useSelector(
    (state: any) => state.currentMeasurementStatisticPerson
  );
  const sorting = useSelector((state: any) => state.mainGraphicMode);
  const [choosenIndex, setChoosenIndex] = useState(1);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  console.log(consultation);
  // const [currentScroll, setCurrentScroll] = useState(0)
  function personListener(event) {
    const scrollContainer = window.document.getElementById("personScroll");
    if (event.code == "ArrowDown" && choosenIndex < data.length - 1) {
      event.preventDefault();
      setChoosenIndex(choosenIndex + 1);
      if (scrollContainer) {
        if (choosenIndex > 10) {
          scrollContainer.scrollTop += 54;
        }
      }
    } else if (event.code == "ArrowUp" && choosenIndex > 1) {
      event.preventDefault();
      setChoosenIndex(choosenIndex - 1);
      if (scrollContainer) {
        if (choosenIndex < data.length - 10) {
          scrollContainer.scrollTop -= 54;
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", personListener);
    return function deleteEvent() {
      document.removeEventListener("keydown", personListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, choosenIndex]);
  useEffect(() => {
    if (data.length > 0 && choosenIndex) {
      dispatch(setCurrentMeasurementStatisticPerson(data[choosenIndex]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenIndex, data]);
  return (
    <>
      {document.body.clientWidth < 640 && (
        <span
          className="person-list-statistic-opener"
          onClick={() => {
            if (isOpened) {
              setIsOpened(false);
            } else {
              setIsOpened(true);
            }
          }}
        >
          {isOpened ? (
            <FormattedMessage id="measurement_statistic_person_list_close" />
          ) : (
            <FormattedMessage id="measurement_statistic_person_list_open" />
          )}
        </span>
      )}
      <div
        className={`${
          document.body.clientWidth < 640 &&
          (isOpened
            ? "person-list-statistic-opened"
            : "person-list-statistic-closed")
        } person-list-statistic`}
        style={{
          maxHeight:
            document.body.clientWidth < 640
              ? isOpened
                ? "100vh"
                : "0px"
              : "100%",
        }}
        id="personScroll"
      >
        {document.body.clientWidth < 640 && (
          <span className="person-list-statistic__title">
            <FormattedMessage id="measurement_statistic_person_list_title" />
          </span>
        )}

        {data &&
          data.length > 0 &&
          data.slice(1).map((person, personIndex) => {
            if (
              personIndex == data.slice(1).length - 1 &&
              data.slice(1).length != 1 &&
              sorting.mode === 5
            ) {
              if (fufich == 0) {
                return (
                  <div
                    key="abge"
                    style={{
                      marginTop: "220px",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "18px",
                      width: "320px",
                    }}
                  >
                    В этой микрозоне у вас нет партнеров из данной группы
                  </div>
                );
              }
              fufich = 0;
            } else if (data.slice(1).length == 1 && sorting.mode === 5) {
              if (
                sorting.mode === 5 &&
                (parseInt(sorting.sort) === person.microanalysisArea ||
                  sorting.sort === "0")
              ) {
                fufich += 1;
              } else {
                fufich = 0;
                return (
                  <div
                    key="abge"
                    style={{
                      marginTop: "220px",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "18px",
                      width: "320px",
                    }}
                  >
                    В этой микрозоне у вас нет партнеров из данной группы
                  </div>
                );
              }
            }
            if (
              sorting.mode === 0 ||
              (sorting.mode === 1 &&
                (parseInt(sorting.sort) === person.meToPersonArea ||
                  sorting.sort === "0")) ||
              (sorting.mode === 2 &&
                (parseInt(sorting.sort) === person.personToMeArea ||
                  sorting.sort === "0")) ||
              sorting.mode === 3 ||
              sorting.mode === 4 ||
              (sorting.mode === 5 &&
                (parseInt(sorting.sort) === person.microanalysisArea ||
                  sorting.sort === "0"))
            ) {
              if (
                sorting.mode === 5 &&
                (parseInt(sorting.sort) === person.microanalysisArea ||
                  sorting.sort === "0")
              ) {
                fufich += 1;
              }

              return (
                <div
                  key={"measurement-statistic-person-list-" + personIndex}
                  className={`person-list-statistic__panel ${
                    currentPerson?.person?.id === person?.person?.id &&
                    "choosen"
                  }`}
                  onClick={() => {
                    dispatch(setCurrentMeasurementStatisticPerson(person));
                    setIsOpened(false);
                    setChoosenIndex(personIndex + 1);
                  }}
                >
                  <span className="person-list-statistic__panel__index">
                    {(personIndex < 9 && <>00{personIndex + 1}</>) ||
                      (personIndex < 99 && <>0{personIndex + 1}</>) ||
                      personIndex + 1}
                  </span>
                  <div
                    className="person-list-statistic__panel__image"
                    style={{
                      background:
                        person?.person?.gender === "Female"
                          ? "#FF8F8F"
                          : "#397DFF",
                    }}
                  >
                    {relationTypes[person?.person?.role]}
                  </div>
                  <div
                    style={{ width: "70%", overflow: "hidden" }}
                    className="person-list-statistic__panel__title"
                  >
                    {(person &&
                      person.person &&
                      person.person.customName &&
                      person.person.customName.length > 25) ||
                    (person && person.comment && person.comment.length > 25) ? (
                      <div className="person__modal__name">
                        <span>
                          <b>Имя:</b>
                          {location.pathname.includes("measurement-watcher") &&
                          consultation?.consultantId &&
                          accountData &&
                          accountData.id &&
                          consultation?.consultantId == accountData.id &&
                          accountData &&
                          accountData.id
                            ? person?.person?.customName.slice(0, 3) + "..."
                            : person?.person?.customName}
                        </span>
                        <span>
                          <b>Комментарий:</b>

                          {person?.comment || "нет"}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    <div style={{ whiteSpace: "nowrap" }}>
                      {location.pathname.includes("measurement-watcher") &&
                      accountData &&
                      accountData.id &&
                      consultation?.consultantId == accountData.id
                        ? person?.person?.customName.slice(0, 3) + "..."
                        : person?.person?.customName}
                    </div>
                    <span style={{ whiteSpace: "nowrap" }}>
                      {person?.comment || ""}
                    </span>
                  </div>
                  <div className="person-list-statistic__panel__rating">
                    <span>
                      {person.outerRating &&
                        person.outerRating.length > 0 &&
                        (person?.outerRating === "0" ? (
                          <pre> 0</pre>
                        ) : (
                          person?.outerRating
                            .replace("+", "Л")
                            .replace("-", "T")
                            .split("")
                            .reverse()
                            .join("")
                        ))}
                    </span>
                    <span>
                      {person.innerRating &&
                        person.innerRating.length > 0 &&
                        (person?.innerRating === "0" ? (
                          <pre> 0</pre>
                        ) : (
                          person?.innerRating
                            .replace("+", "Л")
                            .replace("-", "T")
                            .split("")
                            .reverse()
                            .join("")
                        ))}
                    </span>
                  </div>
                </div>
              );
            }
          })}
      </div>
    </>
  );
};
