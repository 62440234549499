export const SET_MAIN_GRAPHIC_MODE = 'SET_MAIN_GRAPHIC_MODE'
export const SET_MAIN_GRAPHIC_SORT = 'SET_MAIN_GRAPHIC_SORT'

const initialState = {
    mode: 0,
    sort: '0'
}

export const MainGraphicModeReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_MAIN_GRAPHIC_MODE:
            return {
                mode: action.payload,
                sort: ''
            }

        case SET_MAIN_GRAPHIC_SORT:
            
            return {
                ...state,
                sort: action.payload === state.sort ? '0' : action.payload
            }

        default:
            return state
    }
}

export const setMainGraphicMode = (payload: number) => ({type: SET_MAIN_GRAPHIC_MODE, payload})
export const setMainGraphicSort = (payload: string) => ({type: SET_MAIN_GRAPHIC_SORT, payload})