import {PersonStatus} from '../../interfaces/PersonStatus'

const initialState: PersonStatus[] = []

export const GET_PERSON_STATUSES = 'GET_PERSON_STATUSES'
export const SET_PERSON_STATUSES = 'SET_PERSON_STATUSES'

export const PersonStatusesReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_PERSON_STATUSES: 
            return action.payload

        default:
            return state
    }
}

export const getPersonStatuses = () => ({type: GET_PERSON_STATUSES})
export const setPersonStatuses = (payload: PersonStatus[]) => ({type: SET_PERSON_STATUSES, payload})