import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMeasurementsList } from "../../store/reducers/MeasurementsListReducer";

import { MeasurementChoosePanel } from "../../components/MeasurementChoosePanel/index";

import "./styles.sass";
import { cleanCreatingConsultationProcessStorage } from "../../consts";
import { cleanMeasurementConsultationList } from "../../store/reducers/MeasurementConsultationListReducer";
import { cleanConsultation } from "../../store/reducers/CreatingConsultationReducer";
import { MeasurementsListSearch } from "../../components/MeasurementsListSearch";
import { cleanCurrentConsultation } from "../../store/reducers/CurrentConsultationReducer";
import { setConsultationMessages } from "../../store/reducers/ConsultationChatReducer";

export const MeasurementsListContainer = () => {
  const dispatch = useDispatch();

  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

  const measurementsList = useSelector((state: any) => state.measurementsList);

  cleanCreatingConsultationProcessStorage();
  useEffect(() => {
    dispatch(cleanMeasurementConsultationList());
    dispatch(cleanConsultation());
    localStorage.setItem("typeId", "");
    localStorage.setItem("before", "");
    localStorage.setItem("after", "");
    localStorage.setItem("comm", "");
    // dispatch( getMeasurementsList(0) )
    dispatch(cleanCurrentConsultation());
    dispatch(setConsultationMessages([]));
    let pageNum = 0;
    const measurementsListWrapper: HTMLElement | null =
      window.document.getElementById("measurement-list-wrapper");

    const handleScroll = () => {
      let scrollTop = measurementsListWrapper?.scrollTop || 0;
      let clientHeight = measurementsListWrapper?.clientHeight || 0;
      let scrollheight = measurementsListWrapper?.scrollHeight || 0;

      if (Math.ceil(scrollTop + clientHeight) >= Math.ceil(scrollheight)) {
        dispatch(getMeasurementsList(pageNum + 1));
        pageNum += 1;
      }
    };

    measurementsListWrapper?.addEventListener("scroll", handleScroll);

    return () =>
      measurementsListWrapper?.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={`list-measurement-container ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
        id="measurement-list-wrapper"
      >
        <div>
          <MeasurementsListSearch />
        </div>

        <div>
          {measurementsList.list &&
            measurementsList.list.length > 0 &&
            measurementsList.list.map((elem, index) => {
              return (
                <div key={index}>
                  <MeasurementChoosePanel data={elem} />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
