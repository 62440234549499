import { Consultation } from "../../interfaces/Consultation";

export const TRY_TO_GET_CONSULTATIONS_LIST = 'TRY_TO_GET_CONSULTATIONS_LIST'
export const SET_CONSULTATIONS_LIST = 'SET_CONSULTATIONS_LIST'

export interface ConsultationRequestInterface {
    consultations: Consultation[],
    size: {
        COMPLETED: number;
        IN_ARBITRAGE: number;
        IN_WORK: number;
        REQUEST: number;
    },
    page: number
}

const initialState: ConsultationRequestInterface  = {
    consultations: [],
    size: {
        COMPLETED: 0,
        IN_ARBITRAGE: 0,
        IN_WORK: 0,
        REQUEST: 0
    },
    page: 0
}

export const ConsultationsListReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_CONSULTATIONS_LIST:
            if (action.payload.page === 0) {
                return {
                    consultations: action.payload.consultations,
                    size: action.payload.size,
                    page: action.payload.page
                }
            } 
            else {
                return {
                    consultations: [...state.consultations, ...action.payload.consultations],
                    size: action.payload.size,
                    page: action.payload.page
                }
            }
                

        default:
            return state
    }
}

export const tryToGetConsultationsList = (payload: {type: string, page: number}) => ({type: TRY_TO_GET_CONSULTATIONS_LIST, payload})
export const setConsultationsList = (payload: ConsultationRequestInterface) => ({type: SET_CONSULTATIONS_LIST, payload})