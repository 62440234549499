
export const TRY_TO_GET_COUNTRIES_LIST = 'TRY_TO_GET_COUNTRIES_LIST'
export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST'

const initialState = []

export const CountriesListReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_COUNTRIES_LIST:
            return action.payload

        default: 
            return state
    }
}

export const tryToGetCountriesList = () => ({type: TRY_TO_GET_COUNTRIES_LIST})
export const setCountriesList = (payload) => ({type: SET_COUNTRIES_LIST, payload})