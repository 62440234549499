import { Message } from '../../interfaces/Message'

export const GET_ALL_CONSULTATION_MESSAGES = 'GET_ALL_CONSULTATION_MESSAGES'
export const SET_CONSULTATION_MESSAGES = 'SET_CONSULTATION_MESSAGES'
export const SEND_CONSULTATION_MESSAGE = 'SEND_CONSULTATION_MESSAGE'
export const DOWNLOAD_CONSULTATION_REPORT = 'DOWNLOAD_CONSULTATION_REPORT'

const initialState: Message[] = []

export const ConsultationChatReducer = (state = initialState, action) => {
    
    switch (action.type) {

        case SET_CONSULTATION_MESSAGES:
            return action.payload

        default:
            return state
    }
}

export const getAllConsultationMessages = () => ({type: GET_ALL_CONSULTATION_MESSAGES})
export const setConsultationMessages = (payload: Message[]) => ({type: SET_CONSULTATION_MESSAGES, payload})

export const scrollConsultationPage = (payload: number) => ({type: SET_CONSULTATION_MESSAGES, payload})
export const sendConsultationMessage = (payload: string) => ({type: SEND_CONSULTATION_MESSAGE, payload})
export const downloadConsultationReport = (payload: string) => ({type: DOWNLOAD_CONSULTATION_REPORT, payload})