export interface Article {
    title?: string;
    id?: string;
    name?: string;
    children?: Array<Article>;
}


export const defaultValue: Readonly<Article> = {
    title: '',
    id: '',
    name: '',
    children: new Array<Article>(),
};