export const TRY_TO_GET_CONSULTATION_COMMISSION = 'TRY_TO_GET_CONSULTATION_COMMISSION'
export const SET_CONSULTATION_COMMISSION = 'SET_CONSULTATION_COMMISSION'

const initialState: number = 0

export const ConsultationCommisionReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_CONSULTATION_COMMISSION:
            return action.payload

        default:
            return state
    }
}

export const tryToGetConsultationCommission = (payload: number) => ({type: TRY_TO_GET_CONSULTATION_COMMISSION, payload})
export const setConsultationCommission = (payload: number) => ({type: SET_CONSULTATION_COMMISSION, payload})