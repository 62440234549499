import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { Message } from "../interfaces/Message";
import { routes } from "../routes";
import store from "../store";
import { GET_ALL_CONSULTATION_MESSAGES, setConsultationMessages } from "../store/reducers/ConsultationChatReducer";

async function asyncGetAllConsultationMessages() {
    try {
        let id = ''
        if (store.getState().currentConsultation) {
            id = store.getState().currentConsultation.id
        
        const response = await fetch(
            apiUrl + '/consultation/' + id + '/messages?sort=date,desc',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                }
            }
        )

        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    }
    } catch(error) {} 
}

function* GetAllConsultationMessagesWorker() {
    const data: Message[] | false = yield call(asyncGetAllConsultationMessages)
   
    if (data ) {
        localStorage.setItem('firstMessageId', data[0].id)
        yield put( setConsultationMessages(data))
    } 
}

export function* GetAllConsultationMessagesWatcher() {
    yield takeEvery(GET_ALL_CONSULTATION_MESSAGES, GetAllConsultationMessagesWorker)
}