import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { setConsultationCommission, tryToGetConsultationCommission } from '../../../store/reducers/ConsultationCommisionReducer'
import { tryToSetValueConsultation } from '../../../store/reducers/ConsultationsCostReducer'
import { closeModal } from '../../../store/reducers/ModalWindowsReducer'
import './styles.sass'

export const ConsultationSetValueModal = () => {

    const dispatch = useDispatch()

    const commission = useSelector( (state: any) => (state.consultationCommision))

    const [value, setValue] = useState<string>('')

    useEffect( () => {
        dispatch(setConsultationCommission(0))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleValue = (val: string) => {
        if (val.length > 0 && val[val.length-1].match(/[0-9]/)) {
            setValue(val)
            dispatch( tryToGetConsultationCommission(parseFloat(val)) )
        } else {
            dispatch( setConsultationCommission(0) )
            setValue(val)
        }
    }

    return (
        <div
            className='consultations-set-value-modal-wrapper'
        >
            <div
                className='consultations-set-value-modal'
            >
                <span
                    className='consultations-set-value-modal__title'
                >
                    <FormattedMessage id='consultations_chat_set_value_modal_title'/>
                </span>

                <span
                    className='consultations-set-value-modal__description'
                >
                    <FormattedMessage id='consultations_chat_set_value_modal_description'/>
                </span>

                <div
                    className='consultations-set-value-modal__input-wrapper'
                >
                    <div className='text-input'>
                        <input
                            type='text'
                            value={value}
                            onChange={(event) => handleValue(event.target.value)}

                        />
                    </div>
                    <div
                        className='consultations-set-value-modal__input-wrapper__commission'
                    >
                        <FormattedMessage id='consultations_chat_set_value_modal_comission'/>
                        <span>
                            -
                        </span>
                        {
                            commission
                        }
                    </div>
                </div>

                <div
                    className='consultations-set-value-modal__buttons'
                >
                    <div 
                        className='green-button'
                        onClick={() => {
                            if (value.length>0) {
                                let cost = parseInt(value)
                                dispatch( tryToSetValueConsultation(cost) )
                            }
                        }}
                    >
                        <FormattedMessage id='consultations_chat_set_value_modal_add_button'/>
                    </div>

                    <div
                        className='white-button'
                        onClick={() => {
                            dispatch( setConsultationCommission(0) )
                            dispatch( closeModal() )
                        }}
                    >
                        <FormattedMessage id='consultations_chat_set_value_modal_close'/>
                    </div>
                </div>

            </div>
        </div>
    )
}