import { takeEvery, call, put } from "@redux-saga/core/effects";
import { apiUrl } from "../consts";
import { Consultation } from "../interfaces/Consultation";
import { routes } from "../routes";
import store from "../store";

import { setIsRejectinConsultationTrue, TRY_TO_REJECT_CONSULTATION } from "../store/reducers/RejectConsultationReducer";
import { User } from "../interfaces/User"
const asyncRejectConsultation = async () => {
    try {

        const consultation: Consultation  = store.getState().currentConsultation
        const accountData: User = store.getState().accountMainData

            const response = await fetch(
                apiUrl + `/consultation/cancel/${consultation?.id}`,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json;charset=utf-8',
                        'Authorization': 'Bearer ' + localStorage.token
                    },
                }
            )

            if (response.status === 200) {
                if (accountData?.id == consultation.senderId){
                    location.assign(routes.consultations)
                } else {
                    location.assign(routes.consultationForConsultant)
                }
               
                return true
            } else if (response.status === 401) {
                location.assign(routes.login)
            } else {
                return null
            }
    } catch(err) {}
}

function* RejectConsultationWorker() {
    const data: boolean = yield call(asyncRejectConsultation)
    
    if (data === true) {
        yield put(setIsRejectinConsultationTrue())
    }
}

export function* RejectConsultationWatcher() {
    yield takeEvery(TRY_TO_REJECT_CONSULTATION, RejectConsultationWorker)
}