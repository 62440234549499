
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
//import {  useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SearchLoupeIcon } from '../../icons'
//import { Person } from '../../interfaces/Person'
import { ToggleListSmallArrowIcon } from "../../icons"
import { routes } from '../../routes'

//import { setNameSearchPersonList } from '../../store/reducers/PersonsListSearchReducer'
import './styles.sass'

export const PersonListSearch = ({ formSet, formSearch,  setArrayPerson}) => {
    
    const intl = useIntl()
    const navigate = useNavigate()
   // const dispatch = useDispatch()
    const [form, setForm] = useState({
        fio: '',
        sort: 'fioUp'
    })
    
    const [isToggleListOpened, setIsToggleListOpened] = useState<boolean>(false)
    
    //const choosenPersonList: Person[] = useSelector( (state: any) => (state.choosenPersonList) )

    return (
        <div
            className="person-list-search"
        >
            <div
                className='person-list-search__left-side'
            >
                <div 
                    className='person-list-search__left-side__search-input'
                >
                    <SearchLoupeIcon/>
                    <input
                        type='text'
                        onChange={event => {
                        setArrayPerson([])
                          formSet({...formSearch, name: event.target.value, page: 0})  
                        }}
                        placeholder={intl.formatMessage({id: 'persons_list_search_name_placeholder'})}
                    />
                </div>
                
                <div
                    className='person-list-search__left-side__buttons'
                >
                    <div
                        className='white-button'
                        onClick={() => navigate(routes.createPerson)}
                    >
                        <FormattedMessage id='persons_list_search_new_person'/>
                    </div>
                 {/*    <div
                        className={`white-button ${choosenPersonList.length===0 && 'disabled-button'}`}
                        onClick={ () => {
                            if (choosenPersonList.length!==0 ) {
                                navigate(routes.createPattern)
                            }
                        }}
                    >
                        <FormattedMessage id='persons_list_search_create_pattern'/>
                    </div> */}
                </div>

            </div>

            <div
                className="search-filter-consultant-wrapper__list-wrapper"
            >
                <span className="Sort__panel__span">
                    <FormattedMessage id='search_filter_consultation_option' />
                </span>

                <div
                    className='search-filter-consultant-wrapper__list-wrapper__top-side'
                    onClick={() => {
                        if (isToggleListOpened) {
                            setIsToggleListOpened(false)
                        } else {
                            setIsToggleListOpened(true)
                        }
                    }}
                >
                    <div>
                        <FormattedMessage id={'search_filter_persons_' + form.sort.replace('Number','') +  '_placeholder'} />
                    </div>
                    <div
                        className={`search-filter-consultant-wrapper__list-wrapper__top-side__icon 
                            ${isToggleListOpened ? 'rotated' :''}    
                        `}
                    >
                        <ToggleListSmallArrowIcon />
                    </div>
                </div>

                {isToggleListOpened &&
                    <div
                        className={`search-filter-consultant-wrapper__list-wrapper__toggle-list 
                        `}
                    >   
                        {
                            form.sort !== 'birthdayUp'
                            &&
                            <div
                                onClick={() => {
                                    localStorage.setItem('sort', 'birthday,desc')
                                    setIsToggleListOpened(false)
                                    setForm(({...form, sort: 'birthdayUp'}))
                                    setArrayPerson([])
                                    formSet({...formSearch, sort: 'birthday,asc', page: 0})
                                 
                                    
                                 
                                        
                                     }}
                                    
                                   
                                  
                                  
                                
                            >   
                                <FormattedMessage id={'search_filter_persons_birthdayUp_placeholder'} />
                            </div>
                        }
                         {
                            form.sort !== 'birthdayDown'
                            &&
                            <div
                                onClick={() => {
                                    localStorage.setItem('sort', 'birthday,asc')
                                    setIsToggleListOpened(false)
                                    setArrayPerson([])
                                    setForm(({...form, sort: 'birthdayDown'}))
                                    formSet({...formSearch, sort: 'birthday,desc', page: 0})
                                 
                                  
                                }}
                            >   
                                <FormattedMessage id={'search_filter_persons_birthdayDown_placeholder'} />
                            </div>
                        }
                        {
                            form.sort !== 'fioUp'
                            &&
                            <div
                                onClick={() => {
                                    localStorage.setItem('sort', 'customName,desc')
                                    setIsToggleListOpened(false)
                                    setForm({...form, sort: 'fioUp'})
                                    setArrayPerson([])
                                    formSet({...formSearch, sort: 'customName,asc', page: 0})
                                   
                             
                                }}
                            >
                                <FormattedMessage id={'search_filter_persons_fioUp_placeholder'} />
                            </div>
                        }
                         {
                            form.sort !== 'fioDown'
                            &&
                            <div
                                onClick={() => {
                                    localStorage.setItem('sort', 'customName,asc')
                                    setIsToggleListOpened(false)
                                    setArrayPerson([])
                                    setForm({...form, sort: 'fioDown'})
                                  
                                    formSet({...formSearch, sort: 'customName,desc', page: 0})
                                   
                                }}
                            >
                                <FormattedMessage id={'search_filter_persons_fioDown_placeholder'} />
                            </div>
                        }
                    </div>
}
            </div>
        </div>
    )
}