import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ChoosenCheckMarkIcon, EmptyCheckMarkIcon } from "../../../icons";
import { MeasurementInfo } from "../../../interfaces/MeasurementInfo";
import { addMeasurementsListToConsultation } from "../../../store/reducers/CurrentConsultationReducer";
import { addMeasurementToConsultutationList } from "../../../store/reducers/MeasurementConsultationListReducer";
import { getMeasurementsList } from "../../../store/reducers/MeasurementsListReducer";
import { closeModal } from "../../../store/reducers/ModalWindowsReducer";
import "./styles.sass";

export const AddMeasurementToConsultationModal = (props: {
  choosenList?: any[];
}) => {
  const dispatch = useDispatch();

  const choosenMeasurementsList = useSelector(
    (state: any) => state.measurementConsultationList
  );

  const allMeasurementsList = useSelector(
    (state: any) => state.measurementsList
  );

  const [choosenMeasurementIDList, setChoosenMeasurementIDList] = useState<
    string[]
  >([]);

  const [choosenInModalMeasurementsList, setChoosenInModalMeasurementsList] =
    useState<MeasurementInfo[]>([]);
  const [choosenInModalIDSList, setChoosenInModalIDSList] = useState<string[]>(
    []
  );

  useEffect(() => {
    dispatch(getMeasurementsList(0));

    let choosenIDList: string[] = [];

    if (props && props?.choosenList && props?.choosenList.length > 0) {
      props.choosenList.forEach((elem: MeasurementInfo) => {
        if (elem.id) {
          choosenIDList.push(elem.id);
        } else if (elem.measurementId) {
          choosenIDList.push(elem.measurementId);
        }
      });
    } else {
      choosenMeasurementsList.forEach((elem: MeasurementInfo) => {
        if (elem.id) {
          choosenIDList.push(elem.id);
        } else if (elem.measurementId) {
          choosenIDList.push(elem.measurementId);
        }
      });
    }

    setChoosenMeasurementIDList(choosenIDList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let pageNum = 0;
    const measurementsListWrapper: HTMLElement | null =
      window.document.getElementById("measurement-list-wrapper1");

    const handleScroll = () => {
      let scrollTop = measurementsListWrapper?.scrollTop || 0;
      let clientHeight = measurementsListWrapper?.clientHeight || 0;
      let scrollheight = measurementsListWrapper?.scrollHeight || 0;

      if (Math.ceil(scrollTop + clientHeight) >= Math.ceil(scrollheight)) {
        dispatch(getMeasurementsList(pageNum + 1));
        pageNum += 1;
      }
    };

    measurementsListWrapper?.addEventListener("scroll", handleScroll);

    return () =>
      measurementsListWrapper?.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="add-measurement-to-consultation-modal-wrapper">
      <div className="add-measurement-to-consultation-modal">
        <div className="add-measurement-to-consultation-modal__title">
          <FormattedMessage id="consultation_start_page_modal_title" />
        </div>

        <div
          className="add-measurement-to-consultation-modal__main-section"
          id="measurement-list-wrapper1"
        >
          <span className="add-measurement-to-consultation-modal__main-section__title">
            <FormattedMessage id="consultation_start_page_modal_main_section_choosen" />
            {choosenInModalIDSList.length}
            <FormattedMessage id="consultation_start_page_modal_main_section_measurements" />
          </span>

          <div className="add-measurement-to-consultation-modal__main-section__measurement-list">
            {allMeasurementsList &&
              allMeasurementsList.list.length > 0 &&
              allMeasurementsList.list.map((elem, index) => {
                if (!choosenMeasurementIDList.includes(elem.measurementId)) {
                  return (
                    <div
                      key={index}
                      style={
                        elem.stage != "COMPLETED"
                          ? { pointerEvents: "none", opacity: "0.5" }
                          : {}
                      }
                      className="measurement-modal-panel"
                      onClick={() => {
                        if (
                          choosenInModalIDSList.includes(elem.measurementId)
                        ) {
                          let indexOfChoosen: number =
                            choosenInModalIDSList.indexOf(elem.measurementId);
                          setChoosenInModalIDSList([
                            ...choosenInModalIDSList.slice(0, indexOfChoosen),
                            ...choosenInModalIDSList.slice(indexOfChoosen + 1),
                          ]);
                          setChoosenInModalMeasurementsList([
                            ...choosenInModalMeasurementsList.slice(
                              0,
                              indexOfChoosen
                            ),
                            ...choosenInModalMeasurementsList.slice(
                              indexOfChoosen + 1
                            ),
                          ]);
                        } else {
                          setChoosenInModalIDSList([
                            elem.measurementId,
                            ...choosenInModalIDSList,
                          ]);
                          setChoosenInModalMeasurementsList([
                            elem,
                            ...choosenInModalMeasurementsList,
                          ]);
                        }
                      }}
                    >
                      <div className="measurement-modal-panel__left-side">
                        {choosenInModalIDSList.includes(elem.measurementId) ? (
                          <ChoosenCheckMarkIcon />
                        ) : (
                          <EmptyCheckMarkIcon />
                        )}
                        <span className="measurement-modal-panel__left-side__title">
                          {elem.comment
                            ? elem.comment
                            : "Тут должно быть название"}
                        </span>
                      </div>

                      <div className="measurement-modal-panel__desc">
                        <div>
                          <span>
                            <FormattedMessage id="consultation_start_page_modal_measurement_panel_type" />
                          </span>
                          {elem.typeName}
                        </div>

                        <div>
                          <span>
                            <FormattedMessage id="consultation_start_page_modal_measurement_panel_date" />
                          </span>
                          {elem.creationDate.slice(8, 10) +
                            "." +
                            elem.creationDate.slice(5, 7) +
                            "." +
                            elem.creationDate.slice(0, 4)}
                        </div>
                        {/* 
                                                <div>
                                                    <span>
                                                        <FormattedMessage id='consultation_start_page_modal_measurement_panel_level' />
                                                    </span>
                                                    {
                                                        elem.completionStatus
                                                        ?
                                                        elem.completionStatus
                                                        :
                                                        ''
                                                    }
                                                </div> */}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>

        <div className="add-measurement-to-consultation-modal__control-panel">
          <div
            className="green-button"
            onClick={() => {
              if (
                props &&
                props?.choosenList &&
                props?.choosenList.length > 0
              ) {
                dispatch(
                  addMeasurementsListToConsultation(
                    choosenInModalMeasurementsList
                  )
                );
              } else {
                dispatch(
                  addMeasurementToConsultutationList(
                    choosenInModalMeasurementsList
                  )
                );
              }
              dispatch(closeModal());
            }}
          >
            <FormattedMessage id="consultation_start_page_modal_add" />
          </div>

          <div className="white-button" onClick={() => dispatch(closeModal())}>
            <FormattedMessage id="consultation_start_page_modal_cancel" />
          </div>
        </div>
      </div>
    </div>
  );
};
