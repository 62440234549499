import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import "./styles.sass";
import { Consultation } from "../../../interfaces/Consultation";

import { User } from "../../../interfaces/User";
export const PartnerInfoStatistic = (isActivate) => {
  const currentPerson = useSelector(
    (state: any) => state.currentMeasurementStatisticPerson
  );

  const data = useSelector(
    (state: any) => state.measurementStatistic
  ).personalSpaceModes;

  const sorting = useSelector((state: any) => state.mainGraphicMode);

  const [text, setText] = useState<string>("");
  const consultation: Consultation | null = useSelector(
    (state: any) => state.currentConsultation
  );
  const accountData: User = useSelector((state: any) => state.user);
  const [additionalText, setAdditionalText] = useState<any>("");
  const getAdditionalText = async (url) => {
    const res = await fetch(url);
    const txt = await res.json();
    setAdditionalText(txt.text);
  };
  const asyncFn = async (url) => {
    const response = await fetch(url);
    if (response.status === 200) {
      const result = await response.json();
      setText(result.singlePartner);
    }
  };
  
  useEffect(() => {
    if (
      sorting.mode === 0 &&
      currentPerson &&
      currentPerson.relationshipDescriptionLink &&
      currentPerson.relationshipDescriptionLink.length > 0
    ) {
      asyncFn(currentPerson.relationshipDescriptionLink);
    } else if (sorting.mode === 1) {
      // @ts-ignore
      switch (sorting.sort) {
        case "0":
          getAdditionalText(data.meEasyHard.main);
          return;
        case "1":
          getAdditionalText(data.meEasyHard.right);
          return;

        case "2":
          getAdditionalText(data.meEasyHard.left);
          return;
        default:
          return;
      }
    } else if (sorting.mode === 2) {
      switch (sorting.sort) {
        case "0":
          getAdditionalText(data.withMeEasyHard.main);
          return;
        case "1":
          getAdditionalText(data.withMeEasyHard.top);
          return;

        case "2":
          getAdditionalText(data.withMeEasyHard.bottom);
          return;
        default:
          return;
      }
    } else if (sorting.mode === 3) {
      getAdditionalText(data.behaviorStrategy.main);
    } else if (sorting.mode === 4) {
      getAdditionalText(data.socialAdaptation.main);
    } else if (sorting.mode === 5) {
      console.log(data.microanalysis)
      // @ts-ignore
      switch (sorting.sort) {
        case "0":
          getAdditionalText(data.microanalysis.main);
          return;
        case "1":
          getAdditionalText(data.microanalysis.top.right);
          return;
        case "2":
          getAdditionalText(data.microanalysis.top.left);
          return;
        case "3":
          getAdditionalText(data.microanalysis.bottom.left);
          return;
        case "4":
          getAdditionalText(data.microanalysis.bottom.right);
          return;
        case "5":
          getAdditionalText(data.microanalysis.center.right);
          return;
        case "6":
          getAdditionalText(data.microanalysis.top.center);
          return;
        case "7":
          getAdditionalText(data.microanalysis.center.left);
          return;
        case "8":
          getAdditionalText(data.microanalysis.bottom.center);
          return;
            case "9":
          getAdditionalText(data.microanalysis.center.center);
          return;
        default:
          return;
      }
    } else {
      setText("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPerson, sorting]);

  return (
    <div
      className={`${
        !isActivate.isActivate && sorting.mode != 0 && "nonShow"
      } partner-info-statistic`}
    >
      {sorting.mode !== 0 ? (
        <span
          className="partner-info-statistic__additional-text"
          dangerouslySetInnerHTML={{ __html: additionalText || "" }}
        />
      ) : (
        text.length > 0 && (
          <>
            <div className="column big__column">
              <div className="partner-info-statistic__name">
                <div>
                  <FormattedMessage id="measurement_partner_info_name" />
                </div>
                <span>
                  {location.pathname.includes("measurement-watcher") &&
                  consultation?.consultantId == accountData.id
                    ? currentPerson?.person?.customName.slice(0, 3) + "..." ||
                      ""
                    : currentPerson?.person?.customName || ""}
                </span>
              </div>
              <div className="partner-info-statistic__values">
                <div>
                  Тип Отношений:
                  <span>{currentPerson.person.role}</span>
                </div>
              </div>
              <div className="partner-info-statistic__values">
                <div>
                  <div>
                    <FormattedMessage id="measurement_partner_info_me_to_partner" />
                  </div>
                  <span>
                    {currentPerson?.outerRating === "0"
                      ? "0"
                      : currentPerson?.outerRating
                          .replace("+", "Л")
                          .replace("-", "T")
                          .split("")
                          .reverse()
                          .join("")}
                  </span>
                </div>

                <div>
                  <div>
                    <FormattedMessage id="measurement_partner_info_partner_to_me" />
                  </div>
                  <span
                  //style={{marginLeft: '4px'}}
                  >
                    {currentPerson?.innerRating === "0"
                      ? "0"
                      : currentPerson?.innerRating
                          .replace("+", "Л")
                          .replace("-", "T")
                          .split("")
                          .reverse()
                          .join("")}
                  </span>
                </div>
              </div>
              <div
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <div>
                  <FormattedMessage id="measurement_partner_info_comment_title" />
                </div>
                <span>
                  {currentPerson?.comment &&
                  currentPerson?.comment.length > 0 ? (
                    currentPerson?.comment
                  ) : (
                    <FormattedMessage id="measurement_partner_info_comment" />
                  )}
                </span>
              </div>
            </div>

            <div className="column noMarginForP">
              <div>
                <FormattedMessage id="measurement_partner_info_state" />
              </div>
              <span dangerouslySetInnerHTML={{ __html: text }}></span>
            </div>
          </>
        )
      )}
    </div>
  );
};
