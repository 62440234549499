import { useSelector, useDispatch } from "react-redux";
import "./styles.sass";

import { StepBar } from "../../components/StepBar";
import { BottomControlPanel } from "../../components/BottomControlPanel/index";
import { routes } from "../../routes";

import { nextStepBar } from "../../store/reducers/StepBarReducer";
import { changeHeaderTitle } from "../../store/reducers/HeaderTitleReducer";

//import { FormattedMessage } from "react-intl"
import { useEffect } from "react";

import { RatingPanel } from "../../components/RatingPanel/index";
import { ProgressRatingPanel } from "../../components/ProgressRatingPanel/index";
import { FormattedMessage } from "react-intl";
import { BlackArrowIcon } from "../../icons";
import {
  setCurrentRatingMember,
  tryToSetPersonRate,
} from "../../store/reducers/CurrentRatingPersonReducer";
import { useState } from "react";

import store from "../../store";
import { getMeasurementImages } from "../../store/reducers/MeasurementImagesReducer";
import { LeftSideInstruction } from "../../components/LeftSideInstruction";

const navigateNext = (prop) => {
  const personArr = JSON.parse(localStorage.measurement).rateMembers;
  store.dispatch(tryToSetPersonRate(prop.type));

  if (
    personArr.slice(1) === 1 ||
    store.getState().currentRatingPerson.position ===
      personArr.slice(1).length + 1
  ) {
    if (prop.type === "ME_TO_PERSON") {
      return routes.personToMeInstruction;
    } else {
      return routes.measurementEnd;
    }
  } else {
    store.dispatch(
      setCurrentRatingMember(
        personArr.slice(1)[store.getState().currentRatingPerson.position - 1]
      )
    );
    if (prop.type === "ME_TO_PERSON") {
      return routes.meToPersonMeasurementRating;
    } else {
      return routes.personToMeMeasurementRating;
    }
  }
  return "o";
};

export const PersonRatingContainer = (prop: { type: string }) => {
  const dispatch = useDispatch();
  const [mobilButton, setMobileButton] = useState(true);
  const [nextRoute, setNextRoute] = useState<string>("");

  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

  const personList = useSelector(
    (state: any) => state.personMeasurementList
  ).slice(1);
  const currentMember = useSelector((state: any) => state.currentRatingPerson);
  const canRateNextPerson = useSelector(
    (state: any) => state.canRateNextPerson
  );

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        currentHeaderTitle: "header_title_main",
        currentHeaderAdditionalTitle: "header_title_subtitle_measurement_type",
      })
    );
    setNextRoute("");
    if (prop.type === "ME_TO_PERSON") {
      dispatch(nextStepBar(4));
    } else {
      dispatch(nextStepBar(6));
    }

    dispatch(getMeasurementImages());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMember]);
  useEffect(() => {
    const arrSmall = JSON.parse(localStorage.measurement);
    if (prop.type === "ME_TO_PERSON") {
      store.dispatch(
        setCurrentRatingMember(
          arrSmall.rateMembers.slice(1)[arrSmall.rateMembers[0].rates.length]
        )
      );
    } else {
      for (let i = 1; i < arrSmall.rateMembers.length; i++) {
        if (arrSmall.rateMembers[i].rates.length == 0) {
          store.dispatch(setCurrentRatingMember(arrSmall.rateMembers[i]));
          return;
        }
      }
    }
  }, [prop.type]);
  console.log(store.getState().measurement);
  useEffect(() => {
    history.pushState(null, document.title);
    window.addEventListener("popstate", function () {
      history.pushState(null, document.title);
    });
  }, []);
  const navigateBack = () => {};

  return (
    <>
      <ProgressRatingPanel length={personList.length} />

      <div
        className={`rating-measurement-container ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
      >
        <div className="rating-measurement-container__title-wrapper">
          <span className="rating-measurement-container__title-wrapper__title">
            {store.getState().currentRatingPerson.position - 1}{" "}
            <span>из {personList.length}</span>
          </span>

          {document.body.clientWidth > 640 ? (
            <StepBar steps={8} />
          ) : (
            <>
              <div className="rating-measurement-container__title-wrapper__measurement-type">
                <span className="type">
                  <FormattedMessage id="header_title_subtitle_measurement_type" />
                  {store.getState().measurement.typeName}
                </span>

                <span>
                  <FormattedMessage id="header_title_subtitle_measurement_date" />
                  {store.getState().measurement.creationDate.slice(8, 10) +
                    "." +
                    store.getState().measurement.creationDate.slice(5, 7) +
                    "." +
                    store.getState().measurement.creationDate.slice(0, 4)}
                </span>
              </div>

              <div className="horizontal_line" />
            </>
          )}
        </div>

        <div className="rating-measurement-container__description-wrapper">
          <div className="rating-measurement-container__description-wrapper__definition">
            {prop.type === "ME_TO_PERSON" ? (
              <span>
                <FormattedMessage id="measurement_rating_title_me_to_person" />

                <BlackArrowIcon />
              </span>
            ) : (
              <span>
                <FormattedMessage id="measurement_rating_title_person_to_me" />

                <div style={{ transform: "rotate(180deg)" }}>
                  <BlackArrowIcon />
                </div>
              </span>
            )}
          </div>

          <div className="rating-measurement-container__description-wrapper__person">
            <span className="name">
              {store.getState().currentRatingPerson.member.customName}
            </span>
            <span className="role">
              {store.getState().currentRatingPerson.member.role}
            </span>
            <span className="role">
              {store.getState().currentRatingPerson.comment}
            </span>
          </div>
        </div>

        <div className="rating-measurement-container__rating-panels">
          <RatingPanel setMobileButton={setMobileButton} />
        </div>
      </div>

      <LeftSideInstruction
        text={
          prop.type === "ME_TO_PERSON"
            ? JSON.parse(localStorage.measurementTypesN).meToPersonInstruction
            : JSON.parse(localStorage.measurementTypesN).personToMeInstruction
        }
      />

      <BottomControlPanel
        nextPage={nextRoute}
        previousPage={routes.main}
        backFunc={navigateBack}
        useNextFunc2={store.getState().currentRatingPerson.rate ? true : false}
        nextFunc2={() => navigateNext(prop)}
        isNextButtonDisabled={
          document.body.clientWidth > 640
            ? !canRateNextPerson || !store.getState().currentRatingPerson.rate
            : mobilButton
        }
        mobileChange={setMobileButton}
      />
    </>
  );
};
