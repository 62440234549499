import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { GET_MEASUREMENTS_LIST, setMeasurementsList } from "../store/reducers/MeasurementsListReducer";
import { routes } from "../routes";

import store from '../store'

async function asyncGetMeasurementsList(page: number) {
    
    try {

        const response = await fetch(
            apiUrl + `/measurement/by-user/search?page=${page}&typeId=${localStorage.typeId}&before=${localStorage.before}&after=${localStorage.after}&size=20`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    //Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                }
            }
        )

        if (response.status === 200 || response.status === 201) {

            const result = await response.json()

            return result
        } else if (response.status === 401) {
           
            location.assign(routes.login)
            
        } else {
            return null
        }
    } catch(error) {}
}

function* GetMeasurementsListWorker(action) {

    if (action.payload > store.getState().measurementsList.page || action.payload===0) {
       
        const data = yield call(asyncGetMeasurementsList, action.payload)

        if (data && data.length && data.length > 0) {
            yield put(setMeasurementsList({list: data, page: action.payload}))
        } else if (action.payload == 0){
            yield put(setMeasurementsList({list: [], page: 0}))
        }

    }
}

export function* GetMeasurementsListWatcher() {
    yield takeEvery(GET_MEASUREMENTS_LIST, GetMeasurementsListWorker)
}