import { call, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { FIND_FILTER_CONSULTANTS_LIST } from "../store/reducers/ConsultantsListReducer";

import store from '../store'
import { routes } from "../routes";

async function asyncFindFilterConsultantsList(form: any) {
    try {
        const page = store.getState().consultantsList.page

        const response = await fetch(
            apiUrl + `/consultants?page=${page}&sort=consultantInfo.${form.sort},asc`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                }
            }
        )
        
        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(err) {}
}

function* FindFilterConsultantsListWorker(action) {
    yield call(asyncFindFilterConsultantsList, action.payload)
}

export function* FindFilterConsultantsListWatcher() {
    yield takeEvery(FIND_FILTER_CONSULTANTS_LIST, FindFilterConsultantsListWorker)
}