import { ActivatedPackage } from '../../interfaces/ActivatedPackage'

export const GET_ACCOUNT_SERVICES = 'GET_ACCOUNT_SERVICES'
export const SET_ACCOUNT_SERVICES = 'SET_ACCOUNT_SERVICES'

const initialState: ActivatedPackage[] = []

export const AccountServicesReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_ACCOUNT_SERVICES:
            return action.payload

        default:
            return state
    }
}

export const tryToGetAccountServices = () => ({type: GET_ACCOUNT_SERVICES})
export const setAccountServices = (payload: ActivatedPackage[]) => ({type: SET_ACCOUNT_SERVICES, payload})