
export enum Gender {
    MALE="Male",
    FEMALE='Female',
    NONE=''
}

export interface Person {
    id?: string;
    customName?: string;
    name: string;
    patronymic?: string;
    surname?: string;
    role: string;
    gender: Gender;
    birthday: string;
    canDeleted?: boolean;
    updated?: boolean;
    deletable?: boolean;
}