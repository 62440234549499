import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ChatControlPanel } from "../../components/ChatComponents/ChatControlPanel";
import {
  DeleteBlackIcon,
  SmallGreenPlusIcon,
  ThreeLinesSlidersIcon,
} from "../../icons";
import { Consultation, Status } from "../../interfaces/Consultation";
import { User } from "../../interfaces/User";
import {
  closeModal,
  showAddMeasurementToConsultationModal,
  showDeleteMeasurementConsultationModal,
  SHOW_CANCEL_CONSULTATION_MODAL,
  SHOW_CONSULTATION_CHAT_CANCEL_VALUE_MODAL,
  SHOW_CONSULTATION_CHAT_SET_VALUE_MODAL,
  SHOW_DELETE_MEASUREMENT_CONSULTATION_MODAL,
} from "../../store/reducers/ModalWindowsReducer";

import { ConsultationSetValueModal } from "../../components/ChatComponents/ConsultationSetValueModal/index";
import { DeleteMeasurementConsultationModal } from "../../components/ChatComponents/DeleteMeasurementConsultationModal/index";

import "./styles.sass";

import { RejectConsultationModal } from "../../components/ChatComponents/RejectConsultationModal";

import { AddMeasurementToConsultationModal } from "../../components/ConsultationUserComponents/AddMeasurementToConsultationModal/index";
import { Link } from "react-router-dom";
//import { routes } from "../../routes"
import { ConsultationCancelValueModal } from "../../components/ChatComponents/ConsultationCancelValueModal";
import { MessagesList } from "../../components/ChatComponents/MessagesList";

import { getAllConsultationMessages } from "../../store/reducers/ConsultationChatReducer";
import { getCurrentConsultation } from "../../store/reducers/CurrentConsultationReducer";

// const titles = {
//     WAITING_REVIEW: <FormattedMessage id='consultation_chat_title_type_waiting' />,
//     CONFIRMED: <FormattedMessage id='consultation_chat_title_type_paying' />
// }

export const ConsultationChatContainer = ({
  numberOfPage,
  setNumberOfPage,
}) => {
  const dispatch = useDispatch();
  //  const navigate = useNavigate()

  const [arrayOfOldMessages, setArrayOfOldMessages] = useState<any>(
    useSelector((state: any) => state.consultationChat)
  );
  // const [numberOfPage, setNumberOfPage] = useState(1);

  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

  const accountData: User = useSelector((state: any) => state.user);
  const consultation: Consultation | null = useSelector(
    (state: any) => state.currentConsultation
  );

  const [isToggleMeasurementsListOpened, setIsToggleMeasurementsListOpened] =
    useState<boolean>(false);

  const showMeasurementModal = useSelector((state: any) => state.modals);

  const isRejectingSuccess = useSelector(
    (state: any) => state.rejectConsultation
  );

  // const getMeasurementsListHeight = (length: number) => {
  //     if (length%2===0 ) {
  //         return '64px'
  //     }
  //     else {
  //         return 60*Math.floor(length/2)+'px'
  //     }
  // }

  useEffect(() => {
    if (isRejectingSuccess) {
      dispatch(closeModal());

      // navigate(routes.consultations)
    } else if (
      accountData &&
      consultation &&
      accountData.id &&
      consultation.consultantId
    ) {
      dispatch(getAllConsultationMessages());
      dispatch(getCurrentConsultation(consultation.id));
      setNumberOfPage(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData, isRejectingSuccess]);

  return (
    <>
      <div
        className={`consultation-chat-wrapper ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
      >
        {/*   <div
                    className="consultation-chat-wrapper__title-wrapper"
                >   
                    <div>
                        <span>
                            <FormattedMessage id='consultation_chat_title_consultation' />
                            {
                                consultation
                                &&
                                titles[consultation.status]
                            }
                        </span>
                        <div>

                        </div>
                    </div>
                    <span>
                        {
                            accountData?.id !== consultation?.senderId
                            ?
                            consultation?.senderName
                            :
                            consultation?.adresseeName
                        }
                    </span>
                </div> */}

        {consultation && document.body.clientWidth <= 640 && (
          <div
            onClick={() => {
              if (isToggleMeasurementsListOpened) {
                setIsToggleMeasurementsListOpened(false);
              } else {
                setIsToggleMeasurementsListOpened(true);
              }
            }}
            className="consultation-chat-wrapper__title-wrapper__measurements-title"
          >
            <ThreeLinesSlidersIcon />
            <FormattedMessage id="consultation_chat_measurements_title" />
          </div>
        )}

        <div
          className={`
                        consultation-chat-wrapper__measurements-wrapper 
                    `}
          // style={{
          //     height:
          //         (consultation && (document.body.clientWidth >640) )
          //         ?
          //         getMeasurementsListHeight(consultation?.measurements?.length)
          //         :
          //         (
          //             (consultation && (document.body.clientWidth <= 640) && !isToggleMeasurementsListOpened)
          //             ?
          //             'auto'
          //             :
          //             'auto'
          //         )
          // }}
        >
          {" "}
          <div>
            {accountData && accountData?.id != consultation?.senderId && (
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "18px",
                  marginRight: "20px",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Замеры:
              </div>
            )}
          </div>
          {consultation &&
            consultation?.measurements &&
            consultation.measurements.map((elem: any, index) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "20px",
                  position: "relative",
                }}
                key={index}
              >
                <Link
                  to={{
                    pathname: "/measurement-watcher/" + elem.id,
                  }}
                  target={document.body.clientWidth > 640 ? "_blank" : ""}
                  rel="noopener noreferrer"
                  key={index}
                  className="consultation-chat-wrapper__measurements-wrapper__panel"
                  style={
                    {
                      //width: (accountData?.id === consultation?.adresseeId && index + 1 === consultation?.measurements.length) ? '100%' : 'fit-content'
                    }
                  }
                >
                  {elem.type == "PERSONAL" ? (
                    <div className="measInfo">
                      <div>
                        <span>
                          <b>Тип замера:</b> {elem.typeName}
                        </span>

                        <span>
                          <b>Дата создания:</b> {elem.creationDate}{" "}
                        </span>
                        <span>
                          <b>Комментарий:</b>{" "}
                          {elem.comment != null ? elem.comment : "Нет"}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="measInfo">
                      <div>
                        <span>Групповой замер</span>
                      </div>
                    </div>
                  )}
                  <span>{elem.creationDate}</span>
                </Link>
                {accountData && accountData?.id === consultation?.senderId && (
                  <div
                    className="delete_measurement"
                    onClick={() =>
                      dispatch(showDeleteMeasurementConsultationModal(elem))
                    }
                  >
                    <DeleteBlackIcon />
                  </div>
                )}
              </div>
            ))}
          {accountData?.id !== consultation?.adresseeId && (
            <div
              className="consultation-chat-wrapper__measurements-wrapper__add-measurement"
              onClick={() => {
                dispatch(showAddMeasurementToConsultationModal());
              }}
            >
              <FormattedMessage id="consultation_chat_measurements_add" />
              <SmallGreenPlusIcon />
            </div>
          )}
        </div>

        <MessagesList
          arrayMessages={arrayOfOldMessages}
          setArrayMessages={setArrayOfOldMessages}
          numberPage={numberOfPage}
          setNumber={setNumberOfPage}
        />

        {(consultation?.status === Status.WAIT_USER_CONFIRM ||
          consultation?.status === Status.APPROVED) && (
          <div className="consultation-chat-wrapper__bottom-state">
            <span className="consultation-chat-wrapper__bottom-state__title">
              <FormattedMessage id="consultation_chat_cost_created" />
              {consultation?.cost}
              <FormattedMessage id="consultation_chat_cost_created_rub" />
            </span>
            <span className="consultation-chat-wrapper__bottom-state__desc">
              <FormattedMessage id="consultation_chat_waiting_cost" />
            </span>
          </div>
        )}
        {consultation?.status === Status.EXPIRATION && (
          <div
            style={{
              position: "fixed",
              bottom: "30px",
              right: "40px",
              fontSize: "18px",
              zIndex: "9999",
              fontWeight: "bold",
            }}
          >
            Консультация закрыта по истечении времени
          </div>
        )}
        <ChatControlPanel
          setArrayMessages={setArrayOfOldMessages}
          setNumber={setNumberOfPage}
        />
      </div>

      {showMeasurementModal.modal ===
        SHOW_CONSULTATION_CHAT_SET_VALUE_MODAL && <ConsultationSetValueModal />}

      {showMeasurementModal.modal ===
        SHOW_DELETE_MEASUREMENT_CONSULTATION_MODAL && (
        <DeleteMeasurementConsultationModal />
      )}

      {showMeasurementModal.modal ===
        SHOW_CONSULTATION_CHAT_CANCEL_VALUE_MODAL && (
        <ConsultationCancelValueModal />
      )}

      {showMeasurementModal.modal === SHOW_CANCEL_CONSULTATION_MODAL && (
        <RejectConsultationModal />
      )}
      {showMeasurementModal.modal === "ADD_MEASUREMENT_TO_CONSULTATION" && (
        <AddMeasurementToConsultationModal
          choosenList={consultation?.measurements}
        />
      )}
    </>
  );
};
