import { MeasurementType } from "../../interfaces/MeasurementType";

export const TRY_TO_GET_MEASUREMENTS_TYPE_LIST = 'TRY_TO_GET_MEASUREMENTS_TYPE_LIST'
export const SET_MEASUREMENTS_TYPE_LIST = 'SET_MEASUREMENTS_TYPE_LIST'

const initialState: MeasurementType[] = []

export const MeasurementTypeListReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_MEASUREMENTS_TYPE_LIST:
            return action.payload

        default:
            return state
    }
}

export const tryToGetMeasurementsTypeList = () => ({type: TRY_TO_GET_MEASUREMENTS_TYPE_LIST})
export const setMeasurementsTypeList = (payload: MeasurementType[]) => ({type: SET_MEASUREMENTS_TYPE_LIST, payload})