export const routes = {
  main: "/start-measurement",
  login: "/login",
  signup: "/signup",
  loginRecovery: "/login-recovery",
  articles: "/articles",

  // процесс проведения замера
  startMeasurement: "/start-measurement",
  measurementInstruction: "/measurement-instruction",
  makingMeasurementPersonsList: "/measurement-person-list",

  meToPersonInstruction: "/measurement-me-to-person-instruction",
  meToPersonMeasurementRating: "/measurement-me-to-person-rating",

  personToMeMeasurementRating: "/measurement-person-to-me-rating",
  personToMeInstruction: "/measurement-person-to-me-insctruction",
  measurementEnd: "/measurement-end",

  // страницы статистики по замеру
  measurementStatistic: "/measurements-statistic/:id",
  measurementStatisticGroup: "/measurements-statistic-group/:id",

  // список замеров
  measurementsList: "/measurements-list",

  // консультация - пользователь
  startConsultation: "/start-consultation",
  startConsultationFromConsultants: "/start-consultation-consultants",
  choosePersonConcultation: "/choose-person-consultation",
  consultationInfo: "/info-consultation",
  confirmConsultation: "/confirm-consultation",
  consultationChat: "/chat-consultation",
  endConsultation: "/end-consultation",
  consultationReview: "/review-consultation",
  consultationForConsultant: "/consultation-for-consultant",
  // мой аккаунт
  myAccount: "/my-account",
  notifications: "/notifications",
  notificationsMessages: "/notificationsMessages",
  socialNetworks: "/social-networks",
  myServices: "/my-services",
  finances: "/finances",

  consultations: "/consultations",
  consultants: "/consultants",
  persons: "/persons-list",
  consultant: "/consultant",
  createPerson: "/create-person",
  changePerson: "/change-person",

  createPattern: "/create-pattern",

  // templates
  templates: "/templates",
  createTemplates: "/create-templates",
  // страница подтверждения регистрации
  confirm_register: "/confirm-register",
  confirmEmailChange: "/confirmEmailChange",
  // документы
  agreement: "/agreement",
  contacts: "/contacts",
  politics: "/politics",
  offices: "/offices",
  services: "/services",
  measurementWatcher: "/measurement-watcher/:id",
  tarifs: "/tarifs",
  tariffsInfo: "/tariffsInfo",
  payment: "/payment",
  paymentFail: "/payment/fail",
  paymentSuccess: "/payment/success",
  groupRating: "/groupRating",
  GroupStartInstruction: "/groupStartInstruction",
  GroupEndInstruction: '/GroupEndInstruction'
};
