export const SET_IS_CREATING_MEASUREMENT_FOR_CONSULTATION = 'SET_IS_CREATING_MEASUREMENT_FOR_CONSULTATION'
export const DELETE_IS_CREATING_MEASUREMENT_FOR_CONSULTATION = 'DELETE_IS_CREATING_MEASUREMENT_FOR_CONSULTATION'

let initialState: boolean = false

if (localStorage.IS_CREATING_MEASUREMENT_FOR_CONSULTATION === 'true') {
    initialState = true
}

export const CreatingMeasurementStateReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_IS_CREATING_MEASUREMENT_FOR_CONSULTATION:
            localStorage.setItem('IS_CREATING_MEASUREMENT_FOR_CONSULTATION', 'true')
            return true

        case DELETE_IS_CREATING_MEASUREMENT_FOR_CONSULTATION:
            localStorage.setItem('IS_CREATING_MEASUREMENT_FOR_CONSULTATION', 'false')
            return false

        default:
            return state
    }
}

export const setIsCreatingMeasurementForConsultation = () => ({type: SET_IS_CREATING_MEASUREMENT_FOR_CONSULTATION})
export const deleteIsCreatingMeasurementForConsultation = () => ({type: DELETE_IS_CREATING_MEASUREMENT_FOR_CONSULTATION})

