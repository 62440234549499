export const SET_HEADER_TITLE = 'SET_HEADER_TITLE'

interface HeaderTitleStateInterface {
    currentHeaderTitle: string;
    currentHeaderAdditionalTitle: string
}

const initialState: HeaderTitleStateInterface = {
    currentHeaderTitle: 'header_title_choose_measurement_type',
    currentHeaderAdditionalTitle: '',
}

export const HeaderTitleReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_HEADER_TITLE: 
            return {
                currentHeaderTitle: action.payload.currentHeaderTitle,
                currentHeaderAdditionalTitle: action.payload.currentHeaderAdditionalTitle
            }

        default:
            return state
    }
}

export const changeHeaderTitle = (payload: HeaderTitleStateInterface) => ({type: SET_HEADER_TITLE, payload})