import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { User } from "../interfaces/User";
import { routes } from "../routes";
import { setAccountMainData, TRY_TO_UPDATE_PHOTO } from "../store/reducers/AccountMainDataReducer";

const asyncUpdateAccountPhoto = async (formData: any) => {
    try {
        const response = await fetch( 
            apiUrl + '/users/update-photo/',
            {
                method: 'POST',
                headers: {
                    //'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: formData
            }
        )
        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(err) {}
}

function* UpdateAccountPhotoWorker(action) {
    const data: User | null = yield call(asyncUpdateAccountPhoto, action.payload)
    if (data && data.login) {
        yield put( setAccountMainData(data) )
    }
}

export function* UpdateAccountPhotoWatcher() {
    yield takeEvery(TRY_TO_UPDATE_PHOTO, UpdateAccountPhotoWorker)
}