export const SET_CURRENT_MEASUREMENT_STATISTIC_PERSON = 'SET_CURRENT_MEASUREMENT_STATISTIC_PERSON'

const initialState = null

export const CurrentMeasurementStatisticPersonReducer = (state= initialState, action) => {
    switch (action.type) {

        case SET_CURRENT_MEASUREMENT_STATISTIC_PERSON:
            return action.payload

        default: 
            return state
    }
}

export const setCurrentMeasurementStatisticPerson = (payload: any) => ({type: SET_CURRENT_MEASUREMENT_STATISTIC_PERSON, payload})