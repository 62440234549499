import { call, put, takeLatest } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import { TRY_TO_LOGIN, login, loginFailed } from "../store/reducers/LoginReducer";
import { checkIsAuthorized } from "../store/reducers/userReducer";

async function asyncLogin(form) {
    try {
        if (form !== undefined) {
            const response = await fetch(
                apiUrl + '/authenticate',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(form)
                }
            )
            
            const result = await response.json()
          
            return result
        }
        return false
    } catch (error) {}
}

function* loginWorker(action) {
    const data = yield call(asyncLogin, action.payload)
    
    if (data && data.id_token) {
        yield put(login({token: data.id_token, userData: action.payload}))
        location.assign(routes.startMeasurement)
    
        yield put(checkIsAuthorized())
    } else {
        yield put(loginFailed())
    }
    
}

export function* LoginWatcher() {
    yield takeLatest(TRY_TO_LOGIN, loginWorker)
}