import { useSelector } from "react-redux";
import "./styles.sass";




//import { FormattedMessage } from "react-intl"
import { useEffect } from "react";

import { GroupRatingPanel } from "../../components/GroupRatingPanel";

import { FormattedMessage } from "react-intl";
import { BlackArrowIcon } from "../../icons";

import { useState } from "react";


import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";


export const GroupPersonRatingContainer = (prop: { type: string }) => {
    const [num, setNum] = useState(0);
    const [choosenRatingPanel, setChoosenRatingPanel] = useState<number>(
      document.body.clientWidth > 640 ? 8 : 3
    );
  const navigate = useNavigate();
  const [mobilButton, setMobileButton] = useState(true);
  const [width, setWidth] = useState<string>('0%')

  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

 const [choosenRate, setChoosenRate] = useState<number>()
    
  
  useEffect(() => {
    history.pushState(null, document.title);
    window.addEventListener("popstate", function () {
      history.pushState(null, document.title);
    });
  }, []);
 
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('id');
  
  const [members, setMembers] = useState<any>([]);
  
  const [membersRate, setMembersRate] = useState<any>([])
  // const [currentPerson, setCurrentPerson] = useState()
  function setArrayRate (){
    setMembersRate([...membersRate, choosenRate])
  }
  const GroupDetails = () =>{
    fetch(process.env.REACT_APP_API_HOST + '/measurement/details/'+ myParam, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.token,
          },
    }).then((response)=>{
        return response.json()
    }).then((data)=> {
        setMembers(data.members)
    })
  }
 const [popOffice, setPopOffice] = useState(false)
  useEffect(()=>{
    GroupDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  useEffect(()=>{
    if (num == members.length && members.length>0){
      const sendArray : {}[] = [];
      for (let i = 0;i<members.length; i++){
        let obj = { position:'', rate:'', type:''};
        obj.position = members[i].position;
        obj.rate = membersRate[i];
       
        obj.type = 'ME_TO_PERSON';
        sendArray.push(obj);
      }
      fetch(process.env.REACT_APP_API_HOST + '/measurement/rates/'+ myParam,{
        method: 'POST',
         headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.token,
          },
          body: JSON.stringify(sendArray)
      }).then((response)=>{
        if (response.status == 200){
          navigate(routes.GroupEndInstruction + '?id=' + myParam)
        }
      })
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[num])
  useEffect(()=>{
    let i = (100/members.length*num).toString();
    setWidth(i)
  },[num, members.length])
  return (
    <>
    {popOffice && <div className="pop_add_office">
            <div className="pop_office_body">
            <p style={{marginBottom: '20px', textAlign: 'center'}}>Вы уверены , что хотете прервать проведение измерения? Данные не сохранятся</p>
        
            <div className="buttons_office">
                <div className="white-button" onClick={()=>{navigate(routes.measurementsList)}}>
                    Отменить
                </div>
                <div className="green-button" onClick={()=>{setPopOffice(false)}}>Продолжить</div>
            </div>
            </div>
            
        </div>}
      <div className={`progress-rating-panel-wrapper ${ isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}>
            <div 
                style={{width: width+'%'}}
            >
                <span className='percantage-wrapper'>
                    {Number(width).toFixed(0) + '%'}
                </span>
            </div>
        </div>
       
      <div
        className={`rating-measurement-container group_container ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
      >
            
        <div className="rating-measurement-container__title-wrapper">
          <span className="rating-measurement-container__title-wrapper__title">
            {num+1}{' '}
            <span>из {members.length}</span>
          </span>

        </div>

        <div className="rating-measurement-container__description-wrapper">
          <div className="rating-measurement-container__description-wrapper__definition">
            {prop.type === "ME_TO_PERSON" ? (
              <span>
                <FormattedMessage id="measurement_rating_title_me_to_person" />

                <BlackArrowIcon />
              </span>
            ) : (
              <span>
                <FormattedMessage id="measurement_rating_title_person_to_me" />

                <div style={{ transform: "rotate(180deg)" }}>
                  <BlackArrowIcon />
                </div>
              </span>
            )}

          </div>

          <div className="rating-measurement-container__description-wrapper__person">
            <span className="name">
              {members && members.length > 0 && members.length != num && members[num].fullName}
            </span>
         
          </div>
        </div>

        <div className="rating-measurement-container__rating-panels">
          <GroupRatingPanel setMobileButton={setMobileButton} setChoosenRate={setChoosenRate} choosenRatingPanel={choosenRatingPanel} setChoosenRatingPanel={setChoosenRatingPanel}/>
        </div>
      </div>

    

      <div className="bottom_buttons">
       
        <div className="white-button" onClick={() => setPopOffice(true)}>
          Отмена
        </div>
        <div className="green-button" onClick={() =>{setArrayRate();setNum(num+1);setMobileButton(true);if (document.body.clientWidth > 640){setChoosenRatingPanel(8)} else {setChoosenRatingPanel(3)}
          
          }} style={mobilButton ? {pointerEvents: 'none', opacity: '0.6'} : {}}>
          Далее
        </div>
      </div>
    </>
  );
};
