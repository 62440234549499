import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { MyAccountNavbar } from "../../components/MyAccountComponents/MyAccountNavbar"
import { GreenMailEnvelopeIcon, GreenPhoneIcon, MyAccountBirthdayInputIcon, MyAccountEmailInputIcon, MyAccountPhoneInputIcon, RedPhoneIcon, ToggleListSmallArrowIcon } from "../../icons"
import { User } from "../../interfaces/User"
import { tryToChangeAccountMainData, tryToGetAccountMainData } from "../../store/reducers/AccountMainDataReducer"
import { setAccountNavbarTitle } from "../../store/reducers/AccountNavbarReducer"

import { AccountChangePassword } from '../../components/MyAccountComponents/AccountChangePassword/index'
import { MyAccountPhoto } from '../../components/MyAccountComponents/MyAccountPhoto/index'

import '../../globalStyles/account.sass'
import { tryToGetCountriesList } from "../../store/reducers/CountriesListReducer"
import { tryToGetCitiesList } from "../../store/reducers/CitiesListReducer"
import { tryToChangeEmail } from "../../store/reducers/ChangeEmailReducer"

export const MyAccountContainer = () => {

    const intl = useIntl()
    const dispatch = useDispatch()

    const isNavbarShowed = useSelector( (state: any) => (state.navbar.isNavbarShowed))

    const accountData: User = useSelector( (state: any) => (state.accountMainData))

    const countries = useSelector( (state: any) => (state.countries))
    const cities = useSelector( (state: any) => (state.cities) )

    const [currentName, setCurrentName] = useState(accountData.fio)

    const [form, setForm] = useState<User>({
        name: accountData.name,
        surname: accountData.surname,
        patronymic: accountData.patronymic,
        phoneNumber: accountData.phoneNumber,
        gender: accountData.gender,
        birthDay: accountData.birthDay,
        region: accountData?.region?.indexOf(',') !== -1 ? accountData?.region?.slice(0, accountData?.region?.indexOf(',')) : accountData?.region || '',
        login: accountData.login
    })
    const [isChanged, setIsChanged] = useState<boolean>(false)
    const [changedEmail, setChangedEmail] = useState<string>(accountData?.login || '')
    const [changingEmailModal, setChangingEmailModal] = useState<boolean>(false)
    const [confirmPassword, setConfirmPassword] = useState<string>('')

    const [isGenderListOpened, setIsGenderListOpened] = useState<boolean>(false)
    const [isCountriesListOpened, setIsCountriesListOpened] = useState<boolean>(false)
    const [isCityToggleOpened, setIsCityToggleOpened] = useState<boolean>(false)
    
    const [cityList, setCityList] = useState<any[]>([])
    const [countryList, setCountriesList] = useState(countries)

    const [isCancelled, setIsCancelled] = useState<boolean>(false)

    const isEmailChanged = useSelector( (state: any) => (state.changeEmail))

    const [choosenCity, setChoosenCity] = useState<string>(accountData&& accountData.region && accountData?.region?.indexOf(',') !== -1 ? accountData?.region?.slice(accountData?.region?.indexOf(',')+2) : '')
    const [changed, setChanged] = useState<boolean>(false)

    useEffect( () => { 
        if (form && form.id && form.id.length>0 && cities.length > 0 && !isCancelled) {
            setCityList(cities)
        } 
        else if (changed) {
            dispatch( tryToGetAccountMainData() )
            setChanged(false)
        }
        else {
            if (!form.id || accountData?.id?.length===0) {
                dispatch( tryToGetAccountMainData() )
            } 
            dispatch( tryToGetCountriesList() )
            dispatch( setAccountNavbarTitle(0) )
            setForm({
                ...accountData, 
                region: accountData?.region?.indexOf(',') !== -1 ? accountData?.region?.slice(0, accountData?.region?.indexOf(',')) : accountData?.region || '', 
                birthDay: accountData.birthDay 
            })
            setCurrentName(accountData.fio)
            setCountriesList( countries )
            setIsChanged(false)
            setChangedEmail(accountData?.login || '')
            
            if (accountData &&accountData.region && accountData?.region.length > 0) {
                countries?.filter( (elem) => {
                    if (elem.name.toUpperCase().includes(accountData?.region?.slice(0, accountData?.region?.indexOf(',')).toUpperCase() )) {
                        dispatch( tryToGetCitiesList(elem.countryId) )
                    }
                })
                    
            }
            
            setChoosenCity(accountData&& accountData.region && accountData?.region?.indexOf(',') !== -1 ? accountData?.region?.slice(accountData?.region?.indexOf(',')+2) : '')
            setIsCancelled(false)
        }
        //return () => {dispatch( tryToGetAccountMainData() )}
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changed, accountData, cities, isCancelled])

    const [isNameValid, setIsNameValid] = useState<boolean>(false)
    const checkIsNameValid = (name: string) => {

        if (name && name !== accountData.fio) {
            if ( name.indexOf(' ') !== name.lastIndexOf(' ') 
                && 
                name.lastIndexOf(' ') > 1
                &&
                name.lastIndexOf(' ') - name.indexOf(' ') >= 1
            ) {
                setIsNameValid(true)
            } else {
                setIsNameValid(false)
            }
        } else if (name && name === accountData.fio) {
            setIsNameValid(false)
        }

    }

    const onRegionChange = (country: string) => {
        let newCountryList: any[] = []

        countries.filter( (elem: any) => {
            if (elem.name.toUpperCase().includes(country.toUpperCase())) {
                newCountryList = [...newCountryList, elem]
            }
        })
        if (!isCountriesListOpened) {
            setIsCountriesListOpened(true)
        }
        setIsCityToggleOpened(false)
        setCountriesList(newCountryList)
        setForm({...form, region: country})

    }

    const cityHandle = (value: string) => {
        let newCitiesList: any[] = []
        cities.filter( (elem: any) => {
            if (elem.name.toUpperCase().includes(value.toUpperCase())) {
                newCitiesList = [...newCitiesList, elem]
            }
        })
        if (!isCityToggleOpened) {
            setIsCityToggleOpened(true)
        }
        setCityList(newCitiesList)
        setChoosenCity(value)
        setIsChanged(true)
    }

    const birthdateHandle = (value: string) => {
        if (form.birthDay === null) {
            setForm({...form, birthDay: value})
        } else if (form.birthDay && value.length < form.birthDay.length ) {
                if (form.birthDay.length === 1) {
                    
                    setForm({...form, birthDay: value})
                } else 
                if (form.birthDay[form.birthDay.length-1] === '.') {
                    setForm({...form, birthDay: form.birthDay.slice(0, form.birthDay.length-2) })
                } else {
                    setForm({...form, birthDay: form.birthDay.slice(0, form.birthDay.length-1) })
                }
            } else if ( (value.length <= 10) && value[value.length-1].match(/[0-9]/) ) {
                if ( (value.length === 1) && (value[0] <= '3') ) {
                    setForm({...form, birthDay: value})
                } else if ( (value.length === 2) && (value[0] + value[1] <= '31') )  {
                    setForm({...form, birthDay: value + '.'})
                } else if ( (value.length === 4) && (value[value.length-1] <= '1') ) {
                    setForm({...form, birthDay: value})
                } else if (value.length === 5) {
                    if ( ( (value[3] === '1') && ( value[4] <= '2' ) ) || (value[3] === '0') ) {
                        setForm({...form, birthDay: value + '.'})
                    } 
                } else if (value.length > 6) {
                    setForm({...form, birthDay: value})
                } 
                if (value.length === 10) {
                    setForm({...form, birthDay: value})
                }
            }
        setIsChanged(true)
    }

    const phoneHandle = (value: string) => {
        if (value.match(/[()+||0-9]{0,20}$/)) {
            setIsChanged(true)
            setForm({...form, phoneNumber: value})
        }
    }

    return (
        <div
            className={`my-account-wrapper ${isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}
        >
            <div
                className="my-account-wrapper__inside-wrapper"
            >
            <MyAccountNavbar/>

            <MyAccountPhoto />

            <div
                className="my-account-wrapper__container"
            >
                
                <div
                    className="my-account-wrapper__container__row"
                >
                    <div
                        className="my-account-wrapper__container__row__input-wrapper"
                    >   
                        <div
                            className="my-account-wrapper__container__row__input-wrapper__top-info-panel"
                        >
                            <span>
                                <FormattedMessage id='my_account_input_title_fio'/>
                            </span>
                        </div>
                        <div
                            className="text-input"
                        >
                            <input
                                type='text'
                                name='fio'
                                value={currentName|| ''}
                                onChange={(event) => {
                                    checkIsNameValid(event.target.value)
                                    setCurrentName(event.target.value)
                                    setIsChanged(true)
                                    setForm({...form, fio: event.target.value})
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className="my-account-wrapper__container__row__input-wrapper"
                    >
                        <div
                            className="my-account-wrapper__container__row__input-wrapper__top-info-panel"
                        >
                            <span>
                                <FormattedMessage id='my_account_input_title_phone'/>
                            </span>
                            {
                                accountData.phoneStatus
                                ?
                                <GreenPhoneIcon/>
                                :
                                <RedPhoneIcon/>
                            }
                            
                        </div>
                        <div
                            className="text-input"
                        >
                            <input
                                type='text'
                                name='phoneNumber'
                                value={form.phoneNumber|| ''}
                                onChange={(event) => {
                                    phoneHandle(event.target.value)
                                }}
                            />
                            <MyAccountPhoneInputIcon/>
                        </div>
                    </div>
                    <div
                        className="my-account-wrapper__container__row__input-wrapper"
                    >
                        <div
                            className="my-account-wrapper__container__row__input-wrapper__top-info-panel"
                        >
                            <span>
                                <FormattedMessage id='my_account_input_title_gender'/>
                            </span>
                        </div>
                        <div
                            className="text-input"
                            
                        >
                            <input
                                style={{borderRadius: isGenderListOpened ? '12px 12px 0px 0px' : ' 12px'}}
                                type='text'
                                name='gender'
                                value={
                                    (
                                    form.gender === 'Male'
                                    ?
                                    intl.formatMessage({ id: 'my_account_gender_male' })
                                    : form.gender === 'Female' &&
                                    intl.formatMessage({ id: 'my_account_gender_female' })
                                    ) || ''
                                }
                                onChange={() => {}}
                                onBlur={() => setIsGenderListOpened(false)}
                            />  
                        </div>
                        <div
                            className={`my-account-wrapper__container__row__input-wrapper__icon 
                                ${isGenderListOpened ? 'rotated' :''}    
                            `}
                            onClick={ () => {
                                if (isGenderListOpened) {
                                    setIsGenderListOpened(false)
                                } else {
                                    setIsGenderListOpened(true)
                                }
                            }}
                        >
                            <ToggleListSmallArrowIcon/>
                        </div>

                        <div
                            className={`my-account-wrapper__container__row__input-wrapper__toggle-list
                                ${ isGenderListOpened && 'my-account-wrapper__container__row__input-wrapper__opened-list' }
                            `}
                        >
                            <span
                                onClick={() => {
                                    setIsGenderListOpened(false)
                                    setIsChanged(true)
                                    setForm({...form, gender: 'Female'})
                                }}
                            >
                                <FormattedMessage id='my_account_gender_female'/>
                            </span>
                            <span
                                onClick={() => {
                                    setIsGenderListOpened(false)
                                    setIsChanged(true)
                                    setForm({...form, gender: 'Male'})
                                }}
                            >
                                <FormattedMessage id='my_account_gender_male'/>
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    className="my-account-wrapper__container__row"
                >
                    <div
                        className="my-account-wrapper__container__row__input-wrapper"
                    >
                        <div
                            className="my-account-wrapper__container__row__input-wrapper__top-info-panel"
                        >
                            <span>
                                <FormattedMessage id='my_account_input_title_birthday'/>
                            </span>
                        </div>
                        <div
                            className="text-input"
                        >
                            <input type="date" id="meeting-time"
                                placeholder=""
                                name="meeting-time" value={form.birthDay|| ''} onChange={(event) => birthdateHandle(event.target.value)}
                            />
                            <MyAccountBirthdayInputIcon/>
                        </div>
                    </div>
                    
                    <div
                        className="my-account-wrapper__container__row__input-wrapper 
                            my-account-wrapper__container__row__mail-input-wrapper"
                    >
                        <div
                            className="my-account-wrapper__container__row__input-wrapper__top-info-panel"
                        >
                            <span>
                                <FormattedMessage id='my_account_input_title_email'/>
                            </span>
                            <GreenMailEnvelopeIcon/>
                        </div>
                        <div
                            className="text-input"
                        >
                            <input
                                type='text'
                                name='login'
                                value={changedEmail}
                                //placeholder={form.login}
                                onChange={event => setChangedEmail(event.target.value)}
                            />
                            <MyAccountEmailInputIcon />
                        </div>
                    </div>  
                    <div
                        className="my-account-wrapper__container__row__input-wrapper"
                    >
                        <div
                            className="my-account-wrapper__container__row__input-wrapper__top-info-panel"
                        >
                            <span>
                                <FormattedMessage id='my_account_input_title_country'/>
                            </span>
                        </div>
                        <div
                            className="text-input"
                        >
                            <input
                                type='text'
                                name='region'
                                value={form.region|| ''}
                                onChange={(event) => onRegionChange(event.target.value)}
                                onBlur={() => setTimeout(() => setIsCountriesListOpened(false), 500)}
                                style={{borderRadius: isCountriesListOpened ? '12px 12px 0px 0px' : ' 12px'}}
                            />
                        </div>
                        
                        <div
                            className={`my-account-wrapper__container__row__input-wrapper__icon 
                                ${isCountriesListOpened ? 'rotated' :''}    
                            `}
                            onClick={ () => {
                                if (isCountriesListOpened) {
                                    setIsCountriesListOpened(false)
                                } else {
                                    setIsCountriesListOpened(true)
                                }
                            }}
                        >
                            <ToggleListSmallArrowIcon/>
                        </div>
                        <div
                            className={`my-account-wrapper__container__row__input-wrapper__toggle-list
                                ${ isCountriesListOpened && 'my-account-wrapper__container__row__input-wrapper__countries-opened-list' }
                            `}
                        >
                            {
                                !isCityToggleOpened
                                &&
                                countryList
                                &&
                                countryList.length > 0
                                &&
                                countryList.map( (elem, index) => {
                                    return (
                                        <span
                                            key={index}
                                            onClick={() => {
                                                setForm({...form, region: elem.name})
                                                
                                                setIsCountriesListOpened(false)
                                                dispatch( tryToGetCitiesList(elem.countryId) )
                                                setIsCityToggleOpened(true) 
                                                setChoosenCity('')
                                                setIsChanged(true)
                                                
                                                const el: HTMLElement | null = document.getElementById('my_account_city_input')
                                                if (el) { el?.focus() }
                                            }}
                                        >
                                            {
                                                elem.name
                                            }
                                        </span>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>

                <div
                    className="my-account-wrapper__container__row__input-wrapper city-input-wrapper"
                >
                    <div
                        className="my-account-wrapper__container__row__input-wrapper__top-info-panel"
                    >
                        <span>
                            <FormattedMessage id='my_account_input_title_city'/>
                        </span>
                    </div>
                    <div
                        className="text-input"
                    >
                        <input
                            type='text'
                            name='city'
                            id='my_account_city_input'
                            value={choosenCity|| ''}
                            onChange={(event) => cityHandle(event.target.value)}
                            onBlur={() => setTimeout(() => setIsCountriesListOpened(false), 50)}
                            style={{borderRadius: isCityToggleOpened ? '12px 12px 0px 0px' : ' 12px'}}
                        />
                    </div>
                    <div
                            className={`my-account-wrapper__container__row__input-wrapper__icon 
                                ${isCityToggleOpened ? 'rotated' :''}    
                            `}
                            onClick={ () => {
                                if (isCityToggleOpened) {
                                    setIsCityToggleOpened(false)
                                } else {
                                    setIsCityToggleOpened(true)
                                }
                            }}
                        >
                            <ToggleListSmallArrowIcon/>
                        </div>
                        <div
                            className={`my-account-wrapper__container__row__input-wrapper__toggle-list
                                ${ isCityToggleOpened && 'my-account-wrapper__container__row__input-wrapper__countries-opened-list' }
                            `}
                        >
                            {
                                isCityToggleOpened
                                &&
                                (
                                    cityList
                                    &&
                                    cityList.length > 0
                                    &&
                                    cityList.map( (elem, index) => {
                                        return (
                                            <span
                                                key={index}
                                                onClick={() => {
                                                    setChoosenCity(elem.name)
                                                    setIsChanged(true)
                                                    setIsCityToggleOpened(false) 
                                                }}
                                            >
                                                {
                                                    elem.name
                                                }
                                            </span>
                                        )
                                    })
                                )
                            }

                        </div>
               
                </div>
            </div>

            <AccountChangePassword text={accountData.periodLastPasswordChange}/>

            </div>

            <div
                className="my-account-wrapper__bottom-control-panel"
            >
                <div
                    className='my-account-wrapper__bottom-control-panel__buttons'
                >
                    <div
                        className={`white-button`}
                        onClick={() => setIsCancelled(true)}
                    >
                        <FormattedMessage id='my_account_control_panel_cancel'/>
                    </div>

                    <div
                        className={`green-button 
                            ${ ( !(isChanged || isNameValid || (changedEmail !== accountData?.login) ) ) && 'disabled-button' }
                        `}
                        onClick={ () => { 
                            if (changedEmail !== accountData?.login) {
                                setChangingEmailModal(true)
                            } else {
                                setChanged(true)
                                setIsChanged(false)
                                dispatch( tryToChangeAccountMainData({...accountData, ...form, region: (choosenCity.length > 0 ? form.region + ', ' + choosenCity : form.region)}) )
                                //setForm({...form})

                            }
                        }}
                    >
                        <FormattedMessage id='my_account_control_panel_add' />
                    </div>
                </div>
            </div>

            {
                changingEmailModal
                &&
                (
                    isEmailChanged
                    ?
                    <div
                        className="my-account-wrapper__changing-login-modal"
                    >
                        <span
                            className="my-account-wrapper__changing-login-modal__title"
                        >
                            <FormattedMessage id='my_account_modal_change_login_title'/>
                        </span>

                        <div
                            className="my-account-wrapper__changing-login-modal__desc"
                        >
                            <FormattedMessage id='my_account_modal_change_login_success_1' />
                            {
                                changedEmail
                            }
                            <FormattedMessage id='my_account_modal_change_login_success_2' />
                        </div>
                    
                        <div
                            className="my-account-wrapper__notifications-wrapper__modal__buttons"
                        >
                            <div
                                className="white-button"
                                onClick={() => {
                                    setChangingEmailModal(false)
                                }}
                            >
                                <FormattedMessage id='my_account_modal_change_login_success_close'/>
                            </div>
                        </div>
                    </div>
                    :
                    <div
                        className="my-account-wrapper__changing-login-modal"
                    >
                        <span
                            className="my-account-wrapper__changing-login-modal__title"
                        >
                            <FormattedMessage id='my_account_modal_change_login_title'/>
                        </span>

                        <div
                            className="my-account-wrapper__changing-login-modal__desc"
                        >
                            <FormattedMessage id='my_account_modal_change_login_desc' />
                            {
                                changedEmail
                            }
                        </div>
                        
                        <div
                            className="text-input"
                        >
                            <input
                                type='password'
                                value={confirmPassword|| ''}
                                onChange={(event) => {
                                    setConfirmPassword(event.target.value)
                                }}
                            />
                        </div>


                        <div
                            className="my-account-wrapper__notifications-wrapper__modal__buttons"
                        >
                            <div
                                className="white-button"
                                onClick={() => {
                                    setChangingEmailModal(false)
                                }}
                            >
                                <FormattedMessage id='my_account_modal_change_login_cancel'/>
                            </div>
                            <div
                                className={`green-button 
                                    ${ changedEmail.length === 0 && 'disabled-button' }
                                `}  
                                onClick={ () => {
                                    if (confirmPassword.length > 0) {
                                        dispatch( tryToChangeEmail({email: changedEmail, password: confirmPassword}) )
                                    }
                                }}
                            >
                                <FormattedMessage id='my_account_modal_change_login_change' /> 
                            </div>  
                        </div>
                    </div>
                )
                
            }
        </div>
    )
}