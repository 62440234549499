import React from "react";

export default class ErrorBoundary extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.log(error)
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.log(error);
        console.log(info);
       
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            localStorage.clear();
            return <h1>Произошла ошибка, Нажмите ctrl+f5 что бы обновить страницу
        
            </h1>;
        }

        return this.props.children;
    }
}

