import { useEffect, useState} from "react"
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux"
import { useParams } from 'react-router-dom'
import { GroupCharacteristic } from "../../components/MeasurementStatisticComponents/GroupCharacteristic";
import { GroupStatistic } from "../../components/MeasurementStatisticComponents/GroupStatistic";
import { InfoStatisticBlocks } from "../../components/MeasurementStatisticComponents/InfoStatisticBlocks";
import { MainMeasurementData } from "../../components/MeasurementStatisticComponents/MainMeasurementData";
import { MainSquareGraphic } from "../../components/MeasurementStatisticComponents/MainSquareGraphic";
import { MeasurementToggleList } from "../../components/MeasurementStatisticComponents/MeasurementToggleList";
import { PartnerInfoStatistic } from "../../components/MeasurementStatisticComponents/PartnerInfoStatistic";
import { PersonalEnergyDiagram } from "../../components/MeasurementStatisticComponents/PersonalEnergyDiagram";
import { PersonListStatistic } from "../../components/MeasurementStatisticComponents/PersonListStatistic";
import { PsychicalProcessDiagram } from "../../components/MeasurementStatisticComponents/PsychicalProcessDiagram";
import { TypesRationDiagram } from "../../components/MeasurementStatisticComponents/TypesRationDiagram";
//import { mainGraphicSize } from "../../consts";
import preloaderIcon from "./preloader.png";
import {tryToGetMeasurementStatistic} from "../../store/reducers/MeasurementStatisticReducer"
import { User } from "../../interfaces/User"
import './styles.sass'

export const MeasurementWatcher = () => {
    
   
    const dispatch = useDispatch()
   
    const accountData: User = useSelector( (state: any) => (state.user))
    const { id } = useParams()
    const [errors] = useState(true);
    
    const isNavbarShowed = useSelector( (state: any) => (state.navbar.isNavbarShowed))
    const [preloader, setPreloader] = useState(true);
    const measurementStatistic = useSelector( (state: any) => (state.measurementStatistic))
    async function checkStat(){
        // const response = await fetch(`${process.env.REACT_APP_API_HOST}/measurement/${id}/statistic`, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json;charset=utf-8',
        //         Accept: 'application/json',
        //         'Authorization': 'Bearer ' + localStorage.token
        //     },
        // })
       
        // if (response.status == 500 || response.status == 400){
         
        //     setErrors(false)
        // } else {
            
        dispatch( tryToGetMeasurementStatistic(id || '') )
        setTimeout(() => {
            setPreloader(false)    
        }, 500);
        
        }
    
    const [isActivate, setIsactivate] = useState(true)

    useEffect( () => {
        checkStat()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(()=>{
        if (accountData && accountData.authorities){
            accountData.authorities.map((elem)=>{
                if (elem=='CONSULTANT'){
                    setIsactivate(true)
                }
            })
        }
       
    },[accountData, measurementStatistic])
    if (errors){
    return ( 
        <div className={`measurement-statistic-container ${ isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}
         style={{maxWidth: '100%'}}>
          {
              preloader && <div className="preloader">
                  <div>
                        <p>   Пожалуйста подождите</p>
                         
                        
                        <img src={preloaderIcon} className="preloader__image "/>
                  </div>
                  
            </div>
          }  
            <MainMeasurementData data={measurementStatistic?.simpleMeasurement !== null ? measurementStatistic?.simpleMeasurement : null} />

            
            

            {
                document.body.clientWidth > 640
                ?
                <MeasurementToggleList 
                    title={<FormattedMessage id='measurement_statistic_title_personal_enviroment' />} 
                    component={
                        <div
                            className="row"
                        >
                            <div>
                                <PersonListStatistic/>
                                <div
                                    style={document.body.clientWidth > 640 ? {display: 'flex', flexDirection: 'column', marginLeft: '8px', width: '740px'}:
                                    {display: 'flex', flexDirection: 'column', marginLeft: '8px'}
                                }
                                >
                                    <div
                                        style={{display: 'flex', flexDirection: 'row'}}
                                    >
                                        <MainSquareGraphic isActivate={isActivate}/>
                                        <PartnerInfoStatistic isActivate={isActivate}/>
                                
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    }
                    maxHeight={800}
                    isScrollable={true}
                />
                :
                <>
                    <PersonListStatistic/>
                    <MeasurementToggleList 
                        title={<FormattedMessage id='measurement_statistic_title_personal_enviroment' />} 
                        component={
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <MainSquareGraphic/>
                                <InfoStatisticBlocks/>
                            </div>
                        }
                        maxHeight={800}
                    />
                    
                </>
            }
               <MeasurementToggleList 
                title={<FormattedMessage id='measurement_statistic_title_person' />} 
                component={<InfoStatisticBlocks/>}
                maxHeight={1000}
                isActivate={isActivate}
            />
           

            <MeasurementToggleList 
                title={<FormattedMessage id='measurement_statistic_title_conversation_quality' />} 
                component={<GroupStatistic/>}
                maxHeight={1000}
                isActivate={isActivate}
            />

            <MeasurementToggleList 
                title={<FormattedMessage id='measurement_statistic_title_relationships_types' />} 
                component={<TypesRationDiagram/>}
                maxHeight={850}
                isActivate={isActivate}
            />

            <MeasurementToggleList 
                title={<FormattedMessage id='measurement_statistic_title_personal_energy' />} 
                component={<PersonalEnergyDiagram/>}
                maxHeight={document.body.clientWidth>640?1000:1800}
                isActivate={isActivate}
            />

            <MeasurementToggleList 
                title={<FormattedMessage id='measurement_statistic_title_group_characteristics' />} 
                component={<GroupCharacteristic/>}
                maxHeight={document.body.clientWidth>640?500:1100}
                isActivate={isActivate}
            />

            <MeasurementToggleList 
                title={<FormattedMessage id='measurement_statistic_title_psycho_processes' />} 
                nameTitle="physical"
                component={<PsychicalProcessDiagram/>}
                maxHeight={document.body.clientWidth>640?1000:1100}
                isActivate={isActivate}
            />

            
        </div>
        )} else {
            return(<div className="error">
                <p>Произошла ошибка</p>
                <div className="green-button" onClick={() => {window.history.back()}}>
                    Вернуться назад
                </div>
            </div>)
        }
}