import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  BlackBottomTriangle,
  MyAccountBirthdayInputIcon,
  OpeningSimpleArrowIcon,
} from "../../icons";
import { getPersonStatuses } from "../../store/reducers/PersonStatusesReducer";
import { getPersonsList } from "../../store/reducers/PersonsListReducer";
import { routes } from "../../routes";
import "./styles.sass";

export const PersonChangeContainer = (prop: { type: string }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  // const [newPersonSetting, setNewPersonSetting] = useState({
  //     fio: '',
  //     gender: '',
  //     date: '',
  //     type: ''
  // })
  const isNavbarShowed = useSelector((stat: any) => stat.navbar.isNavbarShowed);
  const personStatusesArray = useSelector((stat: any) => stat.personStatuses);

  const [choosenStatusCoords, setChoosenStatusCoords] = useState<number>(0);

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [trySend, setTrySend] = useState(false);
  const [form, setForm] = useState<any>({
    customName: "",
    gender: "Male",
    role: "",
    birthday: "",
  });
  async function saveChangesPerson() {
    setTrySend(true);
    let url = `${process.env.REACT_APP_API_HOST}/person`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + localStorage.token,
      },
      body: JSON.stringify({
        ...form,
      }),
    });

    if (response.status == 200 || response.status == 201) {
      navigate(routes.persons, { replace: true });
      dispatch(getPersonsList({ page: 0, name: "", sort: "" }));
    } else if (response.status == 401 || response.status == 400) {
      if (response.status == 400) {
        if (form.gender === undefined) {
          // let faf =  document.getElementsByClassName('choose-gender-measurement-person-wrapper__gender-panel');
        }
      } else {
        location.assign(routes.login);
      }
    } else {
      return null;
    }
  }

  const getChoosenElementCoords = (index) => {
    const myElement: HTMLElement | null =
      window?.document?.getElementById(index);
    const parent: HTMLElement | null = window?.document?.getElementById(
      "choose-person-statuses-wrapper__person"
    );

    if (myElement !== null && parent !== null) {
      setChoosenStatusCoords(
        myElement.getBoundingClientRect().left -
          parent.getBoundingClientRect().left +
          myElement.getBoundingClientRect().width / 2 -
          8
      );
    }
  };

  const toggleOpenedModal = () => {
    if (isModalOpened) {
      setIsModalOpened(false);
    } else {
      setIsModalOpened(true);
    }
  };

  useEffect(() => {
    dispatch(getPersonStatuses());
    if (prop.type === "change") {
      setForm(state.data);
      if (form.birthday == null) {
        setForm({ ...form, birthday: "" });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  useEffect(() => {
    if (prop.type === "change") {
      personStatusesArray.forEach((elem, index) => {
        if (state.data.role === elem.name) {
          getChoosenElementCoords(index);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personStatusesArray]);
  return (
    <div
      className={`persons-measurement-container ${
        isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
      }`}
    >
      <div className="person-change-container">
        <span className="title">
          {prop.type === "change" ? (
            <FormattedMessage id="person_change_title_change" />
          ) : (
            <FormattedMessage id="person_change_title_create" />
          )}
        </span>

        <div className="row">
          <span>
            <FormattedMessage id="person_change_input_title_fio" />
          </span>
          <div
            style={{
              marginLeft: document.body.clientWidth > 640 ? "103px" : "0",
            }}
            className={`${
              form.customName.length == 0 && trySend && "red"
            } row__input `}
          >
            <input
              type="text"
              value={form.customName}
              onChange={(event) =>
                setForm({ ...form, customName: event.target.value })
              }
            />
          </div>
        </div>
        {form.customName.length == 0 && trySend && (
          <div
            className="error-text"
            style={{ marginLeft: "232px", marginTop: "-14px" }}
          >
            Укажите имя
          </div>
        )}
        <div
          className="row"
          style={
            document.body.clientWidth > 640 ? { marginBottom: "170px" } : {}
          }
        >
          <span>
            <FormattedMessage id="person_change_input_title_role" />
          </span>
          {document.body.clientWidth > 640 ? (
            <div
              className="choose-person-statuses-wrapper"
              id="choose-person-statuses-wrapper__person"
              style={{
                marginLeft: document.body.clientWidth > 640 ? "103px" : "0",
              }}
            >
              <div
                className={`${
                  form.role.length == 0 && trySend && "red"
                } choose-person-statuses-wrapper__top-side`}
              >
                {personStatusesArray.map((elem, index) => {
                  return (
                    <div
                      key={index}
                      id={index}
                      className={
                        form.role === elem.name ? "choosen-status-panel" : ""
                      }
                      onClick={() => {
                        // if (!isInputBlocked) {
                        //     setChoosenStatus(elem.name)
                        //     setWasChoosen(true)
                        //     getChoosenElementCoords(index)
                        //     dispatch(setCurrentPersonRole(elem.name))
                        // }
                        setForm({ ...form, role: elem.name });
                        getChoosenElementCoords(index);
                      }}
                    >
                      {elem.name}
                    </div>
                  );
                })}
              </div>

              <div className="choose-person-statuses-wrapper__bottom-side">
                {personStatusesArray.map((elem, index) => {
                  if (elem.name === form.role) {
                    return (
                      <div
                        key={index}
                        className="choose-person-statuses-wrapper__bottom-side__description-wrapper"
                      >
                        <div style={{ left: choosenStatusCoords + "px" }}>
                          <BlackBottomTriangle />
                        </div>

                        <span
                          style={{
                            left: choosenStatusCoords - 140 + "px",
                          }}
                        >
                          {elem.description}
                        </span>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            <div
              className="adaptive-choose-person-wrapper"
              style={{ width: "100%" }}
            >
              <div
                className="adaptive-choose-person-wrapper__input-wrapper"
                style={{
                  borderRadius: isModalOpened ? "20px 20px 0 0" : "20px",
                  border: isModalOpened
                    ? "1px solid rgba(0, 0, 0, 0.3)"
                    : "none",
                }}
                onClick={() => toggleOpenedModal()}
              >
                <div>
                  {form.role || (
                    <FormattedMessage id="measurement_person_list_page_choose_role_placeholder" />
                  )}
                </div>
                <span
                  className={isModalOpened ? "rotated" : ""}
                  style={{ width: "auto" }}
                >
                  <OpeningSimpleArrowIcon />
                </span>
              </div>

              <div
                className={`adaptive-choose-person-wrapper__modal-wrapper`}
                style={{
                  height: isModalOpened ? "fit-content" : "0",
                  border: isModalOpened
                    ? "1px solid rgba(0, 0, 0, 0.3)"
                    : "none",
                  borderTop: "none",
                }}
              >
                <div>
                  {personStatusesArray.map((elem, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setForm({ ...form, role: elem.name });
                        toggleOpenedModal();
                      }}
                    >
                      <span className="title">{elem.name}</span>
                      <span className="desc">{elem.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}{" "}
          {form.role.length == 0 && trySend && (
            <div
              className="error-text"
              style={{ position: "absolute", bottom: "-26px", left: "232px" }}
            >
              Выберите роль
            </div>
          )}
        </div>
        <div className="row">
          <span>
            <FormattedMessage id="person_change_input_title_gender" />
          </span>
          <div
            className="gender-row"
            style={{
              marginLeft: document.body.clientWidth > 640 ? "103px" : "0",
            }}
          >
            <div
              className="choose-gender-measurement-person-wrapper__gender-panel"
              onClick={() => setForm({ ...form, gender: "Male" })}
            >
              {form.gender === "Male" ? (
                <div className="choose-gender-measurement-person-wrapper__gender-panel__choosen-checkbox" />
              ) : (
                <div className="choose-gender-measurement-person-wrapper__gender-panel__non-choosen-checkbox" />
              )}
              <span>
                <FormattedMessage id="measurement_person_list_page_gender_male" />
              </span>
            </div>

            <div
              className="choose-gender-measurement-person-wrapper__gender-panel"
              onClick={() => setForm({ ...form, gender: "Female" })}
            >
              {form.gender === "Female" ? (
                <div className="choose-gender-measurement-person-wrapper__gender-panel__choosen-checkbox" />
              ) : (
                <div className="choose-gender-measurement-person-wrapper__gender-panel__non-choosen-checkbox" />
              )}
              <span>
                <FormattedMessage id="measurement_person_list_page_gender_female" />
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <span>
            <FormattedMessage id="person_change_input_title_birthday" />
          </span>
          <div
            style={{
              marginLeft: document.body.clientWidth > 640 ? "103px" : "0",
            }}
            className={`row__input`}
          >
            <div className="date-input">
              <input
                type="date"
                id="meeting-time"
                placeholder=""
                name="meeting-time"
                value={form.birthday || ""}
                onChange={(event) =>
                  setForm({ ...form, birthday: event.target.value })
                }
              />
              <label htmlFor="meeting-time" className="date-icon">
                <MyAccountBirthdayInputIcon />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="button-changer">
        <div
          className="white-button"
          onClick={() => navigate(routes.persons, { replace: true })}
        >
          Отменить
        </div>
        <div className="green-button" onClick={() => saveChangesPerson()}>
          Сохранить
        </div>
      </div>
    </div>
  );
};
