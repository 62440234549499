import { RateMember } from "./RateMember";

export enum Mode {
  Package = "Package",
  Text = "Text",
  Picture = "Picture",
}

export enum Stage {
  START_WIZARD = "START_WIZARD",
  READ_COMMON_INSTRUCTION = "READ_COMMON_INSTRUCTION",
  CREATE_PERSON_LIST = "CREATE_PERSON_LIST",
  READ_ME_2_PARTNER_INSTRUCTION = "READ_ME_2_PARTNER_INSTRUCTION",
  RATE_ME_2_PARTNER = "RATE_ME_2_PARTNER",
  READ_PARTNER_2_ME_INSTRUCTION = "READ_PARTNER_2_ME_INSTRUCTION",
  RATE_PARTNER_2_ME = "RATE_PARTNER_2_ME",
  READ_FINAL_INSTRUCTION = "READ_FINAL_INSTRUCTION",
  COMPLETED = "COMPLETED",
}

export interface MeasurementInfo {
  measurementId: string;
  creationDate: string;
  name: string;
  typeId: string;
  mode: Mode;
  stage: Stage;
  rateMembers: RateMember[];
  typeName?: string;
  comment?: string;
  groupId?: string;
  measurementFinished?: boolean;
  id?: string;
  groupName?: string;
  officeName?: string;
}
