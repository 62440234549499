import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"

import './styles.sass'

const titles = [
    'pss',
    'psa',
    'iis',
    'ipe'
]

export const GroupCharacteristic = () => {
    
    const measurementStatistic = useSelector( (state: any) => (state.measurementStatistic)).groupCharacteristic
    
    return (
        <div
            className="group-characteristic"
        >
            {
                titles.map( (title) => {
                    
                    return(
                    <div
                        key={'group-characteristic'+title}
                    >  
                        <span className="title">
                            <FormattedMessage id={`measurement_statistic_group_characterisitic_title_${title}`} />
                        </span>
                        <div>
                            <span>
                                <FormattedMessage id={`measurement_statistic_group_characterisitic_subtitle_${title}`} />
                            </span>
                            <div>
                                {
                                    measurementStatistic[title]
                                }
                            </div>
                        </div>
                        <span className="subtitle" dangerouslySetInnerHTML={{__html: measurementStatistic[title+'Description']}}>
                        </span>
                    </div>
                )})
            }
        </div>
    )
}