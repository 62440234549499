import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { User } from "../interfaces/User";
import { routes } from "../routes";
import { setAccountMainData, TRY_TO_GET_ACCOUNT_MAIN_DATA } from "../store/reducers/AccountMainDataReducer";

async function asyncGetAccountMainData() {
    try {

        const response = await fetch(
            apiUrl + '/account',
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            }
        )
    
        if (response.status === 200) {
            const result = await response.json()

            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch(error) {}
}

function* GetAccountMainDataWorker() {
    const data: User =  yield call(asyncGetAccountMainData)

    if (data.login) {
        yield put( setAccountMainData(data) )
    }
}

export function* GetAccountMainDataWatcher() {
    yield takeEvery(TRY_TO_GET_ACCOUNT_MAIN_DATA, GetAccountMainDataWorker)
}