import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { mainGraphicPointSize, mainGraphicSize } from "../../../consts";
import { CarouselSideArrowIcon } from "../../../icons";
import {
  setMainGraphicMode,
  setMainGraphicSort,
} from "../../../store/reducers/MainGraphicModeReducer";
import "./styles.sass";
import { useNavigate } from "react-router-dom";
import { MobileListStatistic } from "../MobileStatistic";
const yAxisDotsArray = [
  <span style={{ top: "0px" }}>3Л</span>,
  <span style={{ top: (mainGraphicSize / 6) * 1 - 5 + "px" }}>2Л</span>,
  <span style={{ top: (mainGraphicSize / 6) * 2 - 12 + "px" }}>1Л</span>,
  <span style={{ top: (mainGraphicSize / 6) * 3 - 8 + "px" }}>0</span>,
  <span style={{ top: (mainGraphicSize / 6) * 4 - 5 + "px" }}>1Т</span>,
  <span style={{ top: (mainGraphicSize / 6) * 5 - 12 + "px" }}>2Т</span>,
  <span style={{ top: mainGraphicSize - 17 + "px" }}>3Т</span>,
];
const xAxisDotsArray = [
  <span style={{ left: "0px" }}>3Т</span>,
  <span style={{ left: (mainGraphicSize / 6) * 1 - 7 + "px" }}>2Т</span>,
  <span style={{ left: (mainGraphicSize / 6) * 2 - 13 + "px" }}>1Т</span>,
  <span style={{ left: (mainGraphicSize / 6) * 3 - 8 + "px", opacity: "0" }}>
    0
  </span>,
  <span style={{ left: (mainGraphicSize / 6) * 4 - 4 + "px" }}>1Л</span>,
  <span style={{ left: (mainGraphicSize / 6) * 5 - 10 + "px" }}>2Л</span>,
  <span style={{ left: mainGraphicSize - 18 + "px" }}>3Л</span>,
];

const modeTitles = [
  <FormattedMessage id="measurement_statistic_main_graphic_mode_title_1" />,
  <FormattedMessage id="measurement_statistic_main_graphic_mode_title_2" />,
  <FormattedMessage id="measurement_statistic_main_graphic_mode_title_3" />,
  <FormattedMessage id="measurement_statistic_main_graphic_mode_title_4" />,
  <FormattedMessage id="measurement_statistic_main_graphic_mode_title_5" />,
  <FormattedMessage id="measurement_statistic_main_graphic_mode_title_6" />,
];

export const MainSquareGraphic = (isActivate) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  //const [scroll, setScroll] = useState<number>( document.body.clientWidth > 640 ? 0 : 45 )
  const [mode, setMode] = useState<number>(0);
  const [activeMode, setActiveMode] = useState(0);
  const [activeMode2, setActiveMode2] = useState(0);
  const data = useSelector((state: any) => state.measurementStatistic);
  const sorting = useSelector((state: any) => state.mainGraphicMode);

  const [points, setPoints] = useState({
    ["00"]: [],
    ["+10"]: [],
    ["+20"]: [],
    ["+30"]: [],
    ["-10"]: [],
    ["-20"]: [],
    ["-30"]: [],
    ["0+1"]: [],
    ["0+2"]: [],
    ["0+3"]: [],
    ["0-1"]: [],
    ["0-2"]: [],
    ["0-3"]: [],
    ["-3+3"]: [],
    ["-2+3"]: [],
    ["-1+3"]: [],
    ["+1+3"]: [],
    ["+2+3"]: [],
    ["+3+3"]: [],
    ["-3+2"]: [],
    ["-2+2"]: [],
    ["-1+2"]: [],
    ["+1+2"]: [],
    ["+2+2"]: [],
    ["+3+2"]: [],
    ["-3+1"]: [],
    ["-2+1"]: [],
    ["-1+1"]: [],
    ["+1+1"]: [],
    ["+2+1"]: [],
    ["+3+1"]: [],
    ["-3-3"]: [],
    ["-2-3"]: [],
    ["-1-3"]: [],
    ["+1-3"]: [],
    ["+2-3"]: [],
    ["+3-3"]: [],
    ["-3-2"]: [],
    ["-2-2"]: [],
    ["-1-2"]: [],
    ["+1-2"]: [],
    ["+2-2"]: [],
    ["+3-2"]: [],
    ["-3-1"]: [],
    ["-2-1"]: [],
    ["-1-1"]: [],
    ["+1-1"]: [],
    ["+2-1"]: [],
    ["+3-1"]: [],
  });
  function getMeasurementClick(index) {
    setMode(index);
    dispatch(setMainGraphicMode(index));
    dispatch(setMainGraphicSort("0"));
  }

  useEffect(() => {
    let obj = {
      ["00"]: [],
      ["+10"]: [],
      ["+20"]: [],
      ["+30"]: [],
      ["-10"]: [],
      ["-20"]: [],
      ["-30"]: [],
      ["0+1"]: [],
      ["0+2"]: [],
      ["0+3"]: [],
      ["0-1"]: [],
      ["0-2"]: [],
      ["0-3"]: [],
      ["-3+3"]: [],
      ["-2+3"]: [],
      ["-1+3"]: [],
      ["+1+3"]: [],
      ["+2+3"]: [],
      ["+3+3"]: [],
      ["-3+2"]: [],
      ["-2+2"]: [],
      ["-1+2"]: [],
      ["+1+2"]: [],
      ["+2+2"]: [],
      ["+3+2"]: [],
      ["-3+1"]: [],
      ["-2+1"]: [],
      ["-1+1"]: [],
      ["+1+1"]: [],
      ["+2+1"]: [],
      ["+3+1"]: [],
      ["-3-3"]: [],
      ["-2-3"]: [],
      ["-1-3"]: [],
      ["+1-3"]: [],
      ["+2-3"]: [],
      ["+3-3"]: [],
      ["-3-2"]: [],
      ["-2-2"]: [],
      ["-1-2"]: [],
      ["+1-2"]: [],
      ["+2-2"]: [],
      ["+3-2"]: [],
      ["-3-1"]: [],
      ["-2-1"]: [],
      ["-1-1"]: [],
      ["+1-1"]: [],
      ["+2-1"]: [],
      ["+3-1"]: [],
    };
    data.personRates.slice(1).map((elem) => {
      obj = {
        ...obj,
        [elem.innerRating + elem.outerRating]: [
          ...obj[elem.innerRating + elem.outerRating],
          elem.person.id,
        ],
      };
    });
    setPoints({ ...points, ...obj });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const currentPerson = useSelector(
    (state: any) => state.currentMeasurementStatisticPerson
  );
  const [sliderIndex, setSliderIndex] = useState(0);
  useEffect(()=>{
    setMode(0);
    dispatch(setMainGraphicMode(0));
    dispatch(setMainGraphicSort("0"));
    setSliderIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <>
      <div
        className="main-square-graphic"
        style={{
          maxWidth: mainGraphicSize + "px",
        }}
      >
        {" "}
        <div
          className="show__to__buye"
          style={
            isActivate.isActivate || sliderIndex == 0
              ? { display: "none" }
              : { display: "flex" }
          }
        >
          <p>
            Для просмотра этого режима диаграммы необходимо приобрести подписку
          </p>
          <div
            className="green-button"
            onClick={() => {
              Navigate("/tarifs");
            }}
          >
            Купить подписку
          </div>
        </div>
        <div
          className="main-square-graphic__header"
          style={
            document.body.clientWidth > 640
              ? {
                  width: mainGraphicSize + "px",
                }
              : { width: "320px !important" }
          }
        >
          <span
            className={`main-square-graphic__header__left ${
              sliderIndex === 0 && "disabled"
            }`}
            onClick={() => {
              if (mode > 0) {
                // if (mode === 1) {
                //     setScroll( document.body.clientWidth > 640 ? 0 : 45 )
                // } else {
                //     setScroll(scroll-mainGraphicSize+( document.body.clientWidth > 640 ? 60 : -20-mode*3))
                // }
                setMode(mode - 1);
                dispatch(setMainGraphicMode(mode - 1));
                dispatch(setMainGraphicSort("0"));
                setSliderIndex(sliderIndex - 1);
              }
            }}
          >
            <CarouselSideArrowIcon />
          </span>

          <div
            className="main-square-graphic__header__state-line"
            style={{
              minWidth: mainGraphicSize - 60 + "px",
            }}
          >
            <div
              style={
                document.body.clientWidth > 640
                  ? sliderIndex == 5
                    ? { transform: "translateX(-196px)" }
                    : sliderIndex == 4
                    ? { transform: "translateX(-91px)" }
                    : { transform: "translateX(0)" }
                  : sliderIndex == 5
                  ? { transform: "translateX(-630px)" }
                  : sliderIndex == 4
                  ? { transform: "translateX(-500px)" }
                  : sliderIndex == 3
                  ? { transform: "translateX(-280px)" }
                  : sliderIndex == 2
                  ? { transform: "translateX(-180px)" }
                  : { transform: "translateX(0)" }
              }
            >
              {modeTitles.map((elem, index) => (
                <span
                  className={sliderIndex == index ? "activeSlider" : ""}
                  key={
                    "main-square-graphic__header__state-line__mode-panel_" +
                    index
                  }
                  onClick={() => {
                    setSliderIndex(index);
                    getMeasurementClick(index);
                  }}
                >
                  {elem}
                </span>
              ))}
            </div>
          </div>

          <span
            className={`main-square-graphic__header__right ${
              mode === modeTitles.length - 1 && "disabled"
            }`}
            onClick={() => {
              if (mode < modeTitles.length - 1) {
                //setScroll(scroll+mainGraphicSize-( document.body.clientWidth > 640 ? 60 : -20-mode*5))
                setMode(mode + 1);
                dispatch(setMainGraphicMode(mode + 1));
                dispatch(setMainGraphicSort("0"));
                setSliderIndex(sliderIndex + 1);
              }
            }}
          >
            <CarouselSideArrowIcon />
          </span>
        </div>
        <div
          className={`${
            !isActivate.isActivate && sliderIndex != 0 && "nonShow"
          } square`}
          style={{
            height: mainGraphicSize + "px",
            width: mainGraphicSize + "px",
          }}
        >
          <div className="square__x-axis" />
          <div className="square__y-axis" />
          <div className="square__zero-dot" />
          <div
            className="square__diagonal"
            style={{ width: mainGraphicSize * 1.40625 + "px" }}
          />

          <div className="square__first-edge">
            <div className="square__second-edge">
              <div className="square__third-edge" />
            </div>
          </div>

          {/* <div 
                        className='square__dots-array'
                    > */}
          <div className="square__dots-array__y-axis">
            {yAxisDotsArray.map((elem, index) => (
              <span
                className="dotsFont"
                key={Math.random() + index}
                style={{
                  marginTop: index === 3 ? "-10px" : "0px",
                  marginRight: index === 3 ? "-10px" : "0px",
                }}
              >
                {elem}
              </span>
            ))}
          </div>

          <div className="square__dots-array__x-axis">
            {xAxisDotsArray.map((elem) => (
              <span className="dotsFont" key={Math.random()}>
                {elem}
              </span>
            ))}
          </div>

          {/* </div> */}

          <div
            className="square__status-ellipse"
            style={{
              width:
                data.userEllipse &&
                (data.userEllipse.dx === "0.0" ? "5px" : data.userEllipse.dx) *
                  mainGraphicPointSize *
                  2 +
                  "px",
              height:
                data.userEllipse &&
                (data.userEllipse.dy === "0.0" ? "5px" : data.userEllipse.dy) *
                  mainGraphicPointSize *
                  2 +
                  "px",
              borderRadius:
                data.userEllipse &&
                data.userEllipse.dx !== "0.0" &&
                data.userEllipse.dy !== "0.0"
                  ? (data.userEllipse.dy / data.userEllipse.dx) * 100 + "%"
                  : "50%",
              marginBottom:
                data.userEllipse &&
                (parseFloat(data.userEllipse.y) / 3) * mainGraphicSize +
                  (data.userEllipse.y !== "0.0"
                    ? ((data.userEllipse.dy === "0.0"
                        ? 1
                        : data.userEllipse.dy) *
                        mainGraphicPointSize) /
                        2 -
                      3
                    : 0) +
                  "px",
              marginLeft:
                data.userEllipse &&
                (parseFloat(data.userEllipse.x) / 3) * (mainGraphicSize-30 )+
                  (data.userEllipse.x !== "0.0"
                    ? ((data.userEllipse.dx === "0.0"
                        ? -1.2
                        : data.userEllipse.dx) *
                        mainGraphicPointSize) /
                        2 +
                      3
                    : 0) +
                  "px",
            }}
          >
            <div className="square__status-ellipse__dot" />
            <div className="square__status-ellipse__vertical-line" />
            <div className="square__status-ellipse__horizontal-line" />
          </div>

          <div
            className="square__mode-1"
            style={{
              opacity: mode === 1 ? "1" : "0",
              zIndex: mode === 1 ? "14" : "1",
            }}
          >
            <div
              className={`square__mode-1__left ${
                activeMode == 1
                  ? "active__mode_2"
                  : activeMode == 2
                  ? "passive__mode"
                  : ""
              }`}
              onClick={() => {
                dispatch(setMainGraphicSort("2"));
                if (activeMode == 1) {
                  setActiveMode(0);
                } else {
                  setActiveMode(1);
                }
              }}
            />
            <div
              className={`square__mode-1__right ${
                activeMode == 2
                  ? "active__mode_1"
                  : activeMode == 1
                  ? "passive__mode"
                  : ""
              }`}
              onClick={() => {
                dispatch(setMainGraphicSort("1"));
                if (activeMode == 2) {
                  setActiveMode(0);
                } else {
                  setActiveMode(2);
                }
              }}
            />
          </div>

          <div
            className="square__mode-2"
            style={{
              opacity: mode === 2 ? "1" : "0",
              zIndex: mode === 2 ? "14" : "1",
            }}
          >
            <div
              className={`square__mode-2__top ${
                activeMode2 == 1
                  ? "active__mode_1"
                  : activeMode2 == 2
                  ? "passive__mode"
                  : ""
              }`}
              onClick={() => {
                dispatch(setMainGraphicSort("1"));
                if (activeMode2 == 1) {
                  setActiveMode2(0);
                } else {
                  setActiveMode2(1);
                }
              }}
            />
            <div
              className={`square__mode-2__bottom ${
                activeMode2 == 2
                  ? "active__mode_2"
                  : activeMode2 == 1
                  ? "passive__mode"
                  : ""
              }`}
              onClick={() => {
                dispatch(setMainGraphicSort("2"));
                if (activeMode2 == 2) {
                  setActiveMode2(0);
                } else {
                  setActiveMode2(2);
                }
              }}
            />
          </div>

          <div
            className="square__mode-3"
            style={{
              opacity: mode === 3 ? "1" : "0",
              zIndex: mode === 3 ? "14" : "1",
            }}
          >
            <div
              className="square__mode-3__left"
              style={{
                borderLeft: `${
                  mainGraphicSize - 3
                }px solid rgba(255, 143, 143, .5)`,
                borderBottom: `${mainGraphicSize - 3}px solid transparent`,
              }}
            />
            <div
              className="square__mode-3__right"
              style={{
                borderWidth: `0 0 ${mainGraphicSize - 2}px ${
                  mainGraphicSize - 2
                }px`,
                marginLeft: `-${mainGraphicSize - 4}px`,
              }}
            />
          </div>

          <div
            className="square__mode-4"
            style={{
              opacity: mode === 4 ? "1" : "0",
              zIndex: mode === 4 ? "14" : "1",
            }}
          >
            <div
              className="square__mode-4__left"
              style={{
                borderLeft: `${
                  mainGraphicSize - 3
                }px solid rgba(203, 203, 203, .5)`,
                borderBottom: `${mainGraphicSize - 3}px solid transparent`,
              }}
            />
            <div
              className="square__mode-4__right"
              style={{
                borderWidth: `0 0 ${mainGraphicSize - 2}px ${
                  mainGraphicSize - 2
                }px`,
                marginLeft: `-${mainGraphicSize - 4}px`,
              }}
            />
          </div>
          <div
            className="square__mode-4__diagonal"
            style={{
              opacity: mode === 4 ? "1" : "0",
              width: mainGraphicSize * 1.40625 + "px",
              bottom: mainGraphicSize / 2 - 2 + "px",
            }}
          />

          <div
            className="square__mode-5"
            style={{
              opacity: mode === 5 ? "1" : "0",
              zIndex: mode === 5 ? "14" : "1",
            }}
          >
            <div
              style={
                sorting.sort == "2"
                  ? { border: "2px solid #A24A4A" }
                  : { border: "none" }
              }
              className="square__mode-5__left-top"
              onClick={() => dispatch(setMainGraphicSort("2"))}
            />
            <div
              style={
                sorting.sort == "1"
                  ? { border: "2px solid #85A54C" }
                  : { border: "none" }
              }
              className="square__mode-5__right-top"
              onClick={() => dispatch(setMainGraphicSort("1"))}
            />
            <div
              style={
                sorting.sort == "3"
                  ? { border: "2px solid #3d3d3d" }
                  : { border: "none" }
              }
              className="square__mode-5__left-bottom"
              onClick={() => dispatch(setMainGraphicSort("3"))}
            />
            <div
              style={
                sorting.sort == "4"
                  ? { border: "2px solid #0000FF" }
                  : { border: "none" }
              }
              className="square__mode-5__right-bottom"
              onClick={() => dispatch(setMainGraphicSort("4"))}
            />
            <div
              style={
                sorting.sort == "9"
                  ? { border: "2px solid #3d3d3d" }
                  : { border: "none" }
              }
              className="square__mode-5__center"
              onClick={() => dispatch(setMainGraphicSort("9"))}
            ></div>
            <div
              style={
                sorting.sort == "6"
                  ? { border: "2px solid #A24A4A" }
                  : { border: "none" }
              }
              className="square__mode-5__top"
              onClick={() => dispatch(setMainGraphicSort("6"))}
            />
            <div
              style={
                sorting.sort == "7"
                  ? { border: "2px solid #3d3d3d" }
                  : { border: "none" }
              }
              className="square__mode-5__left"
              onClick={() => dispatch(setMainGraphicSort("7"))}
            />
            <div
              style={
                sorting.sort == "5"
                  ? { border: "2px solid #0000FF" }
                  : { border: "none" }
              }
              className="square__mode-5__right"
              onClick={() => dispatch(setMainGraphicSort("5"))}
            />
            <div
              style={
                sorting.sort == "8"
                  ? { border: "2px solid #3d3d3d" }
                  : { border: "none" }
              }
              className="square__mode-5__bottom"
              onClick={() => dispatch(setMainGraphicSort("8"))}
            />
          </div>

          <div className="points-wrapper">
            {data.personRates &&
              data.personRates.length > 0 &&
              data.personRates.slice(1).map((elem) => {
                if (
                  elem.innerRating &&
                  elem.innerRating.length > 0 &&
                  elem.outerRating &&
                  elem.outerRating.length > 0 &&
                  (sorting.sort !== "0"
                    ? mode === 0 ||
                      (mode === 1 &&
                        parseInt(sorting.sort) === elem.meToPersonArea) ||
                      (mode === 2 &&
                        parseInt(sorting.sort) === elem.personToMeArea) ||
                      mode === 3 ||
                      mode === 4 ||
                      (mode === 5 &&
                        parseInt(sorting.sort) === elem.microanalysisArea)
                    : true)
                ) {
                  const size =
                    points[elem.innerRating + elem.outerRating].length === 1
                      ? mainGraphicPointSize * 2
                      : mainGraphicPointSize *
                        2 *
                        (points[elem.innerRating + elem.outerRating].indexOf(
                          elem.person.id
                        ) +
                          1);
                  const additionalParams =
                    currentPerson && elem.person.id === currentPerson.person.id
                      ? {
                          animation: "points .6s linear forwards",
                          width: size + "px",
                          height: size + "px",
                        }
                      : {
                          width: size + "px",
                          height: size + "px",
                        };

                  return (
                    <div
                      className={`point ${
                        currentPerson &&
                        elem.person.id === currentPerson.person.id &&
                        "choosen-partner"
                      }`}
                      key={"statistic-point" + elem.person.id}
                      style={{
                        left:
                          (elem.outerRating === "-3" && `0.5px`) ||
                          (elem.outerRating === "-2" && `16.66%`) ||
                          (elem.outerRating === "-1" && `33.33%`) ||
                          (elem.outerRating === "0" && `50%`) ||
                          (elem.outerRating === "+1" && `66.66%`) ||
                          (elem.outerRating === "+2" && `83.3%`) ||
                          (elem.outerRating === "+3" && `99.9%`) ||
                          "0px",
                        top:
                          (elem.innerRating === "+3" && `0px`) ||
                          (elem.innerRating === "+2" && "16.66%") ||
                          (elem.innerRating === "+1" && `33.33%`) ||
                          (elem.innerRating === "0" && `50%`) ||
                          (elem.innerRating === "-1" && `66.66%`) ||
                          (elem.innerRating === "-2" && `83.3%`) ||
                          (elem.innerRating === "-3" && `99.8%`) ||
                          "0px",
                        borderColor:
                          elem.person.gender === "Male" ? "#0000FF" : "#FF0000",
                        ...additionalParams,
                      }}
                    />
                  );
                }
              })}
          </div>
        </div>
      </div>
      {document.body.clientWidth < 640 &&
        (mode == 1 || mode == 2 || mode == 5) && <MobileListStatistic />}
    </>
  );
};
