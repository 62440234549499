import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { tryToChangeAccountPassword } from '../../../store/reducers/AccountChangePasswordReducer'
import './styles.sass'

export const AccountChangePassword = (props: {text: string | undefined}) => {

    const dispatch = useDispatch()

    const [isFormOpened, setIsFormOpened] = useState<boolean>(false)

    const changingPasswordState: number = useSelector( (state: any) => (state.changingPassword))
    const [repeatError, setRepeatError] = useState<boolean>(false)
    const [isChange, setIsChange] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [form, setForm] = useState({
        oldPassword: '',
        newPassword: '',
        repeatPassword: ''
    })
    
    useEffect( () => {
        if (changingPasswordState === 1) {
            setIsFormOpened(false)
          
        } else if (changingPasswordState === 2) {
           
            setIsChange(false)
            setIsError(true)
        }
    }, [changingPasswordState])
    const [typeError, setTypeError] = useState('')
 
    const validatePasswords = () => {
        let pattern = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[[!@#\$%\^\&*\)\(+=._\-\A-Za-z\d]{6,}$/);
        if (form.newPassword.length  < 6 ){
          setRepeatError(true)
          setTypeError('length')
          return false
          
        }else if(form.newPassword !== form.repeatPassword){
            setRepeatError(true)
            setTypeError('others')
             return false
        } else if(!pattern.test(form.newPassword)){
            setRepeatError(true)
            setTypeError('pattern')
             return false
        } else {
            setRepeatError(false)
            setTypeError('')
            return true
        }
    }
    const isValid = () => {
        if (form.oldPassword.length>0) {
            
            return true
        } else {
            return false
        }
    }

    return (
        <div
            className="account-change-password-wrapper"
        >
            {
                isFormOpened
                ?
                <div
                    className='account-change-password-wrapper__form-wrapper'
                >
                    <div
                        className='account-change-password-wrapper__form-wrapper__row'
                    >
                        <span>
                            <FormattedMessage id='my_account_change_password_old_password'/>
                        </span>
                        <div
                            className='text-input'
                        >
                            <input 
                                type='password'
                                name='oldPassword'
                                value={form.oldPassword}
                                onChange={(event) => {
                                    setIsChange(true)
                                    setForm({...form, [event.target.name]: event.target.value})
                                    
                                }}
                                className={isError ? 'error-input' : ''}
                            />
                        </div>  
                    </div>
                    <div
                        className='account-change-password-wrapper__form-wrapper__row'
                    >
                        <span>
                            <FormattedMessage id='my_account_change_password_new_password'/>
                        </span>
                        <div
                            className='text-input'
                        >
                            <input 
                                type='password'
                                name='newPassword'
                                value={form.newPassword}
                                onChange={(event) => {
                                    setIsChange(true)
                                    setForm({...form, [event.target.name]: event.target.value})
                             
                                }}
                                className={(repeatError) ? 'error-input' : ''}
                            />
                        </div>  
                        {
                            document.body.clientWidth>640
                            &&
                            (repeatError || isError)
                            
                            &&
                            <div style={{width: '340px'}}
                                className='account-change-password-wrapper__form-wrapper__row__error'
                            >
                                {
                                    typeError=='length' ? 'Длинна пароля должна быть не менее 6ти символов.' : typeError=='others'? 'Новые пароли не совпадают.' : typeError=='pattern' ? 'Пароль может содержать только английские символы и цифры.'  : ''
                                }
                                <br/>
                                {
                                    isError && 'Неверный старый пароль.'
                                }
                            
                            </div>
                        }
                    </div>
                    <div
                        className='account-change-password-wrapper__form-wrapper__row'
                    >
                        <span>
                            <FormattedMessage id='my_account_change_password_repeat_password'/>
                        </span>
                        <div
                            className={`text-input`}
                        >
                            <input 
                                type='password'
                                name='repeatPassword'
                                value={form.repeatPassword}
                                onChange={(event) => {
                                    setIsChange(true)
                                    setForm({...form, [event.target.name]: event.target.value})
                                 
                                }}
                                className={repeatError ? 'error-input' : ''}
                            />
                        </div>  
                        {
                            document.body.clientWidth<640
                            &&
                            isError
                            && 
                            isChange
                            &&
                            <div
                                className='account-change-password-wrapper__form-wrapper__row__error'
                            >
                                <FormattedMessage id='my_account_change_password_error' />
                            </div>
                        }
                        <div
                            className='account-change-password-wrapper__form-wrapper__row__buttons-panel'
                        >
                            <div
                                className='white-button'
                                onClick={() => {
                                    setIsChange(false)
                                    setIsFormOpened(false)
                                    setForm({
                                        oldPassword: '',
                                        newPassword: '',
                                        repeatPassword: ''
                                    })
                                }}
                            >
                                <FormattedMessage id='my_account_change_password_cancel'/>
                            </div>
                            <div
                                className={`green-button  
                                    ${!isValid() && 'disabled-button' }
                                `}
                                onClick={() => {
                                    if (isValid() && validatePasswords()) {
                                        dispatch( tryToChangeAccountPassword({
                                            currentPassword: form.oldPassword,
                                            newPassword: form.newPassword
                                        }) )
                                        setForm({
                                            oldPassword: '',
                                            newPassword: '',
                                            repeatPassword: ''
                                        })
                                    }
                                    
                                }}
                            >
                                <FormattedMessage id='my_account_change_password_save'/>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div
                    className='account-change-password-wrapper__closed-panel'
                >
                    <span
                        className='account-change-password-wrapper__closed-panel__title'
                    >
                        <FormattedMessage id='my_account_change_password_title' />
                    </span>

                    <span
                        className='account-change-password-wrapper__closed-panel__description'
                    >
                        {
                            props.text
                            ?
                            props.text
                            :
                            (
                                document.body.clientWidth > 640
                                ?
                                '********                                        '
                                :
                                ''
                            )
                        }
                    </span>                                        

                    <span
                        onClick={() => setIsFormOpened(true)}
                        className={`account-change-password-wrapper__closed-panel__button`}
                    >
                        <FormattedMessage id='my_account_change_password_button_change' />
                    </span>
                </div>
            }
        </div>
    )
}