import { User } from '../../interfaces/User'

export const TRY_TO_GET_ACCOUNT_MAIN_DATA = 'TRY_TO_GET_ACCOUNT_MAIN_DATA'
export const SET_ACCOUNT_MAIN_DATA = 'SET_ACCOUNT_MAIN_DATA'
export const SET_NEW_SOCIAL_NETWORKS = 'SET_NEW_SOCIAL_NETWORKS'
export const TRY_TO_UPDATE_PHOTO = 'TRY_TO_UPDATE_PHOTO'
export const TRY_TO_CHANGE_ACCOUNT_MAIN_DATA = 'TRY_TO_CHANGE_ACCOUNT_MAIN_DATA'

const initialState: User = {
    login: '',
    id: ''
}

export const AccountMainDataReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_ACCOUNT_MAIN_DATA:
            return action.payload

        default:
            return state
    }
}


export const tryToGetAccountMainData = () => ({type: TRY_TO_GET_ACCOUNT_MAIN_DATA})
export const setAccountMainData = (payload: User) => ({type: SET_ACCOUNT_MAIN_DATA, payload})

export const setNewSocialNetworks = (payload: any) => ({type: SET_NEW_SOCIAL_NETWORKS, payload})

export const tryToUpdatePhoto = (payload: any) => ({type: TRY_TO_UPDATE_PHOTO, payload})

export const tryToChangeAccountMainData = (payload: User) => ({type: TRY_TO_CHANGE_ACCOUNT_MAIN_DATA, payload})