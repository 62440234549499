import { useSelector, useDispatch } from "react-redux"

import { StepBar } from "../../components/StepBar"
import { BottomControlPanel } from '../../components/BottomControlPanel/index'
import { routes } from '../../routes'

import { nextStepBar } from "../../store/reducers/StepBarReducer"
import { changeHeaderTitle } from "../../store/reducers/HeaderTitleReducer"

import { SmallInsctructionPageWhatToDo } from '../../components/SmallInsctructionPageWhatToDo/index'
import { BigInsctructionPageWhatToDo } from '../../components/BigInsctructionPageWhatToDo'

import './styles.sass'
import { FormattedMessage } from "react-intl"
import { useEffect, useState } from "react"
import { ChoosenCheckMarkIcon, EmptyCheckMarkIcon } from "../../icons"
import { asyncUpdateMeasurement } from "../../store/reducers/MeasurementReducer"

export const MeasurementInstructionContainer = () => {

    const dispatch = useDispatch()

    const isNavbarShowed = useSelector( (state: any) => (state.navbar.isNavbarShowed))

    const [isUserAgree, setIsUserAgree] = useState<boolean>(false)

    useEffect( () => {
        dispatch(
            changeHeaderTitle({
                currentHeaderTitle:'header_title_main', 
                currentHeaderAdditionalTitle: 'header_title_subtitle_measurement_type'
            })
        )
        dispatch(nextStepBar(1))
        history.pushState(null, document.title);
        window.addEventListener('popstate', function () {
            history.pushState(null, document.title);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigateBack = () => {

    }

    const navigateNext = () => {
        dispatch(asyncUpdateMeasurement('CREATE_PERSON_LIST'))
        return ''
    }

    return (
        <>
        <div className={`main-instruction-measurement-container ${ isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}>

            <div className="main-instruction-measurement-container__title-wrapper">

                <div className="main-instruction-measurement-container__title-wrapper__sub-wrapper">
                    <span className="main-instruction-measurement-container__title-wrapper__title">
                        <FormattedMessage id='measurement_instruction_page_title'/>
                    </span>

                    <span className="main-instruction-measurement-container__title-wrapper__additional-title">
                        <FormattedMessage id='measurement_instruction_page_additional_title'/>
                    </span>
                </div>

                {
                    document.body.clientWidth > 640
                    &&
                    <StepBar steps={8}/>
                }
            </div>


            <div className="main-instruction-measurement-container__main-insctruction">
                <SmallInsctructionPageWhatToDo />
                <BigInsctructionPageWhatToDo/>
            </div>

            <div 
                className="main-instruction-measurement-container__agreement-panel"
                onClick={() => {
                    if (isUserAgree) {
                        setIsUserAgree(false)
                    } else {
                        setIsUserAgree(true)
                    }
                }}
                style={{marginBottom: '0px'}}
            >
                {
                    isUserAgree
                    ?
                    <><ChoosenCheckMarkIcon/></>
                    :
                    <><EmptyCheckMarkIcon/></>
                }
                <span>
                    <FormattedMessage id='measurement_instruction_page_agreement_panel'/>
                </span>
            </div>


        </div>
        <BottomControlPanel 
            nextPage={routes.makingMeasurementPersonsList} 
            previousPage={routes.main}
            backFunc={navigateBack}
            nextFunc={navigateNext}
            isNextButtonDisabled={!isUserAgree}
        />
        </>
    )
}