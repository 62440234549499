import { Gender } from "../../interfaces/Person"
import { RateMember } from "../../interfaces/RateMember"

let initialState: RateMember = {
    position: '',
    member: {
        name: '',
        role: '',
        gender: Gender.MALE,
        birthday: '',
    },
    comment: '',

}

if (localStorage.currentRateMember !== undefined && (typeof localStorage.currentRateMember === 'string') && localStorage.currentRateMember.length > 0) {
    const preState = JSON.parse(localStorage.currentRateMember)
    if (preState && preState.position  && preState.member.customName.length > 0) {
        initialState = preState   
    }
}

export const SET_CURRENT_RATING_MEMBER = 'SET_CURRENT_RATING_MEMBER'
export const SET_RATE = 'SET_RATE'
export const ASYNC_SET_RATE = 'ASYNC_SET_RATE'

export const CurrentRatingPersonReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_CURRENT_RATING_MEMBER:
            if (action.payload !== undefined) {
                localStorage.setItem('currentRateMember', JSON.stringify(action.payload))
                return action.payload
            } else {
                localStorage.setItem('currentRateMember', '')
                return   {
                    position: '',
                    member: {
                        name: '',
                        role: '',
                        gender: Gender.MALE,
                        birthday: '',
                    },
                    comment: '',
                }  
            }


        case SET_RATE:
            return {
                ...state,
                rate: action.payload.toString()
            }

        default:
            return state
    }
}

export const setCurrentRatingMember = (payload: RateMember) => ({type: SET_CURRENT_RATING_MEMBER, payload})
export const setRate = (payload: number) => ({type: SET_RATE, payload})
export const tryToSetPersonRate = (payload: string) => ({type: ASYNC_SET_RATE, payload})
