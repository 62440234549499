import {put, call, takeLatest} from "redux-saga/effects"
import store from '../store'
import {SIGNUP, SIGNUP_CONFIRMATION, signupSuccessCreator, signupUserExistError, signupConfiramtionError} from "../store/reducers/SignupReducer";
import { nextSignupStage } from "../store/reducers/VerticalStepBarReducer";
import { login } from "../store/reducers/LoginReducer";
import { apiUrl } from "../consts";
import { routes } from "../routes";
async function signup(form) {
    try {
        if (form !== undefined) {
            
            const response = await fetch(
                apiUrl+ '/register/',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(form)
                }
            )
            
            const result = await response.json()
            return result
        }
        return false
    } catch (error) {}
}

function* signupWorker(action) {
    const data = yield call(signup, action.payload)

    if (!!data.id) {
        yield put(signupSuccessCreator(data))
        yield put(nextSignupStage())
    } else if ( data.status && (data.errorKey === "userexists") ) {
        yield put(signupUserExistError())
    }
}

async function signupConfirmation(key: string) {
    try {
        if (key !== undefined) {
            
            const response = await fetch(
                apiUrl + '/activate?key='+key,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    }
                }
            )
            if (response.status == 200){
                const result = await response.json()
                location.assign(routes.startMeasurement)
                return result
            }
        }
        return false
    } catch (error) {
    }   
}


function* SignupConfirmationWorker(action) {
    
    const data = yield call(signupConfirmation, action.payload)

    if (data && data.id_token) {
        yield put(
            login({
                token: data.id_token, 
                userData: {
                    username: store.getState().signup.login,
                    password: store.getState().signup.password
                }}))
    } else {
        yield put(signupConfiramtionError())
    }
}

export function* SignupWatcher() {
    yield takeLatest(SIGNUP, signupWorker)
}

export function* SignupCOnfirmationWatcher() {
    yield takeLatest(SIGNUP_CONFIRMATION, SignupConfirmationWorker)
}