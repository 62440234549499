import { RateMember } from "../../interfaces/RateMember"

export const ADD_NEW_PERSON = 'ADD_NEW_PERSON'
export const TRY_TO_ADD_NEW_PERSON = 'TRY_TO_ADD_NEW_PERSON'
export const CLEAN_PERSON_LIST = 'CLEAN_PERSON_LIST'
export const TRY_TO_ADD_EXISTED_PERSON = 'TRY_TO_ADD_EXISTED_PERSON'
export const DELETE_PERSON = 'DELETE_PERSON'
export const EDIT_IN_PERSON_LIST = 'EDIT_IN_PERSON_LIST'

let initialState: RateMember[] = []

if (localStorage.personList !== undefined && (typeof localStorage.personList === 'string') && localStorage.personList.length > 0) {
    const preState = JSON.parse(localStorage.personList)
    if (preState && preState.length>0 && preState[0].position) {
        initialState = preState   
    }
}

export const PersonMeasurementListReducer = (state = initialState, action ) => {

    switch (action.type) {

        case ADD_NEW_PERSON: 
            localStorage.setItem('personList', JSON.stringify(action.payload))
            return action.payload

        case CLEAN_PERSON_LIST:
            localStorage.setItem('personList', '')
            return []

        case DELETE_PERSON:
            localStorage.setItem('personList', JSON.stringify(action.payload))
            return action.payload

        case EDIT_IN_PERSON_LIST:
            localStorage.setItem('personList', JSON.stringify(action.payload))
            return action.payload

        default: 
            return state

    }
}

export const tryToAddNewPerson = (payload: RateMember) => ({type: TRY_TO_ADD_NEW_PERSON, payload })
export const addNewPersonToMeasurement = (payload: RateMember) => ({type: ADD_NEW_PERSON, payload })
export const cleanPersonList = () => ({type: CLEAN_PERSON_LIST})
export const deletePersonFromPersonList = (payload: RateMember[]) => ({type: DELETE_PERSON, payload})
export const addExistedPersonToMeasurement = (payload: RateMember) => ({type: TRY_TO_ADD_EXISTED_PERSON, payload})
export const editPersonInPersonList = (payload: RateMember[]) => ({type: EDIT_IN_PERSON_LIST, payload})