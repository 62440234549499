import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMeasurementsList } from "../../store/reducers/MeasurementsListReducer";
import {
  ChoosenCheckMarkIcon,
  EmptyCheckMarkIcon,
  MeasurementStageEightIcon,
  MeasurementStageFourIcon,
  MeasurementStageOneIcon,
  MeasurementStageSevenIcon,
  MeasurementStageSixIcon,
  MeasurementStageThreeIcon,
  MeasurementStageTwoIcon,
  OnePersonIcon,
  RedTrashBasketIcon,
} from "../../icons";
import group from "./group.svg";
import { MeasurementInfo } from "../../interfaces/MeasurementInfo";
import { updateMeasurement } from "../../store/reducers/MeasurementReducer";
import {
  addMeasurementToConsultutationList,
  deleteMeasurementFromConsultationList,
} from "../../store/reducers/MeasurementConsultationListReducer";
import {
  cleanPersonList,
  addNewPersonToMeasurement,
} from "../../store/reducers/PersonMeasurementListReducer";
import { cleanPersonForm } from "../../store/reducers/CurrentPersonReducer";
import { routes } from "../../routes";

//import { startMeasurement } from "../../store/reducers/MeasurementReducer"
import "./styles.sass";

export const MeasurementChoosePanel = (prop: { data: MeasurementInfo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [popUp, isOpen] = useState(false);
  const [measurement, setMeasurement] = useState({
    name: "",
    id: "",
  });
  const [isChoosen, setIsChoosen] = useState<boolean>(false);
  const deleteItem = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/measurement/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      }
    );
    if (response.status === 200) {
      isOpen(false);
      dispatch(getMeasurementsList(0));
      return true;
    } else if (response.status === 401) {
      location.assign(routes.login);
    } else {
      return null;
    }
  };
  const getMeasurements = async (typeId) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_HOST + "/measurement-types",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );
      let result = await response.json();
      for (let i = 0; i < result.length; i++) {
        if (result[i].id == typeId) {
          localStorage.setItem("measurementTypesN", JSON.stringify(result[i]));

          switch (prop.data.stage) {
            case "READ_FINAL_INSTRUCTION": {
              navigate("/measurement-end");
              break;
            }
            case "READ_COMMON_INSTRUCTION": {
              navigate("/measurement-instruction");
              break;
            }
            case "START_WIZARD": {
              navigate("/measurement-instruction");
              break;
            }
            case "READ_ME_2_PARTNER_INSTRUCTION": {
              navigate("/measurement-me-to-person-instruction");
              break;
            }
            case "RATE_ME_2_PARTNER": {
              navigate("/measurement-me-to-person-rating");
              break;
            }
            case "READ_PARTNER_2_ME_INSTRUCTION": {
              navigate("/measurement-person-to-me-insctruction");
              break;
            }
            case "RATE_PARTNER_2_ME": {
              navigate("/measurement-person-to-me-rating");
              break;
            }
            case "CREATE_PERSON_LIST": {
              navigate("/measurement-person-list");
              break;
            }
          }
        }
      }
    } catch (error) {}
  };
  function getMeasurementById(id) {
    fetch(`${process.env.REACT_APP_API_HOST}/measurement/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(addNewPersonToMeasurement(data.rateMembers));

        localStorage.setItem("measurement", JSON.stringify(data));
        updateMeasurement(data);
        localStorage.setItem("choosenTypeId", data.typeId);
        //dispatch(startMeasurement(data.typeId))
        getMeasurements(data.typeId);

        //localStorage.setItem('measurementType', prop.data.typeName || '')
      });
  }
  function getGroupMeasurementById(id) {
    fetch(`${process.env.REACT_APP_API_HOST}/measurement/details/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("measurement", JSON.stringify(data));

        //dispatch(startMeasurement(data.typeId))

        //localStorage.setItem('measurementType', prop.data.typeName || '')
      });
  }
  const linkToMeassurement = () => {
    if (prop.data.groupId == null) {
      if (prop.data.stage === "COMPLETED") {
        navigate("/measurements-statistic/" + prop.data.measurementId);
      } else {
        getMeasurementById(prop.data.measurementId);
        //localStorage.setItem('measurement', JSON.stringify(prop.data))
        dispatch(cleanPersonList());
        dispatch(cleanPersonForm());
      }
    } else if (prop.data.measurementFinished) {
      navigate("/measurements-statistic-group/" + prop.data.measurementId);
    } else {
      getGroupMeasurementById(prop.data.measurementId);
      navigate(routes.GroupStartInstruction + "?id=" + prop.data.measurementId);
      //localStorage.setItem('measurement', JSON.stringify(prop.data))
      dispatch(cleanPersonList());
      dispatch(cleanPersonForm());
    }
  };
  const toggleIsChoosen = () => {
    if (isChoosen) {
      setIsChoosen(false);
      dispatch(deleteMeasurementFromConsultationList(prop.data.measurementId));
    } else {
      setIsChoosen(true);
      dispatch(addMeasurementToConsultutationList([prop.data]));
    }
  };

  return (
    <div className="choose-measurement-panel">
      {popUp && (
        <div className="pop_up_warning">
          <div className="pop__up__warning__body">
            <p>
              Вы уверены, что хотите удалить замер: <br /> {measurement.name}
            </p>
            <div>
              <div
                className="white-button"
                onClick={() => deleteItem(measurement.id)}
              >
                Удалить
              </div>
              <div className="green-button" onClick={() => isOpen(false)}>
                Отмена
              </div>
            </div>
          </div>
        </div>
      )}

      {document.body.clientWidth > 640 ? (
        <>
          {" "}
          {prop.data.stage === "COMPLETED" ? (
            <div
              className="choose-measurement-panel__check-mark"
              onClick={() => toggleIsChoosen()}
            >
              {isChoosen ? <ChoosenCheckMarkIcon /> : <EmptyCheckMarkIcon />}
            </div>
          ) : (
            <div
              style={{ opacity: "0.3", pointerEvents: "none" }}
              className="choose-measurement-panel__check-mark"
            >
              <EmptyCheckMarkIcon />
            </div>
          )}
          <div className="choose-measurement-panel__person-icon">
            {prop.data.groupId == null ? (
              <OnePersonIcon />
            ) : (
              <img src={group} />
            )}
          </div>
          <span
            onClick={() => linkToMeassurement()}
            className="choose-measurement-panel__title"
          >
            {prop.data.groupId == null
              ? prop.data.comment || "Замер ***"
              : prop.data.name +
                "; группа: " +
                prop.data.groupName +
                "; офис:" +
                prop.data.officeName}
          </span>
        </>
      ) : (
        <div className="choose-measurement-panel__small-row">
          <div
            style={
              prop.data.stage !== "COMPLETED"
                ? { opacity: "0.3", pointerEvents: "none" }
                : {}
            }
            className="choose-measurement-panel__check-mark"
            onClick={() => toggleIsChoosen()}
          >
            {isChoosen ? <ChoosenCheckMarkIcon /> : <EmptyCheckMarkIcon />}
          </div>
          <div className="choose-measurement-panel__person-icon">
            {prop.data.groupId == null ? (
              <OnePersonIcon />
            ) : (
              <img src={group} />
            )}
          </div>

          <span
            onClick={() => linkToMeassurement()}
            className="choose-measurement-panel__title"
          >
            {prop.data.groupId == null ? (
              prop.data.comment || "Замер ***"
            ) : (
              <div className="showPar">
                <div>{prop.data.name};</div>
                <div>группа: {prop.data.groupName};</div>
                <div>группа: {prop.data.officeName};</div>
              </div>
            )}
          </span>
        </div>
      )}

      <div className="choose-measurement-panel__additional-data">
        <div
          onClick={() => linkToMeassurement()}
          className="choose-measurement-panel__additional-data__column type_measurement_width"
          style={
            document.body.clientWidth < 640
              ? { width: "auto" }
              : {
                  width: "380px",
                }
          }
        >
          <span>
            <FormattedMessage id="measurements_list_panel_type" />
          </span>
          <div>
            {prop.data.groupId == null ? prop.data.typeName : "Групповой замер"}
          </div>
        </div>
        <div className="choose-measurement-panel__additional-data__column">
          <span>
            <FormattedMessage id="measurements_list_panel_date" />
          </span>
          <div>
            {prop.data &&
            prop.data.creationDate &&
            prop.data.creationDate.length > 10
              ? prop?.data?.creationDate.slice(8, 10) +
                "." +
                prop.data.creationDate.slice(5, 7) +
                "." +
                prop.data.creationDate.slice(0, 4)
              : "0"}
          </div>
        </div>
      </div>

      <div className="choose-measurement-panel__icons">
        <div
          style={
            prop.data.groupId != null ? { marginRight: "0", width: "0" } : {}
          }
        >
          {(prop.data &&
            prop.data.stage &&
            (prop.data.stage === "READ_COMMON_INSTRUCTION" ||
              prop.data.stage === "CREATE_PERSON_LIST" ||
              prop.data.stage === "START_WIZARD") && (
              <MeasurementStageOneIcon />
            )) ||
            (prop.data.stage === "READ_ME_2_PARTNER_INSTRUCTION" && (
              <MeasurementStageTwoIcon />
            )) ||
            (prop.data.stage === "RATE_ME_2_PARTNER" && (
              <MeasurementStageThreeIcon />
            )) ||
            (prop.data.stage === "READ_PARTNER_2_ME_INSTRUCTION" && (
              <MeasurementStageFourIcon />
            )) ||
            (prop.data.stage === "RATE_PARTNER_2_ME" && (
              <MeasurementStageSixIcon />
            )) ||
            (prop.data.stage === "READ_FINAL_INSTRUCTION" && (
              <MeasurementStageSevenIcon />
            )) ||
            (prop.data.stage === "COMPLETED" && <MeasurementStageEightIcon />)}
        </div>
        {prop.data.groupId != null && prop.data.measurementFinished ? (
          <div>
            <MeasurementStageEightIcon />
          </div>
        ) : (
          prop.data.groupId != null && <div>Новый</div>
        )}
        {prop.data.groupId == null ? (
          <div
            onClick={() => {
              if (prop.data.measurementId && prop.data.typeName) {
                isOpen(true);
                setMeasurement({
                  id: prop.data.measurementId,
                  name: prop.data.typeName,
                });
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <RedTrashBasketIcon />
          </div>
        ) : (
          <div style={{ opacity: "0" }}>
            <RedTrashBasketIcon />
          </div>
        )}
      </div>
    </div>
  );
};
