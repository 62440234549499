import { call,  put,  takeLatest } from 'redux-saga/effects'
import { apiUrl } from '../consts'
import { routes } from '../routes'
import store from '../store'
import { getAllConsultationMessages } from '../store/reducers/ConsultationChatReducer'
import { setConsultationCommission } from '../store/reducers/ConsultationCommisionReducer'
import { TRY_TO_SET_VALUE_CONSULTATION } from '../store/reducers/ConsultationsCostReducer'
import { getCurrentConsultation } from '../store/reducers/CurrentConsultationReducer'
import { closeModal } from '../store/reducers/ModalWindowsReducer'


const asyncSetValue = async (cost: number) => {
    try {
        const consultation = store.getState().currentConsultation
        const response = await fetch(
            apiUrl + '/consultation/set-price/',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    id: consultation?.id,
                    cost: cost
                })
            }
        )

        if (response.status === 200) {
            return true
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(err) {}
}

function* SetConsultationValueWorker(action) {
    const isSuccess = yield call(asyncSetValue, action.payload)
    const consultation = store.getState().currentConsultation
    if (isSuccess) {
        yield put(getAllConsultationMessages())
        yield put(setConsultationCommission(0))
        yield put(getCurrentConsultation(consultation?.id))
        yield put(closeModal())
    }
}

export function* SetConsultationValueWatcher() {
    yield takeLatest(TRY_TO_SET_VALUE_CONSULTATION, SetConsultationValueWorker)
}