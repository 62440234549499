export const apiUrl =  process.env.REACT_APP_API_HOST
export const gettingChatMessagesDelay = Number(process.env.REACT_APP_MESSAGE_DELAY)

export const cleanMeasurementProcessStorage = () => {
    localStorage.setItem('measurement', '')
    localStorage.setItem('measurement_type', '')
    localStorage.setItem('currentRateMember', '')
    localStorage.setItem('personList', '')
}

export const cleanCreatingConsultationProcessStorage = () => {
    localStorage.setItem('createConsultation', '')
    localStorage.setItem('measurementList', '')
}

export const cleanMeasurementType = () => {
    if (localStorage.measurement_type === 'undefined') {
        localStorage.setItem('measurement_type', '')
    }
}

export const mainGraphicPointSize = 6

export const mainGraphicSize = document.body.clientWidth > 640 ? 410 : 320
