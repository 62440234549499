import { MeasurementInfo } from "../../interfaces/MeasurementInfo"

export const ADD_MEASUREMENT_TO_CONSULTATION_LIST = 'ADD_MEASUREMENT_TO_CONSULTATION_LIST'
export const DELETE_MEASUREMENT_FROM_CONSULTATION_LIST = 'DELETE_MEASUREMENT_FROM_CONSULTATION_LIST'
export const CLEAN_MEASUREMENT_CONSULTATION_LIST = 'CLEAN_MEASUREMENT_CONSULTATION_LIST'

let initialState: MeasurementInfo[] = []

if (localStorage.choosenMeasurementsList !== undefined && (typeof localStorage.choosenMeasurementsList === 'string') && localStorage.choosenMeasurementsList.length > 0) {
    const measurementList: MeasurementInfo[] = JSON.parse(localStorage.choosenMeasurementsList)
    if (measurementList.length && measurementList.length>0) {
        initialState = [...measurementList]
    }
}

export const MeasurementConsultationListReducer = (state = initialState, action) => {

    switch (action.type) {

        case ADD_MEASUREMENT_TO_CONSULTATION_LIST:
            localStorage.setItem('choosenMeasurementsList', JSON.stringify([...action.payload, ...state]))
            return [
                ...action.payload,
                ...state
            ]
        
        case DELETE_MEASUREMENT_FROM_CONSULTATION_LIST:
            let newList: MeasurementInfo[] = []
            state.filter( (elem) => {
                if (action.payload !== elem.measurementId) {
                    newList.push(elem)
                }
            })

            localStorage.setItem('choosenMeasurementsList', JSON.stringify(newList))
            return newList
        
        case CLEAN_MEASUREMENT_CONSULTATION_LIST:
            localStorage.setItem('choosenMeasurementsList', JSON.stringify([]))
            return []

        default:
            return state
    }
}

export const addMeasurementToConsultutationList = (payload: MeasurementInfo[]) => ({type: ADD_MEASUREMENT_TO_CONSULTATION_LIST, payload})
export const deleteMeasurementFromConsultationList = (payload: string) => ({type: DELETE_MEASUREMENT_FROM_CONSULTATION_LIST, payload})
export const cleanMeasurementConsultationList = () => ({type: CLEAN_MEASUREMENT_CONSULTATION_LIST})