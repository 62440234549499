import { useState } from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
//import { FacebookCircleIcon, GoogleCircleIcon, LinkedInCircleIcon } from '../../icons'
import './styles.sass'
import { signupCreator } from '../../store/reducers/SignupReducer'
//import vkLogo from '../LoginForm/vk.svg'
import { Link } from 'react-router-dom'
import { routes } from '../../routes'
interface SignupForm {
    email: {
        value: string,
        changed: boolean
    };
    password: {
        value: string,
        changed: boolean
    };
    confirmPassword: {
        value: string,
        changed: boolean
    };
}

interface SignupErrors {
    email: string;
    password: string;
    confirmPassword: string;
}

export const SignUpForm = () => {
    // function getVk(){
    //     document.location.href = `${process.env.REACT_APP_API_VK}/authorization/vk`
    
    // }
    const intl = useIntl()
    const dispatch = useDispatch()

    const isUserExistError= useSelector( (state: any) => (state.signup.userExistError))

    const [isValid, setIsValid] = useState<boolean>(false)
    const [form, setForm] = useState<SignupForm>({
        email: {
            value: '',
            changed: false
        },
        password: {
            value: '',
            changed: false
        },
        confirmPassword: {
            value: '',
            changed: false
        }
    })
    const [errors, setErrors] = useState<SignupErrors>({
        email: '',
        password: '',
        confirmPassword: ''
    })

    const validate = () => {
        let isValidTemp = true;
        let errorsTemp = {
            email: '',
            password: '',
            confirmPassword: ''
        };

        if (form.email.changed && !form.email.value) {
            isValidTemp = false;
            errorsTemp.email = 'signup_text_error_must_field';
        }

        if (form.email.changed && typeof form.email.value !== "undefined") {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(form.email.value)) {
                isValidTemp = false;
                errorsTemp.email = "signup_text_error_incorrect_email";
            }
        }

        if (form.password.changed && !form.password.value) {
            isValidTemp = false;
            errorsTemp.password = 'signup_text_error_must_field';
        }

        if (form.password.changed && typeof form.password.value !== "undefined") {
            let passwordPattern = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[[!@#\$%\^\&*\)\(+=._\-\A-Za-z\d]{6,}$/)
            if (!passwordPattern.test(form.password.value) ) {
                isValidTemp = false;
                errorsTemp.password = "signup_text_error_password_length";
            }

        }

        if (form.confirmPassword.changed && !form.confirmPassword.value) {
            isValidTemp = false;
            errorsTemp.confirmPassword = 'signup_text_error_must_field';
        }

        if (form.confirmPassword.changed && typeof form.confirmPassword.value !== "undefined") {
            if (form.confirmPassword.value !== form.password.value) {
                isValidTemp = false;
                errorsTemp.confirmPassword = "signup_text_error_passwords_not_match";
            }
        }

        setErrors(errorsTemp);
        setIsValid(isValidTemp);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (isValid) {
            dispatch(signupCreator({
                login: form.email.value,
                password: form.password.value
            }))
        } else {
            validate()
        }
    }

    const handleChange = (event) => {

        switch (event.target.name) {
            case "email":
                setForm(
                    {
                        ...form,
                        email: {
                            value: event.target.value,
                            changed: true
                        }
                    }
                );
                break;
            case "password":
                setForm(
                    {
                        ...form,
                        password: {
                            value: event.target.value,
                            changed: true
                        }
                    }
                );
                break;
            case "confirmPassword":
                setForm(
                    {
                        ...form,
                        confirmPassword: {
                            value: event.target.value,
                            changed: true
                        }
                    }
                );
                break;
        }

        if(!isValid) {
            validate()
        }
    }

    const handleFocus = (event) => {
        switch (event.target.name) {
            case "email":
                setForm(
                    {
                        ...form,
                        email: {
                            value: form.email.value,
                            changed: true
                        }
                    }
                );
                break;
            case "password":
                setForm(
                    {
                        ...form,
                        password: {
                            value: form.password.value,
                            changed: true
                        }
                    }
                );
                break;
            case "confirmPassword":
                setForm(
                    {
                        ...form,
                        confirmPassword: {
                            value: form.confirmPassword.value,
                            changed: true
                        }
                    }
                );
                break;
        }
    }

    return (
        <form className='sign-up-form-wrapper' onSubmit={handleSubmit}>
            <span className='sign-up-title'>
                <FormattedMessage id='sign_up_form_title'/>
            </span>

            {
                isUserExistError
                &&
                <div className='login-error-panel'>
                    <span className='login-error-panel__title'>
                        <FormattedMessage id='signup_validation_error_title'/>
                    </span>

                    <span className='login-error-panel__sub-title'>
                        <FormattedMessage id='signup_validation_error_text'/>
                    </span>
                </div>
            }

            <div className={`text-input`}>
                <input
                    type='email'
                    placeholder={ intl.formatMessage({ id: 'sign_up_form_email_placeholder' }) }
                    value={form.email.value}
                    name={"email"}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={validate}
                    className={`${ !isValid && errors.email.length !== 0 && 'error-input'}`}
                />
                {
                    !isValid && errors.email.length !== 0
                    &&
                    <span className={"error-text"}><FormattedMessage id={errors.email}/></span>
                }
            </div>
            <div className={`text-input`}>
                <input
                    type='password'
                    placeholder={ intl.formatMessage({ id: 'sign_up_form_pass_placeholder' })}
                    value={form.password.value}
                    name={"password"}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={validate}
                    className={`${ !isValid && errors.password.length !== 0 && 'error-input'}`}
                />
                {
                    !isValid && errors.password.length !== 0
                    &&
                    <span className={"error-text"}><FormattedMessage id={errors.password}/></span>
                }
            </div>
            <div className={`text-input`}>
                <input
                    type='password'
                    placeholder={ intl.formatMessage({ id: 'sign_up_form_pass_confirm_placeholder' })}
                    value={form.confirmPassword.value}
                    name={"confirmPassword"}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={validate}
                    className={`${ !isValid && errors.confirmPassword.length !== 0 && 'error-input'}`}
                />
                {
                    !isValid && errors.confirmPassword.length !== 0
                    &&
                    <span className={"error-text"}><FormattedMessage id={errors.confirmPassword}/></span>
                }
            </div>
            <button
                className={`green-button ${ !isValid  ? 'disabled-button' : '' }`}
            >
                <FormattedMessage id='signup_page_reg_button'/>
            </button>
            <div className="registration-politics">
                <input type="checkbox" checked></input>
                <span>
                Вы соглашаетесь с <Link target="_blank" to={routes.politics} style={{textDecoration: 'underline', color: '#219653'}}> политикой конфиденциальности</Link> 
                </span>
            </div>
            <div className="registration-politics">
                <input type="checkbox" checked></input>
                <span>
                Вы даете согласие на <Link target="_blank" to={routes.agreement} style={{textDecoration: 'underline', color: '#219653'}}> обработку персональных данных</Link> 
                </span>
            </div>
           {/*  <div className='social-networks-login-wrapper'>
                <span>Или войти с помощью</span>
                <div>
                   
                    <span onClick={() => getVk()}>
                        <img src={vkLogo} style={{width: '50px', cursor: 'pointer'}}/>
                    </span>
                </div>
            </div> */}
           
        </form>
    )
}
