import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { StepBar } from "../../components/StepBar";

import { BottomControlPanel } from "../../components/BottomControlPanel/index";

import { nextStepBar } from "../../store/reducers/StepBarReducer";

import { ChooseConsultantPanel } from "../../components/ConsultationUserComponents/ChooseConsultantPanel/index";
import { tryToCreateConsultation } from "../../store/reducers/CreatingConsultationReducer";
import { useNavigate } from "react-router-dom";

import { routes } from "../../routes";

export const ConfirmConsultationContainer = () => {
  const dispatch = useDispatch();

  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

  const consultation = useSelector((state: any) => state.creatingConsultation);
  const isConsultationConfirmed =
    consultation &&
    consultation.measurements &&
    consultation.measurements.length === 0 &&
    consultation.addresse === "";

  const navigate = useNavigate();

  useEffect(() => {
    if (isConsultationConfirmed) {
      navigate(routes.consultationChat);
    }
    dispatch(nextStepBar(3));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultation]);

  const createConsultationData = useSelector(
    (state: any) => state.creatingConsultation
  );
  const measurementsList = useSelector(
    (state: any) => state.measurementConsultationList
  );
  localStorage.setItem("chooseMeas", JSON.stringify(measurementsList));
  const navigateBack = () => {
    return true;
  };

  const navigateNext = () => {
    dispatch(tryToCreateConsultation(createConsultationData));
    return "";
  };

  return (
    <>
      <div
        className={`start-consultation-container ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
      >
        <div className="start-consultation-container__title-wrapper">
          <span>
            <FormattedMessage id="consultation_start_page_title" />
          </span>

          {document.body.clientWidth > 640 && <StepBar steps={4} />}
        </div>

        <div className="start-consultation-container__additional-title">
          <FormattedMessage id="consultation_confirm_page_additional_title" />
        </div>

        <span className="start-consultation-container__component-title">
          <FormattedMessage id="consultation_confirm_page_your_consultant" />
        </span>

        <div className="start-consultation-container__component">
          <ChooseConsultantPanel
            data={createConsultationData?.consultant}
            choosen={true}
          />
        </div>

        <span className="start-consultation-container__component-title">
          <FormattedMessage id="consultation_confirm_page_measurements" />
        </span>

        <div
          className="
                    start-consultation-container__component 
                    start-consultation-container__measurements-list-wrapper
                    "
        >
          {measurementsList &&
            measurementsList.length > 0 &&
            measurementsList.map((elem, index) => (
              <div
                key={index}
                className="start-consultation-container__measurements-list-wrapper__measurement-panel"
              >
                <div className="start-consultation-container__measurements-list-wrapper__measurement-panel__info">
                  <div>
                    <span>
                      <FormattedMessage id="consultation_start_page_measurement_panel_data" />
                    </span>
                    {elem.creationDate && elem.creationDate.length >= 10
                      ? elem.creationDate.slice(8, 10) +
                        "." +
                        elem.creationDate.slice(5, 7) +
                        "." +
                        elem.creationDate.slice(0, 4)
                      : elem.creationDate}
                  </div>

                  <div>
                    <span>
                      <FormattedMessage id="consultation_start_page_measurement_panel_type" />
                    </span>
                    {elem.typeName}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <span className="start-consultation-container__component-title">
          <FormattedMessage id="consultation_confirm_page_problem" />
        </span>

        <div className="start-consultation-container__problem-text">
          {createConsultationData.problemDesc1}
        </div>

        <div className="start-consultation-container__problem-text">
          {createConsultationData.problemDesc2}
        </div>

        <div className="start-consultation-container__problem-text">
          {createConsultationData.problemDesc3}
        </div>
      </div>

      <BottomControlPanel
        nextPage={routes.consultationChat}
        previousPage={routes.startMeasurement}
        backFunc={navigateBack}
        nextFunc={navigateNext}
        isNextButtonDisabled={false}
      />
    </>
  );
};
