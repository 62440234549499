import { call, put, takeLatest } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { loginRecoveryInitFailed, PASSWORD_RECOVERY_INIT, PASSWORD_RECOVERY_FINISH, passwordRestoreSuccess, recoveryPasswordFinishFailed } from "../store/reducers/RestorePasswordReducer";
import { nextLoginRecoveryStepbarState } from "../store/reducers/VerticalStepBarReducer";
import { routes } from "../routes";
async function initPasswordRecovery(mail) {
    try {
        if (mail !== undefined) {
            const response = await fetch(
                apiUrl + '/account/restore-password/init',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({mail: mail})
                }
            )
            return response
        }
        return false
    } catch (error) {}
}

function* recoveryPasswordInitWorker(action) {

    const data = yield call(initPasswordRecovery, action.payload)

    if (data.status === 400) {
        yield put(loginRecoveryInitFailed())
    } else if (data.status === 200) {
        yield put(nextLoginRecoveryStepbarState())
    }
    
}


async function finishPasswordRecovery(form) {
    try {
        if (form !== undefined) {
            const response = await fetch(
                process.env.REACT_APP_API_HOST+'/account/restore-password/finish',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(form)
                }
            )
            if (response.status == 200){
                location.assign(routes.login)
               
            }
            return response
           
        }
        return false
    } catch (error) {}
}

function* recoveryPasswordFinishWorker(action) {

    const data = yield call(finishPasswordRecovery, action.payload)
   
    if (data.status === 200) {
        yield put(passwordRestoreSuccess())
    } else if (data.status === 500) {
        yield put(recoveryPasswordFinishFailed())
    }
    
}

export function* PasswordRecoveryInitWatcher() {
    yield takeLatest(PASSWORD_RECOVERY_INIT, recoveryPasswordInitWorker)
}

export function* PasswordRecoveryFinishWatcher() {
    yield takeLatest(PASSWORD_RECOVERY_FINISH, recoveryPasswordFinishWorker)
}