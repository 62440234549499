import { call, put, takeLatest } from "redux-saga/effects";
import { FIND_PERSONS_BY_NAME, setPotentialPersonList } from '../store/reducers/FindMeasurementPersonsListReducer'
import { apiUrl } from "../consts";
import { routes } from "../routes";
//import store from '../store'

async function asyncFindPersons(name) {
    try {

        const response = await fetch(
            apiUrl + '/person/search?name='+name,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            }
        )
        
        if (response.status === 200) {
            const result = await response.json()

            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(error) {}
}

function* findPersonsWorker(action) {
    const data = yield call(asyncFindPersons, action.payload)

    if (data) {
        yield put(setPotentialPersonList(data))
    }

}

export function* FindPersonsByNameWatcher() {
    yield takeLatest(FIND_PERSONS_BY_NAME, findPersonsWorker)
}