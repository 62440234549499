import { useState } from 'react'
import { useSelector } from 'react-redux'
import { InfoCircleIcon, ThinGreyDoubleRightArrowIcon } from '../../icons'
import './styles.sass'

export const LeftSideInstruction = (prop: {text: string}) => {

    const isNavbarShowed = useSelector( (state: any) => (state.navbar.isNavbarShowed))

    const [isLeftSideInsctructionOpened, setIsLeftSideInsctructionOpened] = useState<boolean>(false)

    const toggleIsLeftSideInsctructionOpened = () => {
        if (isLeftSideInsctructionOpened) {
            setIsLeftSideInsctructionOpened(false)
        } else {
            setIsLeftSideInsctructionOpened(true)
        }
    }

    return (
        <div 
            className='left-side-instruction-wrapper'
            style={{
                left: 
                    (isNavbarShowed && document.body.clientWidth > 640) 
                    ?
                    '260px'
                    :
                    '0px'
            }}
        >

            <div 
                className={`
                    left-side-instruction-wrapper__text-panel
                    ${isLeftSideInsctructionOpened && 'left-side-instruction-text-panel-opened'}`
                }
                style={{
                   width: 
                    isLeftSideInsctructionOpened 
                    ? 
                    (isNavbarShowed && document.body.clientWidth > 640) && '71.5vw' || '90vw'
                    : 
                    '0px'
                }}
            >
                <div

                >
                    <div dangerouslySetInnerHTML={{__html: prop.text || ''}} />
                </div>
            </div>

            <div
                className='left-side-instruction-wrapper__opening-panel'
                onClick={() => toggleIsLeftSideInsctructionOpened()}
            >
                <div className='left-side-instruction-wrapper__opening-panel__info-icon'>
                    <InfoCircleIcon/>
                </div>
                <div 
                    className='left-side-instruction-wrapper__opening-panel__arrow-icon'
                    style={{transform: isLeftSideInsctructionOpened ? 'rotate(180deg)' : ''}}
                >
                    <ThinGreyDoubleRightArrowIcon/>
                </div>
            </div>



        </div>
    )
}