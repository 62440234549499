import { UserTask } from "../../interfaces/UserTask"

export const TRY_TO_GET_USER_TASK_LIST = 'TRY_TO_GET_USER_TASK_LIST'
export const SET_USER_TASK_LIST = 'SET_USER_TASK_LIST'

const initialState: UserTask[] | null = []

export const UserTaskReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_USER_TASK_LIST:
            return action.payload

        default:    
            return state
    }
}

export const tryToGetUserTaskList = () => ({type: TRY_TO_GET_USER_TASK_LIST})
export const setUserTaskList = (payload: UserTask[]) => ({type: SET_USER_TASK_LIST, payload})
