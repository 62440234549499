import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { MeasurementInfo } from "../interfaces/MeasurementInfo";
import { routes } from "../routes";
import { createMeasurement, START_MEASUREMENT } from "../store/reducers/MeasurementReducer";
import { cleanPersonList } from '../store/reducers/PersonMeasurementListReducer'

async function AsyncCreateNewMeasurement(typeId) {
    try {

        const date = new Date().toISOString()

        const response = await fetch(
            apiUrl + '/measurement/',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    creationDate: date,
                    name: "lorem",
                    stage: "START_WIZARD",
                    typeId: typeId,
                })
            }
        )

        if (response.status === 200) {
            const result: MeasurementInfo = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch (error) {
    } 
}

function* CreateMeasurementWorker(action) {
    const data = yield call(AsyncCreateNewMeasurement, action.payload)

    if (data.id) {
        yield put(cleanPersonList())
        yield put(createMeasurement({...data, typeId: action.payload}))
    }
}

export function* CreateMeasurementWatcher() {
    yield takeEvery(START_MEASUREMENT, CreateMeasurementWorker)
}