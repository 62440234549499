import {all} from "redux-saga/effects"
import {GetMeasurementStatisticWatcher} from "./GetMeasurementStatisticWatcher"

import { SignupWatcher, SignupCOnfirmationWatcher } from './SignupWatcher'
import { LoginWatcher } from './LoginWatcher'
import { UserWatcher } from './UserWatcher'
import { PasswordRecoveryInitWatcher, PasswordRecoveryFinishWatcher } from './PasswordRecoveryWatcher'
import { CreateMeasurementWatcher } from './CreateMeasurementWatcher'
import { UpdateMeasurementWatcher } from './UpdateMeasurementWatcher'
import { PersonStatusesWatcher } from './PersonStatusesWatcher'
import { FindPersonsByNameWatcher } from './FindPersonsByNameWatcher'
import { AddNewPersonWatcher, AddNewExistedPersonWatcher } from './AddNewPersonWatcher'
import { DeletePersonWatcher, EditPersonWatcher } from './EditPersonWatcher'
import { RatePersonWatcher } from './RatePersonWatcher'
import { GetMeasurementImagesWatcher } from './GetMeasurementImagesWatcher'
import { GetMeasurementsListWatcher } from './GetMeasurementsListWatcher'
import { GetConsultantsListWatcher } from './GetConsultantsListWatcher'
import { FindFilterConsultantsListWatcher } from './FindFilterConsultantsListWatcher'
import { CreateConsultationWatcher } from './CreateConsultationWatcher'
import { GetConsultantWatcher } from './GetConsultantWatcher'
import { GetAllConsultationMessagesWatcher } from './GetAllConsultationMessagesWatcher'
import { SendConsultationMessageWatcher } from './SendConsultationMessageWatcher'
import { ConsultationCommissionWatcher } from './ConsultationCommissionWatcher'
import { GetUserTaskListWatcher } from './GetUserTaskListWatcher'
import { SetConsultationValueWatcher } from './SetConsultationValueWatcher'
import { PayConsultationWatcher } from './PayConsultationWatcher'
import { DeleteMeasurementFromConsultationWatcher } from './DeleteMeasurementFromConsultationWatcher'
import { AddMeasurementToConsultationWatcher } from './AddMeasurementToConsultationWatcher'
import { GetCurrentConsultationWatcher } from './GetCurrentConsultationWatcher'
import { RejectConsultationWatcher } from './RejectConsultationWatcher'

import { GetAccountMainDataWatcher } from './GetAccountMainDataWatcher'
import { GetAccountFinancesWatcher } from './GetAccountFinancesWatcher'
import { GetAccountServicesWatcher } from './GetAccountServicesWatcher'
import { ChangeAccountPasswordWatcher } from './ChangeAccountPasswordWatcher'
import { GetNotificationsSettingsWatcher } from './GetNotificationsSettingsWatcher'
import { DeleteSocialNetworksWatcher } from './DeleteSocialNetworksWatcher'
import { SetNewSocialNetworksWatcher } from './SetNewSocialNetworksWatcher'
import { UpdateAccountPhotoWatcher } from './UpdateAccountPhotoWatcher'
import { CountriesListWatcher } from './CountriesListWatcher'
import { CitiesListWatcher } from './CitiesListWatcher'
import { UpdateAccountMainDataWatcher } from './UpdateAccountMainDataWatcher'
import { ChangeEmailWatcher } from './ChangeEmailWatcher'

import { GetConsultationsListWatcher } from './GetConsultationsListWatcher'
import { UserAcceptPayConsultationWatcher } from "./UserAcceptPayConsultationWatcher"
import { CloseConsultationsWatcher } from "./CloseConsultationsWatcher"
import { ConfirmConsultationWatcher } from "./ConfirmConsultationWatcher"
import { GetMeasurementTypeListWatcher } from "./GetMeasurementTypeListWatcher"
import { PersonListWatcher } from "./PersonListWatcher"
import { ConsultationsCancelValueWatcher } from "./ConsultationsCancelValueWatcher"
import { DownloadConsultationReportWatcher } from "./DownloadConsultationReportWatcher"
import { SendConsultationReviewWacther } from "./SendConsultationReviewWacther"
import { EndMeasurementWatcher } from "./EndMeasurementWatcher"


export function* rootWatcher() {
    yield all([
        LoginWatcher(), 
        UserWatcher(), 
        SignupWatcher(), 
        SignupCOnfirmationWatcher(),
        PasswordRecoveryInitWatcher(),
        PasswordRecoveryFinishWatcher(),

        CreateMeasurementWatcher(),
        UpdateMeasurementWatcher(),
        PersonStatusesWatcher(),
        FindPersonsByNameWatcher(),
        AddNewPersonWatcher(),
        AddNewExistedPersonWatcher(),
        DeletePersonWatcher(),
        EditPersonWatcher(),
        RatePersonWatcher(),
        GetMeasurementImagesWatcher(),
        EndMeasurementWatcher(),

        GetConsultantWatcher(),
        GetMeasurementsListWatcher(),
        GetConsultantsListWatcher(),
        FindFilterConsultantsListWatcher(),
        CreateConsultationWatcher(),
        DeleteMeasurementFromConsultationWatcher(),
        GetAllConsultationMessagesWatcher(),
        SendConsultationMessageWatcher(),
        ConsultationCommissionWatcher(),
        GetUserTaskListWatcher(),
        SetConsultationValueWatcher(),
        ConsultationsCancelValueWatcher(),
        PayConsultationWatcher(),
        AddMeasurementToConsultationWatcher(),
        GetCurrentConsultationWatcher(),
        RejectConsultationWatcher(),
        UserAcceptPayConsultationWatcher(),
        CloseConsultationsWatcher(),
        ConfirmConsultationWatcher(),
        DownloadConsultationReportWatcher(),
        SendConsultationReviewWacther(),

        GetMeasurementStatisticWatcher(),

        GetAccountMainDataWatcher(),
        GetAccountFinancesWatcher(),
        GetAccountServicesWatcher(),
        ChangeAccountPasswordWatcher(),
        GetNotificationsSettingsWatcher(),
        DeleteSocialNetworksWatcher(),
        SetNewSocialNetworksWatcher(),
        UpdateAccountPhotoWatcher(),
        CountriesListWatcher(),
        CitiesListWatcher(),
        UpdateAccountMainDataWatcher(),
        ChangeEmailWatcher(),

        GetConsultationsListWatcher(),
        GetMeasurementTypeListWatcher(),

        PersonListWatcher(),
    ])
}