import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
 
import './styles.sass'
import { addExistedPersonToMeasurement, tryToAddNewPerson } from '../../store/reducers/PersonMeasurementListReducer'
import { useState } from 'react'
import { DeleteCrossIcon, EditPencilIcon } from '../../icons'
import { showDeletePersonFromMeasurementModal, showEditPersonMeasurementModal } from '../../store/reducers/ModalWindowsReducer'
import { cleanPersonForm } from '../../store/reducers/CurrentPersonReducer'

export const PersonListPanel = () => {

    const dispatch = useDispatch()

    const currentPerson = useSelector( (state: any) => (state.currentPerson))
    
    const personList = useSelector( (state: any) => (state.personMeasurementList))
    const personListLength = personList.length
   
    const accountData = useSelector( (state: any) => (state.user) )

    const [choosenPerson, setChoosenPerson] = useState<number>(122222)

    const checkIsCurrentPersonEmpty = () => {
        return (
            (currentPerson.member.customName)
            &&
            (currentPerson.member.customName.length > 0)
            &&
            (currentPerson.member.gender.length > 0)
            &&
            (currentPerson.member.role.length > 0)
        )
    }
 
    const toggleChoosenPerson = (index: number) => {
        if (index === choosenPerson) {
            setChoosenPerson(122222222)
        } else {
            setChoosenPerson(index)
        }
    }
    
    return (
        
        <div className='person-list-panel-wrapper'>
            <div className='person-list-panel-wrapper__control-panel'>

                <div 
                    className={`control-panel-green-button ${ currentPerson && !checkIsCurrentPersonEmpty() && 'disabled-panel-green-button'}`}
                    onClick={ () => {
                       if (checkIsCurrentPersonEmpty()){
                        if (currentPerson.member.canDeleted) {
                            dispatch(cleanPersonForm())
                            dispatch(tryToAddNewPerson(currentPerson)) 
                        } else {
                            
                            dispatch(addExistedPersonToMeasurement(currentPerson)) 
                            dispatch(cleanPersonForm())
                        }
                        
                        setTimeout( () => {}, 500)
                       }
                      
                    }}
                >
                    
                    <FormattedMessage id='measurement_person_list_page_control_panel_add'/>
                      
                </div>

                <div>
                    
                    <div>

                    </div>

                    <div>

                    </div>

                </div>
            </div>

            <div className='person-list-panel-wrapper__persons-wrapper'>
                {
                    personList
                    &&
                    accountData
                    &&
                    personList.slice(1).reverse().map( (elem, index) => {
                        if (elem?.member?.id !== accountData.id) {
                            return (
                                <div 
                                    key={index} 
                                    className={`
                                        person-wrapper
                                        ${
                                            (index === choosenPerson) 
                                            &&
                                            'choosen-person-wrapper'
                                        }
                                    `}
                                    onClick={() => 
                                        toggleChoosenPerson(index)
                                    }
                                >
                                    {elem.member.customName}
                                    <div 
                                        className='person-wrapper__number-wrapper'
                                        style={(
                                            elem.member.canDeleted
                                            ?
                                            {background: 'white', color: '#219653'}
                                            :
                                            {background: '#219653', color: 'white'}
                                        )}
                                    >
                                        {personListLength - index -1}
                                    </div>
                                    {
                                        (index === choosenPerson)
                                        &&
                                        <div
                                            className='person-wrapper__edit-wrapper'
                                            onClick={() => dispatch(showEditPersonMeasurementModal(elem))}
                                        >   
                                            <EditPencilIcon/>
                                        </div>
                                    }
                                    {
                                        (index === choosenPerson)
                                        &&
                                        <div
                                            className='person-wrapper__delete-wrapper'
                                            onClick={(event) => {
                                                if (
                                                    (event.target as Element).className === 'person-wrapper__delete-wrapper' 
                                                    ||
                                                    (event.target as any).className.baseVal
                                                    &&
                                                    (event.target as any).className.baseVal === 'delete-icon'
                                                ) {
                                                    dispatch(showDeletePersonFromMeasurementModal(elem.member))
                                                }
                                                
                                            }}
                                        >
                                            <DeleteCrossIcon/>
                                        </div>
                                    }
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}