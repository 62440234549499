import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import preloaderIcon from "./preloader.png";
import { SendMessageArrowIcon } from '../../../icons'

import { Consultation, Status } from '../../../interfaces/Consultation'
import { User } from '../../../interfaces/User'
import { routes } from '../../../routes'

import { sendConsultationMessage } from '../../../store/reducers/ConsultationChatReducer'
import { consultantConfirmConsultation } from '../../../store/reducers/CurrentConsultationReducer'
import { showCancelConsultationModal, showConsultationChatCancelValueModal, showConsultationChatSetValueModal } from '../../../store/reducers/ModalWindowsReducer'

import './styles.sass'
import { Message } from '../../../interfaces/Message'

const getPayLink = (messages) => {

    let str: any = ''
    for (var i=0; i<=(messages.length-1); i++) {
        if (messages[i]?.messageType && messages[i]?.messageType === 'PAYMENT' && !messages[i].canceled ) {
            str = messages[i]?.payUrl || ''
            break
            
        }
    }
    return str
}

export const ChatControlPanel = (prop: { setArrayMessages, setNumber}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const accountData: User = useSelector( (state: any) => (state.user))
    const consultation: Consultation | null = useSelector( (state: any) => (state.currentConsultation) )
    const messagesList: Message[] = useSelector( (state: any) => (state.consultationChat) )
    const [modalClose, setModalClose] = useState(false)
    const [message, setMessage] = useState<string>('')
    const [preloader, setPreloader] = useState(false);
    const [modalCloseChange, setModalCloseChange] = useState(false)
    const handleEnterKey = (event: any) => {
        if (event.key === "Enter" && (message.length> 0) ) {
            let chat = window.document.getElementById('messageChat') || null;
            if (chat?.scrollTop){
                chat.scrollTop = 0
            }
            dispatch( sendConsultationMessage(message) )
            setMessage('')
            //prop.setArrayMessages([])
            prop.setNumber(1)
        }
    }
    function closeConsultation(){
        setPreloader(true)
        fetch(process.env.REACT_APP_API_HOST+'/consultation/'+ consultation?.id +'/close',{
            headers: {'Authorization': 'Bearer ' + localStorage.token},
            method: 'post',
        }).then((response)=>{
            if (response.status == 200){
                navigate(routes.consultationForConsultant)
            } else {
                setModalClose(false)
                setModalCloseChange(true)
                setPreloader(false)
            }
        })
    }
   
    useEffect( () => {

        window.addEventListener("keydown", handleEnterKey)

        return () => window.removeEventListener("keydown", handleEnterKey)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    return (
        <div
            className={`chat-control-panel-wrapper`}
        >   
      {
          modalClose &&
          <div className="confirm__close__consultation">
          <div className="confirm__close__consultation__body">
          <p>Вы действительно хотите завершить консультацию?</p>
          <div>
              <div className="white-button" onClick={()=>{
                   setModalClose(false) 
              }}>Отменить</div>
              <div className="green-button" onClick={()=>{
                  closeConsultation()
              }}>Завершить</div>
          </div>
          </div>
         
      </div>
      }
        {preloader && (
          <div className="preloader">
            <div>
              <p> Пожалуйста подождите</p>

              <img src={preloaderIcon} className="preloader__image " />
            </div>
          </div>
        )}
      {
          modalCloseChange &&
          <div className="confirm__close__consultation">
          <div className="confirm__close__consultation__body">
          <p>Вы не сформировали заключение!</p>
          <div style={{justifyContent: 'center'}}>
              <div className="green-button" onClick={()=>{
                  setModalCloseChange(false)
              }}>Ок</div>
          </div>
          </div>
         
      </div>
      }
            <div
                className='chat-control-panel-wrapper__input-wrapper'
            >
                <div>
                    <div>
                        <input 
                            type='text'
                            value={message}
                            onChange={ (event) => setMessage(event.target.value)}
                            onFocus={()=> {
                          
                                document.title = 'Социомониторинг'}}
                        />
                    </div>

                    <button
                        onClick={() => {
                            if (message.length> 0) {
                                //prop.ws.send(JSON.stringify(message));
                                dispatch( sendConsultationMessage(message) )
                                setMessage('')
                              //  prop.setArrayMessages([])
                                prop.setNumber(1)
                            }  
                        }}
                    >
                        <SendMessageArrowIcon />
                    </button>
                </div>
            </div>

            <div
                className='chat-control-panel-wrapper__buttons-wrapper'
            >   
                {
                    (consultation?.status === Status.WAIT_USER_CONFIRM || consultation?.status === Status.WAIT_INVOICE || (consultation?.status === Status.APPROVED))
                    &&
                    <div
                        className='white-button'
                        onClick={() => dispatch( showCancelConsultationModal() )}
                    >
                        <FormattedMessage id='consultations_chat_control_button_reject' />
                    </div>
                }

                {
                    consultation?.senderId === accountData?.id
                    &&
                    (
                        (
                            consultation?.status === Status.APPROVED
                            &&
                            <>
                            <a target="_blank" href={getPayLink(messagesList)}>
                                <div
                                    className='green-button'
                                >   
                                    
                                        <FormattedMessage id='consultations_chat_control_button_pay' />
                                    
                                </div>
                                </a>
                            </>
                        )   
                        ||
                        (
                            consultation?.status === Status.HAS_CONCLUSION
                            &&
                            <>
                               
                                <div 
                                    className='green-button'
                                    onClick={() => navigate(routes.consultationReview)}
                                >
                                    <FormattedMessage id='consultations_chat_control_button_review' />
                                </div>
                            </>
                        )
                    )
                }

                {
                    consultation?.adresseeId === accountData?.id
                    &&
                    (
                        (
                            consultation?.status === Status.WAIT_INVOICE
                            &&
                            <div
                                className='green-button'
                                onClick={() => dispatch( showConsultationChatSetValueModal() )}
                            >
                                <FormattedMessage id='consultations_chat_control_button_show_value_modal'/>
                            </div>
                        )
                        ||
                        (
                            consultation?.status === Status.WAIT_USER_CONFIRM || consultation?.status === Status.APPROVED
                            &&
                            <div
                                className='green-button'
                                onClick={() => dispatch( showConsultationChatCancelValueModal(consultation?.id) )}
                            >
                                <FormattedMessage id='consultations_chat_control_button_show_cancel_value'/>
                            </div>
                        )
                        ||
                        (
                            consultation?.status === Status.WAIT_CONSULTANT_CONFIRM
                            &&
                            <div
                                className='green-button'
                                onClick={() => dispatch( consultantConfirmConsultation() )}
                            >
                                <FormattedMessage id='consultations_chat_control_button_confirm_consultant'/>
                            </div>
                        )
                        ||
                        (
                            consultation?.status === Status.PAID
                            && <div style={{display: 'flex'}}>
                                 <div
                                className='white-button'
                                onClick={ () => setModalClose(true) }
                            >
                                Завершить консультацию
                            </div>
                                 <div
                                className='green-button'
                                onClick={ () => navigate(routes.endConsultation) }
                            >
                             
                              Сформировать Заключение
                            </div>
                            </div>
                           
                        )
                    )
                }

            </div>
        </div>
    )
}