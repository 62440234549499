import { Consultation } from "../../interfaces/Consultation";

export const SET_CURRENT_CONSULTATION = "SET_CURRENT_CONSULTATION";
export const GET_CURRENT_CONSULTATION = "GET_CURRENT_CONSULTATION";
export const TRY_TO_DELETE_MEASUREMENT_CONSULTATION =
  "TRY_TO_DELETE_MEASUREMENT_CONSULTATION";
export const ADD_MEASUREMENT_LIST_TO_CONSULTATION =
  "ADD_MEASUREMENT_LIST_TO_CONSULTATION";
export const CONSULTANT_CONFIRM_CONSULTATION =
  "CONSULTANT_CONFIRM_CONSULTATION";
export const CLOSE_CONSULTATION = "CLOSE_CONSULTATION";
export const CLEAN_CURRENT_CONSULTATION = "CLEAN_CURRENT_CONSULTATION";

let initialState: Consultation | null = null;

if (
  localStorage.currentConsultation !== undefined &&
  typeof localStorage.currentConsultation === "string" &&
  localStorage.currentConsultation.length > 0
) {
  const preState = JSON.parse(localStorage.currentConsultation);
  if (preState && preState.id && preState.senderId) {
    initialState = preState;
  }
}

export const CurrentConsultationReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case SET_CURRENT_CONSULTATION:
      localStorage.setItem(
        "currentConsultation",
        JSON.stringify(action.payload)
      );
      return action.payload;

    case CLEAN_CURRENT_CONSULTATION:
      localStorage.setItem("currentConsultation", "");
      return null;

    default:
      return state;
  }
};

export const setCurrentConsultation = (payload: Consultation) => ({
  type: SET_CURRENT_CONSULTATION,
  payload,
});
export const tryToDeleteMeasurementConsultation = (payload: string) => ({
  type: TRY_TO_DELETE_MEASUREMENT_CONSULTATION,
  payload,
});

export const getCurrentConsultation = (payload: string) => ({
  type: GET_CURRENT_CONSULTATION,
  payload,
});
export const addMeasurementsListToConsultation = (payload: any[]) => ({
  type: ADD_MEASUREMENT_LIST_TO_CONSULTATION,
  payload,
});

export const consultantConfirmConsultation = () => ({
  type: CONSULTANT_CONFIRM_CONSULTATION,
});

export const closeConsultation = (payload: any) => ({
  type: CLOSE_CONSULTATION,
  payload,
});

export const cleanCurrentConsultation = () => ({
  type: CLEAN_CURRENT_CONSULTATION,
});
