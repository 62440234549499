
import './index.sass';
import logo from './logo.svg'


// or

import {useEffect, useState} from 'react';
//import {apiUrl} from '../const.js';

export function Thanks(){
    const [confirmRegistration, setConfirmRegistration] = useState(false); 
 
    
    const getParam = ()=>{
       
        
        //let query = newUrlSearchParam()
        let url = new URL(document.location.href);
        let key = url.searchParams.get("key");
        
        fetch(`${process.env.REACT_APP_API_HOST}/activate-mobile?key=${key}`)
        .then((response) =>{
			if (response.ok){
			setConfirmRegistration(true)
			return response.json()
			} else {
				 setConfirmRegistration(false)
			};
        })
        
        
    }
    useEffect(()=>{
        getParam();
    },[])
     return(
        <div className="confirm-register">
            <div className="header_comp">
                <img src={logo} alt="Логотип"/>
            </div>
            <div>
               {confirmRegistration && <div className="confirm__message"> Email успешно подтвержден!</div>}
               {!confirmRegistration && <div className="confirm__message"> Произошла ошибка!</div>}
            </div>
        </div>
       
        
    )
    
}