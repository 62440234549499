import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { OnePersonIcon } from "../../icons";
import { Consultant } from "../../interfaces/Consultant";
import { tryToGetConsultantInfo } from "../../store/reducers/ConsultantReducer";
import { useNavigate } from "react-router-dom";
import "./styles.sass";
import { routes } from "../../routes";
import { cleanMeasurementConsultationList } from "../../store/reducers/MeasurementConsultationListReducer";
import { User } from "../../interfaces/User";
import { CloseCrossIcon } from "../../icons";
import { chooseConsultant } from "../../store/reducers/CreatingConsultationReducer";
export const ConsultantContainer = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const accountData: User = useSelector((state: any) => state.user);

  const location = useLocation();

  const [isAllReviewsShown, setIsAllReviewsShown] = useState<boolean>(false);

  const toggleIsAllReviewsShown = () => {
    if (isAllReviewsShown) {
      setIsAllReviewsShown(false);
    } else {
      setIsAllReviewsShown(true);
    }
  };
  const [modalActivated, setModalActivated] = useState(false);
  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

  const consultant: Consultant = useSelector((state: any) => state.consultant);

  useEffect(() => {
    dispatch(tryToGetConsultantInfo(location.state.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function checkActivated() {
    if (accountData.personaAreaActivated) {
      dispatch(cleanMeasurementConsultationList());
      dispatch(chooseConsultant(consultant));
      Navigate(routes.startConsultationFromConsultants);
    } else {
      setModalActivated(true);
    }
  }

  return (
    <div
      className={`consultant-page-wrapper ${
        isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
      }`}
    >
      {modalActivated && (
        <div className="popup_black">
          <div className="show__to__buye">
            <div
              onClick={() => {
                setModalActivated(false);
              }}
            >
              {" "}
              <CloseCrossIcon />
            </div>

            <p>Консультации доступны только при приобритении подписки</p>
            <div
              className="green-button"
              onClick={() => {
                Navigate("/tarifs");
              }}
            >
              Купить подписку
            </div>
          </div>
        </div>
      )}
      <div className="consultant-page-wrapper__header">
        <div className="consultant-page-wrapper__header__photo">
          {consultant && consultant?.photo && consultant?.photo.length > 0 ? (
            <img src={consultant?.photo || ""} />
          ) : (
            <OnePersonIcon />
          )}
        </div>
        <div className="consultant-page-wrapper__header__column">
          <span className="consultant-page-wrapper__header__column__name">
            {consultant?.surname +
              " " +
              consultant?.name +
              " " +
              consultant?.patronymic}
          </span>
          <div
            className="consultant-page-wrapper__header__column__status"
            style={{ background: consultant?.workloadDisplay.color }}
          >
            {consultant?.workloadDisplay.text}
          </div>
        </div>

        <div
          onClick={() => {
            checkActivated();
          }}
          className="green-button"
        >
          <FormattedMessage id="consultant_page_go_consultation" />
        </div>
      </div>

      <div className="consultant-page-wrapper__data-wrapper">
        <div className="consultant-page-wrapper__data-wrapper__rating">
          <span className="title">
            <FormattedMessage id="consultant_page_rating" />
          </span>
          <div>{consultant?.rating || "0.0"}</div>
        </div>

        <div className="consultant-page-wrapper__data-wrapper__desc">
          <span className="title">
            <FormattedMessage id="consultant_page_desc" />
          </span>
          <span className="consultant-page-wrapper__data-wrapper__desc__text">
            {consultant?.consultantInfo}
          </span>
        </div>
      </div>

      <div className="consultant-page-wrapper__reviews-wrapper">
        <span className="consultant-page-wrapper__reviews-wrapper__title">
          <FormattedMessage id="consultant_page_reviews" /> (
          {consultant?.reviews && consultant?.reviews.length > 0
            ? consultant?.reviews.length
            : 0}{" "}
          )
        </span>

        <div className="consultant-page-wrapper__reviews-wrapper__reviews">
          {consultant?.reviews &&
            consultant?.reviews.length > 0 &&
            consultant?.reviews.map((review, index) => {
              if ((!isAllReviewsShown && index <= 3) || isAllReviewsShown) {
                return (
                  <div
                    key={"consultation-page-review-" + index}
                    style={{
                      marginRight: (index + 1) % 4 !== 0 ? "28px" : "28px",
                    }}
                  >
                    {review.text}
                  </div>
                );
              }
            })}
        </div>
        {consultant?.reviews && consultant?.reviews.length !== 0 && (
          <div
            className="white-button"
            onClick={() => toggleIsAllReviewsShown()}
          >
            <FormattedMessage
              id={
                isAllReviewsShown
                  ? "consultant_page_hide_all_reviews"
                  : "consultant_page_show_all_reviews"
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
