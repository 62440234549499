import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ConsultationPanel } from "../../components/ConsultationPanel";

import { Status } from "../../interfaces/Consultation";
import { User } from "../../interfaces/User";
import {
  ConsultationRequestInterface,
  tryToGetConsultationsList,
} from "../../store/reducers/ConsultationsListReducer";
import "./styles.sass";

import { routes } from "../../routes";
import {
  cleanCurrentConsultation,
  setCurrentConsultation,
} from "../../store/reducers/CurrentConsultationReducer";
import { deleteIsCreatingMeasurementForConsultation } from "../../store/reducers/CreatingMeasurementStateReducer";
import { setIsRejectinConsultationFalse } from "../../store/reducers/RejectConsultationReducer";
import { setConsultationMessages } from "../../store/reducers/ConsultationChatReducer";

export const ConsultationsForConsultant = ({ type, setType }) => {
  localStorage.setItem("typeConsultation", "appeals");
  const dispatch = useDispatch();
  const url = window.location.pathname.split("/").pop();

  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

  const consultations: ConsultationRequestInterface | null = useSelector(
    (state: any) => state.consultationsList
  );
  const accountData: User | null = useSelector((state: any) => state.user);

  // const [type, setType] = useState<Status>(Status.WAITING_REVIEW)
  const [requestType, setRequestType] = useState<string>("REQUEST");

  useEffect(() => {
    if (
      !localStorage.consultationTypeOpenAppears ||
      localStorage.consultationTypeOpenAppears.length == 0
    ) {
      dispatch(tryToGetConsultationsList({ type: "REQUEST", page: 0 }));
    } else if (
      localStorage.consultationTypeOpenAppears &&
      localStorage.consultationTypeOpenAppears.length > 0
    ) {
      switch (localStorage.consultationTypeOpenAppears) {
        case "REQUEST":
          setType(Status.WAITING_REVIEW);
          break;
        case "IN_WORK":
          setType(Status.PAID);
          break;
        case "COMPLETED":
          setType(Status.CLOSED);
          break;
        case "IN_ARBITRAGE":
          setType(Status.IN_ARBITRATION);
          break;
      }
      setRequestType(localStorage.consultationTypeOpenAppears);
      dispatch(
        tryToGetConsultationsList({
          type: localStorage.consultationTypeOpenAppears,
          page: 0,
        })
      );
    }

    dispatch(setIsRejectinConsultationFalse());

    dispatch(cleanCurrentConsultation());
    dispatch(setConsultationMessages([]));

    dispatch(deleteIsCreatingMeasurementForConsultation());

    const consultationsWrapper: HTMLElement | null =
      window.document.getElementById("consultations-list-wrapper");

    const handleScroll = () => {
      let scrollTop = consultationsWrapper?.scrollTop || 0;
      let clientHeight = consultationsWrapper?.clientHeight || 0;
      let scrollheight = consultationsWrapper?.scrollHeight || 0;

      if (scrollTop + clientHeight > scrollheight - 25 && consultations) {
        dispatch(
          tryToGetConsultationsList({
            type: requestType,
            page: consultations?.page + 1 || 0,
          })
        );
      }
    };

    consultationsWrapper?.addEventListener("scroll", handleScroll);

    return () =>
      consultationsWrapper?.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, accountData]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={`consultation-page-wrapper ${
        isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
      }`}
    >
      <span className="consultation-page-wrapper__title">Запросы к вам</span>

      {document.body.clientWidth > 640 ? (
        <div className="consultation-page-wrapper__navbar">
          <span
            onClick={() => {
              setType(Status.WAITING_REVIEW);
              setRequestType("REQUEST");
              dispatch(tryToGetConsultationsList({ type: "REQUEST", page: 0 }));
              localStorage.setItem("consultationTypeOpenAppears", "REQUEST");
            }}
          >
            <FormattedMessage id="consultations_list_navbar_waiting" />
            <div className="number">{consultations?.size?.REQUEST}</div>
            {type === Status.WAIT_INVOICE ||
              type === Status.APPROVED ||
              type === Status.WAIT_USER_CONFIRM ||
              (type === Status.WAITING_REVIEW && (
                <div className="choosen-border" />
              ))}
          </span>
          <span
            onClick={() => {
              setType(Status.PAID);
              setRequestType("IN_WORK");
              dispatch(tryToGetConsultationsList({ type: "IN_WORK", page: 0 }));
              localStorage.setItem("consultationTypeOpenAppears", "IN_WORK");
            }}
          >
            <FormattedMessage id="consultations_list_navbar_confirmed" />
            <div className="number">{consultations?.size?.IN_WORK}</div>
            {type === Status.PAID && <div className="choosen-border" />}
          </span>
          <span
            onClick={() => {
              setType(Status.CLOSED);
              setRequestType("COMPLETED");
              dispatch(
                tryToGetConsultationsList({ type: "COMPLETED", page: 0 })
              );
              localStorage.setItem("consultationTypeOpenAppears", "COMPLETED");
            }}
          >
            <FormattedMessage id="consultations_list_navbar_closed" />
            <div className="number">{consultations?.size?.COMPLETED}</div>
            {type === Status.CLOSED && <div className="choosen-border" />}
          </span>
          <span
            onClick={() => {
              setType(Status.IN_ARBITRATION);
              setRequestType("IN_ARBITRAGE");
              dispatch(
                tryToGetConsultationsList({ type: "IN_ARBITRAGE", page: 0 })
              );
              localStorage.setItem(
                "consultationTypeOpenAppears",
                "IN_ARBITRAGE"
              );
            }}
          >
            <FormattedMessage id="consultations_list_navbar_arbitrage" />
            <div className="number">{consultations?.size?.IN_ARBITRAGE}</div>
            {type === Status.IN_ARBITRATION && (
              <div className="choosen-border" />
            )}
          </span>
        </div>
      ) : (
        <>
          <div className="consultation-page-wrapper__navbar">
            <span
              onClick={() => {
                setType(Status.WAITING_REVIEW);
                setRequestType("REQUEST");
                dispatch(
                  tryToGetConsultationsList({ type: "REQUEST", page: 0 })
                );
                console.log("kek");
                localStorage.setItem("consultationTypeOpenAppears", "REQUEST");
              }}
            >
              <FormattedMessage id="consultations_list_navbar_waiting" />
              <div className="number">{consultations?.size?.REQUEST}</div>
              {type === Status.WAITING_REVIEW && (
                <div className="choosen-border" />
              )}
            </span>
            <span
              onClick={() => {
                setType(Status.PAID);
                setRequestType("IN_WORK");
                dispatch(
                  tryToGetConsultationsList({ type: "IN_WORK", page: 0 })
                );
                localStorage.setItem("consultationTypeOpenAppears", "IN_WORK");
              }}
            >
              <FormattedMessage id="consultations_list_navbar_confirmed" />
              <div className="number">{consultations?.size?.IN_WORK}</div>
              {type === Status.PAID && <div className="choosen-border" />}
            </span>
          </div>
          <div className="consultation-page-wrapper__navbar second-row">
            <span
              onClick={() => {
                setType(Status.CLOSED);
                setRequestType("COMPLETED");
                dispatch(
                  tryToGetConsultationsList({ type: "COMPLETED", page: 0 })
                );
                localStorage.setItem(
                  "consultationTypeOpenAppears",
                  "COMPLETED"
                );
              }}
            >
              <FormattedMessage id="consultations_list_navbar_closed" />
              <div className="number">{consultations?.size?.COMPLETED}</div>
              {type === Status.CLOSED && <div className="choosen-border" />}
            </span>
            <span
              onClick={() => {
                setType(Status.IN_ARBITRATION);
                setRequestType("IN_ARBITRAGE");
                dispatch(
                  tryToGetConsultationsList({ type: "IN_ARBITRAGE", page: 0 })
                );
                localStorage.setItem(
                  "consultationTypeOpenAppears",
                  "IN_ARBITRAGE"
                );
              }}
            >
              <FormattedMessage id="consultations_list_navbar_arbitrage" />
              <div className="number">{consultations?.size?.IN_ARBITRAGE}</div>
              {type === Status.IN_ARBITRATION && (
                <div className="choosen-border" />
              )}
            </span>
          </div>
        </>
      )}

      <div
        className="consultation-page-wrapper__consultations-list"
        id="consultations-list-wrapper"
      >
        {consultations &&
          consultations.consultations &&
          consultations.consultations.length > 0 &&
          consultations.consultations.map((elem, index) => {
            //if (elem.status === type) {
            return (
              <Link
                to={routes.consultationChat}
                key={index}
                onClick={() => dispatch(setCurrentConsultation(elem))}
              >
                <ConsultationPanel
                  data={elem}
                  isConsultant={
                    accountData ? elem.adresseeId === accountData.id : false
                  }
                />
              </Link>
            );
          })}
      </div>
    </div>
  );
};
