import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
//import store from "../store";
import { confirmConsultationCreation, TRY_TO_CREATE_CONSULTATION } from "../store/reducers/CreatingConsultationReducer";
import { Consultation } from '../interfaces/Consultation'
import { setCurrentConsultation } from "../store/reducers/CurrentConsultationReducer";
import { routes } from "../routes";


async function AsyncCreateConsultation(data: any) {
   
    try {
        let measurementsId = JSON.parse(localStorage.chooseMeas).map((elem) => (elem.measurementId.length>0) ? {id: elem.measurementId} : {id: ''})
        
        const response = await fetch(
            apiUrl + '/consultation',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    consultantId: data.consultant.id,
                    dealType: "SAFE_DEAL",
                    measurements: measurementsId,
                    problemDesc1: data.problemDesc1,
                    problemDesc2: data.problemDesc2,
                    problemDesc3: data.problemDesc3,
                })
            }
        )
        
        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch(error) {}
}


function* CreateConsultationWorker(action) {
    const data: Consultation | null = yield call(AsyncCreateConsultation, action.payload)

    if (data && data.id) {
        yield put( confirmConsultationCreation() )
        yield put( setCurrentConsultation(data) )
    }
}

export function* CreateConsultationWatcher() {
    yield takeEvery(TRY_TO_CREATE_CONSULTATION, CreateConsultationWorker)
}