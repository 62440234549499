export const TRY_TO_GET_CITIES_LIST = 'TRY_TO_GET_CITIES_LIST'
export const SET_CITIES_LIST = 'SET_CITIES__LIST'

const initialState: any[] = []

export const CitiesListReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_CITIES_LIST:
            return action.payload

        default:
            return state
    }
}

export const tryToGetCitiesList = (payload: string) => ({type: TRY_TO_GET_CITIES_LIST, payload})
export const setCitiesList = (payload: any[]) => ({type: SET_CITIES_LIST, payload})
