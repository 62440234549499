import { MeasurementInfo, Stage, Mode } from '../../interfaces/MeasurementInfo'

let initialState: MeasurementInfo = {
    measurementId: '',
    creationDate: '',
    name: '',
    typeId: '',
    mode: Mode.Text,
    stage: Stage.START_WIZARD,
    rateMembers: []
}

if (localStorage.measurement !== undefined && (typeof localStorage.measurement === 'string') && localStorage.measurement.length > 0) {
   
    const preState = JSON.parse(localStorage.measurement)
    if (preState && preState.id && preState.typeId) {
        initialState = preState   
    }
}

export const START_MEASUREMENT = 'START_MEASUREMENT'
export const CREATE_MEASUREMENT = 'CREATE_MEASUREMENT'
export const ASYNC_UPDATE_MEASUREMENT = 'ASYNC_UPDATE_MEASUREMENT'
export const UPDATE_MEASUREMENT = 'UPDATE_MEASUREMENT'
export const END_MEASUREMENT = 'END_MEASUREMENT'

export const MeasurementReducer = (state = initialState, action) => {

    switch (action.type) {

        case CREATE_MEASUREMENT: 
            localStorage.setItem('currentRateMember', '')
            localStorage.setItem('measurement', JSON.stringify(action.payload))
            return action.payload


        case UPDATE_MEASUREMENT:
           
            //localStorage.setItem('measurement', JSON.stringify(action.payload))
            return action.payload

        default: 
            return state
    }
}

export const startMeasurement = (payload: string) => ({type: START_MEASUREMENT, payload})
export const createMeasurement = (payload: MeasurementInfo) => ({type: CREATE_MEASUREMENT, payload})

export const asyncUpdateMeasurement = (payload: string) => ({type: ASYNC_UPDATE_MEASUREMENT, payload})
export const updateMeasurement = (payload: MeasurementInfo) => ({type: UPDATE_MEASUREMENT, payload})
export const endMeasurement = (payload: string) => ({type: END_MEASUREMENT, payload})