export const SET_PERSON_SEARCH_LIST = 'SET_PERSON_SEARCH_LIST'
export const INC_PAGE_PERSON_SEARCH = 'INC_PAGE_PERSON_SEARCH'
export const SET_NAME_SEARCH_PERSON_LIST = 'SET_NAME_SEARCH_PERSON_LIST'

const initialState = {
    before: '',
    after: '',
    typeId: '',
    page: 0,
    name: ''
}

export const PersonsListSearchReducer = (state = initialState, action) => {

    switch (action.type) {
        case INC_PAGE_PERSON_SEARCH:
            return {
                ...state,
                page: state.page+1
            }

        case SET_PERSON_SEARCH_LIST:
            return action.payload

        case SET_NAME_SEARCH_PERSON_LIST:
            return {
                ...state,
                name: action.payload
            }

        default:
            return state
    }
}

export const setPersonsSearchList = (payload: any) => ({type: SET_PERSON_SEARCH_LIST, payload})
export const incPagePersonsSearchList = () => ({type: INC_PAGE_PERSON_SEARCH})
export const setNameSearchPersonList = (payload: string) => ({type: SET_NAME_SEARCH_PERSON_LIST, payload})