import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { Consultation } from "../interfaces/Consultation";
import { routes } from "../routes";
import store from "../store";
import { setCurrentConsultation, TRY_TO_DELETE_MEASUREMENT_CONSULTATION } from "../store/reducers/CurrentConsultationReducer";

const asyncDeleteMeasurementFromConsultation = async (id: string) => {
    try {

        let currentConsultation = store.getState().currentConsultation

        let measurements: any[] = []
        currentConsultation?.measurements?.forEach( (elem: any) => {
            if (elem && elem?.id !== id) {
                measurements.push(elem)
            }
        })

        let newConsultation = {...currentConsultation, measurements: measurements}

        const response = await fetch(
            apiUrl + '/consultation/edit/',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify(newConsultation)
            }
        )

        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch (error) {
    } 
}

function* DeleteMeasurementFromConsultationWorker(action) {
    const data: Consultation | null = yield call(asyncDeleteMeasurementFromConsultation, action.payload)

    if (data && data.id) {
        yield put( setCurrentConsultation(data) )
    }
}

export function* DeleteMeasurementFromConsultationWatcher() {
    yield takeEvery(TRY_TO_DELETE_MEASUREMENT_CONSULTATION, DeleteMeasurementFromConsultationWorker)
}