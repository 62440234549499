import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import './style.sass';
export const Offices = () =>{
    const isNavbarShowed = useSelector(
        (state: any) => state.navbar.isNavbarShowed
      );
      const [popOffice, setPopOffice] = useState(false);
      const [code, setCode] = useState('');
      const [error, setError] = useState('');
      const [arrData, setArrData] = useState<any>([]);
      function getOffices(){
        fetch(process.env.REACT_APP_API_HOST+'/offices/user',{
            method: 'GET',
           
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + localStorage.token
            },
        }).then((response)=>{
            return response.json()
        }).then((data)=>{
            setArrData(data)
        })
      }
      async function addOffice () {
     
        let response = await fetch(process.env.REACT_APP_API_HOST+'/person/link/'+code,{
            method: 'POST',
           
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + localStorage.token
            },
        })
        if (response.status == 200){
            setPopOffice(false)
            setError('')
            getOffices()
        } else if (response.status !== 200){
            setError('Код недействителен')
        }
        
      }
     
    useEffect(()=>{
        getOffices()
        
    },[])
    return(<div  className={`consultant-page-wrapper ${
        isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
      }`}>
        {popOffice && <div className="pop_add_office">
            <div className="pop_office_body">
            <p>Введите код приглашения</p>
            <span className="error_office">{error}</span>
            <input type="text" value={code} onChange={(event)=>setCode(event.target.value)}/>
            <div className="buttons_office">
                <div className="white-button" onClick={()=>{setPopOffice(false); setError('')}}>
                    Отменить
                </div>
                <div className="green-button" onClick={addOffice}>Отправить</div>
            </div>
            </div>
            
        </div>}
        <div className="officesList">
            {arrData && arrData.length > 0 && arrData.map((elem)=>{
                return <div className="office_em">
                {elem && elem.officeName}
               
            </div>
            })}
            
        </div>
        <div className="bottom_office_add">
            <div className="green-button" onClick={()=> setPopOffice(true)}>
                Добавить офис
            </div>
        </div>
    </div>)
}