import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { Consultant } from "../interfaces/Consultant";
import { routes } from "../routes";
import { setConsultant, TRY_TO_GET_CONSULTANT_INFO } from "../store/reducers/ConsultantReducer";

async function asyncGetConsultant(id: string) {
    try {

        const response = await fetch(
            apiUrl + `/consultants/${id}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                }
            }
        )

        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch (error) {}
}

function* GetConsultantWorker(action) {

    if (action.payload) {
    
        const data: Consultant | null = yield call(asyncGetConsultant, action.payload)

        if (data && data.id) {
            yield put( setConsultant(data) )
        }
    }
}

export function* GetConsultantWatcher() {
    yield takeEvery(TRY_TO_GET_CONSULTANT_INFO, GetConsultantWorker)
}