import {  useNavigate } from "react-router-dom";
import './styles.sass'

export const SubscribeComponent = ({Activate}) =>{
    const Navigate = useNavigate()
    return (
        <div className={ `${!Activate  ? 'show__to__buye' : 'noShow'}`} >
            <p>Для просмотра этого аналитического блока необходимо приобрести подписку</p>
            <div className="green-button" onClick={()=>{
                Navigate('/tarifs')
            }}>Купить подписку</div>
        </div>
    )
}