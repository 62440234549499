import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  CloseCrossIcon,
  RateGreenStarIcon5,
  RateGreenStarIcon4,
  RateGreenStarIcon3,
  RateGreenStarIcon2,
  RateGreenStarIcon1,
} from "../../../icons";
import { Consultant } from "../../../interfaces/Consultant";
import { closeModal } from "../../../store/reducers/ModalWindowsReducer";

import { useNavigate } from "react-router-dom";
import "./styles.sass";
import { routes } from "../../../routes";
export const ConsultantModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const consultant: Consultant = useSelector(
    (state: any) => state.modals.modalInfo
  );

  return (
    <div className="consultant-modal-wrapper">
      <div className="consultant-modal">
        <div
          className="consultant-modal__cross"
          onClick={() => dispatch(closeModal())}
        >
          <CloseCrossIcon />
        </div>

        <div className="consultant-modal__top-panel">
          <div className="consultant-modal__top-panel__image">
            {consultant.photo && <img src={consultant.photo} />}
          </div>

          <div className="consultant-modal__top-panel__name">
            <span>
              {consultant.name} {consultant.surname}
            </span>
            <div style={{ background: consultant.workloadDisplay.color }}>
              {consultant.workloadDisplay.text}
            </div>
          </div>

          <div className="consultant-modal__top-panel__rating">
            <FormattedMessage id="consultation_choose_consultant_panel_rating" />
            <span>{consultant.rating}</span>
          </div>
        </div>

        <div className="consultant-modal__bottom-panel">
          <div className="consultant-modal__bottom-panel__description">
            <span>
              <FormattedMessage id="consultation_choose_consultant_panel_description" />
            </span>
            <div>{consultant.consultantInfo}</div>
          </div>

          <span className="consultant-modal__bottom-panel__reviews-count">
            <FormattedMessage id="consultation_choose_consultant_panel_count_reviews" />
            (
            {consultant.reviews &&
            consultant.reviews.length &&
            consultant.reviews.length > 0
              ? consultant.reviews.length
              : 0}
            )
          </span>

          {consultant && consultant.reviews && consultant.reviews.length >= 1 && (
            <div className="consultant-modal__bottom-panel__review">
              <div className="consultant-modal__bottom-panel__review__stars-wrapper">
                <RateGreenStarIcon1
                  fill={
                    parseFloat(consultant.reviews[0].rating.toString()) >= 1
                      ? "100"
                      : parseFloat(consultant.reviews[0].rating.toString()) !==
                        0
                      ? parseFloat(
                          consultant.reviews[0].rating.toString()
                        ).toString()
                      : "0"
                  }
                />
                <RateGreenStarIcon2
                  fill={
                    parseFloat(consultant.reviews[0].rating.toString()) >= 2
                      ? "100"
                      : parseFloat(consultant.reviews[0].rating.toString()) > 1
                      ? (
                          parseFloat(consultant.reviews[0].rating.toString()) -
                          1
                        ).toString()
                      : "0"
                  }
                />
                <RateGreenStarIcon3
                  fill={
                    parseFloat(consultant.reviews[0].rating.toString()) >= 3
                      ? "100"
                      : parseFloat(consultant.reviews[0].rating.toString()) > 2
                      ? (
                          parseFloat(consultant.reviews[0].rating.toString()) -
                          2
                        ).toString()
                      : "0"
                  }
                />
                <RateGreenStarIcon4
                  fill={
                    parseFloat(consultant.reviews[0].rating.toString()) >= 4
                      ? "100"
                      : parseFloat(consultant.reviews[0].rating.toString()) > 3
                      ? (
                          parseFloat(consultant.reviews[0].rating.toString()) -
                          3
                        ).toString()
                      : "0"
                  }
                />

                <RateGreenStarIcon5
                  fill={
                    parseFloat(consultant.reviews[0].rating.toString()) === 5
                      ? "100"
                      : parseFloat(consultant.reviews[0].rating.toString()) > 4
                      ? (
                          parseFloat(consultant.reviews[0].rating.toString()) -
                          4
                        ).toString()
                      : "0"
                  }
                />
              </div>

              <span>{consultant.reviews[0].text}</span>

              <div
                className="white-button"
                onClick={() => {
                
                  navigate(routes.consultant, { state: { id: consultant.id } });
                }}
              >
                <FormattedMessage id="consultation_choose_consultant_panel_watch_reviews" />
              </div>
            </div>
          )}

          {consultant && consultant.reviews && consultant.reviews.length >= 2 && (
            <div className="consultant-modal__bottom-panel__review">
              <div className="consultant-modal__bottom-panel__review__stars-wrapper">
                <RateGreenStarIcon1
                  fill={
                    parseFloat(consultant.reviews[1].rating.toString()) >= 1
                      ? "100"
                      : parseFloat(consultant.reviews[1].rating.toString()) !==
                        0
                      ? parseFloat(
                          consultant.reviews[1].rating.toString()
                        ).toString()
                      : "0"
                  }
                />
                <RateGreenStarIcon2
                  fill={
                    parseFloat(consultant.reviews[1].rating.toString()) >= 2
                      ? "100"
                      : parseFloat(consultant.reviews[1].rating.toString()) > 1
                      ? (
                          parseFloat(consultant.reviews[1].rating.toString()) -
                          1
                        ).toString()
                      : "0"
                  }
                />
                <RateGreenStarIcon3
                  fill={
                    parseFloat(consultant.reviews[1].rating.toString()) >= 3
                      ? "100"
                      : parseFloat(consultant.reviews[1].rating.toString()) > 2
                      ? (
                          parseFloat(consultant.reviews[1].rating.toString()) -
                          2
                        ).toString()
                      : "0"
                  }
                />
                <RateGreenStarIcon4
                  fill={
                    parseFloat(consultant.reviews[1].rating.toString()) >= 4
                      ? "100"
                      : parseFloat(consultant.reviews[1].rating.toString()) > 3
                      ? (
                          parseFloat(consultant.reviews[1].rating.toString()) -
                          3
                        ).toString()
                      : "0"
                  }
                />

                <RateGreenStarIcon5
                  fill={
                    parseFloat(consultant.reviews[1].rating.toString()) === 5
                      ? "100"
                      : parseFloat(consultant.reviews[1].rating.toString()) > 4
                      ? (
                          parseFloat(consultant.reviews[1].rating.toString()) -
                          4
                        ).toString()
                      : "0"
                  }
                />
              </div>

              <span>{consultant.reviews[1].text}</span>

              <div
                className="white-button"
                onClick={() => {
                 
                  navigate(routes.consultant, { state: { id: consultant.id } });
                }}
              >
                <FormattedMessage id="consultation_choose_consultant_panel_watch_reviews" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
