import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import store from '../../store'

import './styles.sass'

export const ProgressRatingPanel = (prop: {length: number, isGroup?: boolean}) => {

    const [width, setWidth] = useState<string>('0%')

    const isNavbarShowed = useSelector( (state: any) => (state.navbar.isNavbarShowed))
    const currentMember = useSelector( (state: any) => (state.currentRatingPerson))

    useEffect( () => {
        if (currentMember.position === 2) {
            setWidth('0%')
        } else if (!prop.isGroup){
            const personList = JSON.parse(localStorage.measurement).rateMembers.slice(1)

            let number = 0

            number = Math.round(((store.getState().currentRatingPerson.position-2) / personList.length) * 100)

            if (number < 0) {
                number = 100 + number 
            }
            
            setWidth( number + '%')
        }
    }, [currentMember, prop.length, prop.isGroup])

    return (
        <div className={`progress-rating-panel-wrapper  ${ isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}>
            <div 
                style={{width: width}}
            >
                <span className='percantage-wrapper'>
                    {width}
                </span>
            </div>
        </div>
    )
}