import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { initPasswordRestoreCreator } from "../../store/reducers/RestorePasswordReducer"

import './styles.sass'

interface MailInterface {
    value: string;
    changed: boolean;
}

export const LoginPasswordRecoveryFirstStage = () => {

    const intl = useIntl()

    const [isValid, setIsValid] = useState<boolean>(false)
    const [mail, setMail] = useState<MailInterface>({
        value: '',
        changed: false,
    })
    const [errors, setErrors] = useState<string>('')

    const dispatch = useDispatch()

    const isError: boolean = useSelector( (state: any) => (state.restorePassword.error))

    const handleSubmit = (event) => {
        event.preventDefault()

        if (isValid) {
            dispatch(initPasswordRestoreCreator(mail.value))
        }
    }

    const validate = () => {
        let isValidTemp = true;
        let errorsTemp = '';

        if (mail.changed && !mail.value) {
            isValidTemp = false;
            errorsTemp = "signup_text_error_must_field";
        }

        if (mail.changed && typeof mail.value !== "undefined") {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(mail.value)) {
                isValidTemp = false;
                errorsTemp = "signup_text_error_incorrect_email";
            }
        }

        setErrors(errorsTemp);
        setIsValid(isValidTemp);
    }

    const handleChange = (event) => {
        switch (event.target.name) {
            case "email":
                setMail(
                    {
                        value: event.target.value,
                        changed: true
                    }
                );
                break;
        }

        if(!isValid) {
            validate()
        }
    }

    const handleFocus = (event) => {
        switch (event.target.name) {
            case "email":
                setMail(
                    {
                        value: mail.value,
                        changed: true
                    }
                );
                break;
        }
    }
    
    return (
        <form className='login-recovery-first-stage-form-wrapper' onSubmit={handleSubmit}>

            <span className='login-recovery-title'>
                <FormattedMessage id='login_recovery_title'/>
            </span>

            <span className='login-recovery-sub-title'>
                <FormattedMessage id='login_recovery_first_stage_sub_title' />
            </span>

            {
                isError
                &&
                <div className='login-recovery-error-panel'>
                    <span className='login-recovery-error-panel__title'>
                        <FormattedMessage id='login_recovery_error_title' />
                    </span>

                    <span className='login-recovery-error-panel__sub-title'>
                        <FormattedMessage id='login_recovery_error_sub_title'/>
                    </span>
                </div>
            }

            <div className={`text-input`}>
                <input
                    type='text'
                    placeholder={ intl.formatMessage({ id: 'login_recovery_first_stage_input_placeholder' }) }
                    value={mail.value}
                    name={"email"}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={validate}
                    
                    className={`${ !isValid && errors.length !== 0 && 'error-input'}`}
                />
                {
                    !isValid && errors.length !== 0
                    &&
                    <span className={"error-text"}><FormattedMessage id={ errors }/></span>
                }
            </div>

            <button 
                className={`green-button ${ !isValid && 'disabled-button'}`}
            >
                <FormattedMessage id='login_recovery_first_stage_submit_button' />
            </button>

        </form>
    )
}