import { Person } from "../../interfaces/Person"

export const FIND_PERSONS_BY_NAME = 'FIND_PERSONS_BY_NAME'
export const SET_POTENTIAL_PERSONS_LIST = 'SET_POTENTIAL_PERSONS_LIST'

const initialState: Person[] = []

export const FindMeasurementPersonsListReducer = (state= initialState, action) => {

    switch (action.type) {

        case SET_POTENTIAL_PERSONS_LIST:
            return action.payload

        default:
            return state
    }
}

export const findPersonsByName = (payload: string) => ({type: FIND_PERSONS_BY_NAME, payload})
export const setPotentialPersonList = (payload: Person[]) => ({type: SET_POTENTIAL_PERSONS_LIST, payload})