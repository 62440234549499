import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import {  CHECK_IS_AUTHORIZED, setUser,  } from "../store/reducers/userReducer";
import { setAccountMainData } from '../store/reducers/AccountMainDataReducer'
import { exit } from "../store/reducers/LoginReducer";

async function asyncGetUser() {
    try {
        const response = await fetch(
            apiUrl + '/account',
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                }
            }
        )

        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            if (location.pathname !== routes.login && location.pathname !== routes.loginRecovery && location.pathname !== routes.articles && location.pathname !== routes.signup ) {
                localStorage.setItem('lastPage', document.location.href)
                return 'non-auth'
            }
        } else {
            return null
        }

    } catch (error) {
        throw error
    }
}

function* userWorker() {

    const data = yield call(asyncGetUser)

    if (data && data.id) {
        yield put(setUser(data))
        yield put(setAccountMainData(data))
    } else if (data === 'non-auth') {
        yield put(exit())
        location.assign(routes.login)
    }
}

export function* UserWatcher() {
    yield takeEvery(CHECK_IS_AUTHORIZED, userWorker)
}