import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"

import { WarningYellowIcon } from "../../../icons"
import { closeModal } from "../../../store/reducers/ModalWindowsReducer"

import { tryToCancelValueConsultation } from "../../../store/reducers/ConsultationsCostReducer"

export const ConsultationCancelValueModal = () => {

    const dispatch = useDispatch()

    const modalInfo = useSelector( (state: any) => (state.modals.modalInfo))

    return (
        <div
            className='delete-measurement-consultation-modal-wrapper'
        >
            <div
                className='delete-measurement-consultation-modal'
            >

                <span
                    className='delete-measurement-consultation-modal__title'
                >
                    <FormattedMessage id='consultations_chat_cancel_value_modal_title' />
                </span>

                <div 
                    className='delete-measurement-consultation-modal__row'
                >
                    <div>
                        <WarningYellowIcon/>
                    </div>

                    <div 
                        className='delete-measurement-consultation-modal__row__main'
                    >

                        <span
                            className='delete-measurement-consultation-modal__row__main__title'
                        >
                            <FormattedMessage id='consultations_chat_cancel_value_modal_desc' />
                        </span>

                        <div
                            className='delete-measurement-consultation-modal__row__main__buttons'
                        >

                            <div
                                className='white-button'
                                onClick={() => dispatch(closeModal())}
                            >
                                <FormattedMessage id='consultation_chat_delete_measurement_button_cancel'/>
                            </div>

                            <div
                                className='green-button'
                                onClick={() => {
                                    dispatch(tryToCancelValueConsultation(modalInfo?.id))      
                                }}
                            >
                                <FormattedMessage id='consultations_chat_cancel_value_modal_confirm'/>
                            </div>
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
    )
}