import { useEffect, useRef, useState } from "react";

import { MeasurementType } from "../../interfaces/MeasurementType";
import { apiUrl } from "../../consts";
import { StepBar } from "../../components/StepBar";
import { BottomControlPanel } from "../../components/BottomControlPanel/index";
import { routes } from "../../routes";
import { MeasurementTypeCard } from "../../components/MeasurementTypeCard";
import { ChoosenMeasurementTypeCardCircles } from "../../components/ChoosenMeasurementTypeCardCircles";
import { cleanPersonForm } from "../../store/reducers/CurrentPersonReducer";
import "./styles.sass";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  chooseMeasurementType,
  cleanMeasurementTypeCard,
} from "../../store/reducers/MeasurementTypeReducer";

import { startMeasurement } from "../../store/reducers/MeasurementReducer";
import { nextStepBar } from "../../store/reducers/StepBarReducer";
import { SwipingFinger } from "../../images/swipingFinger";
import { cleanMeasurementProcessStorage } from "../../consts";
import { useNavigate } from "react-router-dom";

export const StartMeasurementContainer = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [measurementsTypesArray, setMeasurementsTypesArray] = useState<
    MeasurementType[]
  >([]);
  const [toNext, setToNext] = useState(true);
  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );
  const choosenMeasurementType = useSelector(
    (state: any) => state.measurementType.id
  );
  const Meas = useRef(null);
  const navigateBack = () => {
    Navigate(routes.measurementsList);
    return true;
  };

  const navigateNext = () => {
    dispatch(startMeasurement(choosenMeasurementType));
    return "";
  };

  let measurementTypesIdArray: string[] = [];
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(cleanPersonForm());
    dispatch(cleanMeasurementTypeCard());
    cleanMeasurementProcessStorage();
    const measurmentWrapper = window.document.getElementById(
      "measurement-types-wrapper"
    );

    let result: MeasurementType[] = [];
    const getMeasurements = async () => {
      try {
        const response = await fetch(apiUrl + "/measurement-types", {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.token,
          },
        });
        result = await response.json();
        if (document.body.clientWidth > 640) {
          dispatch(chooseMeasurementType(result[0]));
        }
        console.log(result[0]);
        result.forEach((elem, index) => {
          measurementTypesIdArray[index] = elem.id;
        });

        setMeasurementsTypesArray(result);
      } catch (error) {}
    };

    getMeasurements();

    dispatch(nextStepBar(0));

    if (document.body.clientWidth > 640) {
      measurmentWrapper!.scrollLeft = 180;
    } else {
      measurmentWrapper!.scrollLeft = 330;
    }

    let currentCardIndex = 0;
    function MouseWheelHandler(event) {
      event = window.event || event;
      let delta = 0;

      if (navigator.userAgent.indexOf("Firefox") > 0) {
        delta = 40 * Math.max(-1, Math.min(1, event.detail));
      } else {
        delta = 40 * Math.max(-1, Math.min(1, event.deltaY - event.detail));
      }

      let position = measurmentWrapper!.scrollLeft + delta;

      if (position < 0) {
        position = 0;
      } else if (position > measurmentWrapper!.scrollWidth) {
        position = measurmentWrapper!.scrollWidth;
      }

      measurmentWrapper!.scrollLeft = position;

      return false;
    }

    function handleScroll() {
      var wrapper: HTMLElement | null = window.document.getElementById(
        "measurement-types-wrapper"
      );

      if (wrapper) {
        var changePoint = wrapper.clientWidth / 3;
        var currentScroll = changePoint + wrapper.scrollLeft;
        var cardSectionWidth = (wrapper.scrollWidth - 800) / result.length;
        var index = Math.floor(currentScroll / cardSectionWidth);

        if (currentScroll <= cardSectionWidth) {
          dispatch(chooseMeasurementType(result[0]));
        } else if (index !== currentCardIndex) {
          dispatch(chooseMeasurementType(result[index]));
        } else {
        }
        currentCardIndex = index;
      }
    }

    measurmentWrapper!.addEventListener("scroll", handleScroll);
    measurmentWrapper!.addEventListener("mousewheel", MouseWheelHandler, false);
    measurmentWrapper!.addEventListener(
      "DOMMouseScroll",
      MouseWheelHandler,
      false
    );
    history.pushState(null, document.title);
    window.addEventListener("popstate", function () {
      history.pushState(null, document.title);
    });
    return function cleanupListener() {
      measurmentWrapper!.removeEventListener("scroll", handleScroll);
      measurmentWrapper!.removeEventListener(
        "mousewheel",
        MouseWheelHandler,
        false
      );
      measurmentWrapper!.removeEventListener(
        "DOMMouseScroll",
        MouseWheelHandler,
        false
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={`start-measurement-container ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
      >
        <div className="start-measurement-container__title-wrapper">
          <span>
            <FormattedMessage id="measurement_page_choose_type" />
          </span>

          {document.body.clientWidth > 640 && (
            <StepBar steps={8} changeble={"yes"} />
          )}
        </div>

        {document.body.clientWidth > 640 && (
          <ChoosenMeasurementTypeCardCircles
            array={measurementsTypesArray.map((elem) => elem.id)}
            Meas={Meas.current}
          />
        )}

        <div
          className="measurement-types-wrapper"
          ref={Meas}
          id="measurement-types-wrapper"
        >
          <div>
            {measurementsTypesArray.map((elem, index) => {
              return (
                <div key={index} onClick={() => setToNext(false)}>
                  <MeasurementTypeCard data={elem} />
                </div>
              );
            })}
            <div style={{ width: "800px" }}></div>
          </div>
        </div>

        {document.body.clientWidth <= 640 && (
          <div className="start-measurement-container__mobile-bottom-panel">
            <ChoosenMeasurementTypeCardCircles
              array={measurementsTypesArray.map((elem) => elem.id)}
            />

            <SwipingFinger />
          </div>
        )}
      </div>
      <BottomControlPanel
        nextPage={routes.measurementInstruction}
        previousPage={routes.measurementsList}
        backFunc={navigateBack}
        nextFunc={navigateNext}
        isNextButtonDisabled={toNext && true}
      />
    </>
  );
};
