import { Consultant } from "../../../interfaces/Consultant";

import { FormattedMessage } from "react-intl";

import "./styles.sass";
import { useDispatch, useSelector } from "react-redux";
import { showConsultantModal } from "../../../store/reducers/ModalWindowsReducer";
import { chooseConsultant } from "../../../store/reducers/CreatingConsultationReducer";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";

export const ChooseConsultantPanel = (prop: {
  data: Consultant;
  choosen?: boolean;
  canBeChoosen?: boolean;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const choosenConsultant = useSelector(
    (state: any) => state.creatingConsultation.consultant
  );

  return (
    <div
      className="choose-consultant-panel-wrapper"
      onClick={() => {
        if (!prop.canBeChoosen) {
          navigate(routes.consultant, { state: { id: prop.data.id } });
        }
      }}
    >
      <div
        className="choose-consultant-panel-wrapper__title"
        onClick={() => {
          dispatch(showConsultantModal(prop.data));
        }}
      >
        <div className="choose-consultant-panel-wrapper__title__photo">
          {prop.data && prop.data.photo && prop.data.photo.length > 0 && (
            <img src={prop.data.photo} />
          )}
        </div>
        <span className="choose-consultant-panel-wrapper__title__name">
          {prop.data.surname} {prop.data.name}
        </span>
      </div>

      {document.body.clientWidth < 640 && (
        <div
          className="choose-consultant-panel-wrapper__info__workloadDisplay"
          style={{
            backgroundColor: prop.data.workloadDisplay
              ? prop.data.workloadDisplay.color
              : "",
          }}
        >
          {prop.data.workloadDisplay.text ? prop.data.workloadDisplay.text : ""}
        </div>
      )}

      <div className="choose-consultant-panel-wrapper__info">
        {document.body.clientWidth > 640 && (
          <div
            className="choose-consultant-panel-wrapper__info__workloadDisplay"
            style={{
              backgroundColor: prop.data.workloadDisplay
                ? prop.data.workloadDisplay.color
                : "",
            }}
          >
            {prop.data.workloadDisplay.text
              ? prop.data.workloadDisplay.text
              : ""}
          </div>
        )}

        <div className="choose-consultant-panel-wrapper__info__column">
          <span>
            <FormattedMessage id="consultation_choose_consultant_panel_rating" />
          </span>
          <div>{prop.data.rating}</div>
        </div>

        <div className="choose-consultant-panel-wrapper__info__column">
          <span>
            <FormattedMessage id="consultation_choose_consultant_panel_reviews" />
          </span>
          <div>{prop.data.reviews && prop.data.reviews.length}</div>
        </div>
      </div>

      {!prop.choosen && prop.canBeChoosen && (
        <div
          className="choose-consultant-panel-wrapper__choose-wrapper"
          onClick={() => {
            dispatch(chooseConsultant(prop.data));
            navigate(routes.consultationInfo);
          }}
        >
          {choosenConsultant &&
          choosenConsultant.id &&
          choosenConsultant.id === prop.data.id ? (
            <div className="white-button">
              <FormattedMessage id="consultation_choose_consultant_panel_choosen" />
            </div>
          ) : (
            <div className="green-button">
              <FormattedMessage id="consultation_choose_consultant_panel_choose" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
