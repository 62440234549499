import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { OnePersonIcon } from "../../../icons";
import { Consultation, Status } from "../../../interfaces/Consultation";
import { Message } from "../../../interfaces/Message";
import { User } from "../../../interfaces/User";
import { downloadConsultationReport } from "../../../store/reducers/ConsultationChatReducer";
import store from "../../../store";

const Messages = ({
  arrayMessages,
  setArrayMessages,
  numberPage,
  setNumber,
}) => {
  const dispatch = useDispatch();

  const accountData: User = useSelector((state: any) => state.user);
  // const [arrayOfOldMessages, setArrayOfOldMessages] = useState<any>(useSelector( (state: any) => (state.consultationChat)));
  // const [numberOfPage, setNumberOfPage] = useState(1);

  async function getOldMessages() {
    let id = "";
    if (store.getState().currentConsultation) {
      id = store.getState().currentConsultation.id;
    }
    const response = await fetch(
      process.env.REACT_APP_API_HOST +
        "/consultation/" +
        id +
        "/messages?sort=date,desc&page=" +
        numberPage,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      }
    );

    if (response.status === 200) {
      const result = await response.json();

      if (result.length != 0) {
        setArrayMessages(arrayMessages.concat(result));
        setNumber(numberPage + 1);
      }
    } else if (response.status === 401) {
    } else {
      return null;
    }
  }

  let messagesList = useSelector((state: any) => state.consultationChat);
  //const [pageNum, setPageNum] = useState(0)
  const consultation: Consultation | null = useSelector(
    (state: any) => state.currentConsultation
  );
  useEffect(() => {
    setArrayMessages(messagesList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesList]);

  useEffect(() => {
    const consultationsWrapper: HTMLElement | null =
      window.document.getElementById("messageChat");
    const handleScroll = () => {
      let scrollTop = consultationsWrapper?.scrollTop || 0;
      let clientHeight = consultationsWrapper?.clientHeight || 0;
      let scrollheight = consultationsWrapper?.scrollHeight || 0;
      if (Math.abs(scrollTop) + clientHeight >= scrollheight) {
        getOldMessages();
      }
    };
    if (numberPage == 0 && consultationsWrapper?.scrollTop) {
      consultationsWrapper.scrollTop = 0;
    }
    consultationsWrapper?.addEventListener("scroll", handleScroll);
    return function cleanupListener() {
      consultationsWrapper!.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayMessages, numberPage]);
  return (
    <div
      id="messageChat"
      className={`
                ${
                  consultation?.status === Status.CONFIRMED &&
                  consultation?.senderId == accountData?.id &&
                  "messages-wrapper-short"
                }    
                consultation-chat-wrapper__messages-wrapper 
            `}
    >
      {arrayMessages &&
        arrayMessages.length > 0 &&
        (arrayMessages[0].text &&
        arrayMessages[0].text.length > 0 &&
        arrayMessages[0].text.includes("Описание ощущения проблемы:")
          ? arrayMessages.reverse().map((elem: Message, index) => {
              if (
                elem.messageType === "PAYMENT" &&
                accountData?.id !== consultation?.adresseeId
              ) {
                return (
                  <div
                    key={index}
                    className={`consultation-chat-wrapper__messages-wrapper__message`}
                  >
                    <div className="consultation-chat-wrapper__messages-wrapper__message__image">
                      {accountData &&
                      consultation?.adresseeId === elem.senderId ? (
                        consultation &&
                        consultation.adressePhoto &&
                        consultation?.adressePhoto.length > 0 ? (
                          <img src={consultation.adressePhoto} />
                        ) : (
                          <div>
                            <OnePersonIcon />
                          </div>
                        )
                      ) : consultation &&
                        consultation?.senderPhoto &&
                        consultation?.senderPhoto.length > 0 ? (
                        <img src={consultation?.senderPhoto} />
                      ) : (
                        <div>
                          <OnePersonIcon />
                        </div>
                      )}
                    </div>

                    <div
                      className={`consultation-chat-wrapper__messages-wrapper__message__text`}
                    >
                      <span>
                        {accountData && elem.senderId === accountData.id ? (
                          <>{accountData.fio} (Вы)</>
                        ) : accountData?.id === consultation?.consultantId ? (
                          <>{consultation?.senderName}</>
                        ) : (
                          <>{consultation?.adresseeName}</>
                        )}
                      </span>

                      <div
                        className={`${
                          (elem.canceled ||
                            consultation?.status === Status.WAIT_INVOICE) &&
                          "cancelled-payment-message"
                        } consultation-chat-wrapper__messages-wrapper__message__text__link `}
                      >
                        {elem.canceled ||
                        consultation?.status === Status.WAIT_INVOICE
                          ? elem.text
                          : elem.payUrl &&
                            elem.payUrl.length > 0 &&
                            (elem.paid ? (
                              <div className="last-paid-bill">{elem.text}</div>
                            ) : (
                              <a
                                target="_blank"
                                href={
                                  !elem.canceled && !elem.paid
                                    ? elem.payUrl
                                    : ""
                                }
                              >
                                {elem.text}
                              </a>
                            ))}
                      </div>
                    </div>
                  </div>
                );
              } else if (elem.messageType === "CONSULTATION") {
                return (
                  <div
                    key={index}
                    className={`consultation-chat-wrapper__messages-wrapper__message`}
                  >
                    <div className="consultation-chat-wrapper__messages-wrapper__message__image">
                      {accountData &&
                      consultation?.adresseeId === elem.senderId ? (
                        consultation &&
                        consultation.adressePhoto &&
                        consultation?.adressePhoto.length > 0 ? (
                          <img src={consultation.adressePhoto} />
                        ) : (
                          <div>
                            <OnePersonIcon />
                          </div>
                        )
                      ) : consultation &&
                        consultation?.senderPhoto &&
                        consultation?.senderPhoto.length > 0 ? (
                        <img src={consultation?.senderPhoto} />
                      ) : (
                        <div>
                          <OnePersonIcon />
                        </div>
                      )}
                    </div>

                    <div
                      className={`consultation-chat-wrapper__messages-wrapper__message__text`}
                    >
                      <span>
                        {accountData && elem.senderId === accountData.id ? (
                          <>{accountData.fio} (Вы)</>
                        ) : accountData?.id === consultation?.consultantId ? (
                          <>{consultation?.senderName}</>
                        ) : (
                          <>{consultation?.adresseeName}</>
                        )}
                      </span>

                      <div
                        className={`consultation-chat-wrapper__messages-wrapper__message__text__link `}
                      >
                        {
                          <a
                            onClick={() =>
                              dispatch(downloadConsultationReport(elem.text))
                            }
                          >
                            <FormattedMessage id="consultations_chat_result_download" />
                          </a>
                        }
                      </div>
                    </div>
                  </div>
                );
              } else if (elem.messageType === "CONSULTATION_PAID") {
                return (
                  <div
                    key={index}
                    className="consultation-chat-wrapper__messages-wrapper__message"
                  >
                    <div className="consultation-chat-wrapper__messages-wrapper__message__image">
                      {accountData &&
                      consultation?.adresseeId === elem.senderId ? (
                        consultation &&
                        consultation.adressePhoto &&
                        consultation?.adressePhoto.length > 0 ? (
                          <img src={consultation.adressePhoto} />
                        ) : (
                          <div>
                            <OnePersonIcon />
                          </div>
                        )
                      ) : consultation &&
                        consultation?.senderPhoto &&
                        consultation?.senderPhoto.length > 0 ? (
                        <img src={consultation?.senderPhoto} />
                      ) : (
                        <div>
                          <OnePersonIcon />
                        </div>
                      )}
                    </div>

                    <div className="consultation-chat-wrapper__messages-wrapper__message__text">
                      <span>
                        {accountData && elem.senderId === accountData.id ? (
                          <>{accountData.fio} (Вы)</>
                        ) : accountData?.id === consultation?.consultantId ? (
                          <>{consultation?.senderName}</>
                        ) : (
                          <>{consultation?.adresseeName}</>
                        )}
                      </span>
                      <div>
                        {elem.messageType &&
                          elem.messageType === "CONSULTATION_PAID" && (
                            <div className="consultation-chat-wrapper__messages-wrapper__message__text__invoice">
                              Консультация оплачена
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                );
              } else if (
                elem.messageType === "TEXT" ||
                elem.messageType === "INVOICE" ||
                elem.messageType === "CONSULTATION_PAID"
              ) {
                return (
                  <div
                    key={index}
                    className="consultation-chat-wrapper__messages-wrapper__message"
                  >
                    <div className="consultation-chat-wrapper__messages-wrapper__message__image">
                      {accountData &&
                      consultation?.adresseeId === elem.senderId ? (
                        consultation &&
                        consultation.adressePhoto &&
                        consultation?.adressePhoto.length > 0 ? (
                          <img src={consultation.adressePhoto} />
                        ) : (
                          <div>
                            <OnePersonIcon />
                          </div>
                        )
                      ) : consultation &&
                        consultation?.senderPhoto &&
                        consultation?.senderPhoto.length > 0 ? (
                        <img src={consultation?.senderPhoto} />
                      ) : (
                        <div>
                          <OnePersonIcon />
                        </div>
                      )}
                    </div>

                    <div className="consultation-chat-wrapper__messages-wrapper__message__text">
                      <span>
                        {accountData && elem.senderId === accountData.id ? (
                          <>{accountData.fio} (Вы)</>
                        ) : accountData?.id === consultation?.consultantId ? (
                          <>{consultation?.senderName}</>
                        ) : (
                          <>{consultation?.adresseeName}</>
                        )}
                      </span>
                      <div>
                        {elem.messageType && elem.messageType === "INVOICE" ? (
                          <div className="consultation-chat-wrapper__messages-wrapper__message__text__invoice">
                            {elem.text}
                          </div>
                        ) : (
                          <>{elem.text}</>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            })
          : arrayMessages.map((elem: Message, index) => {
              if (elem.messageType === "PAYMENT") {
                return (
                  <div
                    key={index}
                    className={`consultation-chat-wrapper__messages-wrapper__message`}
                  >
                    <div className="consultation-chat-wrapper__messages-wrapper__message__image">
                      {accountData &&
                      consultation?.adresseeId === elem.senderId ? (
                        consultation &&
                        consultation.adressePhoto &&
                        consultation?.adressePhoto.length > 0 ? (
                          <img src={consultation.adressePhoto} />
                        ) : (
                          <div>
                            <OnePersonIcon />
                          </div>
                        )
                      ) : consultation &&
                        consultation?.senderPhoto &&
                        consultation?.senderPhoto.length > 0 ? (
                        <img src={consultation?.senderPhoto} />
                      ) : (
                        <div>
                          <OnePersonIcon />
                        </div>
                      )}
                    </div>

                    <div
                      className={`consultation-chat-wrapper__messages-wrapper__message__text`}
                    >
                      <span>
                        {accountData && elem.senderId === accountData.id ? (
                          <>{accountData.fio} (Вы)</>
                        ) : accountData?.id === consultation?.consultantId ? (
                          <>{consultation?.senderName}</>
                        ) : (
                          <>{consultation?.adresseeName}</>
                        )}
                      </span>

                      <div
                        className={`${
                          (elem.canceled ||
                            consultation?.status === Status.WAIT_INVOICE) &&
                          "cancelled-payment-message"
                        } consultation-chat-wrapper__messages-wrapper__message__text__link `}
                      >
                        {elem.canceled ||
                        consultation?.status === Status.WAIT_INVOICE
                          ? elem.text
                          : elem.payUrl &&
                            elem.payUrl.length > 0 &&
                            (elem.paid ? (
                              <div className="last-paid-bill">{elem.text}</div>
                            ) : elem.senderId == accountData.id ? (
                              <a
                                target="_blank"
                                style={{ pointerEvents: "none" }}
                                href={
                                  !elem.canceled && !elem.paid
                                    ? elem.payUrl
                                    : ""
                                }
                              >
                                {elem.text}
                              </a>
                            ) : (
                              <a
                                target="_blank"
                                href={
                                  !elem.canceled && !elem.paid
                                    ? elem.payUrl
                                    : ""
                                }
                              >
                                {elem.text}
                              </a>
                            ))}
                      </div>
                    </div>
                  </div>
                );
              } else if (elem.messageType === "CONSULTATION") {
                return (
                  <div
                    key={index}
                    className={`consultation-chat-wrapper__messages-wrapper__message`}
                  >
                    <div className="consultation-chat-wrapper__messages-wrapper__message__image">
                      {accountData &&
                      consultation?.adresseeId === elem.senderId ? (
                        consultation &&
                        consultation.adressePhoto &&
                        consultation?.adressePhoto.length > 0 ? (
                          <img src={consultation.adressePhoto} />
                        ) : (
                          <div>
                            <OnePersonIcon />
                          </div>
                        )
                      ) : consultation &&
                        consultation?.senderPhoto &&
                        consultation?.senderPhoto.length > 0 ? (
                        <img src={consultation?.senderPhoto} />
                      ) : (
                        <div>
                          <OnePersonIcon />
                        </div>
                      )}
                    </div>

                    <div
                      className={`consultation-chat-wrapper__messages-wrapper__message__text`}
                    >
                      <span>
                        {accountData && elem.senderId === accountData.id ? (
                          <>{accountData.fio} (Вы)</>
                        ) : accountData?.id === consultation?.consultantId ? (
                          <>{consultation?.senderName}</>
                        ) : (
                          <>{consultation?.adresseeName}</>
                        )}
                      </span>

                      <div
                        className={`consultation-chat-wrapper__messages-wrapper__message__text__link `}
                      >
                        {
                          <a
                            onClick={() =>
                              dispatch(downloadConsultationReport(elem.text))
                            }
                          >
                            <FormattedMessage id="consultations_chat_result_download" />
                          </a>
                        }
                      </div>
                    </div>
                  </div>
                );
              } else if (elem.messageType === "CONSULTATION_PAID") {
                return (
                  <div
                    key={index}
                    className="consultation-chat-wrapper__messages-wrapper__message"
                  >
                    <div className="consultation-chat-wrapper__messages-wrapper__message__image">
                      {accountData &&
                      consultation?.adresseeId === elem.senderId ? (
                        consultation &&
                        consultation.adressePhoto &&
                        consultation?.adressePhoto.length > 0 ? (
                          <img src={consultation.adressePhoto} />
                        ) : (
                          <div>
                            <OnePersonIcon />
                          </div>
                        )
                      ) : consultation &&
                        consultation?.senderPhoto &&
                        consultation?.senderPhoto.length > 0 ? (
                        <img src={consultation?.senderPhoto} />
                      ) : (
                        <div>
                          <OnePersonIcon />
                        </div>
                      )}
                    </div>

                    <div className="consultation-chat-wrapper__messages-wrapper__message__text">
                      <span>
                        {accountData && elem.senderId === accountData.id ? (
                          <>{accountData.fio} (Вы)</>
                        ) : accountData?.id === consultation?.consultantId ? (
                          <>{consultation?.senderName}</>
                        ) : (
                          <>{consultation?.adresseeName}</>
                        )}
                      </span>
                      <div>
                        {elem.messageType &&
                          elem.messageType === "CONSULTATION_PAID" && (
                            <div className="consultation-chat-wrapper__messages-wrapper__message__text__invoice">
                              Консультация оплачена
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                );
              } else if (
                elem.messageType === "TEXT" ||
                elem.messageType === "INVOICE" ||
                elem.messageType === "CONSULTATION_PAID"
              ) {
                return (
                  <div
                    key={index}
                    className="consultation-chat-wrapper__messages-wrapper__message"
                  >
                    <div className="consultation-chat-wrapper__messages-wrapper__message__image">
                      {accountData &&
                      consultation?.adresseeId === elem.senderId ? (
                        consultation &&
                        consultation.adressePhoto &&
                        consultation?.adressePhoto.length > 0 ? (
                          <img src={consultation.adressePhoto} />
                        ) : (
                          <div>
                            <OnePersonIcon />
                          </div>
                        )
                      ) : consultation &&
                        consultation?.senderPhoto &&
                        consultation?.senderPhoto.length > 0 ? (
                        <img src={consultation?.senderPhoto} />
                      ) : (
                        <div>
                          <OnePersonIcon />
                        </div>
                      )}
                    </div>

                    <div
                      className="consultation-chat-wrapper__messages-wrapper__message__text"
                      style={
                        accountData && elem.senderId !== accountData.id
                          ? { background: "#effdde" }
                          : { background: "#fff" }
                      }
                    >
                      <span>
                        {accountData && elem.senderId === accountData.id ? (
                          <>{/*  {accountData.fio} (Вы) */}</>
                        ) : accountData?.id === consultation?.consultantId ? (
                          <>{/* {consultation?.senderName} */}</>
                        ) : (
                          <>{/*   {consultation?.adresseeName} */}</>
                        )}
                      </span>
                      <div>
                        {elem.messageType && elem.messageType === "INVOICE" ? (
                          <div className="consultation-chat-wrapper__messages-wrapper__message__text__invoice">
                            {elem.text}
                          </div>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{ __html: elem.text }}
                          ></div>
                        )}
                      </div>
                      <div
                        style={{
                          marginTop: "8px",
                          textAlign: "right",
                          color: "gray",
                          opacity: "0.6",
                        }}
                      >
                        {elem.date
                          .substring(0, 10)
                          .split("-")
                          .reverse()
                          .join(".") +
                          " " +
                          elem.date.substring(11, elem.date.length - 5)}
                      </div>
                    </div>
                  </div>
                );
              }
            }))}
    </div>
  );
};

export const MessagesList = React.memo(Messages);
