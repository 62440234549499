import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { ActivatedPackage } from "../interfaces/ActivatedPackage";
import { GET_ACCOUNT_SERVICES, setAccountServices } from "../store/reducers/AccountServicesReducer";
import {routes} from '../routes'

async function asyncGetAccountServices() {
    try {

        const response = await fetch(
            apiUrl + '/user/activated-packages',
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            }
        )
        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch(error) {}
}

function* GetAccountServicesWorker() {
    const data: ActivatedPackage[] | null = yield call(asyncGetAccountServices)

    if (data && data.length > 0) {
        yield put( setAccountServices(data) )
    }
}

export function* GetAccountServicesWatcher() {
    yield takeEvery(GET_ACCOUNT_SERVICES, GetAccountServicesWorker)
}