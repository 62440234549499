import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import { setCountriesList, TRY_TO_GET_COUNTRIES_LIST } from "../store/reducers/CountriesListReducer";

const asyncGetCountriesList = async () => {
    try {
        const response = await fetch(
            apiUrl + '/static-information/nations',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                }
            }
        )
        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(error) {}
}

function* CountriesListWorker() {
    const data: any = yield call(asyncGetCountriesList)
    if (data && data.length > 0) {
        yield put( setCountriesList(data) )
    }
}

export function* CountriesListWatcher() {
    yield takeEvery(TRY_TO_GET_COUNTRIES_LIST, CountriesListWorker)
}