export const MeasurementFace0 = () => (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="26" cy="26" r="26" fill="#AC3838"/>
    <path d="M23.9376 1.46692C14.2551 2.26262 5.75637 8.97615 2.6323 18.2897C0.371694 25.0519 1.20605 32.522 4.91197 38.6245C8.29861 44.2035 13.6346 48.2001 19.8531 49.8206C22.0068 50.3834 23.5982 50.5771 25.9944 50.5771C27.6535 50.5771 28.3614 50.5385 29.4678 50.3638C40.3436 48.7046 48.6968 40.3515 50.3554 29.4757C50.6567 27.5063 50.6567 24.4891 50.3554 22.5292C49.2299 15.1169 45.0195 8.80095 38.6162 4.92033C34.2315 2.25259 29.0702 1.0402 23.9376 1.46692ZM29.4869 2.86404C33.4258 3.46547 37.0057 4.97906 40.2462 7.40433C41.4395 8.29692 43.7001 10.5575 44.5927 11.7508C46.4261 14.1957 47.8042 16.9703 48.5316 19.6968C50.4624 26.8466 49.0557 34.278 44.661 40.1672C43.6811 41.477 41.4692 43.6889 40.1594 44.6689C34.2707 49.0636 26.8388 50.4702 19.6885 48.5399C18.3787 48.1905 17.1176 47.7056 15.6623 47.0073C13.2561 45.8431 11.4518 44.5429 9.45326 42.5443C7.46425 40.5653 6.17406 38.7606 4.99029 36.3353C3.91345 34.1229 3.22468 31.8914 2.83662 29.3984C2.59414 27.807 2.59414 24.1688 2.84616 22.5583C3.23422 20.0553 3.90341 17.9111 4.99029 15.6701C6.17406 13.2448 7.46425 11.4496 9.45326 9.46111C11.4423 7.4726 13.237 6.18191 15.6623 4.99814C18.3496 3.68837 20.5324 3.08694 24.0545 2.6793C24.8302 2.59245 28.517 2.70842 29.4869 2.86404Z" fill="black"/>
    <path d="M16.2639 9.81051C15.7107 10.2372 15.6429 10.1695 20.494 15.0205C23.0357 17.5723 25.1121 19.5803 25.2868 19.6582C25.6844 19.8329 26.3054 19.8329 26.7035 19.6582C26.8782 19.5803 28.954 17.5723 31.5058 15.0205C35.163 11.3532 35.9879 10.4802 35.9879 10.2668C35.9879 9.93702 35.5611 9.57807 35.27 9.66542C35.1535 9.69454 33.0581 11.7222 30.6037 14.1766C28.1493 16.6214 26.0729 18.6295 25.9956 18.6295C25.9178 18.6295 23.8419 16.6214 21.3871 14.1766C18.9327 11.7222 16.8367 9.69454 16.7203 9.66542C16.5938 9.62627 16.4291 9.67446 16.2639 9.81051Z" fill="black"/>
    <path d="M17.4083 17.3389C16.2245 17.7656 15.235 18.6682 14.7014 19.7932C14.1386 20.9961 14.2551 22.6261 15.0021 23.7999C15.6522 24.8285 16.8551 25.6237 18.0775 25.8371C20.9782 26.3321 23.7046 23.3536 22.9476 20.5106C22.6178 19.2686 21.6574 18.1145 20.4932 17.5517C19.9109 17.2706 19.7266 17.2314 18.8631 17.2023C18.1166 17.1742 17.7773 17.2128 17.4083 17.3389ZM19.708 18.5322C20.4746 18.7651 21.1438 19.3178 21.5123 20.0262C21.7452 20.4725 21.7744 20.6276 21.7744 21.4911C21.7744 22.3254 21.7452 22.5292 21.5414 22.9364C21.0173 23.9936 20.1247 24.5564 18.8636 24.6247C17.224 24.7025 16.0503 23.8581 15.6522 22.2963C15.2059 20.5789 16.2245 18.8424 17.9128 18.4448C18.5243 18.2992 19.0479 18.3283 19.708 18.5322Z" fill="black"/>
    <path d="M31.6706 17.3489C30.4969 17.7565 29.4973 18.6491 28.9737 19.7646C28.5566 20.6668 28.4888 21.8892 28.8086 22.84C29.4778 24.8772 31.758 26.2452 33.747 25.8185C35.4448 25.4596 36.9002 24.0042 37.2591 22.3064C37.6763 20.3465 36.3765 18.125 34.368 17.3971C33.5913 17.116 32.398 17.0964 31.6706 17.3489ZM34.1928 18.5904C34.872 18.8233 35.5994 19.5799 35.8811 20.3269C36.0653 20.8023 36.0944 21.0448 36.0653 21.6854C35.9684 23.4319 34.872 24.5278 33.1255 24.6252C32.0777 24.6739 31.389 24.4214 30.6902 23.7326C30.0692 23.1021 29.8267 22.4715 29.8362 21.4429C29.8367 19.1823 31.9327 17.8143 34.1928 18.5904Z" fill="black"/>
    <path d="M23.4819 32.3573C19.7368 32.9296 16.4385 34.5401 13.7994 37.1014C13.1397 37.732 13.0328 38.0522 13.3626 38.353C13.7311 38.6828 14.0223 38.5663 14.9149 37.742C16.0404 36.6943 17.2724 35.8308 18.6208 35.132C24.7038 31.9984 31.9896 33.0265 37.0736 37.742C37.9757 38.5764 38.3151 38.7024 38.6354 38.3434C38.9557 37.9845 38.8487 37.7325 37.9953 36.9172C35.6378 34.6566 32.446 33.0657 29.0503 32.4447C27.8379 32.2218 24.6948 32.1731 23.4819 32.3573Z" fill="black"/>
    </svg>
)

export const MeasurementFace1 = () => (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="26" cy="26" r="26" fill="#BB7248"/>
    <path d="M26 0C11.6638 0 0 11.6638 0 26C0 40.3362 11.6638 52 26 52C40.3373 52 52 40.3362 52 26C52 11.6638 40.3373 0 26 0ZM26 49.7391C12.9096 49.7391 2.26087 39.0904 2.26087 26C2.26087 12.9096 12.9096 2.26087 26 2.26087C39.0893 2.26087 49.7391 12.9096 49.7391 26C49.7391 39.0904 39.0893 49.7391 26 49.7391Z" fill="black"/>
    <path d="M20.348 23.7391C20.348 21.8694 18.8265 20.3478 16.9567 20.3478C15.087 20.3478 13.5654 21.8694 13.5654 23.7391C13.5654 25.6089 15.087 27.1304 16.9567 27.1304C18.8265 27.1304 20.348 25.6089 20.348 23.7391ZM15.8263 23.7391C15.8263 23.1163 16.3339 22.6087 16.9567 22.6087C17.5796 22.6087 18.0872 23.1163 18.0872 23.7391C18.0872 24.362 17.5796 24.8696 16.9567 24.8696C16.3339 24.8696 15.8263 24.362 15.8263 23.7391Z" fill="black"/>
    <path d="M35.0436 20.3478C33.1739 20.3478 31.6523 21.8694 31.6523 23.7391C31.6523 25.6089 33.1739 27.1304 35.0436 27.1304C36.9134 27.1304 38.4349 25.6089 38.4349 23.7391C38.4349 21.8694 36.9134 20.3478 35.0436 20.3478ZM35.0436 24.8696C34.4196 24.8696 33.9132 24.362 33.9132 23.7391C33.9132 23.1163 34.4196 22.6087 35.0436 22.6087C35.6676 22.6087 36.1741 23.1163 36.1741 23.7391C36.1741 24.362 35.6676 24.8696 35.0436 24.8696Z" fill="black"/>
    <path d="M30.5231 15.8258C30.6927 15.8258 30.8657 15.7874 31.0273 15.7071L35.5491 13.4462C36.1075 13.167 36.3336 12.4876 36.0544 11.9292C35.774 11.3708 35.0946 11.1436 34.5373 11.4239L30.0156 13.6848C29.4571 13.964 29.2311 14.6434 29.5103 15.2018C29.7092 15.5975 30.1083 15.8258 30.5231 15.8258Z" fill="black"/>
    <path d="M16.4516 13.4465L20.9734 15.7074C21.135 15.7876 21.308 15.8261 21.4775 15.8261C21.8924 15.8261 22.2914 15.5977 22.4904 15.2009C22.7696 14.6425 22.5435 13.9631 21.9851 13.6839L17.4634 11.423C16.9027 11.1427 16.2255 11.371 15.9463 11.9283C15.666 12.4879 15.8932 13.1661 16.4516 13.4465Z" fill="black"/>
    <path d="M26.0003 31.6522C21.7849 31.6522 17.7696 33.4383 14.9842 36.5504C14.5682 37.0161 14.6077 37.7305 15.0723 38.1465C15.5381 38.5636 16.2525 38.523 16.6685 38.0583C19.0266 35.4233 22.4269 33.9131 26.0003 33.9131C29.5713 33.9131 32.9728 35.4233 35.332 38.0583C35.5547 38.3082 35.8644 38.4348 36.1742 38.4348C36.4432 38.4348 36.7134 38.3398 36.9282 38.1465C37.3928 37.7294 37.4323 37.0161 37.0163 36.5504C34.2287 33.4371 30.2145 31.6522 26.0003 31.6522Z" fill="black"/>
    </svg>
)

export const MeasurementFace2 = () => (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="26" cy="26" r="26" fill="#DD8C45"/>
<path d="M26 0C11.6638 0 0 11.6638 0 26C0 40.3362 11.6638 52 26 52C40.3373 52 52 40.3362 52 26C52 11.6638 40.3373 0 26 0ZM26 49.7391C12.9096 49.7391 2.26087 39.0904 2.26087 26C2.26087 12.9096 12.9096 2.26087 26 2.26087C39.0893 2.26087 49.7391 12.9096 49.7391 26C49.7391 39.0904 39.0893 49.7391 26 49.7391Z" fill="black"/>
<path d="M20.348 21.4783C20.348 19.6085 18.8265 18.087 16.9567 18.087C15.087 18.087 13.5654 19.6085 13.5654 21.4783C13.5654 23.348 15.087 24.8696 16.9567 24.8696C18.8265 24.8696 20.348 23.348 20.348 21.4783ZM15.8263 21.4783C15.8263 20.8554 16.3339 20.3478 16.9567 20.3478C17.5796 20.3478 18.0872 20.8554 18.0872 21.4783C18.0872 22.1011 17.5796 22.6087 16.9567 22.6087C16.3339 22.6087 15.8263 22.1011 15.8263 21.4783Z" fill="black"/>
<path d="M35.0436 18.087C33.1739 18.087 31.6523 19.6085 31.6523 21.4783C31.6523 23.348 33.1739 24.8696 35.0436 24.8696C36.9134 24.8696 38.4349 23.348 38.4349 21.4783C38.4349 19.6085 36.9134 18.087 35.0436 18.087ZM35.0436 22.6087C34.4196 22.6087 33.9132 22.1011 33.9132 21.4783C33.9132 20.8554 34.4196 20.3478 35.0436 20.3478C35.6676 20.3478 36.1741 20.8554 36.1741 21.4783C36.1741 22.1011 35.6676 22.6087 35.0436 22.6087Z" fill="black"/>
<path d="M26.0003 31.6522C21.786 31.6522 17.7707 33.4371 14.9842 36.5504C14.5682 37.0161 14.6077 37.7305 15.0723 38.1465C15.5381 38.5636 16.2525 38.523 16.6685 38.0583C19.0266 35.4233 22.4281 33.9131 26.0003 33.9131C29.5724 33.9131 32.9739 35.4233 35.332 38.0583C35.5547 38.3082 35.8644 38.4348 36.1742 38.4348C36.4421 38.4348 36.7134 38.3398 36.9282 38.1465C37.3928 37.7305 37.4323 37.0161 37.0163 36.5504C34.2298 33.4383 30.2156 31.6522 26.0003 31.6522Z" fill="black"/>
</svg>
)

export const MeasurementFace3 = () => (
<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="26" cy="26" r="26" fill="#DBD55E"/>
<path d="M26 0C11.6638 0 0 11.6638 0 26C0 40.3362 11.6638 52 26 52C40.3362 52 52 40.3362 52 26C52 11.6638 40.3362 0 26 0ZM26 49.7391C12.9096 49.7391 2.26087 39.0904 2.26087 26C2.26087 12.9096 12.9096 2.26087 26 2.26087C39.0904 2.26087 49.7391 12.9096 49.7391 26C49.7391 39.0904 39.0904 49.7391 26 49.7391Z" fill="black"/>
<path d="M16.9567 24.8696C18.8265 24.8696 20.348 23.348 20.348 21.4783C20.348 19.6085 18.8265 18.087 16.9567 18.087C15.087 18.087 13.5654 19.6085 13.5654 21.4783C13.5654 23.348 15.087 24.8696 16.9567 24.8696ZM16.9567 20.3478C17.5796 20.3478 18.0872 20.8554 18.0872 21.4783C18.0872 22.1011 17.5796 22.6087 16.9567 22.6087C16.3339 22.6087 15.8263 22.1011 15.8263 21.4783C15.8263 20.8554 16.3339 20.3478 16.9567 20.3478Z" fill="black"/>
<path d="M35.0436 18.087C33.1739 18.087 31.6523 19.6085 31.6523 21.4783C31.6523 23.348 33.1739 24.8696 35.0436 24.8696C36.9134 24.8696 38.4349 23.348 38.4349 21.4783C38.4349 19.6085 36.9134 18.087 35.0436 18.087ZM35.0436 22.6087C34.4208 22.6087 33.9132 22.1011 33.9132 21.4783C33.9132 20.8554 34.4208 20.3478 35.0436 20.3478C35.6665 20.3478 36.1741 20.8554 36.1741 21.4783C36.1741 22.1011 35.6665 22.6087 35.0436 22.6087Z" fill="black"/>
<path d="M37.3046 31.6522H14.6959C14.0719 31.6522 13.5654 32.1586 13.5654 32.7826C13.5654 33.4066 14.0719 33.913 14.6959 33.913H37.3046C37.9286 33.913 38.435 33.4066 38.435 32.7826C38.435 32.1586 37.9286 31.6522 37.3046 31.6522Z" fill="black"/>
</svg>
)

export const MeasurementFace4 = () => (
<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="26" cy="26" r="26" fill="#92BF48"/>
<path d="M24.1554 3.05189C20.5547 3.3875 17.1807 4.48494 14.1874 6.29914C9.46203 9.17417 5.91577 13.5912 4.17432 18.7883C0.782024 28.9196 4.92676 40.0757 14.1874 45.699C16.8085 47.286 19.5568 48.275 22.7585 48.774C24.4544 49.0462 27.4116 49.0641 29.0075 48.819C35.4471 47.8488 40.7076 44.7105 44.5355 39.5856C46.7215 36.6651 48.209 33.0371 48.7891 29.2459C49.0703 27.4049 49.0703 24.5839 48.7891 22.7518C48.3174 19.6773 47.3199 16.8652 45.778 14.2896C42.1951 8.28514 36.1728 4.24931 29.2798 3.23307C27.8923 3.02467 25.3707 2.93407 24.1554 3.05189ZM29.2798 4.36713C32.9622 4.92946 36.3178 6.35359 39.3384 8.61184C40.4541 9.44642 42.5673 11.5596 43.4019 12.6754C48.3451 19.2783 49.2703 27.8949 45.8235 35.3142C44.7805 37.5729 43.3475 39.6044 41.4788 41.4637C38.7305 44.2209 35.6466 46.0168 31.8647 47.0781C27.5745 48.2844 22.6041 48.076 18.396 46.5247C15.421 45.4183 12.8722 43.7768 10.5505 41.4637C8.69129 39.6133 7.48495 37.9263 6.37859 35.6587C5.37174 33.5906 4.72774 31.5046 4.3649 29.1736C4.13818 27.6861 4.13818 24.2849 4.37381 22.779C4.95445 19.0239 6.33306 15.7405 8.62745 12.6749C9.46203 11.5592 11.5752 9.44595 12.691 8.61137C16.1105 6.05365 19.7384 4.65674 24.1554 4.19439C24.9628 4.11319 28.364 4.22208 29.2798 4.36713Z" fill="black"/>
<path d="M18.2235 14.0813C17.0355 14.3986 16.0559 15.2331 15.5025 16.3761C15.1852 17.0384 15.1758 17.1018 15.1758 18.072C15.1758 19.0423 15.1847 19.0878 15.5114 19.7501C16.7811 22.3257 20.0011 22.9063 22.0148 20.9381C23.7107 19.2784 23.7107 16.8746 22.0331 15.2059C20.9352 14.1174 19.5749 13.7184 18.2235 14.0813ZM20.5001 15.3782C21.0624 15.6504 21.5792 16.1583 21.8515 16.7113C22.0421 17.0924 22.0693 17.2825 22.0693 18.0626C22.0693 18.87 22.0421 19.024 21.8243 19.4323C21.5342 19.9764 20.9718 20.5115 20.4001 20.7654C19.765 21.0555 18.6131 21.0377 17.933 20.7293C15.9831 19.8224 15.6475 17.0741 17.3251 15.7227C18.1146 15.0876 19.5655 14.9337 20.5001 15.3782Z" fill="black"/>
<path d="M31.5751 14.0718C30.2054 14.4436 29.1079 15.4687 28.6362 16.8201C28.4912 17.2374 28.455 17.5097 28.4818 18.2076C28.509 18.9967 28.5451 19.1417 28.8446 19.7585C29.4525 20.9738 30.6588 21.8811 31.9557 22.0806C34.06 22.4073 36.146 20.856 36.5544 18.6705C37.0623 15.9583 34.2417 13.3555 31.5751 14.0718ZM33.8333 15.3782C34.3867 15.641 34.9218 16.1761 35.1847 16.7295C35.4842 17.3646 35.5292 18.4438 35.2753 19.115C35.0124 19.7951 34.4773 20.3941 33.8333 20.7114C33.3888 20.9292 33.2076 20.9653 32.5453 20.9653C31.2845 20.9564 30.4138 20.4574 29.9148 19.4417C29.1075 17.773 29.8153 15.8771 31.4751 15.2786C32.1374 15.0515 33.2348 15.097 33.8333 15.3782Z" fill="black"/>
<path d="M20.104 35.6023C19.8985 36.0301 20.0889 36.2707 20.938 36.6647C24.1737 38.1338 28.0476 38.3796 31.4072 37.3259C32.1608 37.0903 32.137 36.4739 32.137 36.4739C32.137 36.4739 31.7518 36.0608 30.7896 36.3314C27.5022 37.2353 24.5207 36.9838 21.2133 35.5299C20.5195 35.2256 20.276 35.2434 20.104 35.6023Z" fill="black"/>
</svg>
)

export const MeasurementFace5 = () => (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="26" cy="26" r="26" fill="#62B549"/>
<path d="M24.1554 3.05189C20.5547 3.3875 17.1807 4.48494 14.1874 6.29914C9.46203 9.17417 5.91577 13.5912 4.17432 18.7883C0.782024 28.9196 4.92676 40.0757 14.1874 45.699C16.8085 47.286 19.5568 48.275 22.7585 48.774C24.4544 49.0462 27.4116 49.0641 29.0075 48.819C35.4471 47.8488 40.7076 44.7105 44.5355 39.5856C46.7215 36.6651 48.209 33.0371 48.7891 29.2459C49.0703 27.4049 49.0703 24.5839 48.7891 22.7518C48.3174 19.6773 47.3199 16.8652 45.778 14.2896C42.1951 8.28514 36.1728 4.24931 29.2798 3.23307C27.8923 3.02467 25.3707 2.93407 24.1554 3.05189ZM29.2798 4.36713C32.9622 4.92946 36.3178 6.35359 39.3384 8.61184C40.4541 9.44642 42.5673 11.5596 43.4019 12.6754C48.3451 19.2783 49.2703 27.8949 45.8235 35.3142C44.7805 37.5729 43.3475 39.6044 41.4788 41.4637C38.7305 44.2209 35.6466 46.0168 31.8647 47.0781C27.5745 48.2844 22.6041 48.076 18.396 46.5247C15.421 45.4183 12.8722 43.7768 10.5505 41.4637C8.69129 39.6133 7.48495 37.9263 6.37859 35.6587C5.37174 33.5906 4.72774 31.5046 4.3649 29.1736C4.13818 27.6861 4.13818 24.2849 4.37381 22.779C4.95445 19.0239 6.33306 15.7405 8.62745 12.6749C9.46203 11.5592 11.5752 9.44595 12.691 8.61137C16.1105 6.05365 19.7384 4.65674 24.1554 4.19439C24.9628 4.11319 28.364 4.22208 29.2798 4.36713Z" fill="black"/>
<path d="M31.212 7.87722C30.822 8.3851 31.0309 8.68457 32.0194 9.03802C33.9242 9.71817 35.7379 10.7978 37.2437 12.1398C38.3595 13.1283 38.4501 13.1828 38.7496 13.0466C39.0218 12.9288 39.1396 12.5566 39.0035 12.2576C38.8585 11.9403 37.3437 10.6523 36.2735 9.9355C35.2122 9.21921 33.8881 8.52967 32.7089 8.07624C31.8833 7.75893 31.3571 7.68664 31.212 7.87722Z" fill="black"/>
<path d="M19.811 8.45747C18.0878 8.92921 16.3464 9.7544 14.7228 10.8612C13.5347 11.6686 13.1357 12.0403 13.1357 12.3304C13.1357 12.5661 13.4986 12.9383 13.7253 12.9383C13.7887 12.9383 14.2693 12.6116 14.7955 12.2215C16.5459 10.9063 18.3784 10.0173 20.3099 9.52769C21.2712 9.28267 21.5707 9.09256 21.5707 8.75695C21.5707 8.4847 21.244 8.22184 20.9178 8.23076C20.7544 8.23076 20.2555 8.33074 19.811 8.45747Z" fill="black"/>
<path d="M18.2235 14.0813C17.0355 14.3986 16.0559 15.2331 15.5025 16.3761C15.1852 17.0384 15.1758 17.1018 15.1758 18.072C15.1758 19.0423 15.1847 19.0878 15.5114 19.7501C16.7811 22.3257 20.0011 22.9063 22.0148 20.9381C23.7107 19.2784 23.7107 16.8746 22.0331 15.2059C20.9352 14.1174 19.5749 13.7184 18.2235 14.0813ZM20.5001 15.3782C21.0624 15.6504 21.5792 16.1583 21.8515 16.7113C22.0421 17.0924 22.0693 17.2825 22.0693 18.0626C22.0693 18.87 22.0421 19.024 21.8243 19.4323C21.5342 19.9764 20.9718 20.5115 20.4001 20.7654C19.765 21.0555 18.6131 21.0377 17.933 20.7293C15.9831 19.8224 15.6475 17.0741 17.3251 15.7227C18.1146 15.0876 19.5655 14.9337 20.5001 15.3782Z" fill="black"/>
<path d="M31.5751 14.0718C30.2054 14.4436 29.1079 15.4687 28.6362 16.8201C28.4912 17.2374 28.455 17.5097 28.4818 18.2076C28.509 18.9967 28.5451 19.1417 28.8446 19.7585C29.4525 20.9738 30.6588 21.8811 31.9557 22.0806C34.06 22.4073 36.146 20.856 36.5544 18.6705C37.0623 15.9583 34.2417 13.3555 31.5751 14.0718ZM33.8333 15.3782C34.3867 15.641 34.9218 16.1761 35.1847 16.7295C35.4842 17.3646 35.5292 18.4438 35.2753 19.115C35.0124 19.7951 34.4773 20.3941 33.8333 20.7114C33.3888 20.9292 33.2076 20.9653 32.5453 20.9653C31.2845 20.9564 30.4138 20.4574 29.9148 19.4417C29.1075 17.773 29.8153 15.8771 31.4751 15.2786C32.1374 15.0515 33.2348 15.097 33.8333 15.3782Z" fill="black"/>
<path d="M15.4935 33.0738C15.1579 33.4094 15.2578 33.6995 15.9286 34.3524C18.4952 36.8101 22.0692 38.3249 25.5882 38.4431C26.3773 38.4704 26.5589 38.3615 26.5589 37.8808C26.5589 37.4724 26.3322 37.3635 25.3347 37.3002C21.9335 37.0645 19.2035 35.8399 16.5642 33.3728C16.0103 32.856 15.7746 32.7921 15.4935 33.0738Z" fill="black"/>
</svg>
)

export const MeasurementFace6 = () => (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24.5" cy="24.5" r="24.5" fill="#38B23D"/>
<path d="M22.5548 0.0841064C12.8723 0.87981 4.37356 7.59334 1.24948 16.9068C-1.01112 23.6691 -0.176759 31.1391 3.52916 37.2417C6.9158 42.8207 12.2518 46.8173 18.4703 48.4378C20.624 49.0006 22.2154 49.1943 24.6115 49.1943C26.2707 49.1943 26.9786 49.1557 28.085 48.981C38.9608 47.3218 47.314 38.9687 48.9726 28.0929C49.2738 26.1234 49.2738 23.1063 48.9726 21.1464C47.8471 13.7341 43.6366 7.41814 37.2334 3.53751C32.8487 0.869774 27.6874 -0.342609 22.5548 0.0841064ZM28.1041 1.48123C32.043 2.08265 35.6229 3.59625 38.8634 6.02151C40.0567 6.91411 42.3173 9.17471 43.2099 10.368C45.0433 12.8129 46.4214 15.5875 47.1488 18.314C49.0796 25.4638 47.6729 32.8952 43.2782 38.7844C42.2983 40.0942 40.0863 42.3061 38.7766 43.286C32.8879 47.6807 25.4559 49.0874 18.3057 47.1571C16.9959 46.8077 15.7348 46.3228 14.2794 45.6245C11.8733 44.4603 10.069 43.16 8.07045 41.1615C6.08144 39.1825 4.79124 37.3778 3.60748 34.9525C2.53064 32.7401 1.84187 30.5086 1.45381 28.0156C1.21133 26.4242 1.21133 22.786 1.46334 21.1755C1.85141 18.6724 2.5206 16.5283 3.60748 14.2873C4.79124 11.862 6.08144 10.0668 8.07045 8.07829C10.0595 6.08979 11.8542 4.7991 14.2794 3.61533C16.9668 2.30555 19.1496 1.70413 22.6717 1.29649C23.4474 1.20964 27.1342 1.32561 28.1041 1.48123Z" fill="black"/>
<path d="M16.025 15.956C14.8412 16.3828 13.8517 17.2854 13.3181 18.4104C12.7553 19.6133 12.8718 21.2433 13.6188 22.4171C14.2689 23.4457 15.4718 24.2409 16.6942 24.4543C19.5949 24.9492 22.3213 21.9708 21.5643 19.1278C21.2345 17.8858 20.2741 16.7317 19.1099 16.1689C18.5276 15.8878 18.3433 15.8486 17.4798 15.8195C16.7333 15.7914 16.394 15.83 16.025 15.956ZM18.3247 17.1493C19.0913 17.3823 19.7605 17.935 20.129 18.6434C20.3619 19.0897 20.3911 19.2448 20.3911 20.1083C20.3911 20.9426 20.3619 21.1464 20.1581 21.5536C19.634 22.6108 18.7414 23.1736 17.4803 23.2419C15.8407 23.3197 14.667 22.4753 14.2689 20.9135C13.8226 19.1961 14.8412 17.4596 16.5295 17.062C17.141 16.9164 17.6646 16.9455 18.3247 17.1493Z" fill="black"/>
<path d="M30.2873 15.9661C29.1136 16.3737 28.114 17.2663 27.5904 18.3818C27.1733 19.284 27.1055 20.5064 27.4253 21.4572C28.0945 23.4944 30.3747 24.8624 32.3637 24.4357C34.0615 24.0768 35.5169 22.6214 35.8758 20.9236C36.293 18.9637 34.9932 16.7422 32.9847 16.0143C32.208 15.7332 31.0147 15.7136 30.2873 15.9661ZM32.8095 17.2076C33.4887 17.4405 34.2161 18.1971 34.4978 18.9441C34.682 19.4195 34.7111 19.662 34.682 20.3026C34.5851 22.0491 33.4887 23.145 31.7422 23.2424C30.6944 23.2911 30.0057 23.0386 29.3069 22.3498C28.6859 21.7193 28.4434 21.0887 28.4529 20.0601C28.4534 17.7995 30.5494 16.4315 32.8095 17.2076Z" fill="black"/>
<path d="M27.1177 37.0365C30.8628 36.4642 34.1611 34.8538 36.8002 32.2924C37.4599 31.6619 37.5668 31.3416 37.237 31.0409C36.8685 30.7111 36.5773 30.8275 35.6847 31.6519C34.5592 32.6996 33.3272 33.5631 31.9788 34.2619C25.8958 37.3955 18.61 36.3674 13.526 31.6519C12.6239 30.8175 12.2845 30.6915 11.9642 31.0504C11.6439 31.4094 11.7509 31.6614 12.6043 32.4767C14.9618 34.7373 18.1536 36.3282 21.5493 36.9492C22.7617 37.1721 25.9049 37.2208 27.1177 37.0365Z" fill="black"/>
</svg>

)