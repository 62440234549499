import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import { setCitiesList, TRY_TO_GET_CITIES_LIST } from "../store/reducers/CitiesListReducer";

const asyncGetCitiesList = async (info: string) => {
    try {
        const response = await fetch(
            apiUrl + `/static-information/nations/${info}/cities`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                }
            }
        )
        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(error) {}
}

function* CitiesListWorker(action) {
    const data: any[] | null = yield call(asyncGetCitiesList, action.payload)

    if (data && data.length > 0) {
        yield put(setCitiesList(data))
    }
}

export function* CitiesListWatcher() {
    yield takeEvery(TRY_TO_GET_CITIES_LIST, CitiesListWorker)
}