//import { useEffect } from 'react'
import { useState, useEffect } from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { MyAccountBirthdayInputIcon } from "../../icons"
import { Gender } from '../../interfaces/Person'
import { setCurrentPersonBirthday, setCurrentPersonGender } from '../../store/reducers/CurrentPersonReducer'
import './styles.sass'

export const ChooseGenderMeasurementPersonPanel = () => {

    const intl = useIntl()
    const dispatch = useDispatch()

    const currentPerson = useSelector( (state: any) => (state.currentPerson))

    const [choosenGender, setChoosenGender] = useState<string>(currentPerson.member.gender)

    const [birthdateValue, setBirthdateValue] = useState<string>(currentPerson.member.birthday)

    const [isInputBlocked, setIsInputBlocked] = useState<boolean>(false)
  
 
    useEffect( () => {
        setChoosenGender(currentPerson.member.gender)
        setIsInputBlocked(false)
        if (currentPerson.member.gender == null){
            setChoosenGender('')
           
        }
        if (currentPerson.member.birthday == '') { 
      
            setBirthdateValue('')
            
        } else
        if (!currentPerson.member.canDeleted) {
          
            setIsInputBlocked(true)
            setChoosenGender(currentPerson.member.gender)
            if (currentPerson.member.birthday != null  ){
                setBirthdateValue(currentPerson.member.birthday.slice(8) + '.' + currentPerson.member.birthday.slice(5,7) + '.' + currentPerson.member.birthday.slice(0,4))
            } else {
                setBirthdateValue('')
            }
            
        } 
        
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPerson])
   
    const birthdateHandle = (value) => {
        
        if (!isInputBlocked) {
            // if (value.length < birthdateValue.length ) {
            //     if (birthdateValue.length === 1 || birthdateValue == '') {
                   
            //         setBirthdateValue('')
            //     } else 
            //     if (birthdateValue[birthdateValue.length-1] === '.') {
            //         setBirthdateValue( birthdateValue.slice(0, birthdateValue.length-2) )
            //     } else {
            //         setBirthdateValue( birthdateValue.slice(0, birthdateValue.length-1) )
            //     }
            // } else if ( (value.length <= 10) && value[value.length-1].match(/[0-9]/) ) {
            //     if ( (value.length === 1) && (value[0] <= '3') ) {
            //         setBirthdateValue(value)
            //     } else if ( (value.length === 2) && (value[0] + value[1] <= '31') )  {
            //         setBirthdateValue(value + '.')
            //     } else if ( (value.length === 4) && (value[value.length-1] <= '1') ) {
            //         setBirthdateValue(value)
            //     } else if (value.length === 5) {
            //         if ( ( (value[3] === '1') && ( value[4] <= '2' ) ) || (value[3] === '0') ) {
            //             setBirthdateValue(value + '.')
            //         } 
            //     } else if (value.length > 6) {
            //         setBirthdateValue(value)
            //     } 
            //     if (value.length === 10) {
                    
            //         dispatch(setCurrentPersonBirthday(value))
            //         setBirthdateValue(value)
            //     }
            // }
          
           
            setBirthdateValue(value)
            dispatch(setCurrentPersonBirthday(value))
        }
    }

    return (
        <div className='choose-gender-measurement-person-wrapper'>

            <div>

                <div
                    className='choose-gender-measurement-person-wrapper__gender-panel'
                    onClick={() => 
                        {   
                            if (!isInputBlocked) {
                                setChoosenGender(Gender.MALE)
                                dispatch(setCurrentPersonGender(Gender.MALE))
                            }
                        }
                    }
                >
                    {
                        choosenGender === Gender.MALE
                        ?
                        <div className='choose-gender-measurement-person-wrapper__gender-panel__choosen-checkbox'/>
                        :
                        <div className='choose-gender-measurement-person-wrapper__gender-panel__non-choosen-checkbox'/>
                    }
                    <span>
                        <FormattedMessage id='measurement_person_list_page_gender_male'/>
                    </span>
                </div>

                <div
                    className='choose-gender-measurement-person-wrapper__gender-panel'
                    onClick={
                        () => {
                            if (!isInputBlocked) {
                                setChoosenGender(Gender.FEMALE)
                                dispatch(setCurrentPersonGender(Gender.FEMALE))
                            }
                        }
                    } 
                >
                    {
                        choosenGender === Gender.FEMALE
                        ?
                        <div className='choose-gender-measurement-person-wrapper__gender-panel__choosen-checkbox'/>
                        :
                        <div className='choose-gender-measurement-person-wrapper__gender-panel__non-choosen-checkbox'/>
                    }
                    <span>
                        <FormattedMessage id='measurement_person_list_page_gender_female'/>
                    </span>
                </div>

            </div>

            <div className='choose-gender-measurement-person-wrapper__birth-date-panel' style={{position: 'relative'}}>
                <input id="handleDate"
                    type='date'
                    value={birthdateValue.split('.').reverse().join('-')}
                    onChange={(event) => birthdateHandle(event.target.value)}
                    placeholder={ intl.formatMessage({ id: 'measurement_person_list_page_birthdate_placeholder' })}
                />
                <span style={{position: 'absolute', right: '17px', pointerEvents: 'none', cursor: 'pointer', background: 'white'}}>
                    <MyAccountBirthdayInputIcon/>
                </span>
              
            </div>

        </div>
    )
}