import { useSelector, useDispatch } from 'react-redux'
import './styles.sass'

import { StepBar } from "../../components/StepBar"
import { BottomControlPanel } from '../../components/BottomControlPanel/index'
import { routes } from '../../routes'

import { nextStepBar } from "../../store/reducers/StepBarReducer"
import { changeHeaderTitle } from "../../store/reducers/HeaderTitleReducer"

import { FormattedMessage } from "react-intl"
import { useEffect, useState } from "react"
import { ChoosenCheckMarkIcon, EmptyCheckMarkIcon } from "../../icons"
import { asyncUpdateMeasurement } from '../../store/reducers/MeasurementReducer'
import store from '../../store'
import { setCurrentRatingMember } from '../../store/reducers/CurrentRatingPersonReducer'
import { getMeasurementImages } from '../../store/reducers/MeasurementImagesReducer'

export const MeToPersonInstructionContainer = () => {

    //const instructionText = useSelector( (state: any) => (state.measurementType.meToPersonInstruction))

    const dispatch = useDispatch()

    const isNavbarShowed = useSelector( (state: any) => (state.navbar.isNavbarShowed))

    const [isUserAgree, setIsUserAgree] = useState<boolean>(false)

    useEffect( () => {
        dispatch(
            changeHeaderTitle({
                currentHeaderTitle:'header_title_main', 
                currentHeaderAdditionalTitle: 'header_title_subtitle_measurement_type'
            })
        )
        dispatch(nextStepBar(3))
        history.pushState(null, document.title);
        window.addEventListener('popstate', function () {
            history.pushState(null, document.title);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigateBack = () => {

    }

    const navigateNext = () => {
        dispatch(getMeasurementImages())
        dispatch(asyncUpdateMeasurement('RATE_ME_2_PARTNER'))
        const firstMember = store.getState().personMeasurementList[1]
        dispatch(setCurrentRatingMember(firstMember))
        return ''
    }

    return (
        <>
            <div className={`instruction-measurement-container ${ isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}>

                <div className="instruction-measurement-container__title-wrapper">
                    <div className="instruction-measurement-container__title-wrapper__sub-wrapper">
                        <span className="instruction-measurement-container__title-wrapper__title">
                            <FormattedMessage id='measurement_instruction_me_to_person'/>
                        </span>

                        <span className="instruction-measurement-container__title-wrapper__additional-title">
                            <FormattedMessage id='measurement_instruction_page_additional_title'/>
                        </span>
                    </div>

                    
                    {
                        document.body.clientWidth > 640
                        &&
                        <StepBar steps={8}/>
                    }
                </div>

                <div className='large-instruction-panel'>

                  

                    <div dangerouslySetInnerHTML={{__html: JSON.parse(localStorage.measurementTypesN).meToPersonInstruction || ''}} />

                    <div 
                        className="instruction-measurement-container__agreement-panel"
                        onClick={() => {
                            if (isUserAgree) {
                                setIsUserAgree(false)
                            } else {
                                setIsUserAgree(true)
                            }
                        }}
                    >
                        {
                            isUserAgree
                            ?
                            <><ChoosenCheckMarkIcon/></>
                            :
                            <><EmptyCheckMarkIcon/></>
                        }
                        <span>
                            <FormattedMessage id='measurement_instruction_page_agreement_panel'/>
                        </span>
                    </div>

                </div>
                
            </div>

            <BottomControlPanel 
                nextPage={routes.meToPersonMeasurementRating} 
                previousPage={routes.main}
                backFunc={navigateBack}
                nextFunc={navigateNext}
                isNextButtonDisabled={!isUserAgree}
            />
        </>
    )
}