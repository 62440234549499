export const TRY_TO_PAY_CONSULTATION = 'TRY_TO_PAY_CONSULTATION'


const initialState = false

export const ConsultationPayReducer = (state = initialState, action) => {

    switch (action.type) {

        default:
            return state
    }
}

export const tryToPayConsultation = (payload: string) => ({type: TRY_TO_PAY_CONSULTATION, payload})