export const SET_MEASUREMENT_SEARCH_LIST = 'SET_MEASUREMENT_SEARCH_LIST'
export const INC_PAGE_MEASUREMENT_LIST = 'INC_PAGE_MEASUREMENT_LIST'

const initialState = {
    before: '',
    after: '',
    typeId: localStorage.typeId,
    page: 0
}

export const MeasurementListSearchReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case INC_PAGE_MEASUREMENT_LIST:
            return {
                ...state, 
                page: state.page+1
            }

        case SET_MEASUREMENT_SEARCH_LIST:
            return action.payload

        default:
            return state
    }
}

export const setMeasurementSearchList = (payload: any) => ({type: SET_MEASUREMENT_SEARCH_LIST, payload})
export const incPageMeasurementList = () => ({type: INC_PAGE_MEASUREMENT_LIST})