import { useDispatch, useSelector } from 'react-redux'
import { MeasurementType } from '../../interfaces/MeasurementType'
import { chooseMeasurementType } from '../../store/reducers/MeasurementTypeReducer';

import './styles.sass'
import {PeopleGroup} from '../../images/measurementTypeCardPeopleImage'
import { InfoCircleIcon } from '../../icons';
import { Link } from 'react-router-dom';
import { routes } from '../../routes';

export const MeasurementTypeCard = (prop: {data: MeasurementType}) => {

    const typeData = prop.data

    const dispatch = useDispatch();
    const currentChoosenType = useSelector( (state: any) => (state.measurementType.id))

    return (
        <div 
            className={`measurement-type-card-wrapper 
                ${typeData.id === currentChoosenType && `active-measurement-type-card-wrapper`}
            `}
            id={'wrapper-'+prop.data.id}
            onClick={() => dispatch(chooseMeasurementType(typeData))}
        >
            <span 
                className='measurement-type-card-wrapper__title'
                id={'title-'+prop.data.id}
            >
                {typeData.name}
            </span>
            <span 
                className='measurement-type-card-wrapper__desc'
                id={'desc-'+prop.data.id}
            >
                <div dangerouslySetInnerHTML={{__html: typeData.description || ''}} 
                    id={'text-'+prop.data.id}
                />
                
            </span>

            <div 
                className='measurement-type-card-wrapper__icon'
            >
                <Link target="_blank" to={routes.articles+'?key='+prop.data.referenceInformation}>
                    <InfoCircleIcon />
                </Link>
            </div>

            <div 
                className='measurement-type-card-wrapper__image-wrapper'
                id={'img-'+prop.data.id}
            >
                <PeopleGroup />
            </div>
        </div>
    )
}