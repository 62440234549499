import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  MyAccountBirthdayInputIcon,
  ToggleListSmallArrowIcon,
} from "../../icons";
import { User } from "../../interfaces/User";
import { MeasurementType } from "../../interfaces/MeasurementType";
import { routes } from "../../routes";
import { setMeasurementSearchList } from "../../store/reducers/MeasurementListSearchReducer";

import { getMeasurementsList } from "../../store/reducers/MeasurementsListReducer";
import { tryToGetMeasurementsTypeList } from "../../store/reducers/MeasurementTypeListReducer";
import "./styles.sass";

import { CloseCrossIcon } from "../../icons";
export const MeasurementsListSearch = () => {
  const accountData: User = useSelector((state: any) => state.user);
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [choosenType, setChoosenType] = useState({
    name: "",
    id: "",
  });
  const [isToggleListTypeOpened, setIsToggleListTypeOpened] =
    useState<boolean>(false);

  const [dateForm, setDateForm] = useState({
    before: "",
    after: "",
  });

  const choosenMeasurements = useSelector(
    (state: any) => state.measurementConsultationList
  );
  const measurementTypeList: MeasurementType[] = useSelector(
    (state: any) => state.measurementsTypeList
  );

  const [modalActivated, setModalActivated] = useState(false);
  useEffect(() => {
    localStorage.setItem("typeId", choosenType.id ? choosenType.id : "");
    localStorage.setItem("after", dateForm.before ? dateForm.before : "");
    localStorage.setItem("before", dateForm.after ? dateForm.after : "");
    dispatch(
      setMeasurementSearchList({
        before: dateForm.before.length === 10 ? dateForm.before : "",
        after: dateForm.after.length === 10 ? dateForm.after : "",
        typeId: choosenType.id,
      })
    );
    dispatch(getMeasurementsList(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenType, dateForm]);
  useEffect(() => {
    dispatch(tryToGetMeasurementsTypeList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //hiding the dropdown if clicked outside
    const pageClickEvent = (event) => {
      let menu = window.document.getElementById("clickbox");
      if (menu != null) {
        if (menu.contains(event.target)) {
        } else {
          setIsToggleListTypeOpened(false);
        }
      }
    };

    //if the dropdown is active then listens for click
    if (isToggleListTypeOpened) {
      document.addEventListener("click", pageClickEvent);
    }

    //unsetting the listener
    return () => {
      document.removeEventListener("click", pageClickEvent);
    };
  }, [isToggleListTypeOpened]);

  const dateHandle = (name, value) => {
    setDateForm({ ...dateForm, [name]: value });
  };
  function checkActivated() {
    if (choosenMeasurements.length > 0) {
      if (accountData.personaAreaActivated) {
        navigate(routes.startConsultation);
      } else {
        setModalActivated(true);
      }
    }
  }
  return (
    <>
      {document.body.clientWidth > 640 ? (
        <div className={`measurement-list-search-wrapper `}>
          {modalActivated && (
            <div className="popup_black">
              <div className="show__to__buye">
                <div
                  onClick={() => {
                    setModalActivated(false);
                  }}
                >
                  {" "}
                  <CloseCrossIcon />
                </div>

                <p>Консультации доступны только при приобритении подписки</p>
                <div
                  className="green-button"
                  onClick={() => {
                    navigate("/tarifs");
                  }}
                >
                  Купить подписку
                </div>
              </div>
            </div>
          )}

          <div
            className="white-button"
            onClick={() => navigate(routes.startMeasurement)}
          >
            <FormattedMessage id="measurements_list_search_create" />
          </div>

          <div className="measurement-list-search-wrapper__type-input">
            <div
              className="measurement-list-search-wrapper__type-input__input"
              onClick={() => {
                if (isToggleListTypeOpened) {
                  setIsToggleListTypeOpened(false);
                } else {
                  setIsToggleListTypeOpened(true);
                }
              }}
            >
              <span
                style={{
                  color:
                    choosenType.name.length > 0
                      ? "rgba(0, 0, 0,1)"
                      : "rgba(0, 0, 0, 0.43)",
                }}
              >
                {choosenType.name.length > 0 ? (
                  choosenType.name
                ) : (
                  <FormattedMessage id="measurements_list_search_type" />
                )}
              </span>
              <div className={`${isToggleListTypeOpened && "rotated"}`}>
                <ToggleListSmallArrowIcon />
              </div>
            </div>

            <div
              id="clickbox"
              className={`measurement-list-search-wrapper__type-input__toggle-list ${
                isToggleListTypeOpened && "opened-toggle-list"
              }`}
            >
              <div
                onClick={() => {
                  setIsToggleListTypeOpened(false);
                  setChoosenType({ name: "", id: "" });
                }}
              >
                Все
              </div>
              {measurementTypeList &&
                measurementTypeList.length > 0 &&
                measurementTypeList.map((elem, index) => (
                  <div
                    key={"measurement-search-type" + index}
                    onClick={() => {
                      setIsToggleListTypeOpened(false);
                      setChoosenType({ name: elem.name, id: elem.id });
                    }}
                  >
                    {elem.name}
                  </div>
                ))}
            </div>
          </div>

          <div className="measurement-list-search-wrapper__dates-wrapper">
            <span>
              <FormattedMessage id="measurements_list_search_date_before" />
            </span>
            <div>
              <input
                type="date"
                name="before"
                value={dateForm.before}
                onChange={(event) =>
                  dateHandle(event.target.name, event.target.value)
                }
                placeholder={intl.formatMessage({
                  id: "measurements_list_search_date",
                })}
                min="2018-01-01"
                max="2040-12-31"
              />
              <MyAccountBirthdayInputIcon />
            </div>
            <span>
              <FormattedMessage id="measurements_list_search_date_after" />
            </span>
            <div>
              <input
                type="date"
                name="after"
                value={dateForm.after}
                onChange={(event) =>
                  dateHandle(event.target.name, event.target.value)
                }
                placeholder={intl.formatMessage({
                  id: "measurements_list_search_date",
                })}
                min="2018-01-01"
                max="2040-12-31"
              />
              <MyAccountBirthdayInputIcon />
            </div>
          </div>

          <div className="measurement-list-search-wrapper__buttons">
            {/*    <div
              onClick={() => {
                getMeasurementsByParam();
                localStorage.setItem(
                  "typeId",
                  choosenType.id ? choosenType.id : ""
                );
                localStorage.setItem(
                  "after",
                  dateForm.before ? dateForm.before : ""
                );
                localStorage.setItem(
                  "before",
                  dateForm.after ? dateForm.after : ""
                );
                dispatch(
                  setMeasurementSearchList({
                    before:
                      dateForm.before.length === 10 ? dateForm.before : "",
                    after: dateForm.after.length === 10 ? dateForm.after : "",
                    typeId: choosenType.id,
                  })
                );
                dispatch(getMeasurementsList(0));
              }}
            >
              <FormattedMessage id="measurements_list_search_find" />
            </div> */}
            <div
              onClick={() => {
                setDateForm({
                  before: "",
                  after: "",
                });
                setChoosenType({
                  name: "",
                  id: "",
                });
                dispatch(
                  setMeasurementSearchList({
                    before: "",
                    after: "",
                    typeId: "",
                  })
                );
                localStorage.setItem("typeId", "");
                localStorage.setItem("before", "");
                localStorage.setItem("after", "");
                dispatch(getMeasurementsList(0));
              }}
            >
              <FormattedMessage id="measurements_list_search_drop" />
            </div>
          </div>

          <div
            onClick={() => checkActivated()}
            className={`
                            green-button
                            ${
                              choosenMeasurements.length === 0 &&
                              "disabled-button"
                            }
                        `}
            style={{ maxWidth: "fit-content" }}
          >
            <FormattedMessage id="measurements_list_search_consultation" />
          </div>
        </div>
      ) : (
        <>
          <div className={`measurement-list-search-wrapper column`}>
            <div className="measurement-list-search-wrapper__type-input">
              <div className="measurement-list-search-wrapper__type-input__input">
                <span
                  style={{
                    color:
                      choosenType.name.length > 0
                        ? "rgba(0, 0, 0,1)"
                        : "rgba(0, 0, 0, 0.43)",
                  }}
                >
                  {choosenType.name.length > 0 ? (
                    choosenType.name
                  ) : (
                    <FormattedMessage id="measurements_list_search_type" />
                  )}
                </span>
                <div
                  onClick={() => {
                    if (isToggleListTypeOpened) {
                      setIsToggleListTypeOpened(false);
                    } else {
                      setIsToggleListTypeOpened(true);
                    }
                  }}
                  className={`${isToggleListTypeOpened && "rotated"}`}
                >
                  <ToggleListSmallArrowIcon />
                </div>
              </div>

              <div
                className={`measurement-list-search-wrapper__type-input__toggle-list ${
                  isToggleListTypeOpened && "opened-toggle-list"
                }`}
              >
                {measurementTypeList &&
                  measurementTypeList.length > 0 &&
                  measurementTypeList.map((elem, index) => (
                    <div
                      key={"measurement-search-type" + index}
                      onClick={() => {
                        setIsToggleListTypeOpened(false);
                        setChoosenType({ name: elem.name, id: elem.id });
                        dispatch(
                          setMeasurementSearchList({
                            before:
                              dateForm.before.length === 10
                                ? dateForm.before
                                : "",
                            after:
                              dateForm.after.length === 10
                                ? dateForm.after
                                : "",
                            typeId: choosenType.id,
                          })
                        );
                        dispatch(getMeasurementsList(0));
                      }}
                    >
                      {elem.name}
                    </div>
                  ))}
              </div>
            </div>

            <div className="measurement-list-search-wrapper__dates-wrapper">
              <article>
                <span>
                  <FormattedMessage id="measurements_list_search_date_before" />
                </span>
                <div>
                  <input
                    type="date"
                    name="before"
                    value={dateForm.before}
                    onChange={(event) =>
                      dateHandle(event.target.name, event.target.value)
                    }
                    placeholder={intl.formatMessage({
                      id: "measurements_list_search_date",
                    })}
                    min="2018-01-01"
                    max="2040-12-31"
                  />
                  <MyAccountBirthdayInputIcon />
                </div>
              </article>
              <article>
                <span>
                  <FormattedMessage id="measurements_list_search_date_after" />
                </span>
                <div>
                  <input
                    type="date"
                    name="after"
                    value={dateForm.after}
                    onChange={(event) =>
                      dateHandle(event.target.name, event.target.value)
                    }
                    placeholder={intl.formatMessage({
                      id: "measurements_list_search_date",
                    })}
                    min="2018-01-01"
                    max="2040-12-31"
                  />
                  <MyAccountBirthdayInputIcon />
                </div>
              </article>
            </div>

            <div className="measurement-list-search-wrapper__buttons">
              <div
                onClick={() => {
                  setDateForm({
                    before: "",
                    after: "",
                  });
                  setChoosenType({
                    name: "",
                    id: "",
                  });
                  dispatch(
                    setMeasurementSearchList({
                      before: "",
                      after: "",
                      typeId: "",
                    })
                  );
                  dispatch(getMeasurementsList(0));
                }}
              >
                <FormattedMessage id="measurements_list_search_drop" />
              </div>
            </div>

            <div className="measurement-list-search-wrapper__mobile-buttons">
              <div
                className="white-button"
                onClick={() => navigate(routes.startMeasurement)}
              >
                <FormattedMessage id="measurements_list_search_create" />
              </div>
              <div
                onClick={() => checkActivated()}
                className={`
                                    green-button
                                    ${
                                      choosenMeasurements.length === 0 &&
                                      "disabled-button"
                                    }
                                `}
                style={{ maxWidth: "fit-content" }}
              >
                <FormattedMessage id="measurements_list_search_consultation" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
