export enum Type {
    USER_OFFICE,
    USER_CONSULTANT
}

export enum Readed {
    UPDATED_BY_SENDER,
    UPDATED_BY_ADRESSEE,
    SENDER_NOTIFIED,
    READED_BY_SENDER,
    READED_BOTH,
    
}

export enum PayType {
    SAFE_DEAL,
    DIRECT_DEAL
}

export enum Status {
    WAIT_INVOICE='WAIT_INVOICE',
    WAIT_USER_CONFIRM='WAIT_USER_CONFIRM',
    WAIT_CONSULTANT_CONFIRM='WAIT_CONSULTANT_CONFIRM',
    DISCUSSION='DISCUSSION',
    CONFIRMED='CONFIRMED',
    REJECTED_CONS='REJECTED_CONS',
    REJECTED_USER='REJECTED_USER',
    PAID='PAID',
    WAITING_REVIEW='WAITING_REVIEW',
    IN_ARBITRATION='IN_ARBITRATION',
    NOT_CLOSED='NOT_CLOSED',
    CLOSED='CLOSED',
    APPROVED='APPROVED',
    HAS_CONCLUSION='HAS_CONCLUSION',
    EXPIRATION='EXPIRATION'
}

interface Measurement {
    id: string;
    date: string;
}

export interface Consultation {
    id: string;
    adresseeId: string;
    senderId: string;
    adresseeName: string;
    senderName: string;
    consultantId?: string;
    measurements: Measurement[];
    type: Type;
    readed: Readed;
    startDate: string;
    payType: PayType;
    cost: string;
    status: Status;
    lastMessage?: string;
    senderPhoto?: string;
    adressePhoto?: string;
}