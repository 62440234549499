import {useState} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
//import { useNavigate } from "react-router-dom";
import { SignupConfirmationCreator } from '../../store/reducers/SignupReducer'
import './styles.sass'

interface KeyInterface {
    key: string;
    changed: boolean;
}

export const SignUpConfirmation = () => {
   // const navigate = useNavigate();
    const intl = useIntl()
    const dispatch = useDispatch()
    
    const [key, setKey] = useState<KeyInterface>({
        key: '',
        changed: false
    })

    const isCodeError = useSelector( (state: any) => (state.signup.codeError) )
    const [isValid, setIsValid] = useState<boolean>(false)
    const [validError, setValidError] = useState<string>('')

    const validate = () => {
        let isValidTemp = true
        let errorTemp = ''

        if (key.changed && key.key) {
            errorTemp = 'signup_text_error_must_field'
            isValidTemp = false
        }
        
        setIsValid(isValidTemp)
        setValidError(errorTemp)
    }
    const handleSubmit = (event) => {
        event.preventDefault()

        if (isValid) {
            dispatch(SignupConfirmationCreator(key.key));
            //navigate("/my-account", { replace: true });
        } else {
            validate()
        }
    }
    const handleFocus = (event) => {
        setKey({
            key: event.target.value,
            changed: true   
        })
    }

    const handleChange = (event) => {
        setKey({
            key: event.target.value,
            changed: true   
        })

        if(!isValid) {
            validate()
        }
    }

    return (
        <form className='signup-confirmation-wrapper' onSubmit={handleSubmit}>

            <span className='sign-up-title'>
                <FormattedMessage id='signup_confirmation_title'/>
            </span>

            <span className='sign-up-sub-title'>
                <FormattedMessage id='signup_confirmation_sub_title'/>
            </span>

            {
                isCodeError
                &&
                <div className='login-error-panel'>
                    <span className='login-error-panel__title'>
                        <FormattedMessage id='signup_confirmation_error_title'/>
                    </span>

                    <span className='login-error-panel__sub-title'>
                        <FormattedMessage id='signup_confirmation_error_text'/>
                    </span>
                </div>
            }

            <div className={`text-input`}>
                <input
                    type='text'
                    placeholder={ intl.formatMessage({ id: 'signup_confirmation_code_placeholder' }) }
                    value={key.key}
                    name={"key"}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    className={`${ !isValid && validError.length !== 0 && 'error-input'}`}
                />
                {
                    !isValid && (validError.length !== 0)
                    &&
                    <span className={"error-text"}><FormattedMessage id={validError}/></span>
                }
            </div>

            <button
                className={`green-button ${ !isValid ? 'disabled-button' : '' }`}
      
            >
                <FormattedMessage id='signup_confirmation_send_button'/>
            </button>
            <div className="green-button">Отправить Email повторно</div>
        </form>
    )
}