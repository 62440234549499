import { useDispatch } from "react-redux";
import { SmallGreenIcon } from "../../icons";

import { ArticleNavigationTypes } from "../../store/types/ArticleNavigation";
import "./styles.sass";
import { useEffect, useState } from "react";
import { apiUrl } from "../../consts";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const Article = () => {
  const dispatch = useDispatch();
  const changeCurrentSection = (index: number) => ({
    type: ArticleNavigationTypes.OPEN_SECTION,
    payload: index,
  });
  let navigate = useNavigate();

  let url = new URL(document.location.href);
  let key = url.searchParams.get("key");
  function setNewArticle(way) {
    let onlyArticeArr: string[] = [];
    let index = key;
    let articleArr = JSON.parse(localStorage.articles);
    for (let j = 0; j < articleArr.length; j++) {
      for (let i = 0; i < articleArr[j].children.length; i++) {
        onlyArticeArr.push(articleArr[j].children[i].id);
      }
    }
    for (let i = 0; i < onlyArticeArr.length; i++) {
      if (onlyArticeArr[i] === index) {
        if (i != 0 && way == "back") {
          navigate(`/articles?key=${onlyArticeArr[i - 1]}`);
          index = onlyArticeArr[i - 1];
        } else if (i != onlyArticeArr.length - 1 && way == "forward") {
          navigate(`/articles?key=${onlyArticeArr[i + 1]}`);
          index = onlyArticeArr[i + 1];
          break;
        }
      }
    }
    for (let i = 0; i < articleArr.length; i++) {
      let lengthCh = articleArr[i].children?.length;
      if (lengthCh != undefined) {
        articleArr[i].children?.map((article) => {
          if (article.id == index) {
            dispatch(changeCurrentSection(i));
          }
        });
      }
    }
  }

  const [text, setText] = useState({
    header: "",
    text: "",
  });

  useEffect(() => {
    if (key == null) {
      axios
        .get(apiUrl + "/content/article/68d9f2db-c6b3-43da-ae4e-45376366d904")
        .then((response) => {
          setText({
            header: response.data.header,
            text: response.data.text,
          });
        });
      window.history.replaceState(
        null,
        "",
        `?key=68d9f2db-c6b3-43da-ae4e-45376366d904`
      );
    } else {
      axios.get(apiUrl + "/content/article/" + key).then((response) => {
        setText({
          header: response.data.header,
          text: response.data.text,
        });
      });
    }
  }, [key]);

  return (
    <div className="article-section-wrapper">
      <span
        className="article-section-wrapper__title"
        dangerouslySetInnerHTML={{ __html: text.header }}
      ></span>

      <div
        className="article-section-wrapper__main-text"
        dangerouslySetInnerHTML={{ __html: text.text }}
      ></div>

      <div className="article-section-wrapper__control-panel">
        <div
          className="white-button"
          onClick={() => {
            setNewArticle("back");
          }}
        >
          <SmallGreenIcon />
          <span style={{ marginLeft: "4px" }}>Пред. статья</span>
        </div>

        <div
          className="white-button right"
          onClick={() => {
            setNewArticle("forward");
          }}
        >
          <span>След. статья</span>
          <SmallGreenIcon />
        </div>
      </div>
    </div>
  );
};
