import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import "./styles.sass";
import { getPersonStatuses } from "../../store/reducers/PersonStatusesReducer";
import { BlackBottomTriangle, OpeningSimpleArrowIcon } from "../../icons";
import { setCurrentPersonRole } from "../../store/reducers/CurrentPersonReducer";

export const ChoosePersonStatusPanel = (prop: { role?: string }) => {
  const dispatch = useDispatch();

  const currentPerson = useSelector((state: any) => state.currentPerson);

  const editModal = useSelector((state: any) => state.modals);
  const isEditModalPerson: boolean = editModal.modal === "EDIT_PERSON";

  const [choosenStatusCoords, setChoosenStatusCoords] = useState<number>(0);

  const personStatusesArray = useSelector((state: any) => state.personStatuses);

  const [wasChoosen, setWasChoosen] = useState<boolean>(false);

  const [choosenStatus, setChoosenStatus] = useState<string>(
    isEditModalPerson
      ? editModal.modalInfo.member.role
      : personStatusesArray.length > 0
      ? personStatusesArray[0].id
      : ""
  );
  const [nameRole, setNameRole] = useState("");
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const [isInputBlocked, setIsInputBlocked] = useState<boolean>(false);

  const getChoosenElementCoords = (index) => {
    const myElement: HTMLElement | null =
      window?.document?.getElementById(index);
    const parent: HTMLElement | null = window?.document?.getElementById(
      "choose-person-statuses-wrapper"
    );

    if (myElement !== null && parent !== null) {
      setChoosenStatusCoords(
        myElement.getBoundingClientRect().left -
          parent.getBoundingClientRect().left +
          myElement.getBoundingClientRect().width / 2 -
          8
      );
    }
  };
  console.log(nameRole);
  const toggleOpenedModal = () => {
    if (isModalOpened) {
      setIsModalOpened(false);
    } else {
      setIsModalOpened(true);
    }
  };

  useEffect(() => {
    if (personStatusesArray && personStatusesArray.length === 0) {
      dispatch(getPersonStatuses());
    }

    if (isEditModalPerson && !wasChoosen) {
      personStatusesArray.forEach((elem, index) => {
        if (editModal.modalInfo.member.role === elem.name) {
          setIsInputBlocked(false);
          setChoosenStatus(editModal.modalInfo.member.role);
          getChoosenElementCoords(index);
        }
      });
    }
    if (currentPerson.member.role.length === 0) {
      setChoosenStatus("");
      setIsInputBlocked(false);
      getChoosenElementCoords(0);
    }
    if (!currentPerson.member.canDeleted) {
      personStatusesArray.forEach((elem, index) => {
        if (currentPerson.member.role === elem.name) {
          setIsInputBlocked(true);
          setChoosenStatus(currentPerson.member.role);
          getChoosenElementCoords(index);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModal, currentPerson]);
  useEffect(() => {
    if (prop.role) {
      setNameRole(prop.role);
      dispatch(setCurrentPersonRole(prop.role));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop]);
  console.log(choosenStatus);
  return (
    <>
      {document.body.clientWidth > 640 ? (
        <div
          className="choose-person-statuses-wrapper"
          id="choose-person-statuses-wrapper"
        >
          <div className="choose-person-statuses-wrapper__top-side">
            {personStatusesArray.map((elem, index) => {
              return (
                <div
                  key={index}
                  id={index}
                  className={
                    choosenStatus === elem.name ? "choosen-status-panel" : ""
                  }
                  onClick={() => {
                    if (!isInputBlocked) {
                      setChoosenStatus(elem.name);
                      setWasChoosen(true);
                      getChoosenElementCoords(index);
                      dispatch(setCurrentPersonRole(elem.name));
                    }
                  }}
                >
                  {elem.name}
                </div>
              );
            })}
          </div>

          <div className="choose-person-statuses-wrapper__bottom-side">
            {personStatusesArray.map((elem, index) => {
              if (elem.name === choosenStatus) {
                return (
                  <div
                    key={index}
                    className="choose-person-statuses-wrapper__bottom-side__description-wrapper"
                    style={{
                      marginLeft:
                        index === 0 && isEditModalPerson && !wasChoosen
                          ? "40px"
                          : "",
                    }}
                  >
                    <div style={{ left: choosenStatusCoords + "px" }}>
                      <BlackBottomTriangle />
                    </div>

                    <span
                      style={{
                        left: choosenStatusCoords - 140 + "px",
                        border: isEditModalPerson ? "1px solid #E6E6E8" : "",
                      }}
                    >
                      {elem.description}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className="adaptive-choose-person-wrapper">
          <div
            className="adaptive-choose-person-wrapper__input-wrapper"
            style={{
              borderRadius: isModalOpened ? "20px 20px 0 0" : "20px",
              border: isModalOpened ? "1px solid rgba(0, 0, 0, 0.3)" : "none",
            }}
            onClick={() => toggleOpenedModal()}
          >
            <div>
              {choosenStatus || (
                <FormattedMessage id="measurement_person_list_page_choose_role_placeholder" />
              )}
            </div>
            <span className={isModalOpened ? "rotated" : ""}>
              <OpeningSimpleArrowIcon />
            </span>
          </div>

          <div
            className={`adaptive-choose-person-wrapper__modal-wrapper
                        ${isModalOpened && "choose-role-modal-opened"}
                    `}
          >
            <div>
              {personStatusesArray.map((elem, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setChoosenStatus(elem.name);
                    setIsModalOpened(false);
                    dispatch(setCurrentPersonRole(elem.name));
                    setNameRole(elem.name);
                  }}
                >
                  <span className="title">{elem.name}</span>
                  <span className="desc">{elem.description}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

//style={{left: ((document.getElementsByClassName('choosen-status-panel')[0].getBoundingClientRect().left + document.getElementsByClassName('choosen-status-panel')[0].getBoundingClientRect().width / 2) - document.getElementsByClassName('choose-person-statuses-wrapper')[0].getBoundingClientRect().left) + 'px'}}
