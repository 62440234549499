import { User } from "../../interfaces/User";

export const AUTHORIZE = 'AUTHORIZE'
export const CHECK_IS_AUTHORIZED = 'CHECK_IS_AUTHORIZED'
export const NON_AUTHORIZE = 'NON_AUTHORIZE'

const initialState: User | null = null

export const userReducer = (state = initialState, action: {type: string; payload: User}) => {

    switch (action.type) {

        case AUTHORIZE:
            return action.payload

        case NON_AUTHORIZE:
            return null

        default:
            return state
    }
}

export const setUser = (payload: any) => ({type: AUTHORIZE, payload})
export const checkIsAuthorized = () => ({type: CHECK_IS_AUTHORIZED})
export const setNonAuthorized = () => ({type: NON_AUTHORIZE})