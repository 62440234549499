import {FormattedMessage } from 'react-intl'

import './styles.sass'

export const SmallInsctructionPageWhatToDo = () => {

    return (
        <div className='small-instruction-page-what-to-do-wrapper'>
            <span className='small-instruction-page-what-to-do-wrapper__title'>
                <FormattedMessage id='measurement_instruction_page_what_todo_title'/>
            </span>

            

            <div className='small-instruction-page-what-to-do-wrapper__text-wrapper'>

                <div className='vertical-line'/>

                <div className='small-instruction-page-what-to-do-wrapper__text-wrapper__panel'>
                    <div>1</div>
                    <span>
                        <FormattedMessage id='measurement_instruction_page_what_todo_first_text'/>
                    </span>
                </div>
                <div className='small-instruction-page-what-to-do-wrapper__text-wrapper__panel'>
                    <div>2</div>
                    <span>
                        <FormattedMessage id='measurement_instruction_page_what_todo_second_text'/>
                    </span>
                </div>
                <div className='small-instruction-page-what-to-do-wrapper__text-wrapper__panel'>
                    <div>3</div>
                    <span>
                        <FormattedMessage id='measurement_instruction_page_what_todo_third_text'/>
                    </span>
                </div>
                <div className='small-instruction-page-what-to-do-wrapper__text-wrapper__panel'>
                    <div>4</div>
                    <span>
                        <FormattedMessage id='measurement_instruction_page_what_todo_fourth_text'/>
                    </span>
                </div>
            </div>
        </div>
    )
}