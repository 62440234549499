import { useSelector } from 'react-redux'

import {ArticlesNavigation} from '../../components/ArticlesNavigation/index'
import {Article} from '../../components/Article/index'

import './styles.sass'

export const ArticlesContainer = () => {

    const isAuthorized = !!useSelector( (state: any) => (state.login.token))
    const isArticleSidebarOpened = useSelector( (state: any) => (state.articleSideNavigation.isArticleSidebarOpened))
    const isNavbarShowed = useSelector( (state: any) => (state.navbar.isNavbarShowed))
  
  
    return (
        <div className={`article-page-wrapper ${isNavbarShowed && isAuthorized ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}>
            {
                (document.body.clientWidth > 640)
                ?
                <><ArticlesNavigation /></>
                :
                <div className={`articles-navigation-sidebar ${isArticleSidebarOpened && 'articles-navigation-sidebar-opened'}`}>
                    <ArticlesNavigation/>
                </div>
            }

            
            <Article/>
        </div>
    )
}