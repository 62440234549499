import { useDispatch, useSelector } from 'react-redux';
import { BACK_SIGNUP_STAGE } from '../../store/types';
import { WhiteCheckMarkIcon } from '../../icons'
import './styles.sass'
import { useState } from 'react';

export const VerticalShortStepBar = (props: {typeOfStepBar: String}) => {

    const dispatch = useDispatch()
    const backSignupStepbarState = () => ({type: BACK_SIGNUP_STAGE})
    const stage = useSelector( (state: any) => state.verticalStepBar.signup_stage)
    const [popUp, isOpen] = useState(false)
    const refreshReg = () => {
        if (stage === '2') {
            isOpen(false)
            setTimeout(() => {
                
                window.history.forward()
              }, 0)
            window.onunload=function(){return null}
            dispatch(backSignupStepbarState())
        }
    }
    
    return (
        
        <div className='vertical-step-bar-wrapper'>{ popUp && stage === '2' &&
            <div className="pop_up_warning">
                <div className="pop__up__warning__body">
                        <p>
                        Вы уверены, что хотите прервать регистрацию и начать всё заново?
                        </p>
                        <div>
                            <div className="white-button" onClick={() => refreshReg()}>
                                Заново
                            </div>
                            <div className="green-button" onClick={() => isOpen(false)}>
                                Отмена
                            </div>
                        </div>
                </div>
            </div>}
            {
                props.typeOfStepBar === 'signup'
                ?
                <>
                    <div className='vertical-step-bar-connecting-line'/>
                    <div className='vertical-step-bar-wrapper__step-container'>
                        <div className={`step-bar-circle active`}>
                            {
                                stage === '1'
                                ?
                                <>1</>
                                :
                                <><WhiteCheckMarkIcon/></>
                            }
                        </div>

                        <span className='step-bar-title step-bar-cursor' onClick={() => isOpen(true)}>
                            Зарегистрируйтесь
                        </span>
                    </div>

                    <div className='vertical-step-bar-wrapper__step-container'>
                        <div className={`step-bar-circle ${ stage==='2' ? 'active' : 'inactive'}`}>
                            2
                        </div>

                        <span className='step-bar-title'>
                            Подтвердите регистрацию
                        </span>
                    </div>
                </>
                :
                <>
                    {
                        props.typeOfStepBar === 'login'
                        ?
                        <>
                            <div className='vertical-step-bar-wrapper__step-container'>
                                <div className='step-bar-circle active'>
                                    1
                                </div>
        
                                <span className='step-bar-title'>
                                    Вход
                                </span>
                            </div>
                        </>
                        :
                        <>
                            <div className='vertical-step-bar-wrapper__step-container'>
                                <div className='step-bar-circle active'>
                                    1
                                </div>
        
                                <span className='step-bar-title'>
                                    Восстановление пароля
                                </span>
                            </div>
                        </>
                    }
                </>
            }
        </div>
    )
}