import {FormattedMessage } from 'react-intl'

import './styles.sass'

export const BigInsctructionPageWhatToDo = () => {
   // const DataText = JSON.parse(localStorage.measurement_type).formListPersonsInstruction
   
    return(
    <div className='big-instruction-page-what-to-do-wrapper'>
        <span className='small-instruction-page-what-to-do-wrapper__title'>
            <FormattedMessage id='measurement_instruction_page_big_what_todo_title'/>
        </span>

        <p dangerouslySetInnerHTML={{__html: JSON.parse(localStorage.measurementTypesN).formListPersonsInstruction}}>
          
        </p>
       
    </div>
)}