export const TRY_TO_CHANGE_EMAIL = 'TRY_TO_CHANGE_EMAIL'
export const SET_IS_CHANGING_EMAIL_TRUE = 'SET_IS_CHANGING_EMAIL_TRUE'

const initialState: boolean = false

export const ChangeEmailReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_IS_CHANGING_EMAIL_TRUE:
            return true

        default:
            return state
    }
}

export const tryToChangeEmail = (payload: {email: string, password: string}) => ({type: TRY_TO_CHANGE_EMAIL, payload})
export const setIsChangingEmailTrue = () => ({type: SET_IS_CHANGING_EMAIL_TRUE})