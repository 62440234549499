import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import { getAllConsultationMessages } from "../store/reducers/ConsultationChatReducer";
import { TRY_TO_PAY_CONSULTATION } from "../store/reducers/ConsultationPayReducer";

interface PayInterface {
    culture: string;
    description:  string;
    expirationDate: string;
    incCurrLabel:  string;
    invId: number;
    isTest: boolean;
    merchantLogin:  string;
    outSum:  string;
    params: {shp_business_key:  string; shp_operation: string; shp_payment: string;}
    signatureValue: string;
    test: boolean;
}

const asyncGetPayConsultation = async (id: string) => {
    try {
        const response = await fetch(
            apiUrl + '/pay/consultation/' + id,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                }
            }
        )
        
        const result = await response.json()
        
        if (response.status === 200) {
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(error) {}
}

export const asyncPayConsultation = async (data: PayInterface) => {
    try {
        const response = await fetch(
            `https://auth.robokassa.ru/Merchant/Index.aspx?merchantLogin=${data.merchantLogin}&outSum=${data.outSum}&description=${data.description}&incCurrLabel=&invId=${data.incCurrLabel}&culture=${data.culture}&isTest=${data.isTest}&signatureValue=${data.signatureValue}&expirationDate=${data.expirationDate}&shp_business_key=${data.params.shp_business_key}&shp_operation=${data.params.shp_operation}&shp_payment=${data.params.shp_payment}&test=${data.test}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                }
            }
        )
        
        const result = await response.json()
        
        if (response.status === 200) {
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(error) {}
}

function* PayConsultationWorker(action) {
    const data: PayInterface | null = yield call(asyncGetPayConsultation, action.payload)
    
    if (data) {
        yield put( getAllConsultationMessages() )
    //     window.location.assign(`https://auth.robokassa.ru/Merchant/Index.aspx?merchantLogin=${data.merchantLogin}&outSum=${data.outSum}&description=${data.description}&incCurrLabel=&invId=${data.incCurrLabel}&culture=${data.culture}&isTest=${data.isTest}&signatureValue=${data.signatureValue}&expirationDate=${data.expirationDate}&shp_business_key=${data.params.shp_business_key}&shp_operation=${data.params.shp_operation}&shp_payment=${data.params.shp_payment}&test=${data.test}`)
    }
}

export function* PayConsultationWatcher() {
    yield takeEvery(TRY_TO_PAY_CONSULTATION, PayConsultationWorker)
}