import { FormattedMessage } from "react-intl"
import { InfoCircleIcon } from "../../../icons"
import './styles.sass'
import axios from "axios";
import {apiUrl} from "../../../consts";
import {useEffect, useState} from "react";
import {CalculatedData} from "../../../interfaces/CalculatedData";

export const InfoStatisticBlocksForGroup = (measurementId?: string) => {

    const [data, setData] = useState<CalculatedData>();

    useEffect(() => {
        console.log(measurementId)
        axios.get(apiUrl + "/measurement/passing-details/" + measurementId, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                Accept: 'application/json',
                'Authorization': 'Bearer ' + localStorage.token
            }})
            .then((result) => {
                setData(result.data.results);
            });
    }, [measurementId]);
    return (
        <div
            className="info-statistic-blocks" 
        >
            
            <div
                className="info-statistic-blocks__block"
            >
                <span
                    className="info-statistic-blocks__block__title"
                >
                    <FormattedMessage id='measurement_statistic_block_1_title' />
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Потенциал партнеров группы</h3>
<p>Все ваши результаты по данной группе параметров обусловлены в большей степени не вами, а культурным потенциалом членов данной группы.</p></div>                       
 </span>}
                </span>

                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_1_partner_energy' />
                    </span>
                    <span>
                        {
                            data?.tpi || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
                    <div className="showMe">
                    <h3>Энергия партнеров</h3>
                    <p>Параметр «энергия партнеров» характеризует среднюю величину силы воздействия
                партнеров данной группы на вас. Граница числовых значений этого
                параметра находится в интервале единиц от -3 до +3. В случае
                 положительной величины данного параметра, можно
                утверждать, что для партнеров данной группы вы привлекательны. И чем выше
                числовое значение, тем более ваши партнеры к вам тянутся, тем более вы для них интересны, тем
                более они энергетически воздействуют на вас. В случае отрицательного
                    значения величины данного параметра можно утверждать, что вы для партнеров данной
                группы мало известны, таинственны или трудны в понимании вашего поведения. При этом они могут
                стремиться к увеличению дистанции с вами, сторониться вас и уклоняться от общения с вами.</p></div>                       
 </span>}
                </div>
                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_1_diversity_partner_energy' />
                    </span>
                    <span>
                        {
                            data?.tpid || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Разнородность энергии партнеров</h3>
<p>Параметр «разнородность энергии партнеров» позволяет оценить, насколько ваши партнеры
                похожи
                друг с другом в своем отношении к вам, насколько их мнения и реакции в отношении вас одинаковы
                или же наоборот слишком разные. Если значение величины данного параметра будет находиться в
                интервале от 0 до 1.06, то ваши партнеры
                 примерно одинаково к вам относятся. А если значение
                величины данного параметра будет больше 1.06, то
                ваши партнеры из данной группы довольно сильно различаются в
                    своем отношении к вам. В жизни одинаковые реакции от разных людей в отношении нас
                практически невозможны. Однако, информация о том, на сколько реакции людей в отношении нас
                различны или сходны, является очень полезной для планирования нашего поведения в коллективе.</p></div>                       
 </span>}
                </div>
                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_1_personal_energy' />
                    </span>
                    <span>
                        {
                            data?.rvp || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Энергия личная</h3>
<p>Параметр «энергия личная» характеризует среднюю величину вашей жизненной энергии,
                которую вы тратите на партнеров данной группы в целом. Граница числовых значений этого параметра
                находится в интервале единиц от -3 до +3. В случае положительного
                    значения величины данного параметра можно утверждать, что вы свою энергию активно направляете на свое внешнее окружение, и чем больше
                значение величины данного параметра, тем более активно вы расходуете свои жизненные силы на
                партнеров вашего коллектива. Если же величина данного параметра отрицательная, то вы свою энергию
                    направляете не на партнеров, а на самих себя, на
                изменение своего поведения в отношении партнеров данной группы. И, в этом случае, чем больше значение отрицательной величины,
                тем более вы погружены в свой внутренний мир под влиянием
                воздействия партнеров данной
                группы.</p></div>                       
 </span>}
                </div>
                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_1_diversity_personal_energy' />
                    </span>
                    <span>
                        {
                            data?.rvpd || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Разнородность личной энергии</h3>
<p>Параметр «разнородность личной энергии» характеризует полярность
                ваших
                энергозатрат на партнеров
                данной группы. Если числовое значение этого показателя
                находится в интервале от 0 до 1.06, то
                ваши энергетические затраты на партнеров данной группы не велики. А если числовое значение по
                данному параметру больше 1.06, то вы затрачиваете
                    много жизненной энергии при общении с
                партнерами данной группы, и вам требуется значительное время для отдыха и восстановления
                жизненных сил.</p></div>                       
 </span>}
                </div>
                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_1_personal_strategy' />
                    </span>
                    <span>
                        {
                            data?.gdp || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Персональная стратегия</h3>
<p>Параметр «персональная стратегия» характеризует общий вектор направленности вашей
                жизненной энергии по отношению к партнерам данной группы. Если числовое значение параметра
                «персональная стратегия» положительное, то ваша энергия
                направлена на сдерживание реакций
                партнеров, блокированию их активности в отношении вас. Такая стратегия является консервативной,
                и вы – «социальный консерватор», сторонник традиций и устойчивых правил. А если значение данного
                параметра будет отрицательное, то ваша энергия направлена на
                принципиальные для вас изменения в
                характере поведения партнеров данной группы, на перестройку их мнения, ценностей, идей,
                взглядов. Такая стратегия является радикальной, и вы –
                «социальный радикал», сторонник
                решительных действий и взглядов.</p></div>                       
 </span>}
                </div>
            </div>

            <div
                className="info-statistic-blocks__block"
            >
                <span
                    className="info-statistic-blocks__block__title"
                >
                    <FormattedMessage id='measurement_statistic_block_2_title' />
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Личностный потенциал</h3>
<p>Все результаты по данной группе параметров обусловлены, в большей степени, не групповым, а
            именно вашим личным потенциалом, сформированном в предшествующий период вашей жизни и развития.</p></div>                       
 </span>}
                </span>

                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_2_social_intuition' />
                    </span>
                    <span>
                        {
                            data?.pss || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Социальная интуиция</h3>
<p>Параметр «социальная интуиция» характеризует степень вашей
                социальной чувствительности, проницательности, антиципации (предвосхищения) социальных событий в
                данной группе людей. Границы значений данного параметра находятся в
                    интервале от 0 до 100 единиц. Если ваше значение параметра менее 52 единиц, то ваша
                способность к концентрации и быстрой переработке разнообразной
                социальной информации понижена. Снижение значения данного показателя указывает и на снижение вашей
                способности к социальной интуиции. Если значение данного параметра находится в интервале от 56 до 78 единиц, то ваша социальная
                    интуиция достаточно хорошо активирована в данной социальной группе. Если значение
                данного параметра выше 78 единиц, то вы с высокой скоростью предвосхищаете события и предугадываете
                поведение людей данной группы. При этом скорость и переработка разнородных сигналов у вас очень
                высока, так как высока степень интеграции всех сенсорных систем вашего организма.</p></div>                       
 </span>}
                </div>
                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_2_communication_intensivity' />
                    </span>
                    <span>
                        {
                            data?.iis || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Интенсивность коммуникации</h3>
<p>Параметр «интенсивность коммуникации» характеризует
                вашу вовлеченность в процессы живой,
                непосредственной коммуникации с партнерами данной группы. Границы
                числовых значений по данному параметру находятся в интервале от - 6 до + 6. Чем выше значение
                данного показателя, тем более вы
                вовлечены в процессы общения с партнерами в реальном времени. Чем ниже значение данного показателя,
                тем менее интенсивны ваши контакты в реальном времени. Положительное
                    значение величины данного
                параметра характеризует существование общих деловых и творческих
                    интересов у вас и всех партнеров
                данной группы. А отрицательное значение величины данного параметра
                указывает на преобладание у вас разногласий с членами
                    данной группы в
                    ценностях и нормах, в способах и условиях реализации групповых
                целей.</p></div>                       
 </span>}
                </div>
                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_2_independent_state' />
                    </span>
                    <span>
                        {
                            data?.psa || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Состояние независимости</h3>
<p>Параметр «состояние независимости» характеризует вашу
                способность к сопротивляемости
                воздействиям членов данной группы, автономности, критичному мышлению и произвольному поведению по
                отношению к членам данной группы. Границы значений данного
                параметра
                находятся в интервале от 0 до 100 единиц. Оптимальное
                    значение нормы по данному параметру граничит в интервале от 38
                    до 45 единиц. Если величина значения данного параметра ниже 38
                    единиц, то это указывают на снижение вашей способности к
                    сопротивляемости воздействиям со
                стороны членов данной группы, а если выше 45 единиц, то вы
                демонстрируете высокий уровень социальной независимости и
                    автономности в отношении членов
                данной группы. Чем выше значение параметра, тем более вы способны к несогласию с мнением группы и к
                отстаиванию исключительно ваших личных интересов. Чем ниже значение данного параметра, тем более вы
                склонны к сокрытию своего мнения и к отказу от предъявления своей позиции членам группы. Этот
                параметр связан не только с вашими психологическими особенностями, но также может быть обусловлен и вашим состоянием здоровья. Значения по данному параметру
                снижаются на фоне сильной усталости или
                болезни. Но при этом помним, что данный показатель, как и все другие, верны в отношении вас только в
                присутствии членов данной группы. В другой социальной группе все ваши параметры могут иметь другие
                значения!</p></div>                       
 </span>}
                </div>
                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_2_business_strategy' />
                    </span>
                    <span>
                        {
                            data?.ipe || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Деловая стратегия</h3>
<p>Параметр «деловая стратегия» характеризует особенности
                вашего
                поведения в данной группе в аспекте
                содержания целевых ориентиров. Границы числовых значений по данному параметру находятся в интервале
                от - 6 до + 6. Если значение данного параметра отрицательное,
                то вы устремлены к достижению конкретной цели при взаимодействии с
                членами данной группы. И чем выше будет числовое значение, в
                случае отрицательной величины данного параметра, тем более характеристики вашего стремления к
                достижению конкретной цели присутствуют в вашей деловой стратегии, и ее лозунг будет таков: «я
                знаю ЧТО надо делать». Если значение данного
                параметра
                положительное, то вы более всего ориентированы на способы и условия достижения цели, на качественные характеристики
                процесса достижения цели. Тогда
                лозунг вашей деловой стратегии в данной группе людей будет таков: «я знаю 
                   КАК это делать». Обе
                стратегии чрезвычайно важны и полезны, и в разных группах вы можете быть ориентированы на тот или на
                другой тип стратегии. Обычно люди не осознают, какая именно стратегия реализуется ими в той или иной
                группе, так как она в каждом конкретном случае формируется спонтанно, случайно. Поэтому важно знать,
                какая стратегия в данной группе людей у вас сформировалась в текущем времени.</p></div>                       
 </span>}
                </div>
            </div>

            <div
                className="info-statistic-blocks__block"
            >
                <span
                    className="info-statistic-blocks__block__title"
                >
                    <FormattedMessage id='measurement_statistic_block_3_title' />
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Структура психических процессов</h3>
<p>Все результаты по данной группе параметров характеризуют актуальное
                состояние вашей психики, вызванное пребыванием в данной группе
                партнеров.
         
            В блок 3 входят четыре процесса. Суммарно их числовые значения составляют
                100 единиц. Значения
                величин каждого процесса данной группы полезно анализировать в относительном соотношении друг с
                другом: какой параметр имеет большее числовое значение, тот процесс в
                    структуре психики ведущий в
                момент пребывания персоны в данной социальной группе.</p></div>                       
 </span>}
                </span>

                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_3_background_process' />
                    </span>
                    <span>
                        {
                            data?.l0 || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Процесс фоновой рецепции</h3>
<p>Параметр «процесс фоновой рецепции» характеризует размер
                социального пространства
                (численность людей), который находится за границами зоны ясного
                    рецепторного поля. Это так
                называемый неиспользованный социальный резерв. Однако, думать, что люди в нулевой зоне не влияют на
                вас, ошибочно. Вы удерживаете этих людей за границами зоны ясного восприятия, и это напрягает ваши
                физиологические механизмы, ведет к энергетическим затратам. Величину числового значения данного
                параметра нужно анализировать только в соотношении с другими значениями параметров данной группы.
                 Если значение по поданному параметру самое
                    большое, среди остальных трех значений параметров данной группы, то ваше состояние можно
                характеризовать как максимально избирательное, а ваше поведение как
                 максимально скрытое для членов данной группы. Причины такого
                состояния отношений могут быть разные:
                это либо незнакомая группа людей, либо это люди, в которых вы разочаровались, либо что-то другое,
                что привело к необходимости блокировки социальных контактов с большей частью членов данной
                группы.</p></div>                       
 </span>}
                </div>
                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_3_reflex_process' />
                    </span>
                    <span>
                        {
                            data?.l1 || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Рефлексивные процессы</h3>
<p>Параметр «рефлексивных процессов» характеризует степень
                напряжения «сторожевых» функций сознания, особенности
                протекания оценочных процессов и напряжения при планировании
                    действий. При
                высокой активности рефлексивных процессов в состоянии скелетных мышц преобладает статическое
                напряжение, а все обменные функции в организме замедлены для обеспечения максимального
                сосредоточения внимания при распознании поступающих сигналов. Цель такого физиологического
                сосредоточения – максимально точное распознание состояния партнеров по их внешнему облику и всей
                совокупности их двигательных реакций. Величину числового значения данного параметра нужно
                анализировать только в соотношении с другими значениями параметров данной группы. Если значение величины данного параметра самое
                    большое среди остальных трех значений параметров данной группы, то
                ваше внутреннее состояние будет максимально напряженным, а ваше поведение
                    во внешнем плане будет максимально осторожным. При этом все
                    обменные процессы в вашем организме протекают медленно. Возможно снижение аппетита,
                нежелание активно двигаться. Ночной сон будет длиться больше обычного,
                но при этом вы не будете высыпаться. При длительном пребывании в таком состоянии вам потребуется
                эмоциональная разрядка. Возможен резкий выброс эмоций. Будьте осторожны!</p></div>                       
 </span>}
                </div>
                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_3_mind_process' />
                    </span>
                    <span>
                        {
                            data?.l2 || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Рассудочная деятельность</h3>
<p>Параметр «рассудочная деятельность» характеризует процессы рационального плана. Логика,
                инструментальная деятельность со знаками, речевая активность, система доказательств и вся ваша база накопленных знаний активно используются при контактах с
                партнерами на данном структурном уровне
                психических процессов. В физиологическом плане при активной рассудочной (инструментальной,
                орудийной) деятельности в состоянии скелетных мышц и в мышечных волокнах миокарда преобладает
                динамическое напряжение, и все обменные процессы в организме протекают в активной фазе. Величину
                числового значения данного параметра нужно анализировать только в соотношении с другими значениями
                параметров данной группы. Если числовое значение величины по
                данному параметру самое большое, среди параметров данной группы, то
                ваша активность протекает нормально, дневная усталость будет вполне
                компенсирована ночным сном. Вы легко утром просыпаетесь и быстро приступаете к делам.</p></div>                       
 </span>}
                </div>
                <div
                    className="info-statistic-blocks__block__row"
                >
                    <span className="flexic">
                        <FormattedMessage id='measurement_statistic_block_3_emotional_reactivity' />
                    </span>
                    <span>
                        {
                            data?.l3 || ''
                        }
                    </span>
                    {location.pathname.includes('group') && <span className="circleQuest">
                        <InfoCircleIcon/>
<div className="showMe">
<h3>Эмоциональная реактивность</h3>
<p>Параметр «эмоциональной реактивности» характеризует
                степень вашей импульсивности, эмоционального
                    возбуждения, наличия реактивных неконтролируемых
                    состояний. На этом уровне ваши
                физиологические процессы максимально свободны и характеризуются реактивностью протекания под
                влиянием переживания ярких эмоций, как положительных, так и отрицательных. В любом случае, сильное
                эмоциональное переживание состояний счастья или радости, гнева или озлобления, будут сопровождаться
                реактивными выбросами большого количества энергии, «разливаемой» по всему организму. Величину
                числового значения данного параметра нужно анализировать только в соотношении с другими значениями
                параметров данной группы. Если значения величины по данному
                параметру самое большое среди параметров данной группы, то вы
                пребываете в неуравновешенном состоянии, подвержены резким скачкам настроения,
                и вам требуется больше времени для отдыха в конце дня. Компенсировать ваши энергетические затраты
                при доминировании этого состояния могут индивидуальные занятия негрупповыми видами спорта,
                длительные пешие прогулки, чтение интересных книг.</p></div>                       
 </span>}
                </div>
            </div>
        </div>
    )
}
