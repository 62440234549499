import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { nextStepBar } from "../../store/reducers/StepBarReducer";
import { changeHeaderTitle } from "../../store/reducers/HeaderTitleReducer";

import { StepBar } from "../../components/StepBar";
import { BottomControlPanel } from "../../components/BottomControlPanel/index";
import { ChoosePersonStatusPanel } from "../../components/ChoosePersonStatusPanel/index";

import { LeftSideInstruction } from "../../components/LeftSideInstruction/index";
import { ChooseGenderMeasurementPersonPanel } from "../../components/ChooseGenderMeasurementPersonPanel/index";
import { CommentaryMeasurementPersonListPage } from "../../components/CommentaryMeasurementPersonListPage/index";
import { FindPersonMeasurementInput } from "../../components/FindPersonMeasurementInput/index";
import { PersonListPanel } from "../../components/PersonListPanel/index";

import { PersonDeleteMeasurementModal } from "../../components/PersonDeleteMeasurementModal/index";
import { PersonEditMeasurementModal } from "../../components/PersonEditMeasurementModal/index";

import { routes } from "../../routes";

import "./styles.sass";

import { asyncUpdateMeasurement } from "../../store/reducers/MeasurementReducer";
import { useNavigate } from "react-router-dom";

//import store from '../../store'

export const MeasurementPersonListContainer = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

  const isAnyPerson =
    useSelector((state: any) => state.personMeasurementList).slice(1).length ===
    0;
  const [modal, isModal] = useState(false);

  const showMeasurementModal = useSelector((state: any) => state.modals);

  useEffect(() => {
    dispatch(nextStepBar(2));
    dispatch(
      changeHeaderTitle({
        currentHeaderTitle: "header_title_main",
        currentHeaderAdditionalTitle: "header_title_subtitle_measurement_type",
      })
    );
    history.pushState(null, document.title);
    window.addEventListener("popstate", function () {
      history.pushState(null, document.title);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateBack = () => {};

  const navigateNext = () => {
    isModal(true);
  };
  const nextPage = () => {
    const dataMeas = JSON.parse(localStorage.getItem("measurement") || "");
    dataMeas.rateMembers = JSON.parse(localStorage.getItem("personList") || "");
    localStorage.setItem("measurement", JSON.stringify(dataMeas));
    dispatch(asyncUpdateMeasurement("READ_ME_2_PARTNER_INSTRUCTION"));
    Navigate(routes.meToPersonInstruction);
    return routes.meToPersonInstruction;
  };
  return (
    <>
      <div
        className={`person-list-measurement-container ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
      >
        {modal && (
          <div className="modal__person">
            <div className="modal__person__body">
              <p>
                Завершить формирование списка партнеров и приступить к
                выполнению измерения?
                <br />
                (Потом на этот шаг вернуться нельзя)
              </p>
              <div className="modal__person__buttons">
                <div
                  className="white-button"
                  onClick={() => {
                    nextPage();
                  }}
                >
                  Начать измерение
                </div>
                <div
                  className="green-button"
                  onClick={() => {
                    isModal(false);
                  }}
                >
                  К списку партнеров
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="person-list-measurement-container__title-wrapper">
          <span className="person-list-measurement-container__title-wrapper__title">
            <FormattedMessage id="measurement_person_list_page_title" />
          </span>

          {document.body.clientWidth > 640 && <StepBar steps={8} />}
        </div>

        <div className="person-list-measurement-container__inside-wrapper">
          <div className="person-list-measurement-container__inside-wrapper__row">
            <span className="title" style={{ marginRight: "18px" }}>
              <FormattedMessage id="measurement_person_list_page_fio_title" />
            </span>
            <FindPersonMeasurementInput />
          </div>
          <div className="find-person-input-wrapper__finded-persons-panel__prompt">
            <FormattedMessage id="measurement_person_list_page_find_person_input_prompt" />
          </div>
          <div className="person-list-measurement-container__inside-wrapper__row">
            <span
              className="title"
              style={{
                marginRight: "35px",
                marginBottom: document.body.clientWidth > 640 ? "10px" : "0px",
              }}
            >
              <FormattedMessage id="measurement_person_list_page_statuses_title" />
            </span>
            <ChoosePersonStatusPanel />
          </div>

          <div
            className="person-list-measurement-container__inside-wrapper__row"
            style={{
              marginTop: document.body.clientWidth > 640 ? "132px" : "0px",
            }}
          >
            <span className="title" style={{ marginRight: "35px" }}>
              <FormattedMessage id="measurement_person_list_page_gender_title" />
            </span>
            <ChooseGenderMeasurementPersonPanel />
            <span
              style={{
                color: "darkgrey",
                marginLeft: "14px",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "400",
              }}
            >
              - Дата рождения (если помните)
            </span>
          </div>

          <div className="person-list-measurement-container__inside-wrapper__row">
            <CommentaryMeasurementPersonListPage />
          </div>

          <div>
            <PersonListPanel />
          </div>
        </div>
      </div>

      {showMeasurementModal.modal === "DELETE_PERSON" && (
        <PersonDeleteMeasurementModal />
      )}

      {showMeasurementModal.modal === "EDIT_PERSON" && (
        <PersonEditMeasurementModal />
      )}

      <LeftSideInstruction
        text={
          JSON.parse(localStorage.measurementTypesN).formListPersonsInstruction
        }
      />

      <BottomControlPanel
        nextPage={modal && routes.meToPersonInstruction}
        previousPage={routes.main}
        backFunc={navigateBack}
        nextFunc={navigateNext}
        isNextButtonDisabled={isAnyPerson}
      />
    </>
  );
};
