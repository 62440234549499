
import { FormattedMessage } from 'react-intl'
import { MeasurementShortInfo } from '../../../interfaces/MeasurementShortInfo'
import './styles.sass'

export const MainMeasurementData = (props: {data: MeasurementShortInfo}) => {

    return (
        <div
            className='measurement-statistic-header'
        >
            <span className='measurement-statistic-header__title'>
                <FormattedMessage id='measurement' />:
            </span>

            <div
                className='measurement-statistic-header__comment'
            >
                {
                    props.data
                    &&
                    (props.data.comment !== null ? props.data.comment
                    :
                    'Название состоящее из комментария, который вводился в конце замера.')
                }
            </div>

            <div
                className='measurement-statistic-header__description'
            >
                <div
                    className='measurement-statistic-header__description__row'
                >
                    <span
                        className='measurement-statistic-header__description__row__title'
                        style={{
                            marginRight: '24px'
                        }}
                    >
                        <FormattedMessage id='measurement_statistic_data_date' />
                    </span>
                    <span
                        className='measurement-statistic-header__description__row__sub-title'
                    >
                        {
                            props.data
                            &&
                            props.data.creationDate.slice(8,10) + '.' + props.data.creationDate.slice(5,7) + '.' + props.data.creationDate.slice(0,4) 
                        }
                    </span>
                </div>

                <div
                    className='measurement-statistic-header__description__row'
                >
                    <span
                        className='measurement-statistic-header__description__row__title'
                        style={{
                            marginRight: '31px'
                        }}
                    >
                        <FormattedMessage id='measurement_statistic_data_type' />
                    </span>
                    <span
                        className='measurement-statistic-header__description__row__sub-title'
                    >
                        {
                            props.data
                            &&
                            props.data.typeName
                        }
                    </span>
                </div>
            </div>
        </div>
    )
}