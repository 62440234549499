import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { Consultant } from "../interfaces/Consultant";
import { routes } from "../routes";
import store from "../store";
import { GET_CONSULTANTS_LIST, setConsultantsList } from "../store/reducers/ConsultantsListReducer";

async function asyncGetConsultantsList(page: number) {

    
   

    try {
     
        const form = store.getState().consultantFilters
        let numberOfWorkload;
        switch (form.workload){
                case 'Свободен': 
                    numberOfWorkload = 0;
                    break;
                case 'Слабо загружен':
                    numberOfWorkload = 1;
                    break;
                case 'Сильно загружен':
                    numberOfWorkload = 2;
                    break;
                case 'Полностью загружен':
                    numberOfWorkload = 3;
                    break;  
                default: numberOfWorkload = '';
        }
        let sort = `consultantInfo.${form.sort}`;
        
        if (form.sort === 'fio'){
            sort = 'user.surname'
        }
       
        const response = await fetch(
            apiUrl + `/consultants?page=${page}&sort=${sort},asc&fio=${form.fio}&rating=${form.rating}&workload=${numberOfWorkload}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                }
            }
        )

        if (response.status === 200 || response.status === 201) {
            const result: Consultant[] = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch(error) {}
}

function* GetConsultantsListWorker(action) {
    const data = yield call(asyncGetConsultantsList, action.payload)
    if (data && data.length) {
        yield put( setConsultantsList({ page: action.payload, list: data }) )
    }
}

export function* GetConsultantsListWatcher() {
    yield takeEvery(GET_CONSULTANTS_LIST, GetConsultantsListWorker)
}