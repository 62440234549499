import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ChoosenCheckMarkIcon,
  CopyTabletIcon,
  EmptyCheckMarkIcon,
  OnePersonIcon,
  RedTrashBasketIcon,
} from "../../icons";
import { Gender, Person } from "../../interfaces/Person";
import { routes } from "../../routes";
import {
  addPersonToChoosenList,
  deletePersonFromChoosenList,
} from "../../store/reducers/ChoosenPersonListReducer";

import "./styles.sass";

export const PersonChoosePanel = (props: {
  data: Person;
  isChoosen: boolean;
  getPersonsListNew;
  setArrayPerson;
}) => {
  const [popUp, isOpen] = useState(false);
  const [partner, deletePartner] = useState({
    name: "",
    id: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteItem = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/person/remove/${partner.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      }
    );
    if (response.status === 200) {
      isOpen(false);
      window.location.reload();

      return true;
    } else if (response.status === 401) {
      location.assign(routes.login);
    } else {
      return null;
    }
  };
  const data = props.data;

  return (
    <div className="person-choose-panel">
      {popUp && (
        <div className="pop_up_warning">
          <div className="pop__up__warning__body">
            <p>
              Вы уверены, что хотите удалить: <br /> {partner.name}
            </p>
            <div>
              <div className="white-button" onClick={() => deleteItem()}>
                Удалить
              </div>
              <div className="green-button" onClick={() => isOpen(false)}>
                Отмена
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="person-choose-panel__wrap">
        <div
          className="person-choose-panel__check-mark"
          style={{ display: "none" }}
          onClick={() => {
            if (props.isChoosen) {
              dispatch(deletePersonFromChoosenList(data.id!));
            } else {
              dispatch(addPersonToChoosenList(data));
            }
          }}
        >
          {props.isChoosen ? <ChoosenCheckMarkIcon /> : <EmptyCheckMarkIcon />}
        </div>

        <div className="person-choose-panel__person-icon">
          <OnePersonIcon />
        </div>

        <div
          className="person-choose-panel__name-column"
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(routes.changePerson, { state: { data: data } })
          }
        >
          <div>{data.customName}</div>
          <span>{data.role}</span>
        </div>
      </div>

      <div className="person-choose-panel__additional-data">
        <div className="person-choose-panel__additional-data__column">
          <div>
            <FormattedMessage id="persons_list_panel_gender" />
          </div>
          <span>
            {data.gender == Gender.MALE ? (
              <FormattedMessage id="persons_list_panel_gender_male" />
            ) : (
              <FormattedMessage id="persons_list_panel_gender_female" />
            )}
          </span>
        </div>
        <div className="person-choose-panel__additional-data__column">
          <div>
            <FormattedMessage id="persons_list_panel_birthdate" />
          </div>
          <span>
            {data.birthday
              ? data.birthday.slice(8, 10) +
                "." +
                data.birthday.slice(5, 7) +
                "." +
                data.birthday.slice(0, 4)
              : "Отсутствует"}
          </span>
        </div>
      </div>

      <div className="person-choose-panel__icons">
        <div
          onClick={() =>
            navigate(routes.changePerson, { state: { data: data } })
          }
        >
          <CopyTabletIcon />
        </div>
        {data.deletable ? (
          <div
            onClick={() => {
              if (data.customName && data.id) {
                isOpen(true);
                deletePartner({
                  name: data.customName,
                  id: data.id,
                });
              }
            }}
          >
            <RedTrashBasketIcon />
          </div>
        ) : (
          <div style={{ opacity: 0.6 }}>
            <RedTrashBasketIcon />
          </div>
        )}
      </div>
    </div>
  );
};
