import { ArticleNavigationAction, ArticleNavigationTypes } from "../types/ArticleNavigation"
import {Article} from "../../components/ArticlesNavigation/article";

interface ArticleNavigationState {
    currentSectionNum: Number;
    currentArticleNum: Number;
    currentArticle?: Article;
}

const initialState: ArticleNavigationState = {
    currentSectionNum: 0,
    currentArticleNum: 0,
}
export const ArticleNavigationReducer = (state = initialState, action: ArticleNavigationAction ) => {
    switch (action.type) {
        case ArticleNavigationTypes.OPEN_SECTION:
            return {
                currentSectionNum: action.payload,
                currentArticleNum: 0
            }
        case ArticleNavigationTypes.OPEN_ARTICLE:
            return {
                ...state,
                currentArticleNum: action.payload[0],
                currentArticle: action.payload[1]
            }

        case ArticleNavigationTypes.NEXT_ARTICLE:
            const nextPld = action.payload
            
            if ((nextPld.currentArticleNum + 1 === nextPld.currentSectionLength) && (nextPld.currentSectionNum < nextPld.sectionAmount)) {
                return {
                    currentSectionNum: nextPld.currentSectionNum + 1,
                    currentArticleNum: 0,
                }
            } else if (nextPld.currentArticleNum < nextPld.currentSectionLength-1) {
                return {
                    currentSectionNum: nextPld.currentSectionNum,
                    currentArticleNum: nextPld.currentArticleNum + 1
                }
            } else if ((nextPld.currentArticleNum + 1 == nextPld.currentSectionLength) && (nextPld.currentSectionNum == nextPld.sectionAmount)) {
                
                return state
            }
            return state
        case ArticleNavigationTypes.BACK_ARTICLE:
            const backPld = action.payload
            if ( (backPld.currentArticleNum === 0) && (backPld.currentSectionNum !== 0) ) {
                return {
                    currentSectionNum: backPld.currentSectionNum - 1,
                    currentArticleNum: backPld.currentSectionLength - 1
                }
            } else if (backPld.currentArticleNum > 0) {
                return {
                    ...state, 
                    currentArticleNum: backPld.currentArticleNum - 1
                }
            } 
            return state
        default: 
            return state
    }
}