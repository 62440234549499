export const TRY_TO_ACCEPT_PAYING_CONSULTATION = 'TRY_TO_ACCEPT_PAYING_CONSULTATION'
export const SET_IS_REJECTING_CONSULTATION_TRUE = 'SET_IS_REJECTING_CONSULTATION_TRUE '

const initialState: boolean = false

export const AcceptPayingConsultationReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_IS_REJECTING_CONSULTATION_TRUE:
            return true

        default:
            return state
    }
}

export const tryToAcceptPayingConsultation = () => ({type: TRY_TO_ACCEPT_PAYING_CONSULTATION})
export const setIsRejectinConsultationTrue = () => ({type: SET_IS_REJECTING_CONSULTATION_TRUE})