import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import store from "../store";
import { CONSULTANT_CONFIRM_CONSULTATION, getCurrentConsultation } from "../store/reducers/CurrentConsultationReducer";

const asyncConfirmConsultation = async (id: string) => {
    try {
        const response = await fetch(
            apiUrl + `/consultation/start-work/${id}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            }
        )

        if (response.status === 200) {
            return true
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch (err) {}
}

function* ConfirmConsultationWorker() {
    const consultation = store.getState().currentConsultation
    const data = yield call(asyncConfirmConsultation, consultation?.id)

    if (data) {
        yield put(getCurrentConsultation(consultation?.id))
    }
}

export function* ConfirmConsultationWatcher() {
    yield takeEvery(CONSULTANT_CONFIRM_CONSULTATION, ConfirmConsultationWorker)
}