import { useSelector } from "react-redux"

import './styles.sass'
import { WhiteCheckMarkIcon } from '../../icons'
// import { useEffect, useState } from "react"

let StepsArray: number[] = []

export const StepBar = (prop: {steps: number, changeble?: string}) => {

    const currentStep = useSelector( (state: any) => (state.stepbar.currentStep) )
  
    if (prop.steps === 8) {
        StepsArray = [0,1,2,3,4,5,6,7]
    } else if (prop.steps === 4) {
        StepsArray = [0, 1, 2, 3]
    }
    // useEffect(()=>{
    //     if (prop.changeble){
    //         isSlider(true)
    //     }
        
    // },[prop.changeble])
    
    return (
        <div className='step-bar-wrapper'>
            
            {
                StepsArray.map( (elem: number) => {

                    var className:string = 'active'; 
                    var value:number | JSX.Element = elem;

                    if (elem < currentStep) {
                        value = <WhiteCheckMarkIcon/>;
                    } else if (elem === currentStep) {
                        value = elem + 1;
                    } else {
                        value = elem + 1;
                        className = 'inactive';
                    }

                    return (
                        
                        <div key={elem} className="step-wrapper" >
                            <span className={`step-wrapper__circle ${className}`}>
                                {value}
                            </span>
                            {
                                (prop.steps === 8)
                                ?
                                (
                                    (elem !== 7)
                                    &&
                                    <div 
                                        className="step-wrapper__vertical-line"
                                        style={{background: (elem < currentStep) ? '#219653' : '#9292A3'}}
                                    />
                                )
                                :
                                (
                                    (elem !== 3)
                                    &&
                                    <div 
                                        className="step-wrapper__vertical-line"
                                        style={{background: (elem < currentStep) ? '#219653' : '#9292A3'}}
                                    />
                                )
                            }
                        </div>
                    )
                })
            }

        </div>
    )
}