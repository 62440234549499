import { MeasurementType } from "../../interfaces/MeasurementType";

export const CHOOSE_MEASUREMENT_TYPE = "CHOOSE_MEASUREMENT_TYPE_CARD";
export const CLEAN_MEASUREMENT_TYPE_CARD = "CLEAN_MEASUREMENT_TYPE_CARD";

let initialState: MeasurementType = {
  id: "",
  name: "",
  description: "",
  finalStepContent: "",
  personToMeInstruction: "",
  meToPersonInstruction: "",
};

if (
  localStorage.measurement_type !== undefined &&
  typeof localStorage.measurement_type === "string" &&
  localStorage.measurement_type.length > 0 &&
  localStorage.measurement_type !== "undefined"
) {
  const preState = JSON.parse(localStorage.measurement_type);
  if (preState && preState.id && preState.description) {
    initialState = preState;
  }
}

export const MeasurementTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHOOSE_MEASUREMENT_TYPE:
      localStorage.setItem("measurementTypesN", JSON.stringify(action.payload));

      return action.payload;
    case CLEAN_MEASUREMENT_TYPE_CARD:
      return {
        choosenMeasurementTypeCard: "",
      };
    default:
      return state;
  }
};

export const chooseMeasurementType = (payload: MeasurementType) => ({
  type: CHOOSE_MEASUREMENT_TYPE,
  payload,
});
export const cleanMeasurementTypeCard = () => ({
  type: CLEAN_MEASUREMENT_TYPE_CARD,
});
