import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { WarningYellowIcon } from '../../icons'
import store from '../../store'
import { deletePerson } from '../../store/reducers/CurrentPersonReducer'
import { closeModal } from '../../store/reducers/ModalWindowsReducer'
import './styles.sass'

export const PersonDeleteMeasurementModal = () => {
    
    const dispatch = useDispatch()
   

    return (
        <div 
            className='person-delete-modal-wrapper'
        >
            <div
                className='person-delete-modal'
            >
                
                <span
                    className='person-delete-modal__title'
                >
                    <FormattedMessage id='measurement_person_list_page_delete_modal_title' />
                </span>

                <div 
                    className='person-delete-modal__row'
                >
                    <div>
                        <WarningYellowIcon/>
                    </div>

                    <div 
                        className='person-delete-modal__row__main'
                    >
                         { store.getState().modals.modalInfo.canDeleted ? ( <span
                            className='person-delete-modal__row__main__title'
                        >
                            Вы действительно хотите полностью удалить:
                        </span>) :

                        (<span
                            className='person-delete-modal__row__main__title'
                        >
                            Вы действительно хотите удалить из замера:
                        </span>)}

                        <span
                            className='person-delete-modal__row__main__name'
                        >
                            {store.getState().modals.modalInfo.customName}
                        </span>

                        <div
                            className='person-delete-modal__row__main__buttons'
                        >

                            <div
                                className='white-button'
                                onClick={() => dispatch(closeModal())}
                            >
                                <FormattedMessage id='measurement_person_list_page_delete_modal_cancel'/>
                            </div>

                            <div
                                className='person-delete-modal__row__main__buttons__delete-button'
                                onClick={() => {
                                    dispatch(deletePerson(store.getState().modals.modalInfo.id))
                                    dispatch(closeModal())
                                }}
                            >
                                <FormattedMessage id='measurement_person_list_page_delete_modal_delete'/>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}