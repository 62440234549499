import { ReactElement, useEffect, useState } from "react";
import { ToggleListSmallArrowIcon } from "../../../icons";
import { SubscribeComponent } from "../../SubscribeComponent/SubscribeComponent"
import './styles.sass'

export const MeasurementToggleList = (props: {
    component: ReactElement, 
    title: ReactElement, 
    maxHeight: number,
    isScrollable?: boolean,
    nameTitle?: string
    isActivate?: boolean
}) => {

    const [isOpened, setIsOpened] = useState<boolean>(false)
    
    const toggleIsOpened = () => {
        if (isOpened) {
            setIsOpened(false)
            
        } else {
            setIsOpened(true)
            if (props.nameTitle == 'physical'){
                setTimeout(()=>{
                    document.getElementsByClassName('container')[0].scrollTop+=400;
                },100)
            
            }
            
        }
    }
   
    useEffect(()=>{
        if (props.isScrollable){
            setIsOpened(true)
            
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <div
            className={`${isOpened ? 'opened-measurement-toggle-list' : ''} measurement-toggle-list`}
        >
            <div
                className="measurement-toggle-list__header"
                onClick={() => toggleIsOpened()}
            >
                <span>
                    {
                        props.title
                    }
                </span>
                <div className={isOpened ? 'rotated-icon' : '' }>
                    <ToggleListSmallArrowIcon/>
                </div>
            </div>
          
            <div
                className={`measurement-toggle-list__component  ${props.isScrollable && 'scrollable-toggle-list'}`}
             
                >
                      <SubscribeComponent Activate={!props.isScrollable == true ? props.isActivate : true}/>
                      <div    style={(props.isActivate != undefined && !props.isActivate)? {filter: 'blur(7px)'} : { filter: 'none'}}>
                            {
                            props.component
                        }
                      </div>
               
            </div>
        </div>
    )
}