import { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {Link} from 'react-router-dom'
//import {  GoogleCircleIcon, LinkedInCircleIcon } from '../../icons'
import { routes } from '../../routes'
import { tryToLogin } from '../../store/reducers/LoginReducer'
//import vkLogo from './vk.svg';
import './styles.sass'

interface LoginFormInterface {
    email: {
        value: string,
        changed: boolean
    };
    password: {
        value: string,
        changed: boolean
    };
}

interface LoginErrors {
    email: string;
    password: string;
}

export const LoginForm = () => {
  /*   function getVk(){
       
       document.location.href = `${process.env.REACT_APP_API_VK}/authorization/vk`
  
    
    } */
    useEffect(()=>{
        let url = new URL(document.location.href);
        let token = url.searchParams.get("token");
      
        if (token!=null){
            localStorage.setItem('token', token)
            location.assign(routes.startMeasurement)
        }
    },[])
    const intl = useIntl()
    const dispatch = useDispatch()
    //const [numberYerrors, setNumberYerrors] = useState(0)
    const isLoginError: boolean = useSelector( (state: any) => state.login.error)
    const [isValid, setIsValid] = useState<boolean>(false)
    
    const [form, setForm] = useState<LoginFormInterface>({
        email: {
            value: '',
            changed: false
        },
        password: {
            value: '',
            changed: false
        },
    })

    const [errors, setErrors] = useState<LoginErrors>({
        email: '',
        password: '',
    })

    const handleSubmit = (event) => {
        event.preventDefault()

        if (isValid) {
            dispatch(tryToLogin({
                username: form.email.value,
                password: form.password.value,
            }))
           
        }
    }

    const validate = () => {
        
        let isValidTemp = true;
        let errorsTemp = {
            email: '',
            password: '',
            confirmPassword: ''
        };
     



        setErrors(errorsTemp);
        setIsValid(isValidTemp);
    }

    const handleChange = (event) => {
        switch (event.target.name) {
            case "email":
                setForm(
                    {
                        ...form,
                        email: {
                            value: event.target.value,
                            changed: true
                        }
                    }
                );
                break;
            case "password":
                setForm(
                    {
                        ...form,
                        password: {
                            value: event.target.value,
                            changed: true
                        }
                    }
                );
                break;
        }

        if(!isValid) {
            validate()
        }
    }
    function checkAutority(){
        let wrapper = document.getElementById('error_login_pn');
        if (wrapper){
            wrapper.setAttribute('style', 'opacity: 0');
            setTimeout(() => {
                if (wrapper){
                wrapper.setAttribute('style', 'opacity: 1');}
            }, (300));
        }
        
    }
    const handleFocus = (event) => {
        switch (event.target.name) {
            case "email":
                setForm(
                    {
                        ...form,
                        email: {
                            value: form.email.value,
                            changed: true
                        }
                    }
                );
                break;
            case "password":
                setForm(
                    {
                        ...form,
                        password: {
                            value: form.password.value,
                            changed: true
                        }
                    }
                );
                break;
        }
    }
    
   
    return (
        <form className='login-form-wrapper' onSubmit={handleSubmit}>
            <span className='login-title'> 
                <FormattedMessage id='login_form_title'/>
            </span>
            <div id="wrapper_log_error">

            </div>
            {   
                isLoginError &&
               
                        <div className='login-error-panel' id="error_login_pn">
                        <span className='login-error-panel__title'>
                            <FormattedMessage id='login_form_error_panel_title'/>
                        </span>
                  
                        <span className='login-error-panel__sub-title'>
                            <FormattedMessage id='login_form_error_panel_sub_title'/>
                        </span>
                    </div>     
               
               
            }

            <div className={`text-input`}>
                <input
                    type='email'
                    placeholder={ intl.formatMessage({ id: 'login_form_login_placeholder' })}
                    value={form.email.value}
                    name={"email"}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={validate}
                    className={`${ !isValid && errors.email.length !== 0 && 'error-input'}`}
                />
                {
                    !isValid && errors.email.length !== 0
                    &&
                    <span className={"error-text"}><FormattedMessage id={ errors.email }/></span>
                }
            </div>

            <div className={`text-input`}>
                <input
                    type='password'
                    placeholder={ intl.formatMessage({ id: 'login_form_password_placeholder' })}
                    value={form.password.value}
                    name={"password"}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={validate}
                    className={`${ !isValid && errors.password.length !== 0 && 'error-input'}`}
                />
                {
                    !isValid && errors.password.length !== 0
                    &&
                    <span className={"error-text"}><FormattedMessage id={errors.password}/></span>
                }
            </div>

            <button onClick={()=> checkAutority()}
                className={`green-button ${ !isValid && 'disabled-button' }`}
                
            >
                <FormattedMessage id='login_form_submit_button'/>
            </button>

            <Link 
                to={routes.loginRecovery}
                className='login-form-wrapper__forgot-password-button'
            >
                <FormattedMessage id='login_form_forgot_password' />
            </Link>
            <div style={{ fontSize: '16px',padding:'20px',marginTop:'20px', background: 'white',textAlign: 'center', borderRadius: '15px'}}>
                Ещё нет аккаунта? <Link style={{textDecoration: 'underline', color: '#219653'}}  to={routes.signup}>Зарегистрируйтесь</Link>
            </div>
            {/* <div className='social-networks-login-wrapper'>
                <span>Или войти с помощью</span>
                <div>
                   
                    <span onClick={() => getVk()}>
                        <img src={vkLogo} style={{width: '50px', cursor: 'pointer'}}/>
                    </span>
                </div>
            </div> */}
           
        </form>
    )
}