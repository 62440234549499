import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import store from './store';

import App from './App';
import ErrorBoundry from './ErrorBoundry'

ReactDOM.render(
    <ErrorBoundry>
        <Provider store={store}>
            <App/>
        </Provider>
    </ErrorBoundry>,
document.getElementById('root')
)
;

