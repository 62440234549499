import { useEffect, useState} from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { ToggleListSmallArrowIcon } from "../../../icons"
import { setConsultantsListFilter } from "../../../store/reducers/ConsultantsFilterReducer"
import { getConsultantsList } from "../../../store/reducers/ConsultantsListReducer"

import './styles.sass'

const workloads = [
    'Свободен',
    'Слабо загружен',
    'Сильно загружен',
    'Полностью загружен'
]

// const sortTitles = {
//     'ФИО': 'fio',
//     'Занятость': 'workload',
//     'Рейтинг': 'rating'
// }

export const SearchFilterConsultation = () => {

    const intl = useIntl()
    const dispatch = useDispatch()

    const [isToggleListOpened, setIsToggleListOpened] = useState<boolean>(false)

    const [isToggleActivityListOpened, setIsToggleActivityListOpened] = useState<boolean>(false)
   
    const [form, setForm] = useState({
        rating: '',
        workload: '',
        fio: '',
        sort: 'fio'
    })
    
    
    const handleInputChange = (name: string, value: string) => {
            if (name == 'workload'){
                setForm({...form, workload: value})
            } else {
                setForm({...form, [name]: value})
            }
          
           
       
    }
    useEffect(()=>{
        dispatch(setConsultantsListFilter(form))          
        dispatch( getConsultantsList(0) )
     
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[form])
    return (
        <div
            className="search-filter-consultant-wrapper"
        >

            <div>

                <span>
                    <FormattedMessage id='search_filter_consultation_title'/>
                </span>
                <div
                    className="search-filter-consultant-input"
                  
                >
                    <input 
                        type='text'
                        name='fio'
                        value={form.fio}
                        onChange={ event => handleInputChange(event.target.name, event.target.value)}
                        placeholder={ intl.formatMessage({ id: 'search_filter_consultation_fio_placeholder' })}
                    />
                </div>
                <div
                    className="search-filter-consultant-input"
                    id='search-filter-rating-input'
                    style={{minWidth: '20%'}}
                >
                    <input
                        type='text'
                        name='rating'
                        value={form.rating}
                        onChange={ event => handleInputChange(event.target.name, event.target.value)}
                        placeholder={ intl.formatMessage({ id: 'search_filter_consultation_rating_placeholder' })}
                    />
                </div>

                <div
                    className="search-filter-consultant-input"
                    style={{minWidth: '30%'}}
                    onClick={ () => {
                        if (isToggleActivityListOpened) {
                            setIsToggleActivityListOpened(false)
                        } else {
                            setIsToggleActivityListOpened(true)
                        }
                    }}
                >
                    <input
                        type='text'
                        //disabled={true}
                        name='workload'
                        value={form.workload}
                        readOnly
                        placeholder={ form.workload.length > 0 ? form.workload : intl.formatMessage({ id: 'search_filter_consultation_workload_placeholder' })}
                    />

                    <div
                        className={`search-filter-consultant-input__icon 
                            ${isToggleActivityListOpened ? 'rotated' :''}    
                        `}
                    >
                        <ToggleListSmallArrowIcon />
                    </div>
                </div>

                <div
                    className={`activities-toggle-list
                        ${ isToggleActivityListOpened && 'opened-activities-toggle-list'  }
                    `}
                    style={{marginLeft: (document.getElementById('search-filter-rating-input') ? 27.2 + '%' : '22%') }}
                >   <div  onClick={() => {
                    handleInputChange('workload', '')
                    setIsToggleActivityListOpened(false)
                }}>Все</div>
                    {
                        workloads.map( (elem, index) => {
                           
                            return (
                                <div
                                    key={index}
                                    onClick={() => {
                                        handleInputChange('workload', elem)
                                        setIsToggleActivityListOpened(false)
                                    }}
                                >
                                    {elem}
                                </div>
                            )
                        })
                    }
                </div>

               
            </div>

            <div 
                className="search-filter-consultant-wrapper__buttons-wrapper"
            >

                <div
                    style={{marginRight: '9px'}}
                    onClick={ () => {setForm({
                        rating: '',
                        workload: '',
                        fio: '',
                        sort: 'fio'
                    })
                    dispatch(setConsultantsListFilter({
                        rating: '',
                        workload: '',
                        fio: '',
                        sort: 'fio'
                    }))
                        
                    dispatch( getConsultantsList(0) )
                    }
                }
                    
                >
                    <FormattedMessage id='search_filter_consultation_delete' />
                </div>


            </div>

            <div
                className="search-filter-consultant-wrapper__list-wrapper"
            >
                <span>
                    <FormattedMessage id='search_filter_consultation_option' />
                </span>

                <div
                    className='search-filter-consultant-wrapper__list-wrapper__top-side'
                    onClick={() => {
                        if (isToggleListOpened) {
                            setIsToggleListOpened(false)
                        } else {
                            setIsToggleListOpened(true)
                        }
                    }}
                >
                    <div>
                        <FormattedMessage id={'search_filter_consultation_' + form.sort.replace('Number','') +  '_placeholder'} />
                    </div>
                    <div
                        className={`search-filter-consultant-wrapper__list-wrapper__top-side__icon 
                            ${isToggleListOpened ? 'rotated' :''}    
                        `}
                    >
                        <ToggleListSmallArrowIcon />
                    </div>
                </div>

                
                    <div
                        className={`search-filter-consultant-wrapper__list-wrapper__toggle-list 
                            ${ isToggleListOpened && 'opened-toggle-list' }
                        `}
                    >   
                        {
                            form.sort !== 'ratingNumber'
                            &&
                            <div
                                onClick={() => {
                                    setIsToggleListOpened(false)
                                   
                                    setForm(({...form, sort: 'ratingNumber'}))
                                  
                                  
                                }}
                            >   
                                <FormattedMessage id={'search_filter_consultation_rating_placeholder'} />
                            </div>
                        }
                        {
                            form.sort !== 'workload'
                            &&
                            <div
                                onClick={() => {
                                    setIsToggleListOpened(false)
                                    setForm({...form, sort: 'workload'})
                                 
                                }}
                            >
                                <FormattedMessage id={'search_filter_consultation_workload_placeholder'} />
                            </div>
                        }
                        {
                            form.sort !== 'fio'
                            &&
                            <div
                                onClick={() => {
                                    setIsToggleListOpened(false)
                                    setForm({...form, sort: 'fio'})
                                  
                                    
                                }}
                            >
                                <FormattedMessage id={'search_filter_consultation_fio_placeholder'} />
                            </div>
                        }
                    </div>
                
            </div>
        </div>
    )
}