import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import { setIsChangingEmailTrue, TRY_TO_CHANGE_EMAIL } from "../store/reducers/ChangeEmailReducer";

const asyncChangeEmail = async (data: {email: string, password: string}) => {
    try {
        
        const response = await fetch(
            apiUrl + '/users/update-email',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    email: data.email,
                    password: data.password
                })
            }
        )

        if (response.status === 200) {
            return true
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch (err) {}
}

function* ChangeEmailWorker(action) {
    const data: boolean = yield call(asyncChangeEmail, action.payload)
    if (data) {
        yield put( setIsChangingEmailTrue() )
    }
}

export function* ChangeEmailWatcher() {
    yield takeEvery(TRY_TO_CHANGE_EMAIL, ChangeEmailWorker)
}