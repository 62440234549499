import { useDispatch } from "react-redux";
//import { useNavigate } from 'react-router-dom'
import { routes } from "../../routes";
import "./style.sass";
import { setCurrentConsultation } from "../../store/reducers/CurrentConsultationReducer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export const NotificationsContainer = (props) => {
  const Navigate = useNavigate();
  // const Navigate = useNavigate();
  const dispatch = useDispatch();

  const getConsultation = async (id: string) => {
    const response = await fetch(
      process.env.REACT_APP_API_HOST + "/consultation/" + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      }
    );

    if (response.status === 200) {
      const result = await response.json();

      dispatch(setCurrentConsultation(result));
      if (document.body.clientWidth > 640) {
        window.open(routes.consultationChat, "_blank");
      } else {
        Navigate(routes.consultationChat);
      }
    } else if (response.status === 401) {
      location.assign(routes.login);
    }
  };
  const [notifications, setNotifications] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(0);
  function getNotifications() {
    fetch(
      `${process.env.REACT_APP_API_HOST}/notice?page=${pageNumber}&size=20`,
      {
        headers: { Authorization: "Bearer " + localStorage.token },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (pageNumber == 0) {
          setNotifications(data);
        } else {
          setNotifications(notifications.concat(data));
        }
      });
  }
  function updateNotifications() {
    fetch(`${process.env.REACT_APP_API_HOST}/notice?page=0&size=20`, {
      headers: { Authorization: "Bearer " + localStorage.token },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setNotifications(data);
        setPageNumber(0);
      });
  }
  function readAll() {
    fetch(`${process.env.REACT_APP_API_HOST}/notice/all/read`, {
      headers: { Authorization: "Bearer " + localStorage.token },
      method: "post",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setNotifications(data);
        props.getCountNotice();
      });
    setPageNumber(0);
    document.title = "Социомониторинг";
  }
  const scrollUp = () => {
    const personListWrapper: HTMLElement | null =
      window.document.getElementById("person-list-wrapper");
    if (personListWrapper?.scrollTop) {
      personListWrapper.scrollTop = 0;
    }
  };
  const handleScroll = () => {
    const personListWrapper: HTMLElement | null =
      window.document.getElementById("person-list-wrapper");
    let scrollTop = personListWrapper?.scrollTop || 0;
    let clientHeight = personListWrapper?.clientHeight || 0;
    let scrollheight = personListWrapper?.scrollHeight || 0;

    if (Math.ceil(scrollTop + clientHeight) >= Math.ceil(scrollheight)) {
      setPageNumber(pageNumber + 1);
    }
  };

  function readMessage(id) {
    fetch(`${process.env.REACT_APP_API_HOST}/notice/${id}/read`, {
      headers: { Authorization: "Bearer " + localStorage.token },
      method: "post",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setNotifications(data);
        props.getCountNotice();
      });
    setPageNumber(0);
  }

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);
  useEffect(() => {
    props.getCountNotice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="buttons__notice">
        <div
          className="white-button"
          onClick={() => {
            updateNotifications();
            scrollUp();
          }}
        >
          Обновить уведомления
        </div>
        <div
          className="green-button"
          onClick={() => {
            readAll();
          }}
        >
          Отметить все как прочитанные
        </div>
      </div>

      <div
        className="notifications__messages"
        id="person-list-wrapper"
        onScroll={() => {
          handleScroll();
        }}
      >
        {notifications &&
          notifications.length > 0 &&
          notifications.map((elem, index) => {
            return (
              <div
                className="not_elem"
                key={index}
                onClick={() => {
                  if (elem.consultationId) {
                    getConsultation(elem.consultationId);
                  }
                  if (!elem.read) {
                    readMessage(elem.id);
                  }
                }}
              >
                <span
                  style={
                    elem.read
                      ? { fontWeight: "normal" }
                      : { fontWeight: "bold" }
                  }
                >
                  {elem.text}
                </span>
                <span className="elem__date">
                  {" "}
                  {elem.sendDate
                    .substring(0, 10)
                    .split("-")
                    .reverse()
                    .join(".") +
                    " " +
                    elem.sendDate.substring(11, 19)}
                </span>
              </div>
            );
          })}
        {notifications && notifications.length == 0 && (
          <div className="notifications__none">Нет новых уведомлений</div>
        )}
      </div>
    </div>
  );
};
