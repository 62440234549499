import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { Consultation } from "../interfaces/Consultation";
import { routes } from "../routes";
import store from "../store";
import { SEND_CONSULTATION_MESSAGE, setConsultationMessages } from "../store/reducers/ConsultationChatReducer";

async function asyncSendConsultationMessage(text: string) {
    try {

        let currentConsultation: Consultation | null = null
        
        if (store.getState().currentConsultation !== null) {
            currentConsultation = store.getState().currentConsultation
            const response = await fetch(
                apiUrl + `/consultation/${currentConsultation?.id}/messages/send`,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json;charset=utf-8',
                        'Authorization': 'Bearer ' + localStorage.token
                    },
                    body: JSON.stringify({
                        text: text
                    })
                }
            )
                
            if (response.status === 200) {
                const result = await response.json()
                
                return result
            } else if (response.status === 401) {
                location.assign(routes.login)
            } else {
                return null
            }
        }


    } catch(error) {}
}

function* SendConsultationMessageWorker(action) {

    const data = yield call(asyncSendConsultationMessage, action.payload)

    if (data && data.length > 0) {
        yield put( setConsultationMessages(data) )
    }
}

export function* SendConsultationMessageWatcher() {
    yield takeEvery(SEND_CONSULTATION_MESSAGE, SendConsultationMessageWorker)
}