import { Person, Gender } from "../../interfaces/Person"
import { RateMember } from "../../interfaces/RateMember"

export const NAME_CURRENT_PERSON = 'NAME_CURRENT_PERSON'
export const ROLE_CURRENT_PERSON = 'ROLE_CURRENT_PERSON'
export const GENDER_CURRENT_PERSON = 'GENDER_CURRENT_PERSON'
export const BIRTHDAY_CURRENT_PERSON = 'BIRTHDAY_CURRENT_PERSON'
export const COMMENTARY_CURRENT_PERSON = 'COMMENTARY_CURRENT_PERSON'
export const CLEAN_PERSON = 'CLEAN_PERSON'
export const SET_CURRENT_PERSON = 'SET_CURRENT_PERSON'
export const TRY_TO_DELETE_PERSON = 'TRY_TO_DELETE_PERSON'
export const EDIT_PERSON = 'EDIT_PERSON'

const initialState: RateMember = {
    position: '',
    member: {
        id: "",
        birthday: "",
        name: "",
        patronymic: "",
        surname: "",
        gender: Gender.NONE,
        customName: '',
        role: "",
        canDeleted: true,
    },
    comment: '',
    rate: '',
    //isNew: true,
}

export const CurrentPersonReducer = (state = initialState, action) => {

    switch (action.type) {

        case NAME_CURRENT_PERSON: 
            return {
                ...state,
                member: {
                    ...state.member,
                    name: action.payload,
                    customName: action.payload,
                }
            }

        case ROLE_CURRENT_PERSON:
            return {
                ...state,
                member: {
                    ...state.member,
                    role: action.payload,
                }   
            }

        case GENDER_CURRENT_PERSON: 
            return {
                ...state,
                member: {
                    ...state.member,
                    gender: action.payload,
                }
            }

        case BIRTHDAY_CURRENT_PERSON:
            return {
                ...state,
                member: {
                    ...state.member,
                    birthday: action.payload,
                }
            }

        case COMMENTARY_CURRENT_PERSON:
            return {
                ...state,
                comment: action.payload
            }

        case CLEAN_PERSON:
            return {
                position: '',
                member: {
                    id: "",
                    birthday: "",
                    name: "",
                    patronymic: "",
                    surname: "",
                    gender: '',
                    role: "",
                    canDeleted: true,
                    customName: '',
                },
                comment: '',
                rate: '',
            }

        case SET_CURRENT_PERSON:
            return {
                ...state,
                member: {
                    ...action.payload
                },
            }

        default:
            return state
    }
}

export const setCurrentPersonName = (payload: string) => ({type: NAME_CURRENT_PERSON, payload})
export const setCurrentPersonRole = (payload: string) => ({type: ROLE_CURRENT_PERSON, payload})
export const setCurrentPersonGender = (payload: string) => ({type: GENDER_CURRENT_PERSON, payload})
export const setCurrentPersonBirthday = (payload: string) => ({type: BIRTHDAY_CURRENT_PERSON, payload})
export const setCurrentPersonComment = (payload: string) => ({type: COMMENTARY_CURRENT_PERSON, payload})
export const cleanPersonForm = () => ({type: CLEAN_PERSON})
export const setCurrentPerson = (payload: Person) => ({type: SET_CURRENT_PERSON, payload})

export const deletePerson = (payload: string) => ({type: TRY_TO_DELETE_PERSON, payload})
export const editPerson = (payload: RateMember) => ({type: EDIT_PERSON, payload})