import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MyAccountNavbar } from "../../components/MyAccountComponents/MyAccountNavbar"
import { tryToGetAccountFinances } from "../../store/reducers/AccountFinancesReducer"
import { setAccountNavbarTitle } from "../../store/reducers/AccountNavbarReducer"

import { AccountFinancePanel } from '../../components/MyAccountComponents/AccountFinancePanel/index'

export const FinancesContainer = () => {

    const dispatch = useDispatch()

    const isNavbarShowed = useSelector( (state: any) => (state.navbar.isNavbarShowed))
    const financesList = useSelector( (state: any) => (state.accountFinances))

    useEffect( () => { 
        dispatch( setAccountNavbarTitle(5) )
        dispatch( tryToGetAccountFinances() )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            className={`my-account-wrapper ${isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}
        >
            <MyAccountNavbar/>

            <div
                className="my-account-wrapper__finances-wrapper"
            >
                {
                    financesList
                    &&
                    financesList.length > 0
                    &&
                    financesList.map( (elem, index) => (
                        <div key={index}>
                            <AccountFinancePanel data={elem} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}