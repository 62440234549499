import { useEffect } from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CleanInputCrossIcon } from "../../icons";
import {
  cleanPersonForm,
  setCurrentPerson,
  setCurrentPersonName,
} from "../../store/reducers/CurrentPersonReducer";
import {
  findPersonsByName,
  setPotentialPersonList,
} from "../../store/reducers/FindMeasurementPersonsListReducer";

import "./styles.sass";

export const FindPersonMeasurementInput = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const personList = useSelector((state: any) => state.personMeasurementList);

  const currentPerson = useSelector((state: any) => state.currentPerson);

  const findedByNamePersonList = useSelector(
    (state: any) => state.findMeasurementPersonsList
  );

  const [personName, setPersonName] = useState<string>("");

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const [choosenPerson, setChoosenPerson] = useState<number>(-1);

  const [isInputBlocked, setIsInputBlocked] = useState<boolean>(false);

  useEffect(() => {
    if (currentPerson.member.customName.length === 0) {
      setPersonName("");
      setIsInputBlocked(false);
    } else if (!currentPerson.member.canDeleted) {
      setPersonName(currentPerson.member.customName);
      setIsInputBlocked(true);
    }

    const concernedElement: HTMLElement | null = document.getElementById(
      "find-person-input-wrapper__input-wrapper"
    );

    function handleClickOutside(event) {
      if (concernedElement && !concernedElement.contains(event.target)) {
        setIsModalOpened(false);
      }
    }

    function handleKeyDown(event) {
      if (event.code === "Tab") {
        setIsModalOpened(false);
      } else if (
        event.code === "ArrowDown" &&
        findedByNamePersonList.length > 0 &&
        choosenPerson < findedByNamePersonList.length - 1
      ) {
        setChoosenPerson(choosenPerson + 1);
      } else if (
        event.code === "ArrowUp" &&
        findedByNamePersonList.length > 0 &&
        choosenPerson !== 0
      ) {
        setChoosenPerson(choosenPerson - 1);
      } else if (event.code === "Enter" && findedByNamePersonList.length > 0) {
        setIsModalOpened(false);
        dispatch(setCurrentPerson(findedByNamePersonList[choosenPerson]));
      } else {
        return true;
      }
    }

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personList, choosenPerson, currentPerson]);

  const handlePersonName = (name) => {
    if (name.length > 0) {
      setPersonName(name);
      dispatch(findPersonsByName(name));
      dispatch(setCurrentPersonName(name));
    } else {
      setPersonName("");
    }
  };

  return (
    <div
      className="find-person-input-wrapper"
      id="find-person-input-wrapper__input-wrapper"
    >
      <div
        className={`find-person-input-wrapper__input-wrapper ${
          isModalOpened &&
          personName.length > 0 &&
          findedByNamePersonList.length > 0 &&
          "input-with-opened-modal-panel"
        }`}
      >
        <input
          type="text"
          id="find-person-name"
          autoComplete="off"
          value={personName}
          onFocus={() => {
            if (!isInputBlocked) {
              setIsModalOpened(true);
            }
          }}
          onChange={(event) => {
            if (!isInputBlocked) {
              handlePersonName(event.target.value);
            }
          }}
          placeholder={intl.formatMessage({
            id: "measurement_person_list_page_find_person_input_placeholder",
          })}
        />

        {isInputBlocked && (
          <div
            className="input-clean-delete-cross-icon"
            onClick={() => {
              setIsInputBlocked(false);
              dispatch(cleanPersonForm());
            }}
          >
            <CleanInputCrossIcon />
          </div>
        )}

        {personName.length > 0 && (
          <div
            className={`find-person-input-wrapper__finded-persons-panel ${
              isModalOpened && "find-persons-opened-panel"
            }`}
            id="find-person-input-wrapper__input-wrapper"
          >
            <div>
              {findedByNamePersonList.map((elem, index) => {
                for (let i = 0; i < personList.length; i++) {
                  if (personList[i].member.id == elem.id) {
                    return;
                  }
                }
                return (
                  <span
                    key={index}
                    onClick={() => {
                      dispatch(setCurrentPerson(elem));
                      setIsModalOpened(false);
                      dispatch(setPotentialPersonList([]));
                    }}
                    onMouseEnter={() => {
                      setChoosenPerson(index);
                    }}
                    className={
                      choosenPerson === index ? "choosen-person-panel" : ""
                    }
                  >
                    {elem.customName}
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
