import { useEffect, useState } from 'react';
import './styles.sass';
import { useSelector } from 'react-redux';
import { routes } from '../../routes';
import { useNavigate } from 'react-router-dom';

export const GroupEndInstructionContainer = () =>{
    const navigate = useNavigate()
    
    const [text, setText] = useState('');
    const isNavbarShowed = useSelector(
        (state: any) => state.navbar.isNavbarShowed
      );
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('id');
    const GroupDetails = () =>{
        fetch(process.env.REACT_APP_API_HOST + '/measurement/details/'+ myParam, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.token,
              },
        }).then((response)=>{
            return response.json()
        }).then((data)=> {
            setText(data.instruction.lastStepText
                )
        })
      }
      useEffect(()=>{
        GroupDetails()
         // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
    return(<div className={`instruction-measurement-container ${ isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}>
        <div className='text_format'>
        <h3 className="main-instruction-measurement-container__title-wrapper__title">Поздравляем!</h3>
      
        <div   dangerouslySetInnerHTML={{__html: text}}></div>
       
            </div>
        <div className="bottom_buttons">
            <div className="green-button" onClick={()=>navigate(routes.measurementsList)} >К списку замеров</div>
         
        </div>
    </div>)
}