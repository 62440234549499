import { LOCALES } from "./locales";

export const messages = {
  [LOCALES.RUSSIAN]: {
    // header
    header_registration: "Регистрация",
    header_login: "Вход",
    // signup page
    sign_up_form_title: "Регистрация",
    sign_up_form_email_placeholder: "Ваш e-mail",
    sign_up_form_pass_placeholder: "Пароль",
    sign_up_form_pass_confirm_placeholder: "Подтверждение пароля",
    signup_page_reg_button: "Зарегистрироваться",
    signup_validation_error_title: "Ошибка регистрации",
    signup_validation_error_text: "Пользователь с таким e-mail уже существует",
    signup_text_error_must_field: "Это поле обязательно для заполнения.",
    signup_text_error_incorrect_email: "Введите корректный email.",
    signup_text_error_password_length:
      "Пароль должен быть длиной не менее 6 символов и содержать буквы и цифры",
    signup_text_error_passwords_not_match: "Пароли не совпадают.",
    // signup by soc networks
    signup_networks_title: "Спасибо за регистрацию! ",
    signup_networks_sub_title:
      "Мы используем вашу аватарку из социальной сети:",
    signup_networks_button: "Да, использовать",
    signup_networks_next: "Пропустить",
    //signup confirmation
    signup_confirmation_title: "Подтверждение регистрации",
    signup_confirmation_sub_title:
      "Мы отправили вам на e-mail подтверждение, пройдите по ссылке или введите код",
    signup_confirmation_code_placeholder: "Код из письма",
    signup_confirmation_send_button: "Отправить",
    signup_confirmation_error_title: "Ошибка",
    signup_confirmation_error_text: "Кажется, вы ввели неверный код",
    //login form
    login_form_title: "Вход",
    login_form_login_placeholder: "Логин",
    login_form_password_placeholder: "Пароль",
    login_form_submit_button: "Войти",
    login_form_forgot_password: "Забыл пароль",
    login_form_error_panel_title: "Ошибка аутентификации",
    login_form_error_panel_sub_title:
      "Проверьте правильность ввода логина и пароля",
    //login recovery
    login_recovery_title: "Восстановление пароля",
    login_recovery_first_stage_sub_title:
      "Укажите свой e-mail и мы отправим вам с инструкцией о сбросе пароля",
    login_recovery_first_stage_input_placeholder: "E-mail",
    login_recovery_first_stage_submit_button: "Отправить",
    login_recovery_error_title: "Ошибка",
    login_recovery_error_sub_title: "Пользователь с такой почтой не найден",
    login_recovery_password_placeholder: "Пароль",
    login_recovery_password_confirmation_placeholder: "Подтверждение пароля",
    login_recovery_key_placeholder: "Введите код из письма",
    login_recovery_second_stage_submit_button: "Изменить пароль",
    login_recovery_second_stage_error_title: "Ошибка",
    login_recovery_second_stage_error_text:
      "Кажется, вы ввели неправильный код",
    //navbar
    navbar_notifications: "Уведомления",
    navbar_account_your_cabinet: "Ваш кабинет",
    navbar_account_services: "Услуги",
    navbar_account_finances: "Финансы",
    account_navbar_title_state_tarifs: "Тарифы",
    navbar_account_arbitrage: "Арбитраж",
    navbar_list_measures: "Список замеров",
    navbar_list_partners: "Список партнеров",
    navbar_consultions: "Консультации",
    navbar_consultants: "Консультанты",
    navbar_templates: "Шаблоны",
    navbar_help: "Справка",
    navbar_exit: "Выход",
    // control panel
    control_panel_next: "Далее",
    control_panel_cancel: "Отмена",
    control_panel_end: "Завершить",
    // header titles and subtitles
    header_title_choose_measurement_type: "Выбор типа замера",
    header_title_main: "Проведение замера",
    header_title_subtitle_measurement_type: "Тип замера: ",
    header_title_subtitle_measurement_date: "Дата замера: ",
    // measurement titles
    measurement_page_choose_type: "Выберите, что Вы хотите исследовать:",
    measurement_statistic_group: "Групповая статистика",
    measurement_start_page_option_to_scroll:
      "Листайте колесиком мыши типы замеров влево и вправо",
    measurement_instruction_page_title: "Инструкция перед проведением замера",
    measurement_instruction_page_additional_title:
      "Пожалуйста, внимательно прочтите инструкцию и подтвердите прочтение галочкой.",
    measurement_instruction_page_agreement_panel:
      "Я прочитал инструкцию и понимаю, что требутся делать",
    measurement_instruction_me_to_person:
      "Как измерить мое отношение к другому человеку",
    measurement_instruction_person_to_me:
      "Как измерить отношение другого человека ко мне",
    measurement_statistic_title_person: "Я и группа в целом",
    measurement_instruction_me_to_person_desc_title:
      "Как оценить частоту общения от вас -> к партнеру",
    measurement_instruction_person_to_me_desc_title:
      "Как измерить отношение другого человека ко мне",
    measurement_end_desc_title: "Вы завершили замер. Спасибо!",
    measurement_end_input_title: "Ваши комментарии к замеру*:",
    measurement_end_input_sub_title:
      "Любое яркое и краткое описание почему Вы сделали это измерение, например: “поссорился с тем-то”, “влюбился в того-то”, “резко заболел”, “новая работа”, “меня уволили”, “новая компания друзей”, “сильно грущу”,  “захотелось проверить ситуацию” и т.п.",
    measurement_end_input_placeholder:
      "Введите примечание к замеру (до 140 символов)",

    // measurement instruction what to do (with steps)
    measurement_instruction_page_what_todo_title: "Что предстоит сделать",
    measurement_instruction_page_what_todo_description:
      "Чтобы узнать сколько людей вы носите в своем сознании в данный момент и выполнить измерение отношений с ними Вам потребуется:",
    measurement_instruction_page_what_todo_first_text:
      "Сформировать список людей, следуя нашей инструкции (см. рядом).",
    measurement_instruction_page_what_todo_second_text:
      "После формирования списка выполнить измерение отношений с каждым человеком из списка по специальной шкале.",
    measurement_instruction_page_what_todo_third_text:
      "После выполнения измерений провести самоанализ по нашей инструкции и спланировать новые исследования вашего, скрытого внутри вас, социального пространства.",
    measurement_instruction_page_what_todo_fourth_text:
      "При необходимости обратиться за помощью к консультанту.",

    measurement_instruction_page_big_what_todo_title:
      "Инструкция формирования списка людей",
    measurement_instruction_page_big_what_todo_first:
      "Составьте список  из тех людей, которые будут сами собой всплывать в вашей памяти в момент заполнения списка. Ваша задача успеть записать каждого случайно вспомнившегося человека в данный момент и ни в коем случае не исключать его из списка, если он вам покажется не соответствующим текущему переживанию. Он вспомнился – это значит, что данный человек реально находится в вашем сознании  и продолжает активно влиять на вас! Поток вспоминаемых людей может быть очень большим и протекать в памяти очень быстро! Постарайтесь успеть «поймать» их  и занести всех в список! Рекомендуем вам следующую технику ввода: вспомнился человек – в список; вспомнился следующий  - в список и т.д. Как только процесс  случайного вспоминания людей станет очень медленным или полностью остановится, формирование списка можно прекратить и перейти к процедуре измерения отношений  с каждым из вспомнившихся людей.",

    measurement_instruction_page_big_what_todo_second:
      "Внимание! В список могут попасть люди, которых уже нет в живых, но они почему-то вспомнились вами сейчас. Этих людей вам нужно также, как и живых, включить в список данного замера. Объясняем, почему это важно. Если ваш друг уехал из города, вы же продолжаете думать о нем? Конечно! Вы вспоминаете его слова, его дела и т. д. Ваше общение с ним не прервано и он продолжает влиять на вас.   Так и с умершими людьми мы находимся в постоянном общении незаметно для себя, так как наша память сохраняет в полном объеме опыт общения с ними. При высокой скорости воспоминаний вы можете вносить в список краткие  имена людей, а как закончится поток воспоминаний, вы вернетесь в начало списка и спокойно отредактируете записи имен,  чтобы они были в вашем базе данных в полном объеме.",

    measurement_instruction_page_big_what_todo_third:
      "Заполняйте поле «примечание», это поможет вам усилить в памяти образ человека и более точно провести измерение отношений с ним.",

    measurement_instruction_page_big_what_todo_fourth:
      "Внимание! Бывает так, что вспомнившийся человек вам на столько неприятен, что вы даже не хотите его заносить в список. Преодолейте неприязнь и занесите его в список,   т. к. измерение отношений с ним на следующем шаге может проявить глубокие линии отношений других людей с ним и с вами.",

    // measurement page person list
    measurement_person_list_page_title: "Список людей для замера:",
    measurement_person_list_page_fio_title: "ФИО или прозвище*",
    measurement_person_list_page_statuses_title: "Кто он Вам:",
    measurement_person_list_page_gender_title: "Выберите пол*:",
    measurement_person_list_page_gender_male: "Мужской",
    measurement_person_list_page_gender_female: "Женский",
    measurement_person_list_page_birthdate_placeholder:
      "Введите дату рождения человека (если помните)",
    measurement_person_list_page_commentary_placeholder:
      "Введите коментарий (если помните)",
    measurement_person_list_page_commentary_description:
      "Любое яркое и краткое описание текущей ситуации в состоянии отношений, например: «этот подчиненный  крайне упрямый и тупой по жизни», «этот ученик  очень талантлив».",
    measurement_person_list_page_find_person_input_placeholder:
      "Введите ФИО или прозвище",
    measurement_person_list_page_control_panel_add: "+ Добавить персону",
    measurement_person_list_page_control_panel_edit: "Изменить",
    measurement_person_list_page_find_person_input_prompt:
      "Выберите из списка партнера, если вносили его ранее. Добавляйте нового партнера, если это ранее не внесенный вами человек.",
    measurement_person_list_page_choose_role_placeholder: "Укажите, кто он Вам",
    // measurement_person_list_page modal delete person
    measurement_person_list_page_delete_modal_title: "Удаление персоны",
    measurement_person_list_page_delete_modal_desc:
      "Вы действительно хотите полностью удалить:",
    measurement_person_list_page_delete_modal_cancel: "Отмена",
    measurement_person_list_page_delete_modal_delete: "Удалить",

    // measurement_person_list_page modal edit person
    measurement_person_list_page_edit_modal_title: "Редактирование персоны",
    measurement_person_list_page_edit_modal_fio: "ФИО*",
    measurement_person_list_page_edit_modal_gender: "Пол",
    measurement_person_list_page_edit_modal_birthday: "Дата рождения",
    measurement_person_list_page_edit_modal_role: "Кто он Вам:",
    measurement_person_list_page_edit_modal_save: "Сохранить",
    measurement_person_list_page_edit_modal_cancel: "Отмена",
    // rating
    measurement_rating_title_me_to_person: "Измеряю мое отношение к",
    measurement_rating_title_person_to_me: "Измеряю отношение ко мне",

    measurement_end_title: "Вы завершили замер",
    //confirm
    confirm: "Подтвердить",

    // consultation process user
    consultation_start_page_title: "Запрос на консультирование",
    consultation_start_page_additional_title: "Выбор замеров",
    consultation_start_page_measurement_panel_data: "Дата замера: ",
    consultation_start_page_measurement_panel_type: "Тип: ",
    consultation_start_page_measurement_panel_add_measurement: "Добавить замер",

    consultation_start_page_modal_title: "Список ваших замеров",
    consultation_start_page_modal_add: "Добавить",
    consultation_start_page_modal_cancel: "Отмена",
    consultation_start_page_modal_measurement_panel_type: "Тип замера",
    consultation_start_page_modal_measurement_panel_date: "Дата замера",
    consultation_start_page_modal_measurement_panel_level:
      "Степень выполненности",
    consultation_start_page_modal_main_section_choosen: "Выбрано ",
    consultation_start_page_modal_main_section_measurements: " замера(ов)",

    consultation_info_page_title: "Описание проблемы",
    consultation_info_page_description:
      "Опишите вашу текущую жизненную (во внешнем плане) или психологическую (во внутреннем плане) проблему, которую вам необходимо разрешить с помощью специалиста-консультанта",
    consultation_info_page_question_1:
      "1. Опишите, как именно вы чувствуете свою проблему",
    consultation_info_page_question_2:
      "2. В чем она проявляется для вас? (каков фон настроения, каковочув- ствие, сохранение или нарушеВозможно, что-то другое, опишите проблему своими словами)",
    consultation_info_page_question_3:
      "3. Я вас? (каков фон настроения, каково сочувствие, сохранение или нарушеВозможно, что-то",
    consultation_info_page_input_placeholder: "Введите текст",

    consultation_choose_consultant_page_additional_title: "Выбор консультанта",

    consultation_choose_consultant_panel_rating: "Рейтинг",
    consultation_choose_consultant_panel_reviews: "Отзывы",
    consultation_choose_consultant_panel_choose: "Выбрать",
    consultation_choose_consultant_panel_choosen: "Выбрано",
    consultation_choose_consultant_panel_description: "Описание",
    consultation_choose_consultant_panel_watch_reviews: "Смотреть все отзывы",
    consultation_choose_consultant_panel_count_reviews: "Отзывы ",

    search_filter_consultation_title: "Поиск",
    search_filter_consultation_rating_placeholder: "Рейтинг",
    search_filter_consultation_workload_placeholder: "Занятость",
    search_filter_consultation_fio_placeholder: "ФИО",
    search_filter_consultation_delete: "Сброс",
    search_filter_consultation_find: "Поиск",
    search_filter_consultation_option: "Сортировать по",

    consultation_confirm_page_additional_title: "Подтвержение данных",
    consultation_confirm_page_your_consultant: "Ваш консультант",
    consultation_confirm_page_measurements: "Замеры",
    consultation_confirm_page_problem: "Проблема",

    consultations_list_title: "Ваши запросы",
    consultations_list_navbar_waiting: "Запросы",
    consultations_list_navbar_confirmed: "В работе",
    consultations_list_navbar_closed: "Завершённые",
    consultations_list_navbar_arbitrage: "Арбитраж",
    consultations_list_panel_measurements_title: "Количество замеров",
    consultations_list_panel_description_title: "Дополнительное описание",

    consultations_list_panel_state_waiting: "Запрос на рассмотрении",
    consultations_list_panel_state_confirmed: "Запрос в работе",
    consultations_list_panel_state_closed: "Консультация оказана",
    consultations_list_panel_state_arbitrage: "Запрос в арбитраже",
    consultations_list_panel_state_time: "Истекло время",
    consultation_chat_title_consultation: "Консультация",
    consultation_chat_title_type_waiting: " - согласование условий",
    consultation_chat_title_type_paying: " - оплата",
    consultation_chat_measurements_add: "Добавить замер ",
    consultation_chat_cost_created: "Выставлен счёт на сумму: ",
    consultation_chat_cost_created_rub: " руб",
    consultation_chat_waiting_cost: "Ожидается оплата",

    consultation_chat_measurements_title: "Замеры",

    consultations_list_header: "Чаты и запросы",
    consultations_chat_header: "Консультация",
    consultations_chat_header_end: "Результат",

    consultations_end_title:
      "Сформируйте результаты консультации для консультируемого: ",
    consultation_end_input_title_1: "Проблемы",
    consultation_end_input_title_2: "Анализ",
    consultation_end_input_title_3: "Дополнительное описание",
    consultation_end_bottom_control_panel_save_draft: "Сохранить черновик",
    consultation_end_bottom_control_panel_save_draft_short: "Сохр. черновик",
    consultation_end_bottom_control_panel_send: "Сохранить",
    consultation_end_bottom_control_panel_warning:
      "Внимательно отнеситесь к формированию документа",

    consultation_chat_delete_measurement_title: "Удаление замера",
    consultation_chat_delete_measurement_desc:
      "Вы действительно хотите удалить из консультации замер: ",
    consultation_chat_delete_measurement_button_delete: "Удалить",
    consultation_chat_delete_measurement_button_cancel: "Отмена",

    consultations_chat_control_button_reject: "Отказаться",
    consultations_chat_control_button_show_value_modal: "Выставить счёт",
    consultations_chat_control_button_show_new_value_modal: "Новый счёт",
    consultations_chat_control_button_show_cancel_value: "Отменить счёт",
    consultations_chat_control_button_confirm_consultant: "Подтвердить",
    consultations_chat_control_button_show_end: "Завершить",

    consultations_chat_control_button_reject_cost: "Отменить счет",
    consultations_chat_control_reject_cost_title:
      "Ожидается оплата консультируемым",
    consultations_chat_control_button_pay: "Оплатить",
    consultations_chat_control_button_user_accept: "Согласиться",
    consultations_chat_control_button_arbitrage: "Арбитраж",
    consultations_chat_control_button_review: "Оставить отзыв",

    consultations_chat_result_download: "Скачать консультацию",

    consultations_chat_user_pay_link: "Оплатить счёт",

    consultations_chat_reject_modal_title: "Отказ от консультации",
    consultations_chat_reject_modal_desc:
      "Вы уверены, что хотите отказаться от этой консультации?",
    consultations_chat_reject_modal_reject: "Подтвердить",

    consultations_chat_cancel_value_modal_title: "Отмена счёта",
    consultations_chat_cancel_value_modal_desc:
      "Вы уверены, что хотите отменить счёт?",
    consultations_chat_cancel_value_modal_confirm: "Подтвердить",

    consultations_chat_set_value_modal_title:
      "Введите сумму за консультацию по заявке",
    consultations_chat_set_value_modal_description:
      "После подтверждения - сумму предложенная вами будет видна консультируемому. Внимательно отнеситесь к предлаганнию суммы и предстоящей работе",
    consultations_chat_set_value_modal_comission: "Коммисия",
    consultations_chat_set_value_modal_add_button: "Выставить счёт",
    consultations_chat_set_value_modal_close: "Отмена",

    consultation_review_title: "Консультация - завершение",
    consultation_review_sub_title: "Консультант: ",
    consultation_review_question_1_title: "Вопрос 1 Общее качество",
    consultation_review_question_1:
      "Оцените от 1 до 10 как Вас в целом обслужили во время консультации. Общее отношение консультанта к Вам и так далее.",
    consultation_review_question_2_title:
      "Вопрос 2 Как консультант общался с Вами",
    consultation_review_question_2:
      "Оцените от 1 до 10 как была выстроена коммуникация во время консультации. Доходчив ли был консультант, понятен ли Вам результат консультации и так далее.",
    consultation_review_question_3_title:
      "Вопрос 3 Практическая значимость консультации",
    consultation_review_question_3:
      "Оцените от 1 до 10 сможете ли Вы использовать результаты консультации в Вашей повседневной жизни. Считаете ли Вы консультацию полезной и так далее.",
    consultation_review_text_title:
      "Отзыв о работе консультанта. Напишите что понравилось, а что нет при работе с консультантом",
    consultation_review_text_placeholder: "Введите текст",
    consultation_review_text_end: "Отправить",

    account_header_title_main: "Ваш кабинет",
    account_header_title_additional: "Управление услугами",

    account_navbar_title_main: "Ваши данные",
    account_navbar_title_state_main: "Общее",
    account_navbar_title_state_notifications: "Уведомления",
    account_navbar_title_state_social_networks: "Социальные сети",
    account_navbar_title_state_my_services: "Мои услуги",
    account_navbar_title_state_finances: "Финансы",

    my_account_input_title_fio: "ФИО",
    my_account_input_title_phone: "Номер телефона",
    my_account_input_title_gender: "Пол",
    my_account_input_title_gender_male: "Мужской",
    my_account_input_title_gender_female: "Женский",
    my_account_input_title_birthday: "Дата рождения",
    my_account_input_title_email: "E-mail",
    my_account_input_title_country: "Страна",
    my_account_input_title_city: "Город",

    my_account_modal_change_login_title: "Изменение e-mail",
    my_account_modal_change_login_desc:
      "Введите пароль, чтобы подтвердить изменение логина на: ",
    my_account_modal_change_login_change: "Изменить",
    my_account_modal_change_login_cancel: "Отмена",
    my_account_modal_change_login_success_1: "На Вашу почту: ",
    my_account_modal_change_login_success_2:
      " пришло письмо с подтверждением изменения почты",
    my_account_modal_change_login_success_close: "Закрыть",

    my_account_gender_male: "Мужской",
    my_account_gender_female: "Женский",

    my_account_change_password_title: "Пароль",
    my_account_change_password_button_change: "Изменить",
    my_account_change_password_old_password: "Старый пароль",
    my_account_change_password_new_password: "Новый пароль",
    my_account_change_password_repeat_password: "Повторите пароль",
    my_account_change_password_cancel: "Отмена",
    my_account_change_password_save: "Изменить пароль",
    my_account_change_password_error: "Возникла ошибка",

    my_account_finance_date: "Дата",
    my_account_finance_amount: "Сумма",
    my_account_finance_earn_title: "Поступление средств - ",
    my_account_finance_waste_title: "Списание средств - ",

    my_account_notifications_title: "Выберите из способов уведомлять вас",
    my_account_notifications_add: "Добавить",
    my_account_notifications_modal_title: "Добавить способ уведомления",
    my_account_notifications_modal_cancel: "Отмена",
    my_account_notifications_modal_add: "Добавить уведомление",
    my_account_notifications_types_email: "E-mail уведомления",
    my_account_notifications_types_site: "Веб-интерфейс",
    my_account_notifications_modal_delete_title: "Удаление способа уведомлений",
    my_account_notifications_modal_delete_text:
      "Вы действительно больше не хотите получать уведомления с помощью: ",
    my_account_notifications_modal_delete_button: "Удалить",

    my_account_services_amount: "Стоимость",
    my_account_services_date: "Дата приобретения",
    my_account_services_period: "Срок",
    my_account_services_one_month: " месяц",
    my_account_services_months: " месяца",
    my_account_services_months_2: " месяцев",
    my_account_services_one_year: " год",
    my_account_services_years: " года",
    my_account_services_years_2: " лет",

    my_account_control_panel_cancel: "Отмена",
    my_account_control_panel_add: "Сохранить",
    my_account_change_photo_title: "Изменить фото профиля",

    measurements_list_panel_type: "Тип замера",
    measurements_list_panel_date: "Дата замера",
    measurements_list_search_create: "Создать",
    measurements_list_search_consultation: "Консультация",
    measurements_list_search_type: "Тип замера",
    measurements_list_search_date_before: "от",
    measurements_list_search_date_after: "до",
    measurements_list_search_date: "Дата",
    measurements_list_search_find: "Поиск",
    measurements_list_search_drop: "Сброс фильтра",

    persons_list_panel_gender: "Пол",
    persons_list_panel_birthdate: "Дата рождения",
    persons_list_panel_gender_male: "Мужской",
    persons_list_panel_gender_female: "Женский",
    persons_list_search_name_placeholder: "Поиск",
    persons_list_search_new_person: "Добавить партнера",
    persons_list_search_create_pattern: "Создать шаблон",
    persons_list_search_sort_title: "Сортировать по",
    search_filter_persons_birthdayUp_placeholder:
      "Дата рождения по возрастанию",
    search_filter_persons_fioUp_placeholder: "ФИО по возрастанию",
    search_filter_persons_birthdayDown_placeholder: "Дата рождения по убыванию",
    search_filter_persons_fioDown_placeholder: "ФИО по убыванию",

    consultant_page_go_consultation: "Обратиться за консультацией",
    consultant_page_rating: "Рейтинг",
    consultant_page_desc: "Описание",
    consultant_page_reviews: "Отзывы",
    consultant_page_show_all_reviews: "Смотреть все отзывы",
    consultant_page_hide_all_reviews: "Скрыть отзывы",

    measurement: "Замер",
    measurement_statistic_data_date: "Дата замера: ",
    measurement_statistic_data_type: "Тип замера: ",

    measurement_statistic_title_personal_enviroment:
      "Персональное пространство",
    measurement_statistic_title_conversation_quality: " Качество общения",
    measurement_statistic_title_relationships_types: "Типы отношений",
    measurement_statistic_title_strategy: "Стратегия",
    measurement_statistic_title_group_characteristics:
      "Характеристика Вашего поведения",
    measurement_statistic_title_psycho_processes: "Психические процессы",
    measurement_statistic_title_personal_energy: "Состояние личной энергии",

    measurement_statistic_main_graphic_mode_title_1: "Базовый",
    measurement_statistic_main_graphic_mode_title_2: "Мне легко/трудно",
    measurement_statistic_main_graphic_mode_title_3: "Со мной легко/трудно",
    measurement_statistic_main_graphic_mode_title_4: "Стратегия",
    measurement_statistic_main_graphic_mode_title_5: "Социальная адаптация",
    measurement_statistic_main_graphic_mode_title_6: "Микроанализ",

    measurement_statistic_block_1_title: "Блок 1. Потенциал партнеров группы",
    measurement_statistic_block_1_partner_energy: "Энергия партнеров",
    measurement_statistic_block_1_diversity_partner_energy:
      "Разнородность энергии партнеров",
    measurement_statistic_block_1_personal_energy: "Энергия личная",
    measurement_statistic_block_1_diversity_personal_energy:
      "Разнородность личной энергии",
    measurement_statistic_block_1_personal_strategy: "Персональная стратегия",

    measurement_statistic_block_2_title: "Блок 2. Ваш личностный потенциал.",
    measurement_statistic_block_2_social_intuition: "Социальная интуиция",
    measurement_statistic_block_2_communication_intensivity:
      "Интенсивность коммуникации",
    measurement_statistic_block_2_independent_state: "Состояние независимости",
    measurement_statistic_block_2_business_strategy: "Деловая стратегия",

    measurement_statistic_block_3_title:
      "Блок 3. Структура психических процессов",
    measurement_statistic_block_3_background_process:
      "Процесс фоновой рецепции",
    measurement_statistic_block_3_reflex_process: "Рефлексивные процессы",
    measurement_statistic_block_3_mind_process: "Практическое мышление",
    measurement_statistic_block_3_emotional_reactivity:
      "Эмоциональная реактивность",

    measurement_statistic_person_list_title: "Список персон",
    measurement_statistic_person_list_open: "Открыть",
    measurement_statistic_person_list_close: "Закрыть",

    measurement_statistic_group_header_1: "Предельно трудные",
    measurement_statistic_group_header_2: "Значимы для вас",
    measurement_statistic_group_header_3: "Ведут себя странно",
    measurement_statistic_group_header_4: "Полны неопределенности",
    measurement_statistic_group_header_5: "Интересны вам",
    measurement_statistic_group_header_6: "Нравятся результатив-ностью",
    measurement_statistic_group_header_7: "Вызывают радость",

    measurement_statistic_group_main_title_1:
      "Эти партнеры движутся от вас, трудны в общении и еще чего-то ",
    measurement_statistic_group_main_title_2:
      "Эти партнеры значимы для вас,  трудны в общении и еще чего-то ",
    measurement_statistic_group_main_title_3:
      "Эти партнеры Ведут себя странно,  трудны в общении и еще чего-то ",
    measurement_statistic_group_main_title_4:
      "Эти партнеры полны неопределенности,  трудны в общении и еще чего-то ",
    measurement_statistic_group_main_title_5:
      "Эти партнеры интересны вам,  трудны в общении и еще чего-то ",
    measurement_statistic_group_main_title_6:
      "Эти партнеры нравятся результативностью,  трудны в общении и еще чего-то ",
    measurement_statistic_group_main_title_7:
      "Эти партнеры вызывают радость,  трудны в общении и еще чего-то ",

    measurement_statistic_group_he: "Он",
    measurement_statistic_group_she: "Она",
    measurement_statistic_group_me: "Я",

    measurement_statistic_types_ratio_main_text:
      "Вся совокупность людей окружающих нас делится всего лишь на четыре группы: родственники, наставники, сверстники, иные. Родственники и наставники формируют в человеке понятие нормы, которые апробируются, проверяются и развиваются в группе сверстников и иных людей. Если в сумме доля людей родственников и наставников больше чем сверстников и иных, то вы человек нормы, т.е. стремитесь к упорядоченным и общепринятым правилам регулирования своего поведения и того же ожидаете от поведения партнеров. Если же у вас доля сверстников и иных преобладает над долей родственников и наставников, то вы находитесь в ситуации рискованных способов поведения и жизни и вас ожидают эмоциональные бури и разочарования.",
    measurement_statistic_types_ratio_type_relatives: "Родственные отношения",
    measurement_statistic_types_ratio_type_mentors: "Отношения наставничества",
    measurement_statistic_types_ratio_type_peers: "Сверстники",
    measurement_statistic_types_ratio_type_others: "Иные  (малозначимые)",
    measurement_statistic_types_ratio_type_norming: "Нормирующее влияние",
    measurement_statistic_types_ratio_type_free: "Свободное поведение",

    measurement_statistic_types_ratio_type_additional_text_norming_title:
      "У Вас преобладает: нормирующее влияние",
    measurement_statistic_types_ratio_type_additional_text_free_text:
      "В результатах этого измерения у Вас преобладает фактор свободного (не нормированного) типа отношений при общении с партнерами данной группы. Это означает, что вы находитесь в ситуации определенного социального риска, где проверяются на прочность накопленные ранее вами знания и опыт межличностных отношений. В этом случае могут возникнуть ситуации, обличающие публично ваши поступки, слова и внешний облик, и получающие прямую оценку в открытой форме для всех партнеров данной группы. Это может вызвать у вас внутренний протест и сильные переживания. Будьте осторожны в своих действиях с партнерами данной группы        ",
    measurement_statistic_types_ratio_type_additional_text_norming_text:
      "В результатах этого измерения у Вас преобладает нормирующий фактор в системе отношений с партнерами данной группы. Это означает, что   вы среди этих партнеров - человек нормы, т.е. стремитесь к упорядоченным и общепринятым  правилам регулирования своего поведения и того же ожидаете от поведения ваших партнеров в данной группе        ",
    measurement_statistic_types_ratio_type_additional_text_free_title:
      "У Вас преобладает: свободное поведение",

    measurement_statistic_group_characterisitic_title_iis:
      "Интенсивность коммуникации",
    measurement_statistic_group_characterisitic_title_ipe: "Деловая стратегия",
    measurement_statistic_group_characterisitic_title_psa:
      "Автономность, независимость, физическая сопротивляемость",
    measurement_statistic_group_characterisitic_title_pss:
      "Интегрированность всех сенсорных систем организма (полимодальная интеграция)",
    measurement_statistic_group_characterisitic_subtitle_iis:
      "Интенсивность коммуникации",
    measurement_statistic_group_characterisitic_subtitle_ipe:
      "Деловая стратегия",
    measurement_statistic_group_characterisitic_subtitle_psa:
      "Состояние независимости",
    measurement_statistic_group_characterisitic_subtitle_pss:
      "Социальная интуиция",

    measurement_statistic_psycho_processes_title_l0: "Процесс фоновой рецепции",
    measurement_statistic_psycho_processes_title_l1: "Рефлексивные процессы",
    measurement_statistic_psycho_processes_title_l2: "Практическое мышление",
    measurement_statistic_psycho_processes_title_l3:
      "Эмоциональная реактивность",

    measurement_partner_info_name: "Партнер:",
    measurement_partner_info_type: "Тип отношений с партнером:",
    measurement_partner_info_me_to_partner: "Ваше измерение:",
    measurement_partner_info_partner_to_me: "Измерение от партнера:",
    measurement_partner_info_comment_title: "Ваши комментарии:",
    measurement_partner_info_comment:
      "Вы не оставили комментариев при проведении этого измерения",
    measurement_partner_info_state: "Состояние отношений в позиции: ",

    person_change_title_change: "Редактирование персоны",
    person_change_title_create: "Создание персоны",
    person_change_input_title_fio: "ФИО*",
    person_change_input_title_gender: "Пол",
    person_change_input_title_birthday: "Дата рождения",
    person_change_input_title_role: "Кто он Вам:",

    person_change_cancel: "Отмена",
    person_change_save: "Сохранить",
  },
};
