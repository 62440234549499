import { FormattedMessage } from "react-intl"
import { Operation } from "../../../interfaces/Operation"

import './styles.sass'

export const AccountFinancePanel = (props: {data: Operation}) => {
    const data = props.data

    const value = <>
            <div
                className="account-finance-panel__column"
            >
                <span
                    className="account-finance-panel__column__title"
                >
                    <FormattedMessage id='my_account_finance_date' />
                </span>
                <span
                    className="account-finance-panel__column__data"
                >
                    {
                        data.date.slice(8,10) + '.' + data.date.slice(5,7) + '.' + data.date.slice(0,4)
                    }
                </span>
            </div>

            <div
                className="account-finance-panel__column"
            >
                <span
                    className="account-finance-panel__column__title"
                >
                    <FormattedMessage id='my_account_finance_amount' />
                </span>
                <span
                    className="account-finance-panel__column__data"
                >
                    {
                        data.amount < 0
                        ?
                        data.amount*(-1)
                        :
                        data.amount
                    }
                    ₽
                </span>
            </div></>

    return (
        <div
            className={`account-finance-panel 
                ${ data.amount > 0 ? 'plus' : 'minus' }
            `}
        >
            {
                document.body.clientWidth > 640
                ?
                <>
                    {value}
                </>
                :
                <div>
                    {value}
                </div>
            }

            
            <span
                className="account-finance-panel__name"
            >
                {
                    data.amount < 0
                    ?
                    <FormattedMessage id='my_account_finance_waste_title' />
                    :
                    <FormattedMessage id='my_account_finance_earn_title' />
                }
                {
                    data.operationTypeText
                }

            </span>
        </div>
    )
}