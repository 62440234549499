import { useEffect, useState } from "react"
import './styles.sass'
export const AgreementContainer = () => {
    const [text, setText] = useState('')
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_HOST}/content/get/text/root/analyticalTexts/sitepages/user-agreement`).then((response) =>{
            return response.json()
        }).then((data)=>{
            setText(data.text)
        })
    },[])
    return(
    <>
        <div dangerouslySetInnerHTML={{__html: text}} className="documents__text">

        </div>
    </>)
}