import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import { GET_MEASUREMENT_IMAGES, setMeasurementImages } from "../store/reducers/MeasurementImagesReducer";


async function asyncGetMeasurementImages() {
    try {

        const setCardsId = JSON.parse(localStorage.measurement_type).setCardsId

        const response1 = await fetch(
            apiUrl + '/set-cards/'+setCardsId,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            }
        )

        if (response1.status === 200) {
            const links = await response1.json()
            return links.imagesId
        } else if (response1.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(error) {}
}

function* GetMeasurementImagesWorker() {

    const links = yield call(asyncGetMeasurementImages)
    if (links) {
        yield put(setMeasurementImages(links))
    }
}

export function* GetMeasurementImagesWatcher() {
    yield takeEvery(GET_MEASUREMENT_IMAGES, GetMeasurementImagesWorker)
} 