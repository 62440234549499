import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./styles.sass";

const lines = [0, 1, 2, 3, 4, 5, 6];
const greyLines = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const values = ["+3", "+2", "+1", "0", "-1", "-2", "-3"];
const smallValues = ["3", "2", "1", "0"];

export const PersonalEnergyDiagram = () => {
  const data = useSelector((state: any) => state.measurementStatistic);

  const getPosition = (value: string) => {
    let res: any = {
      height: (280 / 3) * parseFloat(value) + "px",
    };
    if (value[0] === "-") {
      res = {
        height: (280 / 3) * parseFloat(value) * -1 + "px",
        top: "calc(50% - 7px)",
      };
    } else {
      res = {
        ...res,
        bottom: "calc(50% + 7px)",
      };
    }
    return res;
  };

  const [text, setText] = useState<any>({
    rvp: "",
    tpi: "",
  });

  useEffect(() => {
    const getDescText = async () => {
      const response1 = await fetch(
        data.statePersonalEnergy?.tpiRvpDescriptionLink,
        {}
      );
      const response2 = await fetch(
        data.statePersonalEnergy?.tpidRvpdDescriptionLink,
        {}
      );

      if (response1.status === 200) {
        const result1 = await response1.json();

        const result2 = await response2.json();
        setText({
          rvp: result1.description,
          tpi: result2.description,
        });
      }
    };
    if (
      data.statePersonalEnergy?.tpiRvpDescriptionLink &&
      data.statePersonalEnergy?.tpidRvpdDescriptionLink
    ) {
      getDescText();
    }
  }, [data]);

  return (
    <div className="personal-energy-diagram">
      <div className="personal-energy-diagram__diagram-wrapper">
        <h4
          style={{
            textAlign: "center",
            marginBottom: "10px",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Мой потенциал
        </h4>
        <div className="personal-energy-diagram__diagram-wrapper__field">
          <div className="personal-energy-diagram__diagram-wrapper__field__green-lines">
            {lines.map((line) => (
              <div
                key={
                  "personal-energy-diagram__diagram-wrapper__field__line" + line
                }
                style={{
                  margin: "43px 0px 45px 0",
                }}
              />
            ))}
          </div>
          <div className="personal-energy-diagram__diagram-wrapper__field__values">
            {values.map((value) => (
              <div
                key={
                  "personal-energy-diagram__diagram-wrapper__field__value" +
                  value
                }
                style={{
                  margin: "16px 0 50px 0",
                }}
              >
                {value}
              </div>
            ))}
          </div>
          <div
            className="personal-energy-diagram__diagram-wrapper__field__positive-panel"
            style={
              data.calculatedData?.tpi
                ? getPosition(data.calculatedData?.tpi)
                : {}
            }
          >
            <div>{data.calculatedData?.tpi}</div>
          </div>
          <div
            className="personal-energy-diagram__diagram-wrapper__field__negative-panel"
            style={
              data.calculatedData?.rvp
                ? getPosition(data.calculatedData?.rvp)
                : {}
            }
          >
            <div>{data.calculatedData?.rvp}</div>
          </div>
        </div>
        <span dangerouslySetInnerHTML={{ __html: text.rvp }} />
      </div>

      <div className="personal-energy-diagram__diagram-wrapper">
        <h4
          style={{
            textAlign: "center",
            marginBottom: "10px",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Моя напряженность
        </h4>
        <div className="personal-energy-diagram__diagram-wrapper__field">
          <div className="personal-energy-diagram__diagram-wrapper__field__green-lines">
            {lines.slice(0, 4).map((line) => (
              <div
                key={
                  "personal-energy-diagram__diagram-wrapper__field__line" + line
                }
                style={{
                  margin: "43px 0px 138px 0",
                }}
              />
            ))}
          </div>
          <div className="personal-energy-diagram__diagram-wrapper__field__grey-lines">
            {greyLines.map((line) => (
              <div
                key={
                  "personal-energy-diagram__diagram-wrapper__field__line" + line
                }
                style={{
                  marginBottom: line % 4 === 0 ? "72px" : "36px",
                }}
              />
            ))}
          </div>
          <div className="personal-energy-diagram__diagram-wrapper__field__values">
            {smallValues.map((value) => (
              <div
                key={
                  "personal-energy-diagram__diagram-wrapper__field__value" +
                  value
                }
                style={{
                  margin: "16px 0 142px 0",
                }}
              >
                {value}
              </div>
            ))}
          </div>
          <div
            className="personal-energy-diagram__diagram-wrapper__field__positive-panel"
            style={{
              height:
                parseFloat(data.calculatedData?.tpid) == 0
                  ? "3px"
                  : (560 / 3) * parseFloat(data.calculatedData?.tpid) + "px",
              bottom: "45px",
            }}
          >
            <div>
              {data.calculatedData?.tpid != 0 && data.calculatedData?.tpid}
            </div>
          </div>
          <div
            className="personal-energy-diagram__diagram-wrapper__field__negative-panel"
            style={{
              height:
                data.calculatedData?.rvpd == 0
                  ? "3px"
                  : (560 / 3) * data.calculatedData?.rvpd + "px",
              bottom: "45px",
            }}
          >
            <div>
              {data.calculatedData?.rvpd != 0 && data.calculatedData?.rvpd}
            </div>
          </div>
        </div>
        <span dangerouslySetInnerHTML={{ __html: text.tpi }} />
      </div>
    </div>
  );
};
