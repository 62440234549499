
export const TRY_TO_SET_VALUE_CONSULTATION = 'TRY_TO_SET_VALUE_CONSULTATION'
export const SET_IS_SETTING_VALUE_CONSULTATION_SUCCESS = 'SET_IS_SETTING_VALUE_CONSULTATION_SUCCESS'
export const TRY_TO_CANCEL_VALUE_CONSULTATION = 'TRY_TO_CANCEL_VALUE_CONSULTATION'

const initialState = false

export const ConsultationsCostReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_IS_SETTING_VALUE_CONSULTATION_SUCCESS:
            return true

        default:
            return state
    }
}

export const tryToSetValueConsultation = (payload: number) => ({type: TRY_TO_SET_VALUE_CONSULTATION, payload}) 
export const setIsSettingValueConsultationSucces = () => ({type: SET_IS_SETTING_VALUE_CONSULTATION_SUCCESS})
export const tryToCancelValueConsultation = (payload: string) => ({type: TRY_TO_CANCEL_VALUE_CONSULTATION, payload}) 