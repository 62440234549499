

export const AccountNavbarTitlesArray: string[] = [
    'account_navbar_title_state_main',
    'account_navbar_title_state_notifications',
    'account_navbar_title_state_social_networks',
    'account_navbar_title_state_tarifs',
    'account_navbar_title_state_my_services',
    'account_navbar_title_state_finances',
]

export const SET_ACCOUNT_NAVBAR_TITLE = 'SET_ACCOUNT_NAVBAR_TITLE'

const initialState: string = AccountNavbarTitlesArray[0]

export const AccountNavbarReducer = ( state = initialState, action ) => {

    switch (action.type) {

        case SET_ACCOUNT_NAVBAR_TITLE:
            return AccountNavbarTitlesArray[action.payload]

        default: 
            return state
    }

}

export const setAccountNavbarTitle = (payload: number) => ({type: SET_ACCOUNT_NAVBAR_TITLE, payload})