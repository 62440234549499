import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { MyAccountNavbar } from "../../components/MyAccountComponents/MyAccountNavbar"
import { GreenPlusIcon, WarningYellowIcon } from "../../icons"
import { NotificationsSettings } from "../../interfaces/NotificationsSettings"
import { setAccountNavbarTitle } from "../../store/reducers/AccountNavbarReducer"
import { closeModal, showAddNotificationSettingModal } from "../../store/reducers/ModalWindowsReducer"
import { tryToAddNewNotificationsSettings, tryToDeleteNotificationsSettings, tryToGetNotificationsSettings } from "../../store/reducers/NotificationsSettingsReducer"
import { DeleteCrossIcon, EditPencilIcon } from '../../icons'

const notificationsTitlesList = {
    EMAIL: <FormattedMessage id='my_account_notifications_types_email'/>,
    SITE: <FormattedMessage id='my_account_notifications_types_site'/>
}

export const NotificationsContainer = () => {
    
    const dispatch = useDispatch()

    const isNavbarShowed = useSelector( (state: any) => (state.navbar.isNavbarShowed))
    const notificationsSettings: NotificationsSettings | null = useSelector( (state: any) => (state.notificationsSettings) )
    
    const isModalShowed: boolean = useSelector( (state: any) => (state.modals)).modal === 'NOTIFICATION_SETTING_MODAL'

    const [modalType, setModalType] = useState<string>('')
    const [choosenModalType, setChoosenModalType] = useState<string>('')
    const [choosenType, setChoosenType] = useState<string>('')

    const [currentChoosenAdditionalNotificationType, setCurrentChoosenAdditionalNotificationType] = useState({
        type: '',
        title: ''
    })
    const [chooseNotification, setChoosenNotifications] = useState(false)
    useEffect(()=>{
        if (notificationsSettings && notificationsSettings.notificationChannels){
            notificationsSettings.notificationChannels.forEach((element) => {
                if (element.use == false){
                    setChoosenNotifications(true)
                    return
                } 
                
                     
                
            });
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[notificationsSettings])
 
    useEffect( () => { 
        dispatch( setAccountNavbarTitle(1) )
        dispatch( tryToGetNotificationsSettings() )

        const clickHandle = (event) => {
            if (event.target.className !== 'my-account-wrapper__notifications-wrapper__notifications-list__panel') {
                setChoosenType('')
            } 
        }

        document.addEventListener('click', clickHandle)

        return () => document.removeEventListener('click', clickHandle)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            className={`my-account-wrapper ${isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}
        >
            <div
                className="my-account-wrapper__inside-wrapper"
            >
            <MyAccountNavbar/>
            
            <div
                className="my-account-wrapper__notifications-wrapper"
            >
                <span
                    className="my-account-wrapper__notifications-wrapper__title"
                >
                    <FormattedMessage id='my_account_notifications_title'/>
                </span>

                <div
                    className="my-account-wrapper__notifications-wrapper__notifications-list"
                >
                    <div style={!chooseNotification ? {opacity: '0.4'} : {opacity: '1'}}
                        className="my-account-wrapper__notifications-wrapper__notifications-list__button"
                        onClick={() => {
                            if (chooseNotification){
                                
                                setModalType('ADD')
                                dispatch( showAddNotificationSettingModal() )
                            }
                            
                            
                        }}
                    >
                        <GreenPlusIcon/>
                        <span>
                            <FormattedMessage id='my_account_notifications_add' />
                        </span>
                    </div>

                    {
                        notificationsSettings
                        && 
                        notificationsSettings.notificationChannels
                        && 
                        notificationsSettings.notificationChannels.length > 0
                        &&
                        notificationsSettings.notificationChannels.map( (elem, index) => {
                           
                            if (elem.use) {
                                return (
                                    <div
                                        className="my-account-wrapper__notifications-wrapper__notifications-list__panel"
                                        key={index}
                                        onClick={() => {
                                            if (elem.type != 'SITE'){
                                                setChoosenType(elem.type)
                                            }
                                            
                                        }}
                                        style={{
                                            background: ((elem.type === choosenType) ? '#211E1E' : '#FFFFFF'),
                                            color: (elem.type === choosenType) ? '#FFFFFF' : '#211E1E'
                                        }}
                                    >
                                        {
                                            notificationsTitlesList[elem.type]
                                        }
                                        {
                                            (elem.type === choosenType && elem.type != 'SITE')
                                            &&
                                            <>
                                                <div
                                                    className="my-account-wrapper__notifications-wrapper__notifications-list__panel__edit-wrapper"
                                                >
                                                    <EditPencilIcon/>
                                                </div>
                                                <div
                                                    className="my-account-wrapper__notifications-wrapper__notifications-list__panel__delete-wrapper"
                                                    onClick={(event) => {
                                                        if (
                                                            (event.target as Element).className === 'my-account-wrapper__notifications-wrapper__notifications-list__panel__delete-wrapper' 
                                                            ||
                                                            (event.target as any).className.baseVal
                                                            &&
                                                            (event.target as any).className.baseVal === 'delete-icon'
                                                        ) {
                                                            dispatch(showAddNotificationSettingModal())
                                                            setModalType('DELETE')
                                                            setChoosenModalType(elem.type)
                                                            setChoosenType(elem.type)
                                                        }

                                                    }}
                                                >
                                                    <DeleteCrossIcon/>             
                                                </div>
                                            </>
                                        }
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                
                {
                    isModalShowed
                    &&
                    (
                        modalType === 'ADD'
                        ?
                        <div
                            className="my-account-wrapper__notifications-wrapper__modal"
                        >
                            <span
                                className="my-account-wrapper__notifications-wrapper__modal__title"
                            >
                                <FormattedMessage id='my_account_notifications_modal_title'/>
                            </span>
                            {           notificationsSettings
                                        && 
                                        notificationsSettings.notificationChannels
                                        && 
                                        notificationsSettings.notificationChannels.length > 0 ?
                            <div style={{flexDirection: 'column'}}
                                className="my-account-wrapper__notifications-wrapper__modal__list"
                            >
                                <span style={{paddingBottom: '10px'}}>
                                    {
                                        currentChoosenAdditionalNotificationType.title
                                    }
                                </span>
                                <div style={{marginTop: '0'}}>
                                    {
                                        notificationsSettings
                                        && 
                                        notificationsSettings.notificationChannels
                                        && 
                                        notificationsSettings.notificationChannels.length > 0
                                        &&
                                        notificationsSettings.notificationChannels.map( (elem, index) => {
                                            if (!elem.use) {
                                                return (
                                                    <div
                                                        className="my-account-wrapper__notifications-wrapper__modal__list__panel"
                                                        key={index}
                                                        onClick={ () => setCurrentChoosenAdditionalNotificationType({title: notificationsTitlesList[elem.type], type: elem.type})}
                                                    >
                                                        {
                                                            notificationsTitlesList[elem.type]
                                                        }
                                                    </div>
                                                )
                                            }
                                        })            
                                    }
                                </div>
                            </div>
                            : <div>
                                Все возможные способы уведомлений добавлены
                            </div>
                            }


                            <div
                                className="my-account-wrapper__notifications-wrapper__modal__buttons"
                            >
                                <div
                                    className="white-button"
                                    style={{marginTop: '0'}}
                                    onClick={() => {
                                        setCurrentChoosenAdditionalNotificationType({type: '', title: ''})
                                        dispatch( closeModal() )
                                    }}
                                >
                                    <FormattedMessage id='my_account_notifications_modal_cancel'/>
                                </div>
                                <div
                                style={{marginTop: '0'}}
                                    className={`green-button 
                                        ${ currentChoosenAdditionalNotificationType.type.length === 0 && 'disabled-button' }
                                    `}  
                                    onClick={ () => {
                                        dispatch( tryToAddNewNotificationsSettings(currentChoosenAdditionalNotificationType.type) )
                                        dispatch( closeModal() )
                                    }}
                                >
                                    <FormattedMessage id='my_account_notifications_modal_add' /> 
                                </div>  
                            </div>
                        </div>
                        :
                        <div
                            className="my-account-wrapper__notifications-wrapper__modal"
                        >
                            <span
                                className="my-account-wrapper__notifications-wrapper__modal__title"
                            >
                                <FormattedMessage id='my_account_notifications_modal_delete_title'/>
                            </span>
                            
                            <div>
                                <div>
                                    <WarningYellowIcon/>
                                </div>
                                <span
                                    className="my-account-wrapper__notifications-wrapper__modal__delete-text"
                                >
                                    <FormattedMessage id='my_account_notifications_modal_delete_text' />
                                    {
                                        notificationsTitlesList[choosenModalType]
                                    }
                                </span>
                            </div>

                            <div
                                className="my-account-wrapper__notifications-wrapper__modal__buttons"
                            >
                                <div
                                    className="white-button"
                                    onClick={() => {
                                        setChoosenModalType('')
                                        setChoosenType('')
                                        dispatch( closeModal() )
                                    }}
                                >
                                    <FormattedMessage id='my_account_notifications_modal_cancel'/>
                                </div>
                                <div
                                    className={`delete-button`}  
                                    onClick={ () => {
                                        dispatch( tryToDeleteNotificationsSettings(choosenModalType) )
                                        setChoosenModalType('')
                                        setChoosenType('')
                                        dispatch( closeModal() )
                                    }}
                                >
                                    <FormattedMessage id='my_account_notifications_modal_delete_button' /> 
                                </div>  
                            </div>
                        </div>
                    )
                    
                }
            </div>
            </div>
        </div>
    )
}