export const TRY_TO_LOGIN = 'TRY_TO_LOGIN'
export const LOGIN = 'LOGIN'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const EXIT = 'EXIT'

export interface LoginFormInterface {
    username: string;
    password: string;
}

const initialState = {
    token: localStorage.token,
    error: false,
    userData: {
        username: '',
        password: ''
    }
}

export const LoginReducer = (state = initialState, action: any) => {

    switch (action.type) {

        case LOGIN:
            
            localStorage.setItem('token', action.payload.token)

            return {
                token: action.payload.token,
                error: false,
                userData: action.payload.userData    
            }

        case LOGIN_FAILED:
            return {
                ...state,
                error: true
            }

        case EXIT: 
            localStorage.setItem('token', '')

            return {
                token: '',
                error: false,
                userData: {
                    username: '',
                    password: ''
                }
            }

        default: 
            return state
    }
}

export const login = (payload: {token: string; userData: LoginFormInterface}) => ({type: LOGIN, payload})
export const loginFailed = () => ({type: LOGIN_FAILED})

export const tryToLogin = (payload: LoginFormInterface)  => ({type: TRY_TO_LOGIN, payload})

export const exit = () => ({type: EXIT})