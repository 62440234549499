import { NotificationsSettings } from '../../interfaces/NotificationsSettings'

export const TRY_TO_GET_NOTIFICATIONS_SETTINGS = 'TRY_TO_GET_NOTIFICATIONS_SETTINGS'
export const SET_NOTIFICATIONS_SETTINGS = 'SET_NOTIFICATIONS_SETTINGS'
export const TRY_TO_ADD_NEW_NOTIFICATIONS_SETTINGS = 'TRY_TO_ADD_NEW_NOTIFICATIONS_SETTINGS'
export const TRY_TO_DELETE_NOTIFICATIONS_SETTINGS = 'TRY_TO_DELETE_NOTIFICATIONS_SETTINGS'

const initialState: NotificationsSettings | null = null

export const NotificationsSettingsReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_NOTIFICATIONS_SETTINGS:
            return action.payload

        default:
            return state
    }
}

export const tryToGetNotificationsSettings = () => ({type: TRY_TO_GET_NOTIFICATIONS_SETTINGS})
export const setNotificationsSettings = (payload: NotificationsSettings) => ({type: SET_NOTIFICATIONS_SETTINGS, payload})
export const tryToAddNewNotificationsSettings = (payload: string) => ({type: TRY_TO_ADD_NEW_NOTIFICATIONS_SETTINGS, payload})
export const tryToDeleteNotificationsSettings = (payload: string) => ({type: TRY_TO_DELETE_NOTIFICATIONS_SETTINGS, payload})