import { useSelector, useDispatch } from 'react-redux'
//import './styles.sass'

import { StepBar } from "../../components/StepBar"
import { BottomControlPanel } from '../../components/BottomControlPanel/index'
import { routes } from '../../routes'

import { nextStepBar } from "../../store/reducers/StepBarReducer"
import { changeHeaderTitle } from "../../store/reducers/HeaderTitleReducer"

import { FormattedMessage, useIntl } from "react-intl"
import { useEffect } from "react"

//import store from '../../store'
//import { tryToAddMeasurementToConsultation } from '../../store/reducers/AddMeasurementToConsultationReducer'
import { asyncUpdateMeasurement } from '../../store/reducers/MeasurementReducer'
import { useState } from 'react'

export const MeasurementEndContainer = () => {

    const intl = useIntl()

    //const instructionText = useSelector( (state: any) => (state.measurementType.finalStepContent))

    const dispatch = useDispatch()

    const isNavbarShowed = useSelector( (state: any) => (state.navbar.isNavbarShowed))

    //const isMeasurementCreatingForConsultation = useSelector( (state: any) => (state.creatingMeasurementState))
    const [commentRequire, setCommentRequire] = useState(false)
    const [comment, setComment] = useState<string>('')

    useEffect( () => {
        dispatch(
            changeHeaderTitle({
                currentHeaderTitle:'', 
                currentHeaderAdditionalTitle: ''
            })
        )
        setTimeout( () => 
            dispatch(asyncUpdateMeasurement('READ_FINAL_INSTRUCTION'))
        , 100)
        dispatch(nextStepBar(7))
        history.pushState(null, document.title);
        window.addEventListener('popstate', function () {
            history.pushState(null, document.title);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigateBack = () => {

    }

    const navigateNext = () => {
        //let measurement = JSON.parse(localStorage.measurement)
        // if (isMeasurementCreatingForConsultation) {
        //     dispatch( tryToAddMeasurementToConsultation(measurement.id) )
        // }
        
        
       // dispatch( endMeasurement(comment) )
     if (comment.length >0){
        localStorage.setItem('comm', comment);
  
        dispatch(asyncUpdateMeasurement('COMPLETED'))
        localStorage.setItem('measurement', '')
        localStorage.setItem('measurement_type', '')
        localStorage.setItem('measurementImages', '')
        localStorage.setItem('personList', '')
       
     }
      else{
          setCommentRequire(true)
      }
    }

    return (
        <>
            <div className={`instruction-measurement-container ${ isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}>

                <div className="instruction-measurement-container__title-wrapper">
                    <div className="instruction-measurement-container__title-wrapper__sub-wrapper">
                        <span className="instruction-measurement-container__title-wrapper__title">
                            <FormattedMessage id='measurement_end_title'/>
                        </span>

                        <span className="instruction-measurement-container__title-wrapper__additional-title">
                            <FormattedMessage id='header_title_subtitle_measurement_type'/>
                            {
                                JSON.parse(localStorage.measurementTypesN).name
                            }
                        </span>
                    </div>

                    
                    {
                        document.body.clientWidth > 640
                        &&
                        <StepBar steps={8}/>
                    }
                </div>

                <div
                    className='measurement-end-commentary-wrapper'
                >
                    <span
                        className='title'
                    >
                        <FormattedMessage id='measurement_end_input_title' />
                    </span>
                    <div   style={commentRequire ?{border: '1px solid red'}:{border: '1px solid #E6E6E8'}}
                        className='input'
                    >
                        <textarea 
                          
                            value={comment}
                            maxLength={140}
                            onChange={ event => {setComment(event.target.value)
                            setCommentRequire(false)
                            }}
                            placeholder={intl.formatMessage({id: 'measurement_end_input_placeholder'})}
                        />
                    </div>
                    <span
                        className='sub-title'
                    >
                        <FormattedMessage id='measurement_end_input_sub_title' />
                    </span>
                </div>

                <div className='large-instruction-panel'>

                    <span>
                        <FormattedMessage id='measurement_end_desc_title'/>
                    </span>

                    <div dangerouslySetInnerHTML={{__html: JSON.parse(localStorage.measurementTypesN).finalStepContent || ''}} />


                </div>

                
                
            </div>

            <BottomControlPanel 
                nextPage={ ''} 
                previousPage={routes.main}
                backFunc={navigateBack}
                nextFunc={navigateNext}
                isNextButtonDisabled={false}
            />
        </>
    )
}