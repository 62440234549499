import { call, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import store from "../store";
import { END_MEASUREMENT } from "../store/reducers/MeasurementReducer";

const asyncEndMeasurement = async (comment: string) => {
    try {
        const meas = store.getState().measurement

        const response = await fetch(
            apiUrl + '/measurement',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    ...meas,
                    comment: comment
                })
            }
        )
        if (response.status === 200) {
            return true
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(error) {}
}

function* EndMeasurementWorker(action) {
    const data: boolean = yield call(asyncEndMeasurement, action.payload)

    if (data) {
        location.assign(routes.measurementsList)
    }
}

export function* EndMeasurementWatcher() {
    yield takeEvery(END_MEASUREMENT, EndMeasurementWorker)
}