import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useDispatch, useSelector } from 'react-redux'
import { MyAccountNavbar } from '../../components/MyAccountComponents/MyAccountNavbar'
import store from '../../store'
import { setNewSocialNetworks, tryToGetAccountMainData } from '../../store/reducers/AccountMainDataReducer'
//import { User } from '../../interfaces/User'
import { setAccountNavbarTitle } from '../../store/reducers/AccountNavbarReducer'
import './styles.sass'

export const SocialNetworksContainer = () => {

    const dispatch = useDispatch()

    const isNavbarShowed = useSelector( (state: any) => (state.navbar.isNavbarShowed))

    //const accountData: any = useSelector( (state: any) => (state.accountMainData))

    const [form, setForm] = useState<any>({
        VK: '',
        INSTAGRAM: '',
        FACEBOOK: ''
    })
    const [isChanged, setIsChanged] = useState<boolean>(false)
    const [isCanceled, setIsCanceled] = useState<boolean>(false)

    useEffect( () => {
         
        dispatch( tryToGetAccountMainData() )
        
        let oldValues = {}
        setTimeout( () => {
            let account = store.getState().accountMainData
            if (account && account.id && account.socialInformation) {
                account.socialInformation.filter( (elem) => {
                    oldValues = {...oldValues, [elem.socialNetwork]: elem.name}
                })
                setForm(oldValues)
                setIsChanged(false)
                setIsCanceled(false)
            }
        }, 100)

        dispatch( setAccountNavbarTitle(2) )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCanceled])

    return (
        <div
            className={`my-account-wrapper ${isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}
        >
            <MyAccountNavbar/>

            <div
                className='my-account-wrapper__social-networks'
            >
                <span>
                   Instagram
                </span>
                <div
                    className='text-input'
                >
                    <input 
                        type='text'
                        value={form.INSTAGRAM}
                        name='INSTAGRAM'
                        onChange={(event) => {
                            setIsChanged(true)
                            setForm({...form, [event.target.name]: event.target.value})
                        }}
                    />
                </div>
                <span>
                   Facebook
                </span>
                <div
                    className='text-input'
                >
                    <input 
                        type='text'
                        value={form.FACEBOOK}
                        name='FACEBOOK'
                        onChange={(event) => {
                            setIsChanged(true)
                            setForm({...form, [event.target.name]: event.target.value})
                        }}
                    />
                </div>
                <span>
                   Vkontakte
                </span>
                <div
                    className='text-input'
                >
                    <input 
                        type='text'
                        value={form.VK}
                        name='VK'
                        onChange={(event) => {
                            setIsChanged(true)
                            setForm({...form, [event.target.name]: event.target.value})
                        }}
                    />
                </div>
            </div>

            <div
                className='my-account-wrapper__bottom-control-panel'
            >
                <div
                    className='my-account-wrapper__bottom-control-panel__buttons'
                >
                    <div
                        className={`white-button`}
                        onClick={() => setIsCanceled(true)}
                    >
                        <FormattedMessage id='my_account_control_panel_cancel'/>
                    </div>

                    <div
                        className={`green-button 
                            ${ !isChanged && 'disabled-button' }
                        `}
                        onClick={ () => {
                            setIsChanged(false)
                            dispatch( setNewSocialNetworks(form) )
                        }}
                    >
                        <FormattedMessage id='my_account_control_panel_add' />
                    </div>
                </div>
            </div>
        </div>
    )
}