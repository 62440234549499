export const TRY_TO_GET_MEASUREMENT_STATISTIC = 'TRY_TO_GET_MEASUREMENT_STATISTIC'
export const SET_MEASUREMENT_STATISTIC = 'SET_MEASUREMENT_STATISTIC'

const initialState: any = {
    personRates: [],
    groups: [],
    groupCharacteristic: {
        iis: '',
        iisDescription: '',
        ipe: '',
        ipeDescription: '',
        psa: '',
        psaDescription: '',
        pss: '',
        pssDescription: '',
    },
    mentalProcess: {
        l0: '0.0',
        l1: '0.0',
        l2: '0.0',
        l3: '0.0',
    }
}

export const MeasurementStatisticReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_MEASUREMENT_STATISTIC:
            return action.payload

        default:
            return state
    }
}

export const tryToGetMeasurementStatistic = (payload: string) => ({type: TRY_TO_GET_MEASUREMENT_STATISTIC, payload})
export const setMeasurementStatistic = (payload: string) => ({type: SET_MEASUREMENT_STATISTIC, payload})