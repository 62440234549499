import { call, put, takeEvery } from "@redux-saga/core/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import { setCurrentMeasurementStatisticPerson } from "../store/reducers/CurrentMeasurementStatisticPersonReducer";
import {setMeasurementStatistic, TRY_TO_GET_MEASUREMENT_STATISTIC} from "../store/reducers/MeasurementStatisticReducer";

const asyncGetMeasurementStatistic = async (id:string) => {
    try {
        const response = await fetch(
            apiUrl + `/measurement/${id}/statistic`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            }
        )

        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
            return null
        } else if (response.status === 400) {
            location.assign(routes.GroupEndInstruction + '?id=' + id)
            return null}
        else {
            return null
        }

    } catch(error) {}
}

function* GetMeasurementStatisticWorker(action) {
    const data = yield call(asyncGetMeasurementStatistic, action.payload)

    if (data) {
        yield put( setMeasurementStatistic(data) )
        yield put( setCurrentMeasurementStatisticPerson(data.personRates[1]) )
    }
}

export function* GetMeasurementStatisticWatcher() {
    yield takeEvery(TRY_TO_GET_MEASUREMENT_STATISTIC, GetMeasurementStatisticWorker)
}