export const NEXT_STEP_BAR = 'NEXT_STEP_BAR'
export const BACK_STEP_BAR = 'BACK_STEP_BAR'

interface StepAction {
    type: string;
    payload: number;
}

const initialState = {
    currentStep:<number> 0
}

export const StepBarReducer = (state = initialState, action: StepAction) => {

    switch (action.type) {

        case NEXT_STEP_BAR:
            return {
                currentStep: action.payload
            }


        default:
            return state
    }
}

export const nextStepBar = (payload: number) => ({type: NEXT_STEP_BAR, payload})