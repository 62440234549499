import { call, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import store from "../store";
import { CLOSE_CONSULTATION } from "../store/reducers/CurrentConsultationReducer";

const asyncCloseConsultation = async (form: any) => {
  try {
    const id = store.getState().currentConsultation.id;
    const response = await fetch(apiUrl + "/consultation/answer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
      body: JSON.stringify({
        id: id,
        analysis: form?.analysis,
        description: form.description,
        problem: form.problem,
      }),
    });

    if (response.status === 200) {
      return true;
    } else if (response.status === 401) {
      location.assign(routes.login);
    } else {
      return null;
    }
  } catch (err) {}
};

function* CloseConsultationsWorker(action) {
  const data: boolean = yield call(asyncCloseConsultation, action.payload);

  if (data) {
    location.assign(routes.consultationChat);
  }
}

export function* CloseConsultationsWatcher() {
  yield takeEvery(CLOSE_CONSULTATION, CloseConsultationsWorker);
}
