import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { TypeDesc } from "../TypesRationDiagram"

import './styles.sass'

const lines = [0,1,2,3,4,5]

const percentages = [100,80,60,40,20,0]

const colors = {
    l0: '#FFDF00',
    l1: '#4811AE',
    l2: '#00B74A',
    l3: '#FF3100',
}

const diagramHeight = 642

export const PsychicalProcessDiagram = () => {

    const measurementStatistic = useSelector( (state: any) => (state.measurementStatistic)).mentalProcess

    const [text, setText] = useState<any>({
        id: '',
        description: '',
    })

    useEffect( () => {
        if (measurementStatistic.descriptionLink){
        const getDescText = async () => {
            
            const response = await fetch(measurementStatistic.descriptionLink || '',{
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            })
            if (response.status === 200) {
                const result = await response.json()
                setText(result)
            }
        }
        getDescText()
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measurementStatistic.descriptionLink])

    return (
        <div
            className="psychical-process-diagram"
        >
            <div
                className="psychical-process-diagram__diagram"
                style={{
                    height: diagramHeight+'px',
                }}
            >

                <div
                    className="psychical-process-diagram__diagram__lines"
                >
                    {
                        lines.map((line) => <div key={"psychical-process-diagram__diagram__line"+line} />)
                    }
                </div>

                <div
                    className="psychical-process-diagram__diagram__percentages"
                >
                    {
                        percentages.map((percentage) => <div key={"psychical-process-diagram__diagram__percentage"+percentage}>{percentage}</div>)
                    }
                </div>

                <div
                    className="psychical-process-diagram__diagram__columns"
                >
                    <div 
                        style={{
                            background: colors.l0,
                            maxHeight: measurementStatistic.l0 == '0' ? '2px' : (diagramHeight - 92)*parseFloat(measurementStatistic.l0)/100+'px',
                        }}
                    />
                    <div 
                        style={{
                            background: colors.l1,
                            maxHeight: measurementStatistic.l1 == '0' ? '2px' : (diagramHeight - 92)*parseFloat(measurementStatistic.l1)/100+'px',
                        }}
                    />
                    <div 
                        style={{
                            background: colors.l2,
                            maxHeight: measurementStatistic.l2 == '0' ? '2px' :(diagramHeight - 92)*parseFloat(measurementStatistic.l2)/100+'px',
                        }}
                    />
                    <div 
                        style={{
                            background: colors.l3,
                            maxHeight: measurementStatistic.l3 == '0' ? '2px' : (diagramHeight - 92)*parseFloat(measurementStatistic.l3)/100+'px',
                        }}
                    />
                </div>
            </div>

            <div
                className="types-ratio-diagram__header"
            >
                <div className="types-ratio-diagram__header__column">
                    <TypeDesc
                        textId={'measurement_statistic_psycho_processes_title_l0'}
                        percentage={measurementStatistic.l0}
                        backgroundColor={colors.l0}
                    />
                    <TypeDesc 
                        textId={'measurement_statistic_psycho_processes_title_l1'}
                        percentage={measurementStatistic.l1}
                        backgroundColor={colors.l1}
                    />
                </div>
                <div className="types-ratio-diagram__header__column">
                    <TypeDesc 
                        textId={'measurement_statistic_psycho_processes_title_l2'}
                        percentage={measurementStatistic.l2}
                        backgroundColor={colors.l2}
                    />
                    <TypeDesc 
                        textId={'measurement_statistic_psycho_processes_title_l3'}
                        percentage={measurementStatistic.l3}
                        backgroundColor={colors.l3}
                    />
                </div>
            </div>

            <div 
                className='psychical-process-diagram__decription' 
            >
                <span dangerouslySetInnerHTML={{__html: text.id}} />
                <div dangerouslySetInnerHTML={{__html: text.description}} />
            </div>
            
        </div>
    )
}