
import '../containers/Thanks/index.sass';
import logo from '../containers/Thanks/logo.svg'
import { Link } from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
// or
import { routes } from '../routes'
import {useEffect, useState} from 'react';
//import {apiUrl} from '../const.js';

export function ConfirmChangeEmail(){
    const [confirmRegistration, setConfirmRegistration] = useState(false); 
 
    
    const getParam = ()=>{
       
        
        //let query = newUrlSearchParam()
        let url = new URL(document.location.href);
        let key = url.searchParams.get("key");
        
        fetch(`${process.env.REACT_APP_API_HOST}/users/confirm-email-change?key=${key}`)
        .then((response) =>{
			if (response.ok){
			setConfirmRegistration(true)
            localStorage.token= ''
            
			return response.json()
			} else {
				 setConfirmRegistration(false)
			};
        })
        
        
    }
    useEffect(()=>{
        getParam();
    },[])
     return(
        <div className="confirm-register">
            <div className="header_comp">
                <img src={logo} alt="Логотип"/>
                <Link 
                                    to={routes.login}
                                    className='green-button header-right-side__login-button'
                                >
                                    <FormattedMessage id='header_login' />
                                </Link>
            </div>
            <div>
               {confirmRegistration && <div className="confirm__message"> Email успешно изменен!</div>}
               {!confirmRegistration && <div className="confirm__message"> Произошла ошибка!</div>}
            </div>
        </div>
       
        
    )
    
}
