export interface FinishRestorePasswordInterface {
    key: string;
    newPassword: string;
}

export const PASSWORD_RECOVERY_INIT = 'PASSWORD_RECOVERY_INIT'
export const PASSWORD_RECOVERY_INIT_FAILED = 'PASSWORD_RECOVERY_INIT_FAILED'
export const PASSWORD_RECOVERY_FINISH = 'PASSWORD_RECOVERY_FINISH'
export const PASSWORD_RECOVERY_SUCCESS = 'PASSWORD_RECOVERY_SUCCESS'
export const PASSWORD_RECOVERY_FINISH_FAILED = 'PASSWORD_RECOVERY_FINISH_FAILED'

const initialState = {
    mail: '',
    success: false,
    error: false,
    finishError: false
}


export const RestorePasswordReducer = (state = initialState, action) => {
    switch (action.type) {

        case PASSWORD_RECOVERY_INIT_FAILED:
            return {
                ...state,
                error: true
            }
        
        case PASSWORD_RECOVERY_SUCCESS:
            return {
                mail: '',
                success: true,
                error: false
            }

        case PASSWORD_RECOVERY_FINISH_FAILED:
            return {
                ...state,
                finishError: true
            }
        default:
            return state
    }
}

export const initPasswordRestoreCreator = (payload: string) => ({type: PASSWORD_RECOVERY_INIT, payload})
export const loginRecoveryInitFailed = () => ({type: PASSWORD_RECOVERY_INIT_FAILED})
export const finishPasswordRestoreCreator = (payload: FinishRestorePasswordInterface) => ({type: PASSWORD_RECOVERY_FINISH, payload})
export const passwordRestoreSuccess = () => ({type: PASSWORD_RECOVERY_SUCCESS})
export const recoveryPasswordFinishFailed = () => ({type: PASSWORD_RECOVERY_FINISH_FAILED})