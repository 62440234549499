import { useState } from "react";
import { Consultation } from "../../interfaces/Consultation";
import { OnePersonIcon, TooltipBurgerIcon } from "../../icons";
import { FormattedMessage } from "react-intl";

import "./styles.sass";

const subTitles = {
  WAITING_REVIEW: "consultations_list_panel_state_waiting",
  WAIT_INVOICE: "consultations_list_panel_state_waiting",
  WAIT_USER_CONFIRM: "consultations_list_panel_state_waiting",
  WAIT_CONSULTANT_CONFIRM: "consultations_list_panel_state_waiting",
  CLOSED: "consultations_list_panel_state_closed",
  REJECTED_CONS: "consultations_list_panel_state_closed",
  REJECTED_USER: "consultations_list_panel_state_closed",
  NOT_APPROVED_USER: "consultations_list_panel_state_closed",
  NOT_APPROVED_CONS: "consultations_list_panel_state_closed",
  HAS_CONCLUSION: "consultations_list_panel_state_closed",
  PAID: "consultations_list_panel_state_confirmed",
  CONFIRMED: "consultations_list_panel_state_confirmed",
  IN_ARBITRATION: "consultations_list_panel_state_arbiitrage",
  APPROVED: "consultations_list_panel_state_waiting",
  DISCUSSION: "consultations_list_panel_state_confirmed",
  EXPIRATION: "consultations_list_panel_state_time"
};

export const ConsultationPanel = (props: {
  data: Consultation;
  isConsultant: boolean;
}) => {
  const data = props.data;

  const [openedTooltipId, setOpenedTooltipId] = useState<string>("");

  return (
    <div className="consultation-panel">
      <div className="consultation-panel__title-wrapper">
        <div>
          {data &&
            (props.isConsultant ? (
              data.senderPhoto && data.senderPhoto.length > 0 ? (
                <img src={data.senderPhoto} />
              ) : (
                <div>
                  <OnePersonIcon />
                </div>
              )
            ) : data.adressePhoto && data.adressePhoto.length > 0 ? (
              <img src={data.adressePhoto} />
            ) : (
              <div>
                <OnePersonIcon />
              </div>
            ))}
        </div>

        <span>{props.isConsultant ? data.senderName : data.adresseeName}</span>
      </div>

      <div className="consultation-panel__measurements">
        <span className="consultation-panel__measurements__title">
          <FormattedMessage id="consultations_list_panel_measurements_title" />
        </span>
        <span className="consultation-panel__measurements__value">
          {data.measurements.length}
        </span>
      </div>

      <div
        style={
          document.body.clientWidth > 640
            ? { marginLeft: "80px" }
            : { marginLeft: "0px" }
        }
        className="consultation-panel__measurements"
      >
        <span className="consultation-panel__measurements__title">
          Дата начала консультации
        </span>
        <span className="consultation-panel__measurements__value">
          {data.startDate.slice(8, 10) +
            "." +
            data.startDate.slice(5, 7) +
            "." +
            data.startDate.slice(0, 4)}
        </span>
      </div>
      <div
        className="consultation-panel__burger-icon"
        onMouseEnter={() => setOpenedTooltipId(data.id)}
        onMouseLeave={() => setOpenedTooltipId("")}
      >
        <TooltipBurgerIcon />
        <div
          className="consultation-panel__tooltip"
          style={{
            opacity: data.id === openedTooltipId ? "1" : "0",
          }}
        >
          {data.lastMessage}
        </div>
      </div>

      {document.body.clientWidth <= 640 && (
        <div className="consultation-panel__description">
          <span>
            <FormattedMessage id="consultations_list_panel_description_title" />
          </span>
          <div>{data.lastMessage}</div>
        </div>
      )}

      <div className="consultation-panel__color-panel">
        <FormattedMessage id={subTitles[data.status]} />
      </div>
    </div>
  );
};
