
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { WarningYellowIcon } from '../../../icons'
import { tryToDeleteMeasurementConsultation } from '../../../store/reducers/CurrentConsultationReducer'
import { closeModal } from '../../../store/reducers/ModalWindowsReducer'
import './styles.sass'

export const DeleteMeasurementConsultationModal = () => {

    const dispatch = useDispatch()

    const modal = useSelector( (state: any) => (state.modals))

    const consultation = useSelector( (state: any) => (state.currentConsultation))

    useEffect( () => {
        let state: boolean = true
        consultation?.measurements.forEach( (elem) => {
            if (elem.id === modal?.modalInfo?.id) {
                state = false
            }
        })
        if (state) {
            dispatch( closeModal() )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consultation])

    return (
        <div
            className='delete-measurement-consultation-modal-wrapper'
        >
            <div
                className='delete-measurement-consultation-modal'
            >

                <span
                    className='delete-measurement-consultation-modal__title'
                >
                    <FormattedMessage id='consultation_chat_delete_measurement_title' />
                </span>

                <div 
                    className='delete-measurement-consultation-modal__row'
                >
                    <div>
                        <WarningYellowIcon/>
                    </div>

                    <div 
                        className='delete-measurement-consultation-modal__row__main'
                    >

                        <span
                            className='delete-measurement-consultation-modal__row__main__title'
                        >
                            <FormattedMessage id='consultation_chat_delete_measurement_desc' />
                        </span>

                        <span
                            className='delete-measurement-consultation-modal__row__main__name'
                        >
                            {
                                modal?.modalInfo?.date
                            }
                        </span>

                        <div
                            className='delete-measurement-consultation-modal__row__main__buttons'
                        >

                            <div
                                className='white-button'
                                onClick={() => dispatch(closeModal())}
                            >
                                <FormattedMessage id='consultation_chat_delete_measurement_button_cancel'/>
                            </div>

                            <div
                                className='green-button'
                                onClick={() => {
                                        dispatch(tryToDeleteMeasurementConsultation(modal.modalInfo.id))
                                        dispatch(closeModal())
                                    
                                }}
                            >
                                <FormattedMessage id='consultation_chat_delete_measurement_button_delete'/>
                            </div>
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
    )
}