import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import store from '../store/index'
import { GET_PERSONS_LIST, setPersonsList } from "../store/reducers/PersonsListReducer";

async function asyncGetPersonList(data: {page: number, name: string, sort: string}) {
    try {
        
        const response = await fetch(
            apiUrl + `/person/search?page=${data.page}&name=${data.name}&sort=${localStorage.getItem('sort')||''}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    //Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                }
            }
        )

        if (response.status === 200 || response.status === 201) {

            const result = await response.json()

            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(error) {}
}

function* PersonListWorker(action) {
    
    let page = store.getState().personList.page
    const data = yield call(asyncGetPersonList, {page: action.payload.page+page, name: ( action.payload.name ? action.payload.name : '' ),sort: (action.payload.sort ? action.payload.sort : '') })
    if (data) {
        yield put(setPersonsList({
            
            page: page+1, 
            list: data,
            name: action.payload.name ? action.payload.name : '',
        }))
    }

}

export function* PersonListWatcher() {
    yield takeEvery(GET_PERSONS_LIST, PersonListWorker)
}