import { useEffect, useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import * as SockJS from "sockjs-client";
import * as Stomp from "@stomp/stompjs";
import { useSelector, useDispatch } from "react-redux";
import { MeasurementStatisticPage } from "./pages/MeasurementStatisticPage";

import { routes } from "./routes";

import { CloseCrossIcon } from "./icons";
import "./globalStyles/main.sass";

import { messages } from "./i18n/messages";
import { LOCALES } from "./i18n/locales";

import { Header } from "./components/Header/index";
import { Navbar } from "./components/Navbar/index";
import { OfficesPage } from "./pages/OfficesPage";
import { SignUpPage } from "./pages/SignUpPage";
import { LoginPage } from "./pages/LoginPage";
import { LoginRecoveryPage } from "./pages/LoginRecoveryPage";
import { ArticlesPage } from "./pages/ArticlesPage";
import { MobilePage } from "./pages/MobilPage";
import { StartMeasurementPage } from "./pages/StartMeasurementPage";
import { MeasurementInstructionPage } from "./pages/MeasurementInstructionPage";
import { MeasurementPersonListPage } from "./pages/MeasurementPersonListPage";

import { MeToPersonInstructionPage } from "./pages/MeToPersonInstructionPage";
import { MeToPersonRatingPage } from "./pages/MeToPersonRatingPage";

import { PersonToMeInstructionPage } from "./pages/PersonToMeInstructionPage";
import { PersonToMeRatingPage } from "./pages/PersonToMeRatingPage";
import { MeasurementEndPage } from "./pages/MeasurementEndPage";

import { MeasurementsListPage } from "./pages/MeasurementsListPage";

import { StartConsultationPage } from "./pages/StartConsultationPage";
import { ConsultantChoosingConsultationPage } from "./pages/ConsultantChoosingConsultationPage";
import { InfoConsultationPage } from "./pages/InfoConsultationPage";
import { ConfirmConsultationPage } from "./pages/ConfirmConsultationPage";
import { PaymentPage } from "./pages/PaymentPage";
import { checkIsAuthorized } from "./store/reducers/userReducer";

import { ConsultationChatPage } from "./pages/ConsultationChatPage";
import { EndConsultationPage } from "./pages/EndConsultationPage";

import { MyAccountPage } from "./pages/MyAccountPage";
import { NotificationsPage } from "./pages/NotificationsPage";
import { SocialNetworksPage } from "./pages/SocialNetworksPage";
import { MyServicesPage } from "./pages/MyServicesPage";
import { FinancesPage } from "./pages/FinancesPage";

import { ConsultantsListPage } from "./pages/ConsultantsListPage";
import { ConsultationsPage } from "./pages/ConsultationsPage";
import { PersonsListPage } from "./pages/PersonsListPage";
import { ConsultantPage } from "./pages/ConsultantPage";
import { CreatePersonPage } from "./pages/CreatePersonPage";
import { ChangePersonPage } from "./pages/ChangePersonPage";
import { cleanMeasurementType } from "./consts";
import { ConsultationReviewPage } from "./pages/ConsultationReviewPage";
import { ConfirmRegister } from "./pages/ConfirmRegister";
import { ConfirmChangeEmail } from "./pages/ConfirmChangeEmail";
import { TemplatesPage } from "./pages/TemplatesPage";
import { Agreement } from "./pages/Agreement";
import { Politics } from "./pages/Politics";
import { Services } from "./pages/Services";
import { Contacts } from "./pages/Contacts";
import { User } from "./interfaces/User";
import { Consultation } from "./interfaces/Consultation";
import { getAllConsultationMessages } from "./store/reducers/ConsultationChatReducer";
import { getCurrentConsultation } from "./store/reducers/CurrentConsultationReducer";
import { tryToGetConsultationsList } from "./store/reducers/ConsultationsListReducer";
import { Status } from "./interfaces/Consultation";
import { NotificationsMessages } from "./pages/NotificationsMessages";
import { MeasurementWatcherPage } from "./pages/MeasurementWatcherPage";
import { LandingContainer } from "./containers/LandingContainer/LandingContainer";
import { Tarifs } from "./pages/Tarifs";
import { Link } from "react-router-dom";
import { StartConsultationFromConsultationContainerPage } from "./pages/StartConsultationFromConsultationContainerPage";
import { TariffsInfo } from "./pages/TariffsInfo";
import { ConsultationForConsultantPage } from "./pages/ConsultationForConsultantPage";
import { PaymentFailPage } from "./pages/PaymentFail";
import { PaymentSuccessPage } from "./pages/PaymentSuccess";
import { GroupRatingPage } from "./pages/GroupRatingPage";
import { GroupInstructionsEndPage } from "./pages/GroupEndInstructionPage";
import { GroupInstructionsStartPage } from "./pages/GroupInstructionsStartPage";
import { MeasurementStatisticGroupPage } from "./pages/MeasurementGroupStatistic";
function App() {
  const accountData: User = useSelector((state: any) => state.user);
  const consultation: Consultation = useSelector(
    (state: any) => state.currentConsultation
  );
  const locale = LOCALES.RUSSIAN;
  const [numberOfPage, setNumberOfPage] = useState(1);
  const [type, setType] = useState<Status>(Status.WAITING_REVIEW);
  const [notificationAccount, setNotificationAccount] = useState(true);
  const dispatch = useDispatch();
  const isAuthorized = !!useSelector((state: any) => state.login.token);
  const [notifications, setNotification] = useState<any>(0);
  //const [socketStart, isSocketStart] = useState(false);
  // const [fich, isFich] = useState(false);
  useEffect(() => {
    if (
      location.pathname !== routes.login &&
      location.pathname !== routes.confirm_register &&
      location.pathname !== routes.confirmEmailChange &&
      location.pathname !== routes.loginRecovery &&
      location.pathname !== routes.articles &&
      location.pathname !== routes.signup &&
      location.pathname !== routes.politics &&
      location.pathname !== routes.agreement &&
      location.pathname !== routes.services &&
      location.pathname !== routes.contacts &&
      location.pathname !== routes.tariffsInfo &&
      location.pathname !== "/"
    ) {
      dispatch(checkIsAuthorized());
      localStorage.setItem("lastPageSet", document.location.href);
    }

    cleanMeasurementType();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  function getCountNotice() {
    fetch(`${process.env.REACT_APP_API_HOST}/notice/unreaded/count`, {
      headers: { Authorization: "Bearer " + localStorage.token },
      method: "get",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setNotification(data.value);
      });
  }

  useEffect(() => {
    const socket = new SockJS(`${process.env.REACT_APP_WS_HOST}`);

    const stompClient = Stomp.Stomp.over(socket);
    if (accountData) {
      if (accountData.profileCompleted) {
        setNotificationAccount(true);
      } else if (!accountData.profileCompleted) {
        setNotificationAccount(false);
      }
      stompClient.connect(
        {},
        (frame) => {
          console.log("Connected: " + frame);
          stompClient.subscribe(
            "/user/" + accountData.id + "/queue/notification",
            (message) => {
              // setNumberOfPage(1)

              getCountNotice();
              console.log(message.body);
              document.title = "Новое сообщение!";

              if (location.pathname == routes.consultationChat) {
                if (consultation && consultation.id) {
                  dispatch(getAllConsultationMessages());
                  setNumberOfPage(0);
                  if (
                    consultation.id == JSON.parse(message.body).consultationId
                  ) {
                    dispatch(
                      getCurrentConsultation(
                        JSON.parse(message.body).consultationId
                      )
                    );
                  }
                }
              }
              if (location.pathname == routes.consultations) {
                localStorage.setItem("typeConsultation", "consultation");
                dispatch(
                  tryToGetConsultationsList({ type: "REQUEST", page: 0 })
                );
                setType(Status.WAITING_REVIEW);
              }
              if (location.pathname == routes.consultationForConsultant) {
                localStorage.setItem("typeConsultation", "appeals");
                dispatch(
                  tryToGetConsultationsList({ type: "REQUEST", page: 0 })
                );
                setType(Status.WAITING_REVIEW);
              }
            }
          );
        },
        (error) => {
          console.log("Error: " + error);
        }
      );
    }

    return function stopConnection() {
      stompClient.disconnect();
      document.title = "Социомониторинг";
      console.log("connection stopped");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData, consultation, consultation?.id]);
  useEffect(() => {
    getCountNotice();
  }, []);
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", vh + "px");

  return (
    <IntlProvider
      locale="ru-RU"
      defaultLocale="ru-RU"
      messages={messages[locale]}
    >
      <BrowserRouter>
        {!(
          location.pathname == routes.confirm_register ||
          location.pathname == routes.confirmEmailChange ||
          location.pathname == "/"
        ) && <Header />}
        {document.body.clientWidth < 640 &&
          false &&
          location.pathname !== "/" && <MobilePage />}
        {isAuthorized &&
          !(
            location.pathname == routes.confirm_register ||
            location.pathname == routes.confirmEmailChange ||
            location.pathname == routes.politics ||
            location.pathname == routes.agreement ||
            location.pathname == routes.services ||
            location.pathname == routes.login ||
            location.pathname == routes.contacts ||
            location.pathname == routes.tariffsInfo ||
            location.pathname == "/" ||
            location.pathname.includes("measurement-watcher")
          ) && <Navbar notifications={notifications} />}

        <div
          className="container"
          style={location.pathname == "/" ? { height: "100vh" } : {}}
        >
          <Routes>
            {isAuthorized ? (
              <>
              <Route
                  path={routes.groupRating}
                  element={<GroupRatingPage />}
                />
                   <Route
                  path={routes.measurementStatisticGroup}
                  element={<MeasurementStatisticGroupPage/>}
                />
                <Route
                  path={routes.GroupEndInstruction}
                  element={<GroupInstructionsEndPage />}
                />
                <Route
                  path={routes.GroupStartInstruction}
                  element={<GroupInstructionsStartPage />}
                />
                <Route
                  path={routes.startMeasurement}
                  element={<StartMeasurementPage />}
                />
                 <Route
                  path={routes.offices}
                  element={<OfficesPage />}
                />
                <Route
                  path={routes.paymentSuccess}
                  element={<PaymentSuccessPage />}
                />
                <Route
                  path={routes.paymentFail}
                  element={<PaymentFailPage />}
                />
                <Route path={routes.payment} element={<PaymentPage />} />
                <Route
                  path={routes.measurementInstruction}
                  element={<MeasurementInstructionPage />}
                />
                <Route
                  path={routes.makingMeasurementPersonsList}
                  element={<MeasurementPersonListPage />}
                />

                <Route
                  path={routes.measurementWatcher}
                  element={<MeasurementWatcherPage />}
                />
                <Route
                  path={routes.startConsultationFromConsultants}
                  element={<StartConsultationFromConsultationContainerPage />}
                />
                <Route
                  path={routes.meToPersonInstruction}
                  element={<MeToPersonInstructionPage />}
                />
                <Route
                  path={routes.meToPersonMeasurementRating}
                  element={<MeToPersonRatingPage />}
                />

                <Route
                  path={routes.personToMeInstruction}
                  element={<PersonToMeInstructionPage />}
                />
                <Route
                  path={routes.personToMeMeasurementRating}
                  element={<PersonToMeRatingPage />}
                />
                <Route
                  path={routes.notificationsMessages}
                  element={
                    <NotificationsMessages getCountNotice={getCountNotice} />
                  }
                />
                <Route
                  path={routes.measurementEnd}
                  element={<MeasurementEndPage />}
                />

                <Route
                  path={routes.measurementsList}
                  element={<MeasurementsListPage />}
                />

                <Route path={routes.tarifs} element={<Tarifs />} />
                <Route
                  path={routes.startConsultation}
                  element={<StartConsultationPage />}
                />
                <Route
                  path={routes.choosePersonConcultation}
                  element={<ConsultantChoosingConsultationPage />}
                />
                <Route
                  path={routes.consultationInfo}
                  element={<InfoConsultationPage />}
                />
                <Route
                  path={routes.confirmConsultation}
                  element={<ConfirmConsultationPage />}
                />

                <Route
                  path={routes.consultationChat}
                  element={
                    <ConsultationChatPage
                      numberOfPage={numberOfPage}
                      setNumberOfPage={setNumberOfPage}
                    />
                  }
                />
                <Route
                  path={routes.endConsultation}
                  element={<EndConsultationPage />}
                />
                <Route
                  path={routes.consultationReview}
                  element={<ConsultationReviewPage />}
                />

                <Route path={routes.myAccount} element={<MyAccountPage />} />
                <Route
                  path={routes.notifications}
                  element={<NotificationsPage />}
                />
                <Route
                  path={routes.socialNetworks}
                  element={<SocialNetworksPage />}
                />
                <Route path={routes.myServices} element={<MyServicesPage />} />
                <Route path={routes.finances} element={<FinancesPage />} />

                <Route
                  path={routes.measurementStatistic}
                  element={<MeasurementStatisticPage />}
                />

                <Route
                  path={routes.consultants}
                  element={<ConsultantsListPage />}
                />
                <Route
                  path={routes.consultations}
                  element={<ConsultationsPage type={type} setType={setType} />}
                />
                <Route
                  path={routes.consultationForConsultant}
                  element={
                    <ConsultationForConsultantPage
                      type={type}
                      setType={setType}
                    />
                  }
                />

                <Route path={routes.persons} element={<PersonsListPage />} />
                <Route
                  path={routes.createPerson}
                  element={<CreatePersonPage />}
                />
                <Route
                  path={routes.changePerson}
                  element={<ChangePersonPage />}
                />
                <Route path={routes.consultant} element={<ConsultantPage />} />

                <Route path={routes.templates} element={<TemplatesPage />} />
              </>
            ) : (
              <>
                <Route path={routes.signup} element={<SignUpPage />} />
              </>
            )}
            <Route
              path={routes.loginRecovery}
              element={<LoginRecoveryPage />}
            />
            <Route path={routes.login} element={<LoginPage />} />
            <Route path="/" element={<LandingContainer />} />
            <Route path={routes.agreement} element={<Agreement />} />
            <Route path={routes.contacts} element={<Contacts />} />
            <Route path={routes.politics} element={<Politics />} />
            <Route path={routes.services} element={<Services />} />
            <Route path={routes.tariffsInfo} element={<TariffsInfo />} />

            <Route path={routes.articles} element={<ArticlesPage />} />
            <Route
              path={routes.confirm_register}
              element={<ConfirmRegister />}
            />
            <Route
              path={routes.confirmEmailChange}
              element={<ConfirmChangeEmail />}
            />
          </Routes>
          {/* <div className="short__menu" style={document.body.clientWidth < 640 ? {
            zIndex: '991',
            position: 'absolute',
            top: '20px',
            transform: 'translateX(21%)',
            cursor: 'pointer',
            fontSize: '16px',
            right: '0',
            left: '0'
          } : {cursor: 'pointer'}}>
            <p
              onClick={() => {
                isFich(!fich);
              }}
            >
              Официальная информация &#9660;
            </p>
            {fich && (
              <ul>
                <li>
                  <Link to={routes.services} target="_blank">
                    Описание услуги
                  </Link>
                </li>
                <li>
                  <Link to={routes.tariffsInfo} target="_blank">
                    Тарифы
                  </Link>
                </li>

                <li>
                  <Link to={routes.agreement} target="_blank">
                    Пользовательское соглашение
                  </Link>
                </li>
                <li>
                  <Link to={routes.politics} target="_blank">
                    Политика в отношении обработки персональных данных
                  </Link>
                </li>
                <li>
                  <Link to={routes.contacts} target="_blank">
                    Контакты
                  </Link>
                </li>
              </ul>
            )}
          </div>*/}
        </div>

        {!notificationAccount && (
          <div className="notification__account">
            <div
              style={{
                textAlign: "right",
                paddingRight: "5px",
              }}
              onClick={() => {
                setNotificationAccount(true);
              }}
            >
              {" "}
              <CloseCrossIcon />{" "}
            </div>
            <p style={{ marginBottom: "20px" }}>
              Вам необходимо заполнить профиль для более точных результатов
              измерений!
            </p>

            <Link to={routes.myAccount}>
              <div className="green-button">Заполнить профиль</div>
            </Link>
          </div>
        )}
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
