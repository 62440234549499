import { MeasurementInfo } from "../../interfaces/MeasurementInfo"

export const GET_MEASUREMENTS_LIST = 'GET_MEASUREMENTS_LIST'
export const SET_MEASUREMENTS_LIST = 'SET_MEASUREMENTS_LIST'

const initialState: {
    page: number; 
    list: MeasurementInfo[]
} = {
    page: 0,
    list: []
}

export const MeasurementsListReducer = ( state = initialState, action ) => {

    switch (action.type) {

        case SET_MEASUREMENTS_LIST:
            if (action.payload.page === 0) {
                return {page: 0, list: action.payload.list}
            } 
            else {
                return {page: action.payload.page, list: [...state.list, ...action.payload.list]}
            }

        default:
            return state
    }
}

export const getMeasurementsList = (payload: number) => ({type: GET_MEASUREMENTS_LIST, payload})
export const setMeasurementsList = (payload: {
    page: number; 
    list: MeasurementInfo[]
}) => ({type: SET_MEASUREMENTS_LIST, payload})