import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AccountNavbarTitlesArray } from "../../../store/reducers/AccountNavbarReducer";

import { routes } from "../../../routes";

import "./styles.sass";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { CarouselSideArrowIcon } from "../../../icons";

const routesArray = [
  routes.myAccount,
  routes.notifications,
  routes.socialNetworks,
  routes.tarifs,
  routes.myServices,
  routes.finances,
];

export const MyAccountNavbar = () => {
  //const [numNavbar, setNumNavbar] = useState(0);
  const currentNavbarState = useSelector((state: any) => state.accountNavbar);
  const Navigate = useNavigate();
  //const [side, setSide] = useState<string>("left");

  const { pathname } = useLocation();
  //console.log(numNavbar);
  useEffect(() => {
    var item: HTMLElement | null = window.document.getElementById(
      "my-account-navbar-wrapper__navbar"
    );
    if (pathname === routesArray[3]) {
      if (item) {
        item.scrollTo({ left: 270 });
      }
    } else if (pathname === routesArray[2]) {
      if (item) {
        item.scrollTo({ left: 85 });
      }
    } else if (pathname === routesArray[4]) {
      if (item) {
        item.scrollTo({ left: 300 });
      }
    } else if (pathname === routesArray[5]) {
      if (item) {
        item.scrollTo({ left: 450 });
      }
    }
  }, [pathname]);

  return (
    <div className={`my-account-navbar-wrapper `}>
      <div className="my-account-navbar-wrapper__title">
        <FormattedMessage id="account_navbar_title_main" />
      </div>

      {document.body.clientWidth <= 640 && (
        <div
          className="my-account-navbar-wrapper__navbar__left-button"
          onClick={() => {
            if (pathname === routesArray[2]) {
              Navigate(routesArray[0]);
            }
            if (pathname === routesArray[3]) {
              Navigate(routesArray[2]);
            }
            if (pathname === routesArray[4]) {
              Navigate(routesArray[3]);
            }
            if (pathname === routesArray[5]) {
              Navigate(routesArray[4]);
            }
          }}
        >
          <div style={{ opacity: pathname === routesArray[0] ? "0.43" : "1" }}>
            <CarouselSideArrowIcon />
          </div>
        </div>
      )}

      {document.body.clientWidth <= 640 && (
        <div
          className="my-account-navbar-wrapper__navbar__right-button"
          onClick={() => {
            if (pathname === routesArray[0]) {
              Navigate(routesArray[2]);
            }
            if (pathname === routesArray[2]) {
              Navigate(routesArray[3]);
            }
            if (pathname === routesArray[3]) {
              Navigate(routesArray[4]);
            }
            if (pathname === routesArray[4]) {
              Navigate(routesArray[5]);
            }
          }}
        >
          <div style={{ opacity: pathname == routesArray[5] ? "0.43" : "1" }}>
            <CarouselSideArrowIcon />
          </div>
        </div>
      )}
      <div
        className="my-account-navbar-wrapper__navbar"
        id="my-account-navbar-wrapper__navbar"
      >
        {AccountNavbarTitlesArray.map((elem, index) => {
          if (index != 1) {
            return (
              <div
                key={index}
                className={`
                                        my-account-navbar-wrapper__navbar__nav-panel 
                                        ${
                                          currentNavbarState === elem &&
                                          "current-nav-panel"
                                        }
                                    `}
                style={{
                  marginLeft: index === 0 ? "0px" : "16px",
                }}
              >
                <Link to={routesArray[index]}>
                  <FormattedMessage id={elem} />
                </Link>
                {currentNavbarState === elem && (
                  <div className="my-account-navbar-wrapper__navbar__nav-panel__choosen-line" />
                )}
              </div>
            );
          }
        })}

        <div className="my-account-navbar-wrapper__navbar__line" />
      </div>
    </div>
  );
};
