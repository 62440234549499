import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import logo from "../../logo.svg";
import "./styles.sass";
import { BlackMenuFoldIcon } from "../../icons";
import { routes } from "../../routes";
import { InfoCircleIcon, SimpleBurgerIcon, WhiteCrossIcon } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import { StepBar } from "../StepBar";
import { toggleIsNavbarShowed } from "../../store/reducers/NavbarReducer";
import { Consultation } from "../../interfaces/Consultation";
import { User } from "../../interfaces/User";

const subTitles = {
  WAITING_REVIEW: "consultations_list_panel_state_waiting",
  WAIT_INVOICE: "consultations_list_panel_state_waiting",
  WAIT_USER_CONFIRM: "consultations_list_panel_state_waiting",
  WAIT_CONSULTANT_CONFIRM: "consultations_list_panel_state_waiting",
  CLOSED: "consultations_list_panel_state_closed",
  REJECTED_CONS: "consultations_list_panel_state_closed",
  REJECTED_USER: "consultations_list_panel_state_closed",
  NOT_APPROVED_USER: "consultations_list_panel_state_closed",
  NOT_APPROVED_CONS: "consultations_list_panel_state_closed",
  HAS_CONCLUSION: "consultations_list_panel_state_closed",
  PAID: "consultations_list_panel_state_confirmed",
  CONFIRMED: "consultations_list_panel_state_confirmed",
  IN_ARBITRATION: "consultations_list_panel_state_arbiitrage",
  APPROVED: "consultations_list_panel_state_waiting",
  DISCUSSION: "consultations_list_panel_state_confirmed",
  EXPIRATION: "consultations_list_panel_state_time",
};

export const Header = () => {
  const isAuthorized = !!useSelector((state: any) => state.login.token);
  const isArticleSidebarOpened = useSelector(
    (state: any) => state.articleSideNavigation.isArticleSidebarOpened
  );
  const currentHeaderTitles = useSelector((state: any) => state.headerTitle);
  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );
  //const measurementTypeInfo = useSelector( (state: any) => (state.measurementType))
  const consultation: Consultation | null = useSelector(
    (state: any) => state.currentConsultation
  );
  const accountData: User = useSelector((state: any) => state.user);

  const dispatch = useDispatch();
  const toggleIsArticleSidebarOpened = () => ({
    type: "TOGGLE_IS_ARTICLE_SIDEBAR_OPENED",
  });

  const { pathname } = useLocation();

  return (
    <div className="header-outer">
      <div
        className={`header-inner ${
          isNavbarShowed && isAuthorized && "shorter-header-inner"
        }`}
      >
        {document.body.clientWidth < 640 && pathname === routes.articles && (
          <div
            onClick={() => {
              dispatch(toggleIsArticleSidebarOpened());
            }}
          >
            <SimpleBurgerIcon />
          </div>
        )}

        {(!isAuthorized ||
          pathname === routes.politics ||
          pathname === routes.agreement ||
          pathname === routes.services ||
          pathname === routes.tariffsInfo ||
          pathname === routes.contacts) && (
          <div className="header-logo">
            {pathname == routes.login || pathname == routes.signup ? (
              <a href="/">
                <img src={logo} />
              </a>
            ) : (
              <img src={logo} />
            )}
          </div>
        )}

        {isAuthorized && !isNavbarShowed && (
          <div
            style={{ transform: "rotate(180deg)", cursor: "pointer" }}
            onClick={() => dispatch(toggleIsNavbarShowed())}
          >
            <BlackMenuFoldIcon />
          </div>
        )}

        {isAuthorized &&
          pathname.includes("measurement-") &&
          document.body.clientWidth <= 640 && (
            <div className="header-stepbar-wrapper">
              <StepBar steps={8} />
            </div>
          )}

        {isAuthorized &&
          (pathname === routes.startConsultation ||
            pathname === routes.choosePersonConcultation ||
            pathname === routes.confirmConsultation ||
            pathname === routes.consultationInfo) &&
          document.body.clientWidth <= 640 && (
            <div className="header-stepbar-wrapper">
              <StepBar steps={4} />
            </div>
          )}

        {isAuthorized &&
          document.body.clientWidth > 640 &&
          (pathname === routes.myAccount ||
            pathname === routes.socialNetworks ||
            pathname === routes.notifications) && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">
                <FormattedMessage id="account_header_title_main" />
              </span>
            </div>
          )}

        {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.createPattern && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">
                <FormattedMessage id="account_header_title_main" />
              </span>
            </div>
          )}
  {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.offices && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">
                Мои офисы
              </span>
            </div>
          )}
        {isAuthorized &&
          document.body.clientWidth > 640 &&
          (pathname === routes.myServices || pathname === routes.finances) && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">
                <FormattedMessage id="account_header_title_additional" />
              </span>
            </div>
          )}

        {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.consultations && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">
                <FormattedMessage id="consultations_list_header" />
              </span>
            </div>
          )}
          
        {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.GroupStartInstruction && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">
                Проведения 
              </span>
            </div>
          )}
           {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.groupRating && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">
                Выполнение группового измерения
              </span>
            </div>
          )}
             {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.GroupEndInstruction && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">
                Завершение группового измерения
              </span>
            </div>
          )}
        {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.consultationChat && (
            <div className="header-titles-wrapper">
              <span
                className="header-titles-wrapper__title"
                style={{ color: "rgba(33, 30, 30, 0.54)" }}
              >
                {consultation?.senderId === accountData?.id
                  ? "Консультант" || ""
                  : "Консультируемый" || ""}
              </span>
              <div
                style={{
                  margin: "auto 8px",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  background: "black",
                }}
              />
              <span>
                {consultation?.senderId === accountData?.id
                  ? consultation?.adresseeName || ""
                  : consultation?.senderName || ""}
              </span>
              <div
                style={{
                  margin: "auto 8px",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  background: "black",
                }}
              />
              <span>
                {consultation?.status && (
                  <FormattedMessage id={subTitles[consultation?.status]} />
                )}
              </span>
            </div>
          )}
        {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.consultants && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">КОНСУЛЬТАНТЫ</span>
            </div>
          )}

        {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.notificationsMessages && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">УВЕДОМЛЕНИЯ</span>
            </div>
          )}
        {document.body.clientWidth > 640 && pathname === routes.articles && (
          <div className="header-titles-wrapper">
            <span className="header-titles-wrapper__title">СПРАВКА</span>
          </div>
        )}
        {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.measurementsList && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">
                СПИСОК ЗАМЕРОВ
              </span>
            </div>
          )}
        {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.tarifs && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">ТАРИФЫ</span>
            </div>
          )}
        {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.persons && (
            <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">
                СПИСОК ПАРТНЕРОВ
              </span>
            </div>
          )}
        {isAuthorized &&
          document.body.clientWidth > 640 &&
          pathname === routes.endConsultation && (
            <div className="header-titles-wrapper">
              <span
                className="header-titles-wrapper__title"
                style={{ color: "rgba(33, 30, 30, 0.54)" }}
              >
                <FormattedMessage id="consultations_chat_header" />
              </span>
              <div
                style={{
                  margin: "auto 8px",
                  width: "6px",
                  height: "6px",
                  borderRadius: "50%",
                  background: "black",
                }}
              />
              <span style={{ color: "rgba(33, 30, 30, 0.54)" }}>
                {consultation?.senderId === accountData?.id
                  ? consultation?.adresseeName || ""
                  : consultation?.senderName || ""}
              </span>
              <div
                style={{
                  margin: "auto 8px",
                  width: "6px",
                  height: "6px",
                  borderRadius: "50%",
                  background: "black",
                }}
              />
              <span className="header-titles-wrapper__title">
                <FormattedMessage id="consultations_chat_header_end" />
              </span>
            </div>
          )}

        {isAuthorized &&
        pathname.includes("measurement-") &&
        document.body.clientWidth > 640 &&
        currentHeaderTitles.currentHeaderTitle.length > 0 ? (
          <div className="header-titles-wrapper">
            <span className="header-titles-wrapper__title">
              <FormattedMessage id={currentHeaderTitles.currentHeaderTitle} />
            </span>

            <span className="header-titles-wrapper__additional-title">
              {currentHeaderTitles.currentHeaderAdditionalTitle.length > 0 && (
                <>
                  <FormattedMessage
                    id={currentHeaderTitles.currentHeaderAdditionalTitle}
                  />
                  {JSON.parse(localStorage.measurementTypesN).name}
                </>
              )}
            </span>
          </div>
        ) : (
          <></>
        )}
        
        {isAuthorized &&
        pathname.includes("statistic-group") &&
        document.body.clientWidth > 640 && (
          <div className="header-titles-wrapper">
              <span className="header-titles-wrapper__title">
                Результаты измерения
              </span>
            </div>
        )}
        <div className="header-right-side">
          {!isAuthorized && (
            <>
              {pathname !== routes.signup &&
                (document.body.clientWidth > 640 ||
                  pathname === routes.login) && (
                  <Link
                    to={routes.signup}
                    className="white-button header-right-side__reg-button"
                  >
                    <FormattedMessage id="header_registration" />
                  </Link>
                )}

              {pathname !== routes.login && (
                <Link
                  to={routes.login}
                  className="green-button header-right-side__login-button"
                >
                  <FormattedMessage id="header_login" />
                </Link>
              )}
            </>
          )}

          {pathname == routes.personToMeMeasurementRating ||
          pathname == routes.meToPersonMeasurementRating ||
          pathname == routes.measurementInstruction ||
          pathname == routes.makingMeasurementPersonsList ||
          pathname == routes.meToPersonInstruction ||
          pathname == routes.personToMeInstruction ||
          pathname == routes.measurementEnd ? (
            <Link
              to={
                routes.articles +
                "?key=" +
                JSON.parse(localStorage.measurementTypesN).referenceInformation
              }
              className="header-right-side__info-circle"
            >
              <InfoCircleIcon />
            </Link>
          ) : (
            <Link
              to={routes.articles}
              className="header-right-side__info-circle"
            >
              <InfoCircleIcon />
            </Link>
          )}
        </div>
      </div>

      {document.body.clientWidth < 640 && (
        <div
          className={`black-article-header ${
            isArticleSidebarOpened && "opened-black-header"
          }`}
        >
          <div
            onClick={() => {
              dispatch(toggleIsArticleSidebarOpened());
            }}
          >
            <WhiteCrossIcon />
          </div>
        </div>
      )}
    </div>
  );
};
