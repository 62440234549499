import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ChooseConsultantPanel } from "../../components/ConsultationUserComponents/ChooseConsultantPanel"
import { SearchFilterConsultation } from "../../components/ConsultationUserComponents/SearchFilterConsultation"
import { getConsultantsList } from "../../store/reducers/ConsultantsListReducer"
import { closeModal } from "../../store/reducers/ModalWindowsReducer"
export const ConsultantsListContainer = () => {

    const dispatch = useDispatch()

    const isNavbarShowed = useSelector( (state: any) => (state.navbar.isNavbarShowed))
    const consultantsList = useSelector( (state: any) => (state.consultantsList))
  
    useEffect( () => {
        dispatch( getConsultantsList(0) )
    
        const consultantsListWrapper: HTMLElement | null = window.document.getElementById('consultants-page-list-wrapper')

        function handleScroll() {
            let scrollTop = consultantsListWrapper?.scrollTop || 0
            let clientHeight = consultantsListWrapper?.clientHeight || 0
            let scrollheight = consultantsListWrapper?.scrollHeight || 0
            if ((scrollTop + clientHeight > scrollheight-5) && consultantsList) {
                let page = consultantsList.page
              
                dispatch( getConsultantsList(page+1) )
            }
        }
        consultantsListWrapper?.addEventListener('scroll', handleScroll)
       
        return () => {document?.removeEventListener('scroll', handleScroll)
        dispatch(closeModal())}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            className={`start-consultation-container ${ isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}
            id='consultants-page-list-wrapper'
        >
            <SearchFilterConsultation/>

            <div
                className="start-consultation-container__consultants"
                
            >
                    {   
                        consultantsList.list.length>0
                        ?
                        consultantsList.list.map( (elem, index) => {
                            return (
                                <div
                                    key={index}
                                >
                                    <ChooseConsultantPanel data={elem} choosen={false} canBeChoosen={false} />
                                </div>
                            )
                        }) :
                        <div className="not_found" style={{fontSize: '24px', fontWeight: 'bold',marginTop: '30px',marginLeft: '15px'}}>
                            Записей не найдено
                        </div>
                    }
                    


                </div>
        </div>
    )
}