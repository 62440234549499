import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { Consultation } from "../interfaces/Consultation";
import { routes } from "../routes";
//import store from "../store";
import {
  GET_CURRENT_CONSULTATION,
  setCurrentConsultation,
} from "../store/reducers/CurrentConsultationReducer";

const asyncGetCurrentConsultation = async (id: string) => {
  try {
    const response = await fetch(apiUrl + "/consultation/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    });

    if (response.status === 200) {
      const result = await response.json();
      return result;
    } else if (response.status === 401) {
      location.assign(routes.login);
    } else {
      return null;
    }
  } catch (error) {}
};

function* GetCurrentConsultationWorker(action) {
  const data: Consultation | null = yield call(
    asyncGetCurrentConsultation,
    action.payload
  );
 
  if (data && data.id) {
   
    yield put(setCurrentConsultation(data));
  }
}

export function* GetCurrentConsultationWatcher() {
  yield takeEvery(GET_CURRENT_CONSULTATION, GetCurrentConsultationWorker);
}
