import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { updateMeasurement } from "../store/reducers/MeasurementReducer";
import { cleanPersonForm, EDIT_PERSON, TRY_TO_DELETE_PERSON } from "../store/reducers/CurrentPersonReducer";
import { deletePersonFromPersonList, editPersonInPersonList } from '../store/reducers/PersonMeasurementListReducer'

import store from '../store'
import { routes } from "../routes";

async function asyncDeletePerson(id:string) {
    try {
        const measurementId = store.getState().measurement.id
        const response = await fetch(
            apiUrl + '/measurement/'+ measurementId + '/remove/person/'+id,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },

            }
        )
        if (response.status === 200) {
            const result = await response.json()

            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(error) {}
}

function* deletePersonWorker(action) {

    const data = yield call(asyncDeletePerson, action.payload) 

    if (data && data.id) {
        yield put(updateMeasurement(data))
        yield put(deletePersonFromPersonList(data.rateMembers))
    }
}

export function* DeletePersonWatcher() {
    yield takeLatest(TRY_TO_DELETE_PERSON, deletePersonWorker)
}

async function asyncEditPerson(person) {
    try {
        let date = ''
        if (person.member.birthday && person.member.birthday.length !== 10) {
            date = new Date().toISOString().slice(0,10)
        } else {
            date = person.member.birthday
        }   
    
        if (date.toString().includes('.')){
            date = date.split('.').reverse().join('-')
        }
        let meas = JSON.parse(localStorage.measurement || '');
     
        const measurementId = meas.id
        
        const response = await fetch(
            apiUrl + '/measurement/'+ measurementId + '/person',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    ...person.member,
                    customName: person.member.name,
                    birthday: date,
                })
            }
        )
        let result = await response.json()
        return result
        
    } catch(error) {}
}

function* editPersonWorker(action) {

    const data = yield call(asyncEditPerson, action.payload) 
    
    if (data && data.id) {
        let oldMeasurement = store.getState().measurement
     
        oldMeasurement.rateMembers.forEach( (elem, index) => {
            if (data.id === elem.member.id) {
                oldMeasurement.rateMembers[ index ].member = data
            }
        })
        localStorage.measurement = JSON.stringify(oldMeasurement);
      
        yield put(updateMeasurement(data))
        yield put(editPersonInPersonList(data.rateMembers))
        yield put(cleanPersonForm())

    }
} 

export function* EditPersonWatcher() {
    yield takeLatest(EDIT_PERSON, editPersonWorker)
}