import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { LongGreyArrow } from "../../icons";
import "./styles.sass";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

export const BottomControlPanel = (prop: any) => {
  const navigate = useNavigate();

  const currentStepBar = useSelector((state: any) => state.stepbar.currentStep);

  return (
    <div className="bottom-control-panel-wrapper">
      {location.pathname == routes.startMeasurement && (
        <div className="start-measurement-container__info-panel-wrapper">
          <LongGreyArrow />

          <span>
            <FormattedMessage id="measurement_start_page_option_to_scroll" />
          </span>

          <div>
            <LongGreyArrow />
          </div>
        </div>
      )}
      <Link to={prop.previousPage}>
        <button className={`white-button`}>
          <FormattedMessage id="control_panel_cancel" />
        </button>
      </Link>

      {!prop.isNextButtonDisabled ? (
        prop && prop?.useNextFunc2 ? (
          <button
            className={`green-button`}
            onClick={() => {
              if (prop?.useNextFunc2 === true) {
                navigate(prop?.nextFunc2());
                prop?.mobileChange(true);
              } else {
                prop?.nextFunc();
              }
            }}
          >
            {currentStepBar === 7 ? (
              <FormattedMessage id="control_panel_end" />
            ) : (
              <FormattedMessage id="control_panel_next" />
            )}
          </button>
        ) : (
          <Link
            to={prop?.nextPage.length === 0 ? "" : prop?.nextPage}
            onClick={() => prop.nextFunc()}
          >
            <button className={`green-button`}>
              {currentStepBar === 7 ? (
                <FormattedMessage id="control_panel_end" />
              ) : (
                <FormattedMessage id="control_panel_next" />
              )}
            </button>
          </Link>
        )
      ) : (
        <button className={`green-button disabled-button`}>
          {currentStepBar === 7 ? (
            <FormattedMessage id="control_panel_end" />
          ) : (
            <FormattedMessage id="control_panel_next" />
          )}
        </button>
      )}
    </div>
  );
};
