import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MyAccountNavbar } from "../../components/MyAccountComponents/MyAccountNavbar";
//import { User } from "../../interfaces/User";
import { setAccountNavbarTitle } from "../../store/reducers/AccountNavbarReducer";
import iconYes from "./Vector.svg";
import "./styles.sass";
declare global {
  interface Date {
    addDays(days: number):  Date
  }
}
export const TarifsContainer = () => {
  const dispatch = useDispatch();
  //const accountData: User = useSelector((state: any) => state.user);
  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );
  const [idTariff, setIdTariff] = useState("");
  const [isPromo, setIsPromo] = useState("");
 
  Date.prototype.addDays = function(days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  
  const [timeTariff, setTimeTariff] = useState(5)
 
  function getSubscribe() {
    fetch(
      isPromo.length > 0
        ? process.env.REACT_APP_API_HOST +
            "/pay/tariff/" +
            idTariff +
            "?promo=" +
            isPromo
        : process.env.REACT_APP_API_HOST + "/pay/tariff/" + idTariff,
      {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTimeout(() => {
          document.location.href = data.result;
          if (document.location.href == "/my-services") {
            window.location.reload();
          }
        }, 100);
      });
  }
  const [tariffInfo, setTariffInfo] = useState<any>({
    time: "",
    price: 0,
  });

  const [tariffsArr, setTariffsArr] = useState<any>([]);
  const [promo, setPromo] = useState("");
  const [buyNext, setBuyNext] = useState(false);
  const [errorPromo, setErrorPromo] = useState(false);
  const [finalSumm, setFinalSumm] = useState<any>({
    finalSumm: 0,
    finalDiscount: 0,
  });
  let date = new Date()
  function handleChange(event) {
    setPromo(event.target.value);
  }
  function checkPromo() {
    if (promo.length > 0) {
      fetch(
        process.env.REACT_APP_API_HOST +
          "/promo-code/" +
          promo +
          "/tariff/" +
          idTariff,
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.token,
          },
        }
      )
        .then((response) => {
          if (response.status == 200) {
            setErrorPromo(false);
            setIsPromo(promo);
            return response.json();
          } else {
            setErrorPromo(true);
            return false;
          }
        })
        .then((data) => {
          setFinalSumm({
            finalSumm: data.actualPrice,
            finalDiscount: data.amountDiscount,
          });
        });
    }
  }

  useEffect(() => {
    dispatch(setAccountNavbarTitle(3));
    fetch(process.env.REACT_APP_API_HOST + "/tariffs?type=PERSONAL_AREA", {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTariffsArr(data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`my-account-wrapper ${
        isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
      }`}
    >
      <MyAccountNavbar />
      <div
        className="my-account-wrapper__finances-wrapper"
        style={
          document.body.clientWidth > 640
            ? { flexDirection: "row", overflowX: "scroll", overflowY: "auto" }
            : {
                flexDirection: "column",
                overflowX: "scroll",
                overflowY: "auto",
              }
        }
      >
        {!buyNext &&
          tariffsArr &&
          tariffsArr.map((elem) => {
            return (
              <div className="tariffs__component">
                <p className="tariffs__title">{elem.tradeName}</p>
                <ul className="tariffs__elem">
                  <li>
                    <img src={iconYes} alt="{iconYes}" />
                    <p>Детальный анализ социального пространства</p>
                  </li>
                  <li>
                    <img src={iconYes} alt="{iconYes}" />
                    <p>Анализ качества общения</p>
                  </li>
                  <li>
                    <img src={iconYes} alt="{iconYes}" />
                    <p>Анализ типов отношений с партнерами</p>
                  </li>
                  <li>
                    <img src={iconYes} alt="{iconYes}" />
                    <p>Описание характеристики Вашего поведения</p>
                  </li>
                  <li>
                    <img src={iconYes} alt="{iconYes}" />
                    <p>Описание психических процессов</p>
                  </li>
                  <li>
                    <img src={iconYes} alt="{iconYes}" />
                    <p>Получение консультации у экспертов</p>
                  </li>
                </ul>
                <p className="tariffs__description">Всё в режиме мониторинга</p>

                <button
                  className="buy__tariffs_button"
                  onClick={() => {
                    setBuyNext(true);
                    setTariffInfo({
                      time: elem.durationText,
                      price: elem.price,
                    });
                    setFinalSumm({
                      finalSumm: elem.price,
                      finalDiscount: finalSumm.finalDiscount,
                    });
                    setIdTariff(elem.id);
                    if (elem.tarifTimeDays){
                      setTimeTariff(elem.tarifTimeDays)
                    }
                  }
               }
                  
                >
                  Подключить за {elem.price} р. на {elem.durationText}
                </button>
              </div>
            );
          })}
      </div>
      {buyNext && (
        <div className="buy__next">
          <div className="buy_next_title">Тариф премиум</div>
          <div className="buy__next__titles">
            <p>Срок действия услуги:</p>
            <span>{tariffInfo.time}</span>
          </div>
          <div className="buy__next__titles"><span>С <b>{date.toISOString().split('T')[0].split('-').reverse().join('.')}</b> по <b>{date.addDays(timeTariff).toISOString().split('T')[0].split('-').reverse().join('.')}</b></span> </div>
          <div className="buy__next__titles">
            <p>Стоимость тарифа:</p>
            <span>{tariffInfo.price} руб.</span>
          </div>
          {errorPromo && (
            <div style={{ color: "red" }}>Промокод недействителен</div>
          )}
          {!errorPromo && isPromo.length > 0 && (
            <div
              style={{
                color: "black",
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "20px",
              }}
            >
              Ваша скидка:
              <b style={{ fontWeight: "500", marginLeft: "10px" }}>
                {finalSumm.finalDiscount}руб.
              </b>
            </div>
          )}
          {isPromo.length == 0 && (
            <div className="promo">
              <input
                type="text"
                value={promo}
                onChange={handleChange}
                placeholder="Ввести промокод"
              />

              <button className="green-button" onClick={() => checkPromo()}>
                Активировать
              </button>
            </div>
          )}

          <div className="buy__next__titles">
            <p>Всего к оплате:</p>
            <span style={{ fontWeight: "bold" }}>
              {finalSumm.finalSumm} руб.
            </span>
          </div>
          <div className="button_buy_next">
            <button
              className="white-button"
              onClick={() => {
                setBuyNext(false);
                setPromo("");
                setFinalSumm({
                  finalSumm: 0,
                  finalDiscount: 0,
                });
                setIsPromo("");
                setErrorPromo(false);
              }}
            >
              назад
            </button>
            <button className="green-button" onClick={() => getSubscribe()}>
              К оплате
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
