import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import "./styles.sass";
//import { routes } from "../../routes"
import { useEffect, useState } from "react";
import { SmallWarningTriangleIcon } from "../../icons";
import { closeConsultation } from "../../store/reducers/CurrentConsultationReducer";
import { Consultation } from '../../interfaces/Consultation'

export const EndConsultationContainer = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const consultation: Consultation | null = useSelector( (state: any) => (state.currentConsultation) )
  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

  

  const [form, setForm] = useState({
    problem: "",
    analysis: "",
    description: "",
  });
  useEffect(() => {
   
    fetch(`${process.env.REACT_APP_API_HOST}/consultation/${consultation?.id}/answer`,{
      headers: {'Authorization': 'Bearer ' + localStorage.token},
      method: 'get',
  }).then((response)=>{
      return response.json()
  }).then((data)=>{
    
      if (data){
        setForm({
          problem: data.problem,
          analysis: data.analysis,
          description: data.description,
         })
      }
      
    
    
  })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <div
        className={`end-consultation-container ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
      >
        <div className="start-consultation-container__title-wrapper">
          <span>
            <FormattedMessage id="consultations_end_title" />
          </span>
        </div>

        <div className="end-consultation-container__main-section">
          <div className="end-consultation-container__main-section__panel">
            <span>
              <FormattedMessage id="consultation_end_input_title_1" />
            </span>

            <div>
              <textarea
                value={form.problem}
                style={{whiteSpace: 'pre-wrap'}}
                onChange={(event) =>
                  setForm({ ...form, problem: event.target.value })
                }
                placeholder={intl.formatMessage({
                  id: "consultation_info_page_input_placeholder",
                })}
                //onBlur={(event) => dispatch( setTextData({value: event.target.value, num: '1'}) )}
              />
            </div>
          </div>

          <div className="end-consultation-container__main-section__panel">
            <span>
              <FormattedMessage id="consultation_end_input_title_2" />
            </span>

            <div>
              <textarea
                value={form.analysis}
                style={{whiteSpace: 'pre-wrap'}}
                onChange={(event) =>
                  setForm({ ...form, analysis: event.target.value })
                }
                placeholder={intl.formatMessage({
                  id: "consultation_info_page_input_placeholder",
                })}
                //onBlur={(event) => dispatch( setTextData({value: event.target.value, num: '2'}) )}
              />
            </div>
          </div>

          <div className="end-consultation-container__main-section__panel">
            <span>
              <FormattedMessage id="consultation_end_input_title_3" />
            </span>

            <div>
              <textarea
                value={form.description}
                style={{whiteSpace: 'pre-wrap'}}
                onChange={(event) =>
                  setForm({ ...form, description: event.target.value })
                }
                placeholder={intl.formatMessage({
                  id: "consultation_info_page_input_placeholder",
                })}
                //onBlur={(event) => dispatch( setTextData({value: event.target.value, num: '3'}) )}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`end-consultation-container__bottom-control-panel`}
        style={{
          paddingLeft: isNavbarShowed ? "280px" : "20px",
        }}
      >
        <div className="end-consultation-container__bottom-control-panel__right-side">
          <span style={{ display: "" }}>
            <SmallWarningTriangleIcon />
            <FormattedMessage id="consultation_end_bottom_control_panel_warning" />
          </span>
          <div
            className={`green-button`}
            onClick={() => {
             
                dispatch(closeConsultation(form));
              
            }}
          >
            <FormattedMessage id="consultation_end_bottom_control_panel_send" />
          </div>
        </div>
      </div>
    </>
  );
};
