import { FormattedMessage } from "react-intl";
import { ActivatedPackage } from "../../../interfaces/ActivatedPackage";

import './styles.sass'

export const MyServicePanel = (props: { data: ActivatedPackage }) => {

    const data = props.data

    const setDate = () => {
            let month = data.termServiceProvision 
            switch (month) {
                case 1: return <span>{month} <FormattedMessage id='my_account_services_one_month'/></span>

                case 2:
                case 3:
                case 4:
                    return <span>{month} <FormattedMessage id='my_account_services_months'/></span>
                
                default:
                    return <span>{month} <FormattedMessage id='my_account_services_months_2'/></span>
            }
     
    }

    return (
        <div
            className="my-service-panel"
        >
            <span
                className="my-service-panel__title"
            >
                {
                    data.name
                }
            </span>

            <div
                className="my-service-panel__amount_date-wrapper"
            >
                <div>
                    <div>
                        <FormattedMessage id='my_account_services_amount' />
                    </div>
                    <span>
                        {
                            data.price
                        }
                        ₽
                    </span>
                </div>
                <div>
                    <div>
                        <FormattedMessage id='my_account_services_date' />
                    </div>
                    <span>
                        {
                            data.startDate.slice(8,10) + '.' + data.startDate.slice(5,7) + '.' + data.startDate.slice(0,4)
                        }
                    </span>
                </div>    
               
            </div>
                
            <div
                className="my-service-panel__period "
            >
                <div>
                    <div>
                        <FormattedMessage id='my_account_services_period' />
                    </div>
                    {
                        setDate()
                    }
                </div>
                <div>
                    <div>
                        Дата окончания услуги
                    </div>
                    <span>
                        {
                            data.endDate.slice(8,10) + '.' + data.endDate.slice(5,7) + '.' + data.endDate.slice(0,4)
                        }
                    </span>
                </div>   
            </div>
            
        </div>
    )
}