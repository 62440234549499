import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { StepBar } from "../../components/StepBar";

import { BottomControlPanel } from "../../components/BottomControlPanel/index";

import "./styles.sass";
import { routes } from "../../routes";
import { useEffect, useState } from "react";
import { nextStepBar } from "../../store/reducers/StepBarReducer";
import { InfoConsultant } from "../../images/InfoConsultant";
import { setTextData } from "../../store/reducers/CreatingConsultationReducer";

export const InfoConsultationContainer = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

  useEffect(() => {
    dispatch(nextStepBar(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form, setForm] = useState({
    text1: "",
    text2: "",
    text3: "",
  });

  const navigateBack = () => {
    return true;
  };

  const navigateNext = () => {
    return "";
  };

  return (
    <>
      <div
        className={`info-consultation-container ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
      >
        <div className="start-consultation-container__title-wrapper">
          <span>
            <FormattedMessage id="consultation_start_page_title" />
          </span>

          {document.body.clientWidth > 640 && <StepBar steps={4} />}
        </div>

        <div className="info-consultation-container__header">
          <span className="info-consultation-container__header__title">
            <FormattedMessage id="consultation_info_page_title" />
          </span>

          <div className="info-consultation-container__header__description">
            <div>
              <InfoConsultant />
            </div>

            <span className="info-consultation-container__header__description__text">
              <FormattedMessage id="consultation_info_page_description" />
            </span>
          </div>
        </div>

        <div className="info-consultation-container__main-section">
          <div className="info-consultation-container__main-section__panel">
            <span>
              Как эта проблема проявляется в вашем внутреннем состоянии?
              Испытываете ли Вы беспокойство, страх, гнев, ярость, грусть,
              тоску, печаль или что-то другое? Удается ли Вам сохранять темп
              жизни, работы или учебы? Удается ли вам нормально питаться,
              отдыхать, следить за своим внешним видом? Возможно, что-то другое,
              опишите Ваше состояние своими словами.
            </span>

            <div>
              <textarea
                value={form.text1}
                onChange={(event) =>
                  setForm({ ...form, text1: event.target.value })
                }
                placeholder={intl.formatMessage({
                  id: "consultation_info_page_input_placeholder",
                })}
                onBlur={(event) =>
                  dispatch(setTextData({ value: event.target.value, num: "1" }))
                }
              />
            </div>
          </div>

          <div className="info-consultation-container__main-section__panel">
            <span>
              Когда по времени возникла эта проблема, и с какими, на ваш взгляд,
              людьми она связана: с родственниками, с друзьями, с учителями, с
              начальниками или с кем-то другим?
            </span>

            <div>
              <textarea
                value={form.text2}
                onChange={(event) =>
                  setForm({ ...form, text2: event.target.value })
                }
                placeholder={intl.formatMessage({
                  id: "consultation_info_page_input_placeholder",
                })}
                onBlur={(event) =>
                  dispatch(setTextData({ value: event.target.value, num: "2" }))
                }
              />
            </div>
          </div>

          <div className="info-consultation-container__main-section__panel">
            <span>
              Кто из окружающих Вас людей знает, что у вас есть проблема, или
              они считают, что у вас все хорошо? Есть ли люди, кому Вы можете
              доверить свою проблему? Вы уже обращались ранее к кому-то за
              помощью? Каков был ее результат?
            </span>

            <div>
              <textarea
                value={form.text3}
                onChange={(event) =>
                  setForm({ ...form, text3: event.target.value })
                }
                placeholder={intl.formatMessage({
                  id: "consultation_info_page_input_placeholder",
                })}
                onBlur={(event) =>
                  dispatch(setTextData({ value: event.target.value, num: "3" }))
                }
              />
            </div>
          </div>
        </div>
      </div>

      <BottomControlPanel
        nextPage={routes.confirmConsultation}
        previousPage={routes.startMeasurement}
        backFunc={navigateBack}
        nextFunc={navigateNext}
        isNextButtonDisabled={
          !(
            form.text1.length > 0 &&
            form.text2.length > 0 &&
            form.text3.length > 0
          )
        }
      />
    </>
  );
};
