import { Operation } from '../../interfaces/Operation'

export const GET_ACCOUNT_FINANCES = 'GET_ACCOUNT_FINANCES'
export const SET_ACCOUNT_FINANCES = 'SET_ACCOUNT_FINANCES'

const initialState: Operation[] = []

export const AccountFinancesReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_ACCOUNT_FINANCES:
            return action.payload

        default:
            return state
    }
}

export const tryToGetAccountFinances = () => ({type: GET_ACCOUNT_FINANCES})
export const setAccountFinances = (payload: Operation[]) => ({type: SET_ACCOUNT_FINANCES, payload})