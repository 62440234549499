import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PersonChoosePanel } from "../../components/PersonChoosePanel";
import { PersonListSearch } from "../../components/PersonListSearch";
import { Person } from "../../interfaces/Person";

import "./styles.sass";

export const PersonsListContainer = () => {
  const [arrayPerson, setArrayPerson] = useState<any[]>([]);
  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );
  const [formSearch, setFormSearch] = useState({
    page: 0,
    name: "",
    sort: "customName, asc",
  });

  async function getPersonsListNew() {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_HOST +
          `/person/search?page=${formSearch.page}&name=${formSearch.name}&sort=${formSearch.sort}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        const result = await response.json();

        setArrayPerson(arrayPerson.concat(result));
      } else if (response.status === 401) {
      } else {
        return null;
      }
    } catch (error) {}
  }
  // const personList = useSelector( (state: any) => (state.personList) )
  const choosenPersonList: Person[] = useSelector(
    (state: any) => state.choosenPersonList
  );

  let choosenIds: string[] = [""];
  if (choosenPersonList.length > 0) {
    choosenIds = choosenPersonList.map((elem) => elem.id!);
  }
  const handleScroll = () => {
    const personListWrapper: HTMLElement | null =
      window.document.getElementById("person-list-wrapper");
    let scrollTop = personListWrapper?.scrollTop || 0;
    let clientHeight = personListWrapper?.clientHeight || 0;
    let scrollheight = personListWrapper?.scrollHeight || 0;

    if (Math.ceil(scrollTop + clientHeight) >= Math.ceil(scrollheight)) {
      setFormSearch({ ...formSearch, page: (formSearch.page += 1) });
    }
  };

  useEffect(() => {
    getPersonsListNew();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSearch]);

  return (
    <div
      className={`persons-measurement-container ${
        isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
      }`}
      id="person-list-wrapper"
      onScroll={() => handleScroll()}
    >
      <PersonListSearch
        formSet={setFormSearch}
        formSearch={formSearch}
        setArrayPerson={setArrayPerson}
      />

      <div className="listPersonsBox">
        {arrayPerson &&
          arrayPerson.length > 0 &&
          arrayPerson.map((elem, index) => {
            return (
              <div key={"person-list-" + index}>
                <PersonChoosePanel
                  data={elem}
                  isChoosen={choosenIds.indexOf(elem.id!) !== -1}
                  getPersonsListNew={getPersonsListNew}
                  setArrayPerson={setArrayPerson}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
