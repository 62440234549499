//import { useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"

import {RelationType} from '../../../interfaces/RelationType'

import './styles.sass'

const typesColors = {
    relatives: '#6CB4EE',
    mentors: '#23649B',
    others: '#FFC5AD',
    peers: '#FF966A',
    norming: '#6991B3',
    free: '#FFB08F'
}

export const TypeDesc = (props: {textId: string, backgroundColor: string; percentage: string}) => (
    <div
        className="types-ratio-diagram__header__column__type-panel"
    >
        <div className="types-ratio-diagram__header__column__type-panel__color" style={{background: props.backgroundColor}}/>
        <span>
            <FormattedMessage id={props.textId}/>
        </span>
        <div
            className="types-ratio-diagram__header__column__type-panel__percentage"
        >
            {
               props.percentage+'%' 
            }
        </div>
    </div>
)

export const TypesRationDiagram = () => {

    const measurementStatistic: RelationType = useSelector( (state: any) => (state.measurementStatistic)).relationType || {
        description: '',
        dominantTypeBehaviour: '',
        freeBehavior: '',
        percentFamily: '',
        percentMentor: '',
        percentNormalizingInfluence: '',
        percentOther: '',
        percentPeer: '',
    }

    return (
        <div
            className="types-ratio-diagram"
        >
            <span  
                className="types-ratio-diagram__main-text" dangerouslySetInnerHTML={{__html: measurementStatistic.description}}
            >
               
            </span>

            <div
                className="types-ratio-diagram__header"
            >
                <div className="types-ratio-diagram__header__column">
                    <TypeDesc 
                        textId={'measurement_statistic_types_ratio_type_relatives'}
                        percentage={measurementStatistic.percentFamily}
                        backgroundColor={typesColors.relatives}
                    />
                    <TypeDesc 
                        textId={'measurement_statistic_types_ratio_type_mentors'}
                        percentage={measurementStatistic.percentMentor}
                        backgroundColor={typesColors.mentors}
                    />
                </div>
                <div className="types-ratio-diagram__header__column">
                    <TypeDesc 
                        textId={'measurement_statistic_types_ratio_type_peers'}
                        percentage={measurementStatistic.percentPeer}
                        backgroundColor={typesColors.peers}
                    />
                    <TypeDesc 
                        textId={'measurement_statistic_types_ratio_type_others'}
                        percentage={measurementStatistic.percentOther}
                        backgroundColor={typesColors.others}
                    />
                </div>
            </div>

            <div
                className="types-ratio-diagram__graphic"
            >
                <div
                    style={{
                        background: typesColors.relatives,
                        width: measurementStatistic.percentFamily + '%',
                    }}
                >
                    <span>
                        {
                            measurementStatistic.percentFamily+ '%'
                        }
                    </span>
                </div>
                <div
                    style={{
                        background: typesColors.mentors,
                        width: measurementStatistic.percentMentor + '%',
                    }}
                >
                    <span>
                        {
                            
                            measurementStatistic.percentMentor + '%'
                        }
                    </span>
                </div>
                <div
                    style={{
                        background: typesColors.peers,
                        width: measurementStatistic.percentPeer+ '%',
                    }}
                >
                    <span>
                        {
                            measurementStatistic.percentPeer !== '0.0'
                            ?
                            measurementStatistic.percentPeer + '%'
                            :
                            <></>
                        }
                    </span>
                </div>
                <div
                    style={{
                        background: typesColors.others,
                        width: measurementStatistic.percentOther + '%',
                    }}
                >
                    <span>
                        {
                            measurementStatistic.percentOther !== '0.0'
                            ?
                            measurementStatistic.percentOther + '%'
                            :
                            <></>
                        }
                    </span>
                </div>
                
                <div className="types-ratio-diagram__graphic__line"/>

                <div className="types-ratio-diagram__graphic__vertical-line" style={{
                    opacity: measurementStatistic.percentFamily!=='0.0'?'1':'0', left: `calc(${measurementStatistic.percentFamily}% - 1.5px)`}} />
                <div className="types-ratio-diagram__graphic__vertical-line" style={{
                    opacity: measurementStatistic.percentFamily!=='0.0'&&measurementStatistic.percentMentor!=='0.0' ?'1':'0',
                    left: `calc(${ Number(measurementStatistic.percentFamily)+Number(measurementStatistic.percentMentor)}% - 1.5px)`
                    }} 
                />{
                    
                }
                <div className="types-ratio-diagram__graphic__vertical-line" 
                    style={{
                        opacity: measurementStatistic.percentFamily!=='0.0'&& measurementStatistic.percentMentor!=='0.0'&&measurementStatistic.percentPeer!=='0.0' ?'1':'0',
                        left: `calc(${Number(measurementStatistic.percentFamily)+Number(measurementStatistic.percentMentor)+Number(measurementStatistic.percentPeer)}% - 1.5px)`
                    }} 
                />
            </div>

            <div
                className="types-ratio-diagram__axis"
            >
                <div className="types-ratio-diagram__axis__line"/>
                <div className="types-ratio-diagram__axis__vertical-line" style={{left: '0%'}}/>
                <div className="types-ratio-diagram__axis__vertical-line" style={{right: '0%'}}/>
                <span>
                    100 %
                </span>
            </div>

            <div
                className="types-ratio-diagram__graphic small-graphic"
            >
                <div
                    style={{
                        background: typesColors.norming,
                        width: measurementStatistic.percentNormalizingInfluence + '%',
                    }}
                >
                    <span>
                        {
                            measurementStatistic.percentNormalizingInfluence !== '0.0'
                            ?
                            measurementStatistic.percentNormalizingInfluence + '%'
                            :
                            <></>
                        }
                    </span>
                </div>
                <div
                    style={{
                        background: typesColors.free,
                        width: measurementStatistic.freeBehavior + '%',
                    }}
                >
                    <span>
                        {
                            measurementStatistic.freeBehavior !== '0.0'
                            ?
                            measurementStatistic.freeBehavior + '%'
                            :
                            <></>
                        }
                    </span>
                </div>
                
                <div className="types-ratio-diagram__graphic__line small-line"/>

                <div className="types-ratio-diagram__graphic__vertical-line" style={{opacity: measurementStatistic.freeBehavior!=='0.0'&&measurementStatistic.percentNormalizingInfluence!=='0.0'?'1':'0', left: `calc(${measurementStatistic.percentNormalizingInfluence}% - 1.5px)`}} />
            </div>

            <div
                className="types-ratio-diagram__header"
            >
                <div className="types-ratio-diagram__header__column">
                    <TypeDesc 
                        textId={'measurement_statistic_types_ratio_type_norming'}
                        percentage={measurementStatistic.percentNormalizingInfluence}
                        backgroundColor={typesColors.norming}
                    />
                    <TypeDesc 
                        textId={'measurement_statistic_types_ratio_type_free'}
                        percentage={measurementStatistic.freeBehavior}
                        backgroundColor={typesColors.free}
                    />
                </div>
            </div>

            <div
                className="types-ratio-diagram__additional-text"
            >
                <span>
                    {
                        measurementStatistic.typeName
                    }
                </span>
                <div dangerouslySetInnerHTML={{__html: measurementStatistic.dominantTypeBehaviour || ''}} />

            </div>
        </div>
    )
}