import { useSelector } from "react-redux"

import './styles.sass'

export const ChoosenMeasurementTypeCardCircles = (prop: {array: Array<string> , Meas?: any}) => {
    
    const choosenMeasurementTypeId = useSelector( (state: any) => (state.measurementType.id))
    function getScrollDiv(index){
        const wrapper = window.document.getElementById('measurement-types-wrapper');
        if (wrapper){
            if (index == prop.array.length-1){
                
                wrapper.scrollLeft = index * ((prop.Meas.scrollWidth-800) / Number(prop.array.length)) 
            } else {
                wrapper.scrollLeft = index * ((prop.Meas.scrollWidth-800) / Number(prop.array.length))
            }
            
        }
        
    }
    return (
        <div className="choosen-measurement-type-card-circles-wrapper">
            {
                prop.array.map( (elem, index) => {
                    if (elem === choosenMeasurementTypeId) {
                        return <div key={index} className="green-circle forNextCard" onClick={()=>{ getScrollDiv(index)}}/>
                    } else {
                        return <div key={index} className="white-circle forNextCard" onClick={()=>{ getScrollDiv(index)}}/>
                    }
                })
            }
        </div>
    )
}