import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Consultation } from "../../interfaces/Consultation";
import {
  setConsultationReviewRate,
  setConsultationReviewText,
  tryToSendConsultationReview,
} from "../../store/reducers/ConsultationReviewReducer";
import store from "../../store";
import "./styles.sass";

const rateArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const MobileChooseConsultationRatePanel = (props: { id: string }) => {
  const dispatch = useDispatch();

  const currentRate = useSelector((state: any) => state.consultationReview)[
    props.id
  ];

  useEffect(() => {
    //slider
    //slider
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(currentRate);
  return (
    <div className="mobile-choose-consultation-rate-panel-wrapper">
      <div className="mobile-choose-consultation-rate-panel-wrapper__header">
        <div>1</div>
        <div>5</div>
        <div>10</div>
      </div>
      <div className="mobile-choose-consultation-rate-panel">
        {rateArray.map((elem) => (
          <div
            key={"mobile-choose-consultation-rate-panel" + elem}
            className={`${
              currentRate == elem && "choosen"
            } mobile-choose-consultation-rate-panel__dot`}
            onClick={() => {
              dispatch(setConsultationReviewRate({ id: props.id, rate: elem }));
            }}
          />
        ))}

        <div
          className="drag-icon"
          id={"dragging-rate-consultation-icon" + props.id}
          style={{
            left:
              currentRate === -1
                ? "-15px"
                : currentRate === 2
                ? "21px"
                : currentRate === 3
                ? "56px"
                : currentRate === 4
                ? "91px"
                : currentRate === 5
                ? "126px"
                : currentRate === 6
                ? "162px"
                : currentRate === 7
                ? "197px"
                : currentRate === 8
                ? "232px"
                : currentRate === 9
                ? "267px"
                : currentRate === 10
                ? "302px"
                : currentRate === 1
                ? "-15px"
                : "-15px",
          }}
        />
        <div
          className="mobile-choose-consultation-rate-panel__progress-bar"
          style={{
            width:
              currentRate === -1
                ? "25px"
                : currentRate === 2
                ? "60px"
                : currentRate === 3
                ? "95px"
                : currentRate === 4
                ? "130px"
                : currentRate === 5
                ? "165px"
                : currentRate === 6
                ? "200px"
                : currentRate === 7
                ? "235px"
                : currentRate === 8
                ? "270px"
                : currentRate === 9
                ? "305px"
                : currentRate === 10
                ? "340px"
                : currentRate === 10
                ? "25px"
                : "25px",
          }}
        />
      </div>
    </div>
  );
};

const DesktopChooseConsultationRatePanel = (props: { id: string }) => {
  const dispatch = useDispatch();

  const currentRate = useSelector((state: any) => state.consultationReview)[
    props.id
  ];

  return (
    <div className="desktop-choose-consultation-rate-panel">
      <div className="desktop-choose-consultation-rate-panel__line" />
      {rateArray.map((elem) => {
        return (
          <div
            key={
              "desktop-choose-consultation-rate-panel__rating" + elem + props.id
            }
            className={`${
              currentRate === elem && "choosen"
            } desktop-choose-consultation-rate-panel__rating`}
            style={{
              marginRight: elem === 10 ? "0px" : "16px",
            }}
            onClick={() =>
              dispatch(setConsultationReviewRate({ id: props.id, rate: elem }))
            }
          >
            {elem}
          </div>
        );
      })}
    </div>
  );
};

const QuestionForm = (props: { id: string }) => {
  return (
    <div className="consultation-review-wrapper__question-panel">
      <span className="consultation-review-wrapper__question-panel__title">
        Общее качество
      </span>
      <span className="consultation-review-wrapper__question-panel__sub-title">
        <FormattedMessage id={`consultation_review_question_${props.id}`} />
      </span>
      <div>
        {document.body.clientWidth > 640 ? (
          <DesktopChooseConsultationRatePanel id={props.id} />
        ) : (
          <MobileChooseConsultationRatePanel id={props.id} />
        )}
      </div>
    </div>
  );
};

export const ConsultationReviewContainer = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const review = store.getState().consultationReview;
  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

  const consultation: Consultation | null = useSelector(
    (state: any) => state.currentConsultation
  );
  const [fullAreas, isFullAreas] = useState(false);
  const currentText = useSelector(
    (state: any) => state.consultationReview
  ).text;

  return (
    <>
      <div
        className={`consultation-review-wrapper ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
      >
        <span className="consultation-review-wrapper__title">
          <FormattedMessage id="consultation_review_title" />
        </span>
        <span className="consultation-review-wrapper__title">
          <FormattedMessage id="consultation_review_sub_title" />
          {consultation && consultation.adresseeName}
        </span>

        <QuestionForm id="1" />
        {/*  <QuestionForm id='2' />
                <QuestionForm id='3' /> */}

        <div className="consultation-review-wrapper__text-panel">
          <span>
            <FormattedMessage id="consultation_review_text_title" />
          </span>
          <div>
            <textarea
              value={currentText}
              onChange={(event) =>
                dispatch(setConsultationReviewText(event.target.value))
              }
              placeholder={intl.formatMessage({
                id: "consultation_review_text_placeholder",
              })}
              //onBlur={(event) => dispatch( setTextData({value: event.target.value, num: '2'}) )}
            />
          </div>
        </div>
      </div>
      <div className="consultation-review-wrapper__bottom-control-panel">
        {" "}
        {fullAreas && (
          <div
            style={{
              position: "absolute",

              right: "165px",
              color: "red",
              fontWeight: "bold",
            }}
          >
            Заполните все поля
          </div>
        )}
        <div
          className="green-button"
          onClick={() => {
            if (review[1] != -1) {
              isFullAreas(false);
              dispatch(tryToSendConsultationReview());
            } else {
              isFullAreas(true);
            }
          }}
        >
          <FormattedMessage id="consultation_review_text_end" />
        </div>
      </div>
    </>
  );
};
