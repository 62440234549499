import { call, put, takeEvery } from "redux-saga/effects";
import { User } from "../interfaces/User";
import { apiUrl } from "../consts";
import { setAccountMainData, TRY_TO_CHANGE_ACCOUNT_MAIN_DATA } from "../store/reducers/AccountMainDataReducer";
import { setUser } from "../store/reducers/userReducer";
import { routes } from "../routes";

const asyncUpdateAccountMainData = async (account: User) => {
    try { 
        if (account !== undefined) {
            let date = ''

            let fio: string | undefined = account.fio
            let cDate = account.birthDay || ''
            if (typeof fio === 'string' && typeof cDate === 'string') {
                if (cDate && cDate.length >0 && cDate.length !== 10) {
                    date = new Date().toISOString().slice(0,10)
                } else {
                    date = cDate
                }
                
            const response = await fetch(
                apiUrl + '/account',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/problem+json',
                        'Authorization': 'Bearer ' + localStorage.token
                    },
                    body: JSON.stringify({
                        ...account,
                        fio: account?.fio,
                        birthDay: date
                    })
                }
            )
            if (response.status === 200) {
                const result = await response.json()
                return result 
            } else if (response.status === 401) {
                location.assign(routes.login)
            } else {
                return null
            }
        }
        }
    } catch(error) {}
}

function* UpdateAccountMainDataWorker(action) {
    const data: User | null = yield call(asyncUpdateAccountMainData, action.payload)
    if (data && data.login) {
        yield put( setUser(data) )
        yield put( setAccountMainData( data ) )
    }
}

export function* UpdateAccountMainDataWatcher() {
    yield takeEvery(TRY_TO_CHANGE_ACCOUNT_MAIN_DATA, UpdateAccountMainDataWorker)
}