import { Consultant } from "../../interfaces/Consultant"

export const CLOSE_MODAL = 'CLOSE_MODAL'
export const SHOW_DELETE_PERSON_FROM_MEASUREMENT = 'SHOW_DELETE_PERSON_FROM_MEASUREMENT'
export const SHOW_EDIT_PERSON_MEASUREMENT = 'SHOW_EDIT_PERSON_MEASUREMENT'
export const SHOW_ADD_MEASUREMENT_TO_CONSULTATION_MODAL = 'SHOW_ADD_MEASUREMENT_TO_CONSULTATION_MODAL'
export const SHOW_CONSULTANT_MODAL = 'SHOW_CONSULTANT_MODAL'
export const SHOW_ADD_NOTIFICATION_SETTING_MODAL = 'SHOW_ADD_NOTIFICATION_SETTING_MODAL'
export const SHOW_CONSULTATION_CHAT_SET_VALUE_MODAL = 'SHOW_CONSULTATION_CHAT_SET_VALUE_MODAL'
export const SHOW_CONSULTATION_CHAT_CANCEL_VALUE_MODAL = 'SHOW_CONSULTATION_CHAT_CANCEL_VALUE_MODAL'
export const SHOW_DELETE_MEASUREMENT_CONSULTATION_MODAL = 'SHOW_DELETE_MEASUREMENT_CONSULTATION_MODAL'
export const SHOW_CANCEL_CONSULTATION_MODAL = 'SHOW_CANCEL_CONSULTATION_MODAL'

const initialState: any = {
    modal: '',
    modalInfo: ''
}

export const ModalWindowsReducer = (state = initialState, action) => {

    switch (action.type) {

        case CLOSE_MODAL:
            return {
                modal: '',
                modalInfo: ''
            }

        case SHOW_DELETE_PERSON_FROM_MEASUREMENT:
            return {
                modal: 'DELETE_PERSON',
                modalInfo: action.payload
            }

        case SHOW_EDIT_PERSON_MEASUREMENT:
            return  {
                modal: 'EDIT_PERSON',
                modalInfo: action.payload
            }

        case SHOW_ADD_MEASUREMENT_TO_CONSULTATION_MODAL:
            return {
                modal: 'ADD_MEASUREMENT_TO_CONSULTATION',
                modalInfo: {}
            }

        case SHOW_CONSULTANT_MODAL:
            return {
                modal: 'CONSULTANT_MODAL',
                modalInfo: action.payload
            }

        case SHOW_ADD_NOTIFICATION_SETTING_MODAL:
            return {
                modal: 'NOTIFICATION_SETTING_MODAL',
                modalInfo: {}
            }

        case SHOW_CONSULTATION_CHAT_SET_VALUE_MODAL:
            return {
                modal: SHOW_CONSULTATION_CHAT_SET_VALUE_MODAL,
                modalInfo: {}
            }

        case SHOW_DELETE_MEASUREMENT_CONSULTATION_MODAL:
            return {
                modal: SHOW_DELETE_MEASUREMENT_CONSULTATION_MODAL,
                modalInfo: action.payload
            }

        case SHOW_CANCEL_CONSULTATION_MODAL:
            return {
                modal: SHOW_CANCEL_CONSULTATION_MODAL,
                modalInfo: {}
            }

        case SHOW_CONSULTATION_CHAT_CANCEL_VALUE_MODAL:
            return {
                modal: SHOW_CONSULTATION_CHAT_CANCEL_VALUE_MODAL,
                modalInfo: {
                    id: action.payload
                }
            }

        default:
            return state
    }
}

export const closeModal = () => ({type: CLOSE_MODAL})
export const showDeletePersonFromMeasurementModal = (payload: any) => ({type: SHOW_DELETE_PERSON_FROM_MEASUREMENT, payload})
export const showEditPersonMeasurementModal = (payload: any) => ({type: SHOW_EDIT_PERSON_MEASUREMENT, payload})
export const showConsultantModal = (payload: Consultant) => ({type: SHOW_CONSULTANT_MODAL, payload})
export const showAddMeasurementToConsultationModal = () => ({type: SHOW_ADD_MEASUREMENT_TO_CONSULTATION_MODAL})
export const showAddNotificationSettingModal = () => ({type: SHOW_ADD_NOTIFICATION_SETTING_MODAL})
export const showConsultationChatSetValueModal = () => ({type: SHOW_CONSULTATION_CHAT_SET_VALUE_MODAL})
export const showConsultationChatCancelValueModal = (payload: string) => ({type: SHOW_CONSULTATION_CHAT_CANCEL_VALUE_MODAL, payload})
export const showDeleteMeasurementConsultationModal = (payload: any) => ({type: SHOW_DELETE_MEASUREMENT_CONSULTATION_MODAL, payload})
export const showCancelConsultationModal = () => ({type: SHOW_CANCEL_CONSULTATION_MODAL})