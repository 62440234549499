import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { UserTask } from "../interfaces/UserTask";
import { routes } from "../routes";
import { setUserTaskList, TRY_TO_GET_USER_TASK_LIST } from "../store/reducers/UserTaskReducer";

export const asyncGetUserTaskList = async () => {
    try {

        const response = await fetch(
            apiUrl + '/task/',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            }
        )

        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch(error) {}
}

function* GetUserTaskListWorker() {
    const data: UserTask[] | null = yield call(asyncGetUserTaskList)
    if (data && data.length > 0) {
        yield put( setUserTaskList(data) )
    }
}

export function* GetUserTaskListWatcher() {
    yield takeEvery(TRY_TO_GET_USER_TASK_LIST, GetUserTaskListWorker)
}