
export const TRY_TO_CHANGE_ACCOUNT_PASSWORD = 'TRY_TO_CHANGE_ACCOUNT_PASSWORD'
export const SET_IS_PASSWORD_CHANGING_SUCCESS = 'SET_IS_PASSWORD_CHANGING_SUCCESS'
export const SET_IS_PASSWORD_CHANGING_ERROR = 'SET_IS_PASSWORD_CHANGING_ERROR'

export interface ChangingPasswordInterface {
    currentPassword: string;
    newPassword: string;
}

const initialState: number = 0

export const AccountChangePasswordReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_IS_PASSWORD_CHANGING_SUCCESS:
            return 1
        
        case SET_IS_PASSWORD_CHANGING_ERROR:
            return 2

        default:
            return state
    }
}

export const tryToChangeAccountPassword = (payload: ChangingPasswordInterface) => ({type: TRY_TO_CHANGE_ACCOUNT_PASSWORD, payload})
export const setIsPasswordChangingSuccess = () => ({type: SET_IS_PASSWORD_CHANGING_SUCCESS})
export const setIsPasswordChangingError = () => ({type: SET_IS_PASSWORD_CHANGING_ERROR})