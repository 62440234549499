import { call, takeEvery } from "@redux-saga/core/effects";
import { TRY_TO_SEND_CONSULTATION_REVIEW } from "../store/reducers/ConsultationReviewReducer";

import { routes } from '../routes'
import store from "../store";
import { apiUrl } from "../consts";

const asyncSendConsultationReview = async () => {
    try {
        const consultation = store.getState().currentConsultation
        const review = store.getState().consultationReview

        const response = await fetch(
            apiUrl + '/consultation/review/',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    id: consultation?.id,
                    generalImpression: `${review['1']}`,
                    qualityCommunication: `${review['2']}`,
                    practicalSignificance: `${review['3']}`,
                    text: review.text
                })
            }
        )

        if (response.status === 200) {
            return true
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return false
        }
    } catch(err) {}
}

function* SendConsultationReviewWorker() {
    const data:boolean = yield call(asyncSendConsultationReview)

    if (data) {
        location.assign(routes.consultations)
    }
}

export function* SendConsultationReviewWacther() {
    yield takeEvery(TRY_TO_SEND_CONSULTATION_REVIEW, SendConsultationReviewWorker)
}