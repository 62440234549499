import {NEXT_SIGNUP_STAGE, BACK_SIGNUP_STAGE, NEXT_LOGIN_RECOVERY_STAGE, BACK_LOGIN_RECOVERY_STAGE} from '../types'

const initialState = {
    signup_stage: '1',
    login_recovery_stage: '1',
}

export const VerticalStepBarReducer = (state = initialState, action) => {
    switch (action.type) {

        case NEXT_SIGNUP_STAGE:
            return {
                ...state,
                signup_stage: '2'
            }

        case BACK_SIGNUP_STAGE:
            return {
                ...state,
                signup_stage: '1'
            }
        
        case NEXT_LOGIN_RECOVERY_STAGE:
            return {
                ...state,
                login_recovery_stage: '2'
            }

        case BACK_LOGIN_RECOVERY_STAGE:
            return {
                ...state,
                login_recovery_stage: '1'
            }

        default: 
            return {...state}
    }
}

export const nextSignupStage = () => ({type: NEXT_SIGNUP_STAGE})
export const nextLoginRecoveryStepbarState = () => ({type: NEXT_LOGIN_RECOVERY_STAGE})