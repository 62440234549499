import { FormattedMessage } from "react-intl";

import "./styles.sass";

import {
  BlackMenuFoldIcon,
  NotificationBellIcon,
  OnePersonIcon,
  OpeningSimpleArrowIcon,
} from "../../icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { exit } from "../../store/reducers/LoginReducer";
import { toggleIsNavbarShowed } from "../../store/reducers/NavbarReducer";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../logo.svg";
import { routes } from "../../routes";
import { useLocation } from "react-router-dom";
import { User } from "../../interfaces/User";
import store from "../../store";

export const Navbar = ({ notifications }) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const accountData: User | null = useSelector((state: any) => state.user);

  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );
  const [isAccountPanelOpened, setIsAccountPanelOpened] =
    useState<boolean>(false);

  const toggleIsAccountPanelOpened = () => {
    if (isAccountPanelOpened) {
      setIsAccountPanelOpened(false);
    } else {
      setIsAccountPanelOpened(true);
    }
  };

  const handleExit = () => {
    localStorage.token = "";
    store.dispatch({
      type: "STORE_RESET",
    });
    Navigate(routes.login);
    dispatch(exit());
  };

  const closeNavbar = () => {
    dispatch(toggleIsNavbarShowed());
  };
  useEffect(() => {
    if (window.innerWidth < 640) {
      let titles = document.getElementsByClassName("navbar-title");
      for (let i = 0; i < titles.length; i++) {
        titles[i].addEventListener("click", () => {
          closeNavbar();
        });
      }
      closeNavbar();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={`navbar-wrapper ${isNavbarShowed && "navbar-wrapper-opened"}`}
    >
      <div className="navbar-logo">
        <Link to={routes.measurementsList}>
          <img src={logo} />
        </Link>
      </div>

      <span
        className="navbar-title"
        onClick={() => {
          Navigate(routes.notificationsMessages);
        }}
      >
        <NotificationBellIcon />

        <FormattedMessage id="navbar_notifications" />
        {notifications != 0 && (
          <div className="notifications__number">{notifications}</div>
        )}
      </span>
      <div className={`navbar-account`}>
        <div
          className="navbar-account__info-wrapper"
          onClick={toggleIsAccountPanelOpened}
        >
          <div>
            <div className="navbar-account__info-wrapper__circle">
              {(accountData && accountData.photo && (
                <img src={accountData.photo} />
              )) || <OnePersonIcon />}
            </div>
            <span className="navbar-account__info-wrapper__name">
              {accountData ? accountData.fio : <>ВЫ</>}
            </span>
          </div>

          <span className={`openingArrow ${isAccountPanelOpened && "rotated"}`}>
            <OpeningSimpleArrowIcon />
          </span>
        </div>
        <div
          className={`navbar-account-panel ${
            isAccountPanelOpened && "navbar-account-panel-opened"
          }`}
        >
          <span
            className={`navbar-title ${
              pathname === routes.myAccount && "current-navbar-title"
            }`}
          >
            <Link to={routes.myAccount}>
              <FormattedMessage id="navbar_account_your_cabinet" />
            </Link>
          </span>
          <span
            className={`navbar-title  ${
              pathname === routes.myServices && "current-navbar-title"
            }`}
          >
            <Link to={routes.myServices}>
              <FormattedMessage id="navbar_account_services" />
            </Link>
          </span>
          <span
            className={`navbar-title ${
              pathname === routes.finances && "current-navbar-title"
            }`}
          >
            <Link to={routes.finances}>
              <FormattedMessage id="navbar_account_finances" />
            </Link>
          </span>
          {/* <span className={`navbar-title`}>
                        <Link to={routes.myAccount}>
                            <FormattedMessage id='navbar_account_arbitrage'/>
                        </Link>
                    </span> */}
        </div>
      </div>
      <span
        className={`navbar-title ${
          pathname === routes.offices && "current-navbar-title"
        }`}
      >
        <Link to={routes.offices}>
          Мои офисы
        </Link>
      </span>
      <span
        className={`navbar-title ${
          pathname === routes.measurementsList && "current-navbar-title"
        }`}
      >
        <Link to={routes.measurementsList}>
          <FormattedMessage id="navbar_list_measures" />
        </Link>
      </span>
      <span
        className={`navbar-title ${
          pathname === routes.persons && "current-navbar-title"
        }`}
      >
        <Link to={routes.persons}>
          <FormattedMessage id="navbar_list_partners" />
        </Link>
      </span>
      <span
        className={`navbar-title ${
          pathname === routes.consultations && "current-navbar-title"
        }`}
      >
        <Link to={routes.consultations}>
          <FormattedMessage id="navbar_consultions" />
        </Link>
      </span>
      {accountData?.authorities?.includes("CONSULTANT") && (
        <span
          className={`navbar-title ${
            pathname === routes.consultationForConsultant &&
            "current-navbar-title"
          }`}
        >
          <Link to={routes.consultationForConsultant}>Обращения</Link>
        </span>
      )}
      <span
        className={`navbar-title ${
          pathname === routes.consultants && "current-navbar-title"
        }`}
      >
        <Link to={routes.consultants}>
          <FormattedMessage id="navbar_consultants" />
        </Link>
      </span>
      {/*   <span className='navbar-title'>
                <Link to={routes.templates}>
                    <FormattedMessage id='navbar_templates'/>
                </Link>
            </span> */}
      <span
        className={`navbar-title ${
          pathname === routes.articles && "current-navbar-title"
        }`}
      >
        <Link to={routes.articles}>
          <FormattedMessage id="navbar_help" />
        </Link>
      </span>
      <span className="navbar-title" onClick={handleExit}>
        <FormattedMessage id="navbar_exit" />
      </span>

      <div className="navbar-toggle-menu-state-panel" onClick={closeNavbar}>
        <span>
          <BlackMenuFoldIcon />
        </span>
      </div>
    </div>
  );
};
