
const initialState = {
    isArticleSidebarOpened: false,
}

export const ArticleSidebarNavigationReducer = (state = initialState, action: any) => {

    switch (action.type) {

        case 'TOGGLE_IS_ARTICLE_SIDEBAR_OPENED':
            if (state.isArticleSidebarOpened) {
                return {
                    isArticleSidebarOpened: false
                }
            } else {
                return {
                    isArticleSidebarOpened: true
                }
            }
            return state
        default: 
            return state
    }
    
}