import { useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { WarningYellowIcon } from "../../../icons"
import { closeModal } from "../../../store/reducers/ModalWindowsReducer"
import { tryToRejectConsultation } from "../../../store/reducers/RejectConsultationReducer"
import { routes } from '../../../routes'

export const RejectConsultationModal = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isRejectingSuccess = useSelector( (state: any) => (state.rejectConsultation))

    useEffect( () => {

        if (isRejectingSuccess) {

            dispatch(closeModal())
           
            navigate(routes.consultations)
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRejectingSuccess])

    return (
        <div
            className='delete-measurement-consultation-modal-wrapper'
        >
            <div
                className='delete-measurement-consultation-modal'
            >

                <span
                    className='delete-measurement-consultation-modal__title'
                >
                    <FormattedMessage id='consultations_chat_reject_modal_title' />
                </span>

                <div 
                    className='delete-measurement-consultation-modal__row'
                >
                    <div>
                        <WarningYellowIcon/>
                    </div>

                    <div 
                        className='delete-measurement-consultation-modal__row__main'
                    >

                        <span
                            className='delete-measurement-consultation-modal__row__main__title'
                        >
                            <FormattedMessage id='consultations_chat_reject_modal_desc' />
                        </span>

                        <div
                            className='delete-measurement-consultation-modal__row__main__buttons'
                        >

                            <div
                                className='white-button'
                                onClick={() => dispatch(closeModal())}
                            >
                                <FormattedMessage id='consultation_chat_delete_measurement_button_cancel'/>
                            </div>

                            <div
                                className='green-button'
                                onClick={() => {
                                        dispatch(tryToRejectConsultation())
                                        dispatch(closeModal())
                                    
                                }}
                            >
                                <FormattedMessage id='consultations_chat_reject_modal_reject'/>
                            </div>
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
    )
}