import { Consultant } from '../../interfaces/Consultant'

export const GET_CONSULTANTS_LIST = 'GET_CONSULTANTS_LIST'
export const SET_CONSULTANTS_LIST = 'SET_CONSULTANTS_LIST'
export const FIND_FILTER_CONSULTANTS_LIST = 'FIND_FILTER_CONSULTANTS_LIST'

const initialState: {
    page: number;
    list: Consultant[]
} = {
    page: 0,
    list: []
}

export const ConsultantsListReducer = (state = initialState, action) => {
    
    switch (action.type) {
       
        case SET_CONSULTANTS_LIST:
            if (action.payload.page === 0) {
               
                return {
                    page: 1,
                    list: action.payload.list
                }
            } 
            else if (state.list[state.list.length-1].id !== action.payload.list[action.payload.list.length-1].id ) {
              
                return {
                    page: action.payload,
                    list: [...state.list, ...action.payload.list]
                }
            } 
            else if (action.payload == 0) {
               
               return {
                    page: state.page,
                    list: []
                }
            } else {
               
                return {
                   
                    page: action.payload,
                    list: state.list
                }
            }

        default:
            if (action.payload == 0){
                return {
                    page: 0,
                    list: []
                }
            } else{
            return state
            }
    }
}

export const getConsultantsList = (payload: number) => ({type: GET_CONSULTANTS_LIST, payload})
export const setConsultantsList = (payload: { page: number; list: Consultant[] } ) => ({ type: SET_CONSULTANTS_LIST, payload })
export const findFilterConsultantsList = (payload: any) => ({type: FIND_FILTER_CONSULTANTS_LIST, payload})
