import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { Consultation } from "../interfaces/Consultation";
import store from "../store";
import { ADD_MEASUREMENT_LIST_TO_CONSULTATION, setCurrentConsultation } from "../store/reducers/CurrentConsultationReducer";
import { routes } from '../routes'

const asyncAddMeasurementToConsultation = async (newMeasurements: any[]) => {
    try {
        
        const consultation: Consultation = store.getState().currentConsultation
     
        const newIds = newMeasurements.map( elem => ({date: elem.creationDate, id: elem.measurementId}))
    
        let measurements = [...consultation.measurements, ...newIds]
       
        const response = await fetch(
            apiUrl + '/consultation/edit/',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    ...consultation,
                    measurements: measurements
                })
            }
        )

        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch (err) {}
}

function* AddMeasurementToConsultationWorker(action) {
    const data: Consultation | null = yield call(asyncAddMeasurementToConsultation, action.payload)

    if (data && data.id) {
        yield put( setCurrentConsultation(data))
    }
}

export function* AddMeasurementToConsultationWatcher() {
    yield takeEvery(ADD_MEASUREMENT_LIST_TO_CONSULTATION, AddMeasurementToConsultationWorker)
}