import { useEffect, useState } from "react";
import "./style.sass";
export const PaymentContainer = () => {
  const [pay, setPay] = useState<any>({});
  const [error1, setError] = useState(false);
  const getParam = () => {
    //let query = newUrlSearchParam()
    let url = new URL(document.location.href);
    let key = url.searchParams.get("InvId");

    fetch(`${process.env.REACT_APP_API_HOST}/payment/${key} `, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPay(data);
      });
  };
  function checkPay() {
    let url = new URL(document.location.href);
    let key = url.searchParams.get("InvId");
    fetch(`${process.env.REACT_APP_API_HOST}/payment/check-invoice/${key}`, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data.valid) {
          document.location.href = pay.link;
        } else {
          setError(true);
        }
      });
  }

  useEffect(() => {
    getParam();
  }, []);
  return (
    <div className="payment_style">
      {pay.confirm && !pay.canceled ? (
        <div>
          <h1>Консультация оплачена</h1>
        </div>
      ) : pay.canceled ? (
        <h1>Счет на оплату консультации отменен</h1>
      ) : (
        <div className="get_cons">
          <h1>Подтвердите Оплату</h1>
          <div>
            <p>Имя консультанта:</p>
            <span>{pay.consultantName || ""}</span>
          </div>
          <div>
            <p>Сумма оплаты:</p>
            <span>{pay.amount + " руб." || ""}</span>
          </div>

          <div
            style={error1 ? { pointerEvents: "none", opacity: 0.5 } : {}}
            className="green-button"
            onClick={() => checkPay()}
          >
            Оплатить
          </div>
        </div>
      )}
    </div>
  );
};
