import {VerticalShortStepBar} from '../../components/VerticalShortStepBar/index'
import {LoginPasswordRecoveryFirstStage} from '../../components/LoginPasswordRecoveryFirstStage/index'
import {LoginPasswordRecoverySecondStage} from '../../components/LoginPasswordRecoverySecondStage/index'

import './styles.sass'
import { useSelector } from 'react-redux'

export const LoginRecoveryContainer = () => {

    const stage = useSelector( (state: any) => state.verticalStepBar.login_recovery_stage)

    return (
        <div className='login-recovery-container'>
            <VerticalShortStepBar typeOfStepBar='loginRecovery'/>
            {
                stage == '1'
                ?
                <><LoginPasswordRecoveryFirstStage /></>
                :
                <><LoginPasswordRecoverySecondStage /></>
            }
        </div>
    )
}