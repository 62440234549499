import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { routes } from "../routes";
import { setConsultationCommission, TRY_TO_GET_CONSULTATION_COMMISSION } from "../store/reducers/ConsultationCommisionReducer";

const asyncGetConsultationCommission = async (value) => {
    try {
        
        const response = await fetch(
            apiUrl + '/commission/calculate/',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({value: value})
            }
        )

        if (response.status === 200) {
            const result = await response.json()
            return result.value
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch (err) {}
}

function* ConsultationCommissionWorker(action) {
    const data: number | null = yield call(asyncGetConsultationCommission, action.payload)

    if (data) {
        yield put( setConsultationCommission(data) )
    }
} 

export function* ConsultationCommissionWatcher() {
    yield takeEvery(TRY_TO_GET_CONSULTATION_COMMISSION, ConsultationCommissionWorker)
}