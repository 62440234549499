import { Person } from "../../interfaces/Person"

export const ADD_PERSON_TO_CHOOSEN_LIST = 'ADD_PERSON_TO_CHOOSEN_LIST'
export const DELETE_PERSON_FROM_CHOOSEN_LIST = 'DELETE_PERSON_FROM_CHOOSEN_LIST'
export const CLEAN_CHOOSEN_PERSON_LIST = 'CLEAN_CHOOSEN_PERSON_LIST'

let initialState: Person[] = []

if (localStorage.choosenPersonList && localStorage.choosenPersonList.length > 0) {
    initialState = JSON.parse(localStorage.choosenPersonList)
}

export const ChoosenPersonListReducer = (state = initialState, action) => {

    switch (action.type) {

        case ADD_PERSON_TO_CHOOSEN_LIST:
            localStorage.setItem('choosenPersonList', JSON.stringify([action.payload, ...state]))
            return [action.payload, ...state]

        case DELETE_PERSON_FROM_CHOOSEN_LIST:
            let newArr: Person[] = []
            state.filter( (elem: Person) => {
                if (elem.id !== action.payload) {
                    newArr = [...newArr, elem]
                }
            })
            localStorage.setItem('choosenPersonList', JSON.stringify([...newArr]))
            return newArr

        case CLEAN_CHOOSEN_PERSON_LIST:
            localStorage.setItem('choosenPersonList', JSON.stringify([]))
            return []

        default:
            return state
    }
}

export const addPersonToChoosenList = (payload: Person) => ({type: ADD_PERSON_TO_CHOOSEN_LIST, payload})
export const deletePersonFromChoosenList = (payload: string) => ({type: DELETE_PERSON_FROM_CHOOSEN_LIST, payload})
export const cleanChoosenPersonList = () => ({type: CLEAN_CHOOSEN_PERSON_LIST})