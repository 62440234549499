//types
export enum ArticleNavigationTypes {
    NEXT_ARTICLE = 'NEXT_ARTICLE',
    BACK_ARTICLE = 'BACK_ARTICLE',
    OPEN_SECTION = 'OPEN_SECTION',
    OPEN_ARTICLE = 'OPEN_ARTICLE',
}

// actions payloads

export interface SwitchArticleActionPayload {
    currentArticleNum: number;
    currentSectionNum: number;
    currentSectionLength: number;
    sectionAmount: number;
}

//actions
interface OpenArticleAction {
    type: ArticleNavigationTypes.OPEN_ARTICLE;
    payload: Number;
}

interface OpenSectionAction {
    type: ArticleNavigationTypes.OPEN_SECTION;
    payload: Number;
}

interface NextArticleAction {
    type: ArticleNavigationTypes.NEXT_ARTICLE;
    payload: SwitchArticleActionPayload;
}

interface BackArticleAction {
    type: ArticleNavigationTypes.BACK_ARTICLE;
    payload: SwitchArticleActionPayload;
}

export type ArticleNavigationAction = OpenArticleAction | OpenSectionAction | NextArticleAction | BackArticleAction;