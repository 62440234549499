export const GET_MEASUREMENT_IMAGES = 'GET_MEASUREMENT_IMAGES'
export const SET_MEASUREMENT_IMAGES = 'SET_MEASUREMENT_IMAGES'
export const CLEAN_MEASUREMENT_IMAGES = 'CLEAN_MEASUREMENT_IMAGES'

let initialState: string[] = []

// if (localStorage.measurementImages && localStorage.measurementImages.length > 0) {
//     let arr = JSON.parse(localStorage.measurementImages)
//     initialState = arr
// }

export const MeasurementImagesReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_MEASUREMENT_IMAGES:
            //localStorage.setItem('measurementImages', JSON.stringify(action.payload))
            return action.payload

        case CLEAN_MEASUREMENT_IMAGES:
            localStorage.setItem('measurementImages', '')
            return []

        default:
            return state
    }
}

export const getMeasurementImages = () => ({type: GET_MEASUREMENT_IMAGES})
export const setMeasurementImages = (payload: string[]) => ({type: SET_MEASUREMENT_IMAGES, payload})
export const cleanMeasurementImages = () => ({type: CLEAN_MEASUREMENT_IMAGES})