import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
//import store from '../store'
import { MeasurementInfo } from "../interfaces/MeasurementInfo";
import {
  updateMeasurement,
  ASYNC_UPDATE_MEASUREMENT,
} from "../store/reducers/MeasurementReducer";
import { routes } from "../routes";

async function AsyncUpdateMeasurement(stage) {
  try {
    let meas = JSON.parse(localStorage.getItem("measurement") || "");

    if (localStorage.getItem("comm")) {
      meas.comment = localStorage.getItem("comm");
    }
   
    const response = await fetch(apiUrl + "/measurement", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
      body: JSON.stringify({
        ...meas,
        stage: stage,
      }),
    });
    if (response.status === 200) {
      const result: MeasurementInfo = await response.json();
      localStorage.removeItem("comm");
      if (stage == "COMPLETED") {
        
        location.assign("measurements-statistic/" + result?.id);
      }
      return result;
    } else if (response.status === 401) {
      location.assign(routes.login);
    } else {
      return null;
    }
  } catch (error) {}
}

function* UpdateMeasurementWorker(action) {
  const data = yield call(AsyncUpdateMeasurement, action.payload);

  if (data.id) {
    yield put(updateMeasurement(data));
  }
}

export function* UpdateMeasurementWatcher() {
  yield takeEvery(ASYNC_UPDATE_MEASUREMENT, UpdateMeasurementWorker);
}
