import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { ChangingPasswordInterface, setIsPasswordChangingError, setIsPasswordChangingSuccess, TRY_TO_CHANGE_ACCOUNT_PASSWORD } from "../store/reducers/AccountChangePasswordReducer";
import { tryToGetAccountMainData } from "../store/reducers/AccountMainDataReducer";
import { routes } from '../routes'
async function asyncTryToChangePassword(form: ChangingPasswordInterface) {
    try {
        
        const response = await fetch(
            apiUrl + '/account/change-password/',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify(form)
            }
        )

        if (response.status === 200) {
            return true
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch (err) {}
}

function* ChangeAccountPasswordWorker(action) {
    const isPasswordChanged = yield call(asyncTryToChangePassword, action.payload)

    if (isPasswordChanged) {
        yield put( setIsPasswordChangingSuccess() )
        yield put( tryToGetAccountMainData() )
    } else {
        yield put( setIsPasswordChangingError() )
    }
}

export function* ChangeAccountPasswordWatcher() {
    yield takeEvery(TRY_TO_CHANGE_ACCOUNT_PASSWORD, ChangeAccountPasswordWorker)
}