import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
// import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {InfoStatisticBlocksForGroup} from "../../components/MeasurementStatisticComponents/InfoStatisticBlocksForGroup";

import { MeasurementToggleList } from "../../components/MeasurementStatisticComponents/MeasurementToggleList";

//import { mainGraphicSize } from "../../consts";
import preloaderIcon from "./preloader.png";
// import { tryToGetMeasurementStatistic } from "../../store/reducers/MeasurementStatisticReducer";

import "./styles.sass";
import {useSelector} from "react-redux";

export const MeasurementStatisticGroupContainer = () => {
  // const dispatch = useDispatch();

  const { id } = useParams();
  

  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );
  const [preloader, setPreloader] = useState(true);

  async function checkStat() {
    // const response = await fetch(`${process.env.REACT_APP_API_HOST}/measurement/${id}/statistic`, {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json;charset=utf-8',
    //         Accept: 'application/json',
    //         'Authorization': 'Bearer ' + localStorage.token
    //     },
    // })

    // if (response.status == 500 || response.status == 400){

    //     setErrors(false)
    // } else {

    // dispatch(tryToGetMeasurementStatistic(id || ""));
    setTimeout(() => {
      setPreloader(false);
    }, 500);
  }

  
 
  useEffect(() => {
    checkStat();
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
    return (
      <div
        className={`measurement-statistic-container fichesStatic ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
      >
        {preloader && (
          <div className="preloader">
            <div>
              <p> Пожалуйста подождите</p>

              <img src={preloaderIcon} className="preloader__image " />
            </div>
          </div>
        )}
    

        <MeasurementToggleList
          title={<FormattedMessage id="measurement_statistic_title_person" />}
          component={InfoStatisticBlocksForGroup(id)}
          maxHeight={1000}
          isActivate={true}
          isScrollable={true}
    
        />

          </div>)
};
