import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { StepBar } from "../../components/StepBar";
import { GreenPlusIcon, RedTrashBasketIcon } from "../../icons";

import { BottomControlPanel } from "../../components/BottomControlPanel/index";

import {
  cleanMeasurementConsultationList,
  deleteMeasurementFromConsultationList,
} from "../../store/reducers/MeasurementConsultationListReducer";
import { showAddMeasurementToConsultationModal } from "../../store/reducers/ModalWindowsReducer";

import { AddMeasurementToConsultationModal } from "../../components/ConsultationUserComponents/AddMeasurementToConsultationModal/index";

import { nextStepBar } from "../../store/reducers/StepBarReducer";

import "./styles.sass";
import { routes } from "../../routes";
import { useEffect } from "react";

export const StartConsultationFromCosultationListContainer = () => {
  const dispatch = useDispatch();

  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

  const measurementsList = useSelector(
    (state: any) => state.measurementConsultationList
  );

  const showMeasurementModal = useSelector((state: any) => state.modals);

  const navigateBack = () => {
    dispatch(cleanMeasurementConsultationList());
  };

  const navigateNext = () => {
    return "";
  };

  useEffect(() => {
    dispatch(nextStepBar(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={`start-consultation-container ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
      >
        <div className="start-consultation-container__title-wrapper">
          <span>
            <FormattedMessage id="consultation_start_page_title" />
          </span>

          {document.body.clientWidth > 640 && <StepBar steps={4} />}
        </div>

        <div className="start-consultation-container__additional-title">
          <FormattedMessage id="consultation_start_page_additional_title" />
        </div>

        <div className="start-consultation-container__measurements-list-wrapper">
          <div
            className="start-consultation-container__measurements-list-wrapper__add-measurement-panel"
            onClick={() => dispatch(showAddMeasurementToConsultationModal())}
          >
            <GreenPlusIcon />
            <span>
              <FormattedMessage id="consultation_start_page_measurement_panel_add_measurement" />
            </span>
          </div>

          {measurementsList &&
            measurementsList.length > 0 &&
            measurementsList.map((elem, index) => (
              <div
                key={index}
                className="start-consultation-container__measurements-list-wrapper__measurement-panel"
              >
                <div className="start-consultation-container__measurements-list-wrapper__measurement-panel__info">
                  <div>
                    <span>
                      <FormattedMessage id="consultation_start_page_measurement_panel_data" />
                    </span>
                    {elem.creationDate && elem.creationDate.length >= 10
                      ? elem.creationDate.slice(8, 10) +
                        "." +
                        elem.creationDate.slice(5, 7) +
                        "." +
                        elem.creationDate.slice(0, 4)
                      : elem.creationDate}
                  </div>

                  <div>
                    <span>
                      <FormattedMessage id="consultation_start_page_measurement_panel_type" />
                    </span>
                    {elem.typeName}
                  </div>
                </div>

                <div
                  className="start-consultation-container__measurements-list-wrapper__measurement-panel__delete"
                  onClick={() =>
                    dispatch(
                      deleteMeasurementFromConsultationList(elem.measurementId)
                    )
                  }
                >
                  <RedTrashBasketIcon />
                </div>
              </div>
            ))}
        </div>
      </div>

      {showMeasurementModal.modal === "ADD_MEASUREMENT_TO_CONSULTATION" && (
        <AddMeasurementToConsultationModal />
      )}

      <BottomControlPanel
        nextPage={routes.consultationInfo}
        previousPage={routes.startMeasurement}
        backFunc={navigateBack}
        nextFunc={navigateNext}
        isNextButtonDisabled={measurementsList.length > 0 ? false : true}
      />
    </>
  );
};
