import { call, put, takeEvery} from "redux-saga/effects";
import { apiUrl } from "../consts";
import { Person } from "../interfaces/Person";
import { addNewPersonToMeasurement, TRY_TO_ADD_NEW_PERSON, TRY_TO_ADD_EXISTED_PERSON } from "../store/reducers/PersonMeasurementListReducer";

import store from '../store/index'
import { MeasurementInfo } from "../interfaces/MeasurementInfo";
import { updateMeasurement } from "../store/reducers/MeasurementReducer";
import { cleanPersonForm } from "../store/reducers/CurrentPersonReducer";
import { routes } from "../routes";

async function AsyncAddNewPerson(person) {
    try {

        var date = ''
      

        if (!person.member.birthday && person.member.birthday.length !== 10) {
           
            date = '';
        } else {
            date = person.member.birthday
        }
        delete person.member.id
        
        const response = await fetch(
            apiUrl + '/measurement/' + JSON.parse(localStorage.measurement).id + '/add/person/',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    ...person.member,
                    birthday: date, 
                    comment: person.comment,
                    customName: person.member.name
                })
            }
        )
        const result: Person = await response.json()

        if (result && result.id && result.id.length>0) {
            return result
        } 

    } catch(err) {}
}

async function AsyncAddPersonToMeasurement(personId) {
    try {
        let meas = JSON.parse(localStorage.measurement || '');
       
        const measurementId = meas.id

        const response = await fetch(
            apiUrl + '/measurement/' + measurementId + '/add/person/'+personId,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/problem+json',
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    measurementId: measurementId,
                    personId: personId,
                    comment: store.getState().currentPerson.comment
                })
            }
        )

        if (response.status === 200) {
            const result: MeasurementInfo = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch(err) {}
}

function* addNewPersonWorker(action) {

    const data = yield call(AsyncAddNewPerson, action.payload)

    if (data && data.id) {
            yield put(addNewPersonToMeasurement(data.rateMembers))
            yield put(updateMeasurement(data))
            yield put(cleanPersonForm())
    }
}

export function* AddNewPersonWatcher() {
    yield takeEvery(TRY_TO_ADD_NEW_PERSON, addNewPersonWorker)
}

function* addExistedPersonWorker(action) {
    const data = yield call(AsyncAddPersonToMeasurement, action.payload.member.id)
    if (data && data.id) {
        yield put(addNewPersonToMeasurement(data.rateMembers))
        yield put(updateMeasurement(data))
        yield put(cleanPersonForm())
    }
}


export function* AddNewExistedPersonWatcher() {
    yield takeEvery(TRY_TO_ADD_EXISTED_PERSON, addExistedPersonWorker)
}