export const SET_CAN_RATE_NEXT_PERSON = 'SET_CAN_RATE_NEXT_PERSON'
export const SET_CAN_NOT_RATE_NEXT_PERSON = 'SET_CAN_NOT_RATE_NEXT_PERSON'

const initialState: boolean = false

export const CanRateNextPersonReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_CAN_RATE_NEXT_PERSON:
            return true
        
        case SET_CAN_NOT_RATE_NEXT_PERSON:
            return false

        default:
            return state
    }
}

export const setCanRateNextPerson = () => ({type: SET_CAN_RATE_NEXT_PERSON})
export const setCanNotRateNextPerson = () => ({type: SET_CAN_NOT_RATE_NEXT_PERSON})