import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { SignUpForm } from "../../components/SignUpForm/index";
/* import {SignUpBySocNetworks} from '../../components/SignUpBySocNetworks/index' */
import { SignUpConfirmation } from "../../components/SignUpConfirmation/index";
import { VerticalShortStepBar } from "../../components/VerticalShortStepBar/index";
import { Link } from "react-router-dom";
import "./styles.sass";

export const SignUpFormContainer = () => {
  const navigate = useNavigate();

  const isAuthorized = !!useSelector((state: any) => state.login.token);

  if (isAuthorized) {
    navigate(routes.startMeasurement, { replace: true });
  }
  const stage = useSelector((state: any) => state.verticalStepBar.signup_stage);

  return (
    <div className="sign-up-container">
      <VerticalShortStepBar typeOfStepBar="signup" />
      {stage == "1" ? (
        <>
          <SignUpForm />
        </>
      ) : (
        <>
          <SignUpConfirmation />{" "}
        </>
      )}

      <footer className="footer__links" style={{ display: "none" }}>
        <Link target="_blank" to={routes.services}>
          Услуги
        </Link>
        <Link target="_blank" to={routes.agreement}>
          Согласие на обработку персональных данных
        </Link>
        <Link target="_blank" to={routes.politics}>
          Политика конфиденциальности
        </Link>
        <Link target="_blank" to={routes.contacts}>
          Контакты
        </Link>
      </footer>
    </div>
  );
};
