import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { NotificationsSettings } from "../interfaces/NotificationsSettings";
import { routes } from "../routes";
import {  setNotificationsSettings } from "../store/reducers/NotificationsSettingsReducer";
import { TRY_TO_GET_NOTIFICATIONS_SETTINGS } from "../store/reducers/NotificationsSettingsReducer";

async function asyncGetNotificationsSettings() {
    try {

        const response = await fetch(
            apiUrl + '/user/notification-settings/',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            }
        )

        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch(error) {}
}

function* GetNotificationsSettingsWorker() {
    const data: NotificationsSettings | null = yield call(asyncGetNotificationsSettings)

    if (data && data.notificationChannels) {
        yield put( setNotificationsSettings(data) )
    }
}

export function* GetNotificationsSettingsWatcher() {
    yield takeEvery(TRY_TO_GET_NOTIFICATIONS_SETTINGS, GetNotificationsSettingsWorker)
}