export const TOGGLE_IS_NAVBAR_SHOWED = 'TOGGLE_IS_NAVBAR_SHOWED'

const initialState = {
    isNavbarShowed: true
}

export const NavbarReducer = (state = initialState, action) => {
    switch (action.type) {

        case TOGGLE_IS_NAVBAR_SHOWED:
            if (state.isNavbarShowed) {
                return {
                    ...state,
                    isNavbarShowed: false
                }
            } 
            else {
                return {
                    ...state,
                    isNavbarShowed: true
                }
            }

        default: 
            return state
    }
}

export const toggleIsNavbarShowed = () => ({type: TOGGLE_IS_NAVBAR_SHOWED})