import { User } from '../../interfaces/User'

export const SIGNUP = 'SIGNUP'
export const SIGNUP_CONFIRMATION = 'SIGNUP_CONFIRMATION'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_USER_EXIST_ERROR = 'SIGNUP_USER_EXIST_ERROR'
export const SIGNUP_CONFIRMATION_CODE_ERROR = 'SIGNUP_CONFIRMATION_CODE_ERROR'

const initialState = {
    login: '',
    password: '',
    userExistError: false,
    codeError: false
}

export const SignupReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGNUP:
            return {
                login: action.payload.login,
                password: action.payload.password,
                userExistError: false,
                codeError: false
            }

        case SIGNUP_CONFIRMATION:
            return {
                ...state
            }

        case SIGNUP_USER_EXIST_ERROR:
            return {
                ...state,
                userExistError: true
            }
        
        case SIGNUP_CONFIRMATION_CODE_ERROR: {
            return {
                ...state,
                codeError: true
            }
        }

        default:
            return state
    }
}

export const signupCreator = (payload: User) => ({type: SIGNUP, payload})
export const signupUserExistError = () => ({type: SIGNUP_USER_EXIST_ERROR})
export const signupSuccessCreator = (payload: User) => ({type: SIGNUP_SUCCESS, payload})
export const SignupConfirmationCreator = (payload: string) => ({type: SIGNUP_CONFIRMATION, payload})
export const signupConfiramtionError = () => ({type: SIGNUP_CONFIRMATION_CODE_ERROR})