import { call, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { Consultation } from "../interfaces/Consultation";
import { routes } from "../routes";
import store from "../store";
import { TRY_TO_ACCEPT_PAYING_CONSULTATION } from "../store/reducers/AcceptPayingConsultationReducer";

const asyncUserAcceptPayConsultation = async () => {
    try {

        const consultation: Consultation = store.getState().currentConsultation


            const response = await fetch(
                apiUrl + `/consultation/confirm-price/${consultation.id}`,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json;charset=utf-8',
                        'Authorization': 'Bearer ' + localStorage.token
                    },
                    // body: JSON.stringify({
                    //     id: consultation.id
                    // })
                }
            )
            
            if (response.status === 200) {
                return true
            } else if (response.status === 401) {
                location.assign(routes.login)
            } else {
                return null
            }

    } catch(err) {}
}

function* UserAcceptPayConsultationWorker() {
    yield call(asyncUserAcceptPayConsultation)
}

export function* UserAcceptPayConsultationWatcher() {
    yield takeEvery(TRY_TO_ACCEPT_PAYING_CONSULTATION, UserAcceptPayConsultationWorker)
}