export const SET_CONSULTATION_REVIEW_TEXT = 'SET_CONSULTATION_REVIEW_TEXT'
export const SET_CONSULTATION_REVIEW_RATE = 'SET_CONSULTATION_REVIEW_RATE'
export const TRY_TO_SEND_CONSULTATION_REVIEW = 'TRY_TO_SEND_CONSULTATION_REVIEW'

const initialState = {
    1: -1,
    2: -1,
    3: -1,
    text: ''
}

export const  ConsultationReviewReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_CONSULTATION_REVIEW_TEXT:
            return {
                ...state, 
                text: action.payload
            }

        case SET_CONSULTATION_REVIEW_RATE:
            return {
                ...state,
                [action.payload.id]: action.payload.rate
            }

        default:
            return state
    }
}

export const setConsultationReviewText = (payload: string) => ({type: SET_CONSULTATION_REVIEW_TEXT, payload})
export const setConsultationReviewRate = (payload: {id: string, rate: number}) => ({type: SET_CONSULTATION_REVIEW_RATE, payload})
export const tryToSendConsultationReview = () => ({type: TRY_TO_SEND_CONSULTATION_REVIEW})