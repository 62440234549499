import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { Operation } from "../interfaces/Operation";
import { routes } from "../routes";
import { GET_ACCOUNT_FINANCES, setAccountFinances } from "../store/reducers/AccountFinancesReducer";

async function asyncGetAccountFinances() {
    try {

        const response = await fetch(
            apiUrl + '/user/operations',
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
            }
        )

        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }

    } catch(error) {}
}

function* GetAccountFinancesWorker() {
    const data: Operation[] | null = yield call(asyncGetAccountFinances)

    if (data && data.length > 0) {
        yield put( setAccountFinances(data) )
    }
}

export function* GetAccountFinancesWatcher() {
    yield takeEvery(GET_ACCOUNT_FINANCES, GetAccountFinancesWorker)
}