import { call, takeEvery } from "redux-saga/effects";
import { LOCALES } from "../i18n/locales";
import { messages } from "../i18n/messages";
import store from "../store";
//import { apiUrl } from "../consts";
import { DOWNLOAD_CONSULTATION_REPORT } from "../store/reducers/ConsultationChatReducer";

const asyncDownloadConsultationReport = async (api) => {
    try {

        const response = await fetch(
            api,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
            }
        )
        const date = store.getState().currentConsultation.startDate.slice(8,10) + '.' + store.getState().currentConsultation.startDate.slice(5,7) + '.' + store.getState().currentConsultation.startDate.slice(0,4)
        const blob = await response.blob()
        var ak = document.createElement("a")
        ak.href = URL.createObjectURL(blob)
        ak.setAttribute("download", 
            messages[LOCALES.RUSSIAN].consultation_chat_title_consultation + " " + date + ' ' + store.getState().currentConsultation.adresseeName+'.pdf'
        );
        ak.click();;    
 
    } catch(err) {}
}

function* DownloadConsultationReportWorker(action) {
    yield call(asyncDownloadConsultationReport, action.payload)
} 

export function* DownloadConsultationReportWatcher() {
    yield takeEvery(DOWNLOAD_CONSULTATION_REPORT, DownloadConsultationReportWorker)
}