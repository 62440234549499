export const SET_CONSULTANTS_LIST_FILTER = 'SET_CONSULTANTS_LIST_FILTER'

const initialState = {
    workload: '',
    fio: '',
    rating: '',
    sort: 'fio'
}

export const ConsultantsFilterReducer = (state = initialState, action) => {

    switch (action.type) {
    
        case SET_CONSULTANTS_LIST_FILTER:
            return action.payload

        default:
            return state
    }
}

export const setConsultantsListFilter = (payload: any) => ({type: SET_CONSULTANTS_LIST_FILTER, payload}) 