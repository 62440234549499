import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Gender } from "../../interfaces/Person";
import { MyAccountBirthdayInputIcon } from "../../icons";
import { editPerson } from "../../store/reducers/CurrentPersonReducer";
import { closeModal } from "../../store/reducers/ModalWindowsReducer";

import { ChoosePersonStatusPanel } from "../ChoosePersonStatusPanel/index";

import "./styles.sass";

export const PersonEditMeasurementModal = () => {
  const dispatch = useDispatch();

  const currentModalEditPerson = useSelector(
    (state: any) => state.modals.modalInfo
  );
  const currentPersonRole = useSelector(
    (state: any) => state.currentPerson.member.role
  );

  const [form, setForm] = useState({
    name: currentModalEditPerson.member.customName,
    gender: currentModalEditPerson.member.gender,
    birthday: currentModalEditPerson.member.birthday || "",
    role: currentModalEditPerson.member.role,
    comment: currentModalEditPerson.comment || "",
  });

  const birthdateHandle = (value) => {
    setForm({ ...form, birthday: value });
  };

  return (
    <div className="person-edit-modal-wrapper">
      <div className="person-edit-modal">
        <span className="person-edit-modal__title">
          <FormattedMessage id="measurement_person_list_page_edit_modal_title" />
        </span>

        <div className="person-edit-modal__form-wrapper">
          <div className="person-edit-modal__form-wrapper__row">
            <span>
              <FormattedMessage id="measurement_person_list_page_edit_modal_fio" />
            </span>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={(event) =>
                setForm({ ...form, [event.target.name]: event.target.value })
              }
            />
          </div>
          {document.body.clientWidth < 640 && (
            <div className="person-edit-modal__form-wrapper__row modal-role-wrapper">
              <span>
                <FormattedMessage id="measurement_person_list_page_edit_modal_role" />
              </span>
              <div>
                <ChoosePersonStatusPanel role={form.role} />
              </div>
            </div>
          )}
          <div className="person-edit-modal__form-wrapper__row">
            <span>
              <FormattedMessage id="measurement_person_list_page_edit_modal_gender" />
            </span>

            <div className="modal-gender-wrapper">
              <div
                className="choose-gender-measurement-person-wrapper__gender-panel"
                onClick={() =>
                  // setChoosenGender(Gender.MALE)
                  // dispatch(setCurrentPersonGender(Gender.MALE))
                  setForm({ ...form, gender: Gender.MALE })
                }
              >
                {form.gender === Gender.MALE ? (
                  <div className="choose-gender-measurement-person-wrapper__gender-panel__choosen-checkbox" />
                ) : (
                  <div className="choose-gender-measurement-person-wrapper__gender-panel__non-choosen-checkbox" />
                )}
                <span>
                  <FormattedMessage id="measurement_person_list_page_gender_male" />
                </span>
              </div>

              <div
                className="choose-gender-measurement-person-wrapper__gender-panel"
                onClick={() => {
                  setForm({ ...form, gender: Gender.FEMALE });
                }}
              >
                {form.gender === Gender.FEMALE ? (
                  <div className="choose-gender-measurement-person-wrapper__gender-panel__choosen-checkbox" />
                ) : (
                  <div className="choose-gender-measurement-person-wrapper__gender-panel__non-choosen-checkbox" />
                )}
                <span>
                  <FormattedMessage id="measurement_person_list_page_gender_female" />
                </span>
              </div>
            </div>
          </div>

          <div
            className="person-edit-modal__form-wrapper__row"
            style={{ position: "relative" }}
          >
            <span>
              <FormattedMessage id="measurement_person_list_page_edit_modal_birthday" />
            </span>
            <input
              type="date"
              name="birthday"
              value={form.birthday}
              onChange={(event) => birthdateHandle(event.target.value)}
            />
            <span
              style={{
                position: "absolute",
                left: document.body.clientWidth > 640 ? "325px" : "90%",
                top: "30px",
                pointerEvents: "none",
                cursor: "pointer",
                background: "white",
              }}
            >
              <MyAccountBirthdayInputIcon />
            </span>
          </div>
          <div className="person-edit-modal__form-wrapper__row">
            <span>Комментарий</span>
            <input
              type="text"
              name="comment"
              value={form.comment}
              onChange={(event) =>
                setForm({ ...form, [event.target.name]: event.target.value })
              }
            />
          </div>
          {document.body.clientWidth > 640 && (
            <div className="person-edit-modal__form-wrapper__row modal-role-wrapper">
              <span>
                <FormattedMessage id="measurement_person_list_page_edit_modal_role" />
              </span>
              <div>
                <ChoosePersonStatusPanel role={form.role} />
              </div>
            </div>
          )}

          <div className="person-edit-modal__form-wrapper__button-wraper">
            <div
              className="green-button"
              onClick={() => {
                dispatch(
                  editPerson({
                    ...currentModalEditPerson,
                    member: {
                      ...currentModalEditPerson.member,
                      gender: form.gender,
                      name: form.name,
                      customName: form.name,
                      birthday: form.birthday,
                      comment: form.comment,
                      role:
                        currentPersonRole.length === 0 ||
                        currentPersonRole === form.role
                          ? form.role
                          : currentPersonRole,
                    },
                  })
                );
                dispatch(closeModal());
              }}
            >
              <FormattedMessage id="measurement_person_list_page_edit_modal_save" />
            </div>

            <div
              className="white-button"
              onClick={() => dispatch(closeModal())}
            >
              <FormattedMessage id="measurement_person_list_page_edit_modal_cancel" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
