//import { useEffect } from 'react'
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginForm } from "../../components/LoginForm/index";
import { VerticalShortStepBar } from "../../components/VerticalShortStepBar/index";
import { routes } from "../../routes";

import "./styles.sass";

export const LoginContainer = () => {
  const navigate = useNavigate();

  const isAuthorized = !!useSelector((state: any) => state.login.token);

  useEffect(() => {
    if (isAuthorized) {
      navigate(routes.startMeasurement, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  return (
    <div className="login-container">
      <VerticalShortStepBar typeOfStepBar="login" />
      <LoginForm />
    </div>
  );
};
