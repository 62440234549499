import "./style.sass";
import logo from "./img/logo.svg";
import tg from "./img/tg.svg";
import vk from "./img/vk.svg";
import ram from "./img/ram.svg";
import yt from "./img/yt.svg";
import dz from "./img/dz.svg";
import ok from "./img/ok.svg";
import planet from "./img/planet.png";
import icon1 from "./img/1icon.svg";
import icon2 from "./img/2icon.svg";
import icon3 from "./img/3icon.svg";
import icon4 from "./img/4icon.svg";
import icon5 from "./img/5icon.svg";
import icon6 from "./img/6icon.svg";
import icons from "./img/icons.svg";
import videoButton from "./img/videoButton.svg";
import coron from "./img/coron.svg";
import arrow from "./img/arrow.svg";
import firstSphere from "./img/firstSphere.svg";
import circle2 from "./img/circle2.svg";
import avatar from "./img/avatar.png";
import rotate from "./img/rotate.svg";
import apostrof from "./img/apostrof.svg";
import burger from "./img/burger.svg";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

export function LandingContainer() {
  const Navigate = useNavigate();
  const [question1, setQuestion1] = useState(false);
  const [question2, setQuestion2] = useState(false);
  const [question3, setQuestion3] = useState(false);
  const [question4, setQuestion4] = useState(false);
  const [question5, setQuestion5] = useState(false);
  const [question6, setQuestion6] = useState(false);
  const [question7, setQuestion7] = useState(false);
  const [question8, setQuestion8] = useState(false);
  const [question9, setQuestion9] = useState(false);
  const [visible, isVisible] = useState(false);
  const [mobileMenu, isOpenMenu] = useState(false);
  const isAuthorized = !!useSelector((state: any) => state.login.token);
  useEffect(() => {
    if (isAuthorized) {
      Navigate(routes.startMeasurement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="landing__body">
      <div className="landing__header">
        {document.body.clientWidth < 640 && (
          <div className="mobile__header">
            <div className="visible__part">
              <div>
                <img src={logo} alt="" />
                <p>
                  Персональный <br /> Социомониторинг
                </p>
              </div>
              <img
                src={burger}
                alt=""
                onClick={() => isOpenMenu(!mobileMenu)}
              />
            </div>
            <div
              className="content__part"
              style={mobileMenu ? { height: "95vh" } : { height: "0" }}
            >
              <div className="mobile__menu">
                <ul>
                  <li>
                    <a href="">О сервисе</a>
                  </li>
                  <div className="gray__line"></div>
                  <li>
                    <a href="">Сферы для исследования</a>
                  </li>
                  <div className="gray__line"></div>
                  <li>
                    <a href="">Сравнение аккаунтов</a>
                  </li>
                  <div className="gray__line"></div>
                  <li>
                    <a href="">Ответы на вопросы</a>
                  </li>
                  <div className="gray__line"></div>
                  <li>
                    <a href="">Отзывы</a>
                  </li>
                </ul>
              </div>
              <ul className="mobile__header__ul">
                <li>
                  <a href="https://t.me/sociomonitioring" target="_blank">
                    <img src={tg} alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://rutube.ru/channel/26516507/" target="_blank">
                    <img src={ram} alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://vk.com/sociomon" target="_blank">
                    <img src={vk} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@sociomonitoring"
                    target="_blank"
                  >
                    <img src={yt} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://dzen.ru/id/6391e7732c71257886f6ca5a"
                    target="_blank"
                  >
                    <img src={dz} alt="" />
                  </a>
                </li>
              </ul>

              <a href="/signup" className="landing_registration">
                Регистрация
              </a>
              <a href="/login" className="landing__auth">
                Вход
              </a>
            </div>
          </div>
        )}
        <div
          className="landing__nav"
          style={document.body.clientWidth < 640 ? { display: "none" } : {}}
        >
          <img src={logo} alt="" />
          <p>
            Персональный <br /> Социомониторинг
          </p>
          <ul>
            <li>
              <a href="https://t.me/sociomonitioring" target="_blank">
                <img src={tg} alt="" />
              </a>
            </li>
            <li>
              <a href="https://rutube.ru/channel/26516507/" target="_blank">
                <img src={ram} alt="" />
              </a>
            </li>
            <li>
              <a href="https://vk.com/sociomon" target="_blank">
                <img src={vk} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@sociomonitoring"
                target="_blank"
              >
                <img src={yt} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://dzen.ru/id/6391e7732c71257886f6ca5a"
                target="_blank"
              >
                <img src={dz} alt="" />
              </a>
            </li>
          </ul>

          <a href="/signup" className="landing_registration">
            Регистрация
          </a>
          <a href="/login" className="landing__auth">
            Вход
          </a>
        </div>
        <div className="header__body">
          <div className="header__body__content">
            <h1>
              Персональный <br /> Социомониторинг
            </h1>
            <ul>
              <li>
                <img src={ok} alt="" />
                <p>Мир в тебе и вокруг тебя</p>
              </li>
              <li>
                <img src={ok} alt="" />
                <p>Исследуй, контролируй, проектируй, наблюдай.</p>
              </li>
              <li>
                <img src={ok} alt="" />
                <p>Персональная психология без психологов</p>
              </li>
            </ul>
            <div className="header__body__text">
              <h3>
                Здравствуй! Ты на сайте <span> своего мира!</span>
              </h3>
              <p>
                Каждый человек имеет свой таинственный и уникальный внутренний
                мир. Он скрыт даже от самого человека, поэтому люди ищут себе
                психологов. Но через узенькие щели психологических тестов
                внутренний мир все равно не увидеть! Мы сделали открытие для
                самих себя и <span>делимся с тобой</span> . Этот сайт для тех,
                кто хочет владеть своим внутренним миром без посредников.{" "}
                <span>Входи и царствуй!</span>
              </p>
              <a href="/signup">Заглянуть в свой космос</a>
            </div>
          </div>
          <div className="header__body__img">
            <img src={planet} alt="" />
          </div>
          {document.body.clientWidth < 640 && (
            <a className="mobil__header__button" href="/signup">
              Заглянуть в свой космос
            </a>
          )}
        </div>
      </div>
      {/*  Видео */}
      <div className="landing__video" style={{ display: "none" }}>
        <h2>Видео</h2>
        <p>О том для чего предназначен наш сервис</p>
        <div className="video__container">
          <img src={videoButton} alt="" />
        </div>
      </div>
      {/* Проблемы */}
      <div className="landing__problems">
        <h2>
          Проблемы, которые <br /> мы поможем решить
        </h2>
        <div className="landing__problems__content">
          <div className="landing__problems_row">
            <div className="landing__problems_em">
              <img src={icon1} alt="" />
              <h3>По жизни</h3>
              <p>
                Жил, жил не тужил и вдруг все рухнуло и поплыло как краски с
                картины от капель дождя … Кто я? Где я? Что происходит?
                Остановитесь и загляните внутрь себя, чтобы вернуть силы и
                устоять в бурном потоке жизни, и при этом вам не потребуются
                психологи. Действуйте здесь и сейчас!
              </p>
              <a href="/login" target="_blank">
                Исследовать
              </a>
            </div>
            <div className="landing__problems_em">
              <img src={icon2} alt="" />
              <h3>Работа</h3>
              <p>
                Современные люди живут не дома, а на работе. Говорят, работа
                забирает у человека лучшее время и свежесть сил. Увы, но это не
                работа, а коллеги загружают нас своими проблемами. Какие ваши
                отношения в коллективе, вредоносные или гармоничные? Исследуйте
                здесь и сейчас.
              </p>
              <a href="/login" target="_blank">
                Исследовать
              </a>
            </div>
            <div className="landing__problems_em">
              <img src={icon3} alt="" />
              <h3>Спорт</h3>
              <p>
                Все думают что спорт – это прежде всего сила мышц и здоровое
                тело! Неверно! Разлад отношений в команде, в семье, с тренером,
                с друзьями, с любимым человеком рвет на части душу даже у
                богатыря и огонь победы в сердце гаснет уже на старте. Проверьте
                свою спортивную ауру здесь и сейчас. Действуйте!
              </p>
              <a href="/login" target="_blank">
                Исследовать
              </a>
            </div>
          </div>
          <div className="landing__problems_row">
            <div className="landing__problems_em">
              <img src={icon4} alt="" />
              <h3>Хобби</h3>
              <p>
                Работа, учеба, семья замыкают нас в жесткие рамки ответственных
                отношений. А вот наши увлечения – это открытый мир свободы!
                Тонкие ниточки, связывающие нас с интересными людьми, надо
                беречь и лелеять. Какие они у вас? Исследуйте здесь и сейчас.
              </p>
              <a href="/login" target="_blank">
                Исследовать
              </a>
            </div>
            <div className="landing__problems_em">
              <img src={icon5} alt="" />
              <h3>Школа/Вуз</h3>
              <p>
                Одноклассники в нашей жизни – это несгораемый капитал и это
                навсегда! Школа – это фундамент. Какой он, устойчивый или, того
                гляди, развалится, зависит от одноклассников и учителей.
                Исследуйте это здесь и сейчас!
              </p>
              <a href="/login" target="_blank">
                Исследовать
              </a>
            </div>
            <div className="landing__problems_em">
              <img src={icon6} alt="" />
              <h3>Детский сад</h3>
              <p>
                Ваш малыш в детском саду! Хорошо ему там или плохо? От этого
                зависит не только спокойный рабочий день мамы, но и радостное
                утро понедельника! Исследуйте окружение вашего малыша в садике
                здесь и сейчас.
              </p>
              <a href="/login" target="_blank">
                Исследовать
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="social__networks">
        <img src={firstSphere} alt="" className="first_sphere" />
        <div className="social__networks__content">
          <h2>
            Больше полезной <br /> информации в <br /> наших социальных <br />
            сетях
          </h2>
          <p>
            Подписывайтесь на наши социальные сети, изучайте наши материалы,
            пишите ваши отклики. Мы готовим много интересного, в том числе
            онлайн курс для тех кто захочет профессионально использовать этот
            метод.
          </p>
          <ul>
            <li>
              <img src={ok} alt="" />
              <p>Полезные статьи</p>
            </li>
            <li>
              <img src={ok} alt="" />
              <p>Живое общение</p>
            </li>
            <li>
              <img src={ok} alt="" />
              <p>Видео инструкции</p>
            </li>
            <li>
              <img src={ok} alt="" />
              <p>Скидки</p>
            </li>
            <li>
              <img src={ok} alt="" />
              <p>Обновления </p>
            </li>
            <li>
              <img src={ok} alt="" />
              <p>Другое</p>
            </li>
          </ul>
        </div>
        <div className="social__networks__icons">
          <img src={icons} alt="" />
        </div>
      </div>
      <div className="landing__accounts">
        <img src={circle2} alt="" className="circle2" />
        <div>
          <h2>
            Сравнение {document.body.clientWidth > 640 && <br />} базового и
            {document.body.clientWidth > 640 && <br />} премиального <br />{" "}
            аккаунтов
          </h2>
          {document.body.clientWidth > 640 && (
            <a href="/signup" className="button__link__lc">
              Попробовать бесплатно
            </a>
          )}
        </div>

        <div className="basic">
          <h3>Базовый</h3>
          <ul>
            <li>Выполнение измерений (10 типов)</li>
            <li>Визуализация персонального социального пространства</li>
            <li>
              Описание состояния парных отношений с каждым социальным партнером
            </li>
            <li>Блок параметров «Потенциал партнеров группы»</li>
            <li>Блок параметров «Ваш личностный потенциал»</li>
          </ul>
          <div className="gray_line"></div>
          <div className="price">Бесплатно</div>
        </div>
        <div className="premium">
          <div>
            <h3>Премиум</h3>
            <img src={coron} alt="" className="coron" />
          </div>

          <ul>
            <li>Выполнение измерений (10 типов)</li>
            <li>Визуализация персонального социального пространства</li>
            <li>
              Описание состояния парных отношений с каждым социальным партнером
            </li>
            <li>Блок параметров «Потенциал партнеров группы»</li>
            <li>Блок параметров «Ваш личностный потенциал»</li>

            <li>Блок параметров «Структура психических процессов»</li>
            <li>
              Анализ персонального социального пространства «Мне легко/трудно»
            </li>
            <li>
              Анализ персонального социального пространства «Со мной
              легко/трудно»
            </li>
            <li>
              Детальный анализ персонального социального пространства
              «Микроанализ»
            </li>
            <li>
              Анализ персонального социального пространства «Деловая стратегия»
            </li>
            <li>
              Анализ персонального социального пространства «Социальная
              адаптация»
            </li>
            <li>Аналитический блок «Качество общения»</li>
            <li>Аналитический блок «Типы отношений»</li>
            <li>Аналитический блок «Состояние личной энергии»</li>
            <li>Аналитический блок «Характеристика вашего поведения»</li>
            <li>Аналитический блок «Психические процессы»</li>
          </ul>
          <div className="gray_line"></div>
          <div className="price">
            700 ₽ <span>/год</span>
          </div>
        </div>
      </div>
      <div className="landing__questions">
        <h2>Ответы на вопросы</h2>
        <p>Больше информации вы сможете найти в наших социальных сетях:</p>
        <ul>
          <li>
            <a href="https://t.me/sociomonitioring" target="_blank">
              <img src={tg} alt="" />
            </a>
          </li>
          <li>
            <a href="https://rutube.ru/channel/26516507/" target="_blank">
              <img src={ram} alt="" />
            </a>
          </li>
          <li>
            <a href="https://vk.com/sociomon" target="_blank">
              <img src={vk} alt="" />
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/@sociomonitoring" target="_blank">
              <img src={yt} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://dzen.ru/id/6391e7732c71257886f6ca5a"
              target="_blank"
            >
              <img src={dz} alt="" />
            </a>
          </li>
        </ul>
        <div className="questions__body">
          <div className="row__question">
            <div
              className="question__em"
              onClick={() => setQuestion1(!question1)}
            >
              <div>
                <p>Для чего мне это всё нужно?</p>
                <img src={arrow} alt="" />
              </div>
              <div
                className="height__question"
                style={
                  question1 && document.body.clientWidth > 640
                    ? { height: "80px", marginTop: "8px" }
                    : question1 && document.body.clientWidth < 640
                    ? { height: "fit-content", marginTop: "8px" }
                    : { height: "0", marginTop: "0" }
                }
              >
                Если очень коротко, для того чтобы упростить себе жизнь,
                избегать конфликтов и наладить и поддерживать хорошие отношения
                с окружающими. Более подробно можно прочитать в наших социальных
                сетях.
              </div>
            </div>
            <div
              className="question__em"
              onClick={() => setQuestion2(!question2)}
            >
              <div>
                <p>Кому будет полезен этот сервис?</p>
                <img src={arrow} alt="" />
              </div>
              <div
                className="height__question"
                style={
                  question2 && document.body.clientWidth > 640
                    ? { height: "105px", marginTop: "8px" }
                    : question2 && document.body.clientWidth < 640
                    ? { height: "fit-content", marginTop: "8px" }
                    : { height: "0", marginTop: "0" }
                }
              >
                Персональный социомониторинг полезен всем, кто хочет
                по-настоящему жить! Можно лететь по жизни без оглядки, и
                оказаться одиноким бомжом, сидящем на дороге, мимо которого
                проходят тысячи пар ног! А можно жизнь беречь в каждой ее
                маленькой крупинке! А крупинками жизни нашей являются окружающие
                нас люди.
              </div>
            </div>
            <div
              className="question__em"
              onClick={() => setQuestion3(!question3)}
            >
              <div>
                <p>Что будет мне доступно после регистрации?</p>
                <img src={arrow} alt="" />
              </div>
              <div
                className="height__question"
                style={
                  question3 && document.body.clientWidth > 640
                    ? { height: "60px", marginTop: "8px" }
                    : question3 && document.body.clientWidth < 640
                    ? { height: "fit-content", marginTop: "8px" }
                    : { height: "0", marginTop: "0" }
                }
              >
                После регистрации Вам будут доступны все функции базового
                аккаунта, Вы сможет выполнять все типы измерений и производить
                их самостоятельный анализ
              </div>
            </div>
            <div
              className="question__em"
              onClick={() => setQuestion4(!question4)}
            >
              <div>
                <p>Это научный метод?</p>
                <img src={arrow} alt="" />
              </div>
              <div
                className="height__question"
                style={
                  question4 && document.body.clientWidth > 640
                    ? { height: "120px", marginTop: "8px" }
                    : question4 && document.body.clientWidth < 640
                    ? { height: "fit-content", marginTop: "8px" }
                    : { height: "0", marginTop: "0" }
                }
              >
                Персональный социомониторинг – это метод созданный на основе
                исключительно российской научной школы и который предназначен не
                для ученых, а для всех людей на земле. Разработка метода велась
                в течение почти 20 лет. Научную основу составляют труды
                следующих ученых: П.А. Сорокин, А.А. Ухтомский, К.Д. Ушинский,
                М.М. Бахтин, В.И. Вернадский, Рене Декарт, В.М. Бехтерев, Д.И.
                Менделеев.
              </div>
            </div>
            <div
              className="question__em"
              onClick={() => setQuestion5(!question5)}
            >
              <div>
                <p>Для чего надо указывать свой город в профиле?</p>
                <img src={arrow} alt="" />
              </div>
              <div
                className="height__question"
                style={
                  question5 && document.body.clientWidth > 640
                    ? { height: "120px", marginTop: "8px" }
                    : question5 && document.body.clientWidth < 640
                    ? { height: "fit-content", marginTop: "8px" }
                    : { height: "0", marginTop: "0" }
                }
              >
                Для автоматизированного анализа используются статистические
                нормы. Нормы могут отличаться в разных регионах, в следствие
                разных условий жизни (тем жизни, уровень образования, отличия в
                рынке труда и т. п.). Нормы необходимо периодически
                пересчитывать. Город в профиле используется для того чтобы
                подобрать корректные статистические нормы для анализа Ваших
                данных
              </div>
            </div>
          </div>
          <div className="row__question">
            <div
              className="question__em"
              onClick={() => setQuestion6(!question6)}
            >
              <div>
                <p>С чего начать?</p>
                <img src={arrow} alt="" />
              </div>
              <div
                className="height__question"
                style={
                  question6 && document.body.clientWidth > 640
                    ? { height: "65px", marginTop: "8px" }
                    : question6 && document.body.clientWidth < 640
                    ? { height: "fit-content", marginTop: "8px" }
                    : { height: "0", marginTop: "0" }
                }
              >
                Начните с самого интересного типа замера: «Все кого ношу в своей
                голове». Вам сразу же начнут открываться секреты
                психологического анализа и вы поймете, что лучший на земле
                психолог – это вы сами!
              </div>
            </div>
            <div
              className="question__em"
              onClick={() => setQuestion7(!question7)}
            >
              <div>
                <p>Что делать если я ничего не понимаю?</p>
                <img src={arrow} alt="" />
              </div>
              <div
                className="height__question"
                style={
                  question7 && document.body.clientWidth > 640
                    ? { height: "175px", marginTop: "8px" }
                    : question7 && document.body.clientWidth < 640
                    ? { height: "fit-content", marginTop: "8px" }
                    : { height: "0", marginTop: "0" }
                }
              >
                Если вы абсолютно ничего не понимаете, но все же хотите что-то
                понять, то начните измерения в сервисе (внимательно читайте
                инструкции), прочитайте аналитику, сопоставьте с окружающей Вас
                действительностью. Изучите справочные материалы, подпишитесь на
                наши социальные сети и прочитайте там наши популярные статьи,
                примите участие в онлайн вебинарах для начинающих и Вы
                постепенно войдете в понимание сути персонального
                социомониторинга. Если надо разобраться очень быстро, у Вас есть
                актуальная проблема, которую Вы хотите решить, то тогда
                обратитесь к консультанту на нашей консультационной площадке.
              </div>
            </div>
            <div
              className="question__em"
              onClick={() => setQuestion8(!question8)}
            >
              <div>
                <p>
                  А можно использовать это метод для организации в которой я
                  работаю?
                </p>
                <img src={arrow} alt="" />
              </div>
              <div
                className="height__question"
                style={
                  question8 && document.body.clientWidth > 640
                    ? { height: "120px", marginTop: "8px" }
                    : question8 && document.body.clientWidth < 640
                    ? { height: "fit-content", marginTop: "8px" }
                    : { height: "0", marginTop: "0" }
                }
              >
                Существует метод группового социомониторинга, он был разработан
                раньше персонального Социомониторинга и применяется в практике
                образовательных учреждений (детские сады, школы, ВУЗы), бизнес
                структур, спортивных коллективов и т. п. Если Вы хотите
                использовать его в вашей работе, то напишите нам письмо в
                произвольной форме на адрес manager@sociomonitoring.com , мы
                свяжемся с Вами и обсудим детали.
              </div>
            </div>
            <div
              className="question__em"
              onClick={() => setQuestion9(!question9)}
            >
              <div>
                <p>Если я хочу большего?</p>
                <img src={arrow} alt="" />
              </div>
              <div
                className="height__question"
                style={
                  question9 && document.body.clientWidth > 640
                    ? { height: "65px", marginTop: "8px" }
                    : question9 && document.body.clientWidth < 640
                    ? { height: "fit-content", marginTop: "8px" }
                    : { height: "0", marginTop: "0" }
                }
              >
                Подписывайтесь на наши социальные сети, изучайте наши материалы,
                пишите Ваши отклики. Мы готовим много интересного, в том числе
                онлайн курс для тех кто захочет профессионально использовать
                этот метод.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="landing__rewiews" style={{ display: "none" }}>
        <h2>
          Отзывы наших <br />
          пользователей
        </h2>
        <div className="landing__reviews__row">
          <div className="landing__reviews__em">
            <img src={avatar} alt="" className="reviews__em" />
            <div className="reviews__em__content">
              <h4>София</h4>
              <div>Интернет-маркетолог</div>
              <p>
                «Уже больше года я исследую свою внутреннюю жизнь с помощью
                социомониторинга. У меня стабильная психика, нет депрессии или
                психических расстройств, да и вообще нет особых проблем. Плюс
                тут есть консультанты, помогающие прокачать себя».
              </p>
            </div>
            <img src={apostrof} alt="" />
          </div>
          <div className="landing__reviews__em">
            <img src={avatar} alt="" className="reviews__em" />
            <div className="reviews__em__content">
              <h4>София</h4>
              <div>Интернет-маркетолог</div>
              <p>
                «Уже больше года я исследую свою внутреннюю жизнь с помощью
                социомониторинга. У меня стабильная психика, нет депрессии или
                психических расстройств, да и вообще нет особых проблем. Плюс
                тут есть консультанты, помогающие прокачать себя».
              </p>
            </div>
            <img src={apostrof} alt="" />
          </div>
        </div>
        <div className="landing__reviews__row">
          <div className="landing__reviews__em">
            <img src={avatar} alt="" className="reviews__em" />
            <div className="reviews__em__content">
              <h4>София</h4>
              <div>Интернет-маркетолог</div>
              <p>
                «Уже больше года я исследую свою внутреннюю жизнь с помощью
                социомониторинга. У меня стабильная психика, нет депрессии или
                психических расстройств, да и вообще нет особых проблем. Плюс
                тут есть консультанты, помогающие прокачать себя».
              </p>
            </div>
            <img src={apostrof} alt="" />
          </div>
          <div className="landing__reviews__em">
            <img src={avatar} alt="" className="reviews__em" />
            <div className="reviews__em__content">
              <h4>София</h4>
              <div>Интернет-маркетолог</div>
              <p>
                «Уже больше года я исследую свою внутреннюю жизнь с помощью
                социомониторинга. У меня стабильная психика, нет депрессии или
                психических расстройств, да и вообще нет особых проблем. Плюс
                тут есть консультанты, помогающие прокачать себя».
              </p>
            </div>
            <img src={apostrof} alt="" />
          </div>
        </div>
        <div
          className="landing__reviews__row"
          style={visible ? { display: "flex" } : { display: "none" }}
        >
          <div className="landing__reviews__em">
            <img src={avatar} alt="" className="reviews__em" />
            <div className="reviews__em__content">
              <h4>София</h4>
              <div>Интернет-маркетолог</div>
              <p>
                «Уже больше года я исследую свою внутреннюю жизнь с помощью
                социомониторинга. У меня стабильная психика, нет депрессии или
                психических расстройств, да и вообще нет особых проблем. Плюс
                тут есть консультанты, помогающие прокачать себя».
              </p>
            </div>
            <img src={apostrof} alt="" />
          </div>
          <div className="landing__reviews__em">
            <img src={avatar} alt="" className="reviews__em" />
            <div className="reviews__em__content">
              <h4>София</h4>
              <div>Интернет-маркетолог</div>
              <p>
                «Уже больше года я исследую свою внутреннюю жизнь с помощью
                социомониторинга. У меня стабильная психика, нет депрессии или
                психических расстройств, да и вообще нет особых проблем. Плюс
                тут есть консультанты, помогающие прокачать себя».
              </p>
            </div>
            <img src={apostrof} alt="" />
          </div>
        </div>
        <div
          className="landing__reviews__row"
          style={visible ? { display: "flex" } : { display: "none" }}
        >
          <div className="landing__reviews__em">
            <img src={avatar} alt="" className="reviews__em" />
            <div className="reviews__em__content">
              <h4>София</h4>
              <div>Интернет-маркетолог</div>
              <p>
                «Уже больше года я исследую свою внутреннюю жизнь с помощью
                социомониторинга. У меня стабильная психика, нет депрессии или
                психических расстройств, да и вообще нет особых проблем. Плюс
                тут есть консультанты, помогающие прокачать себя».
              </p>
            </div>
            <img src={apostrof} alt="" />
          </div>
          <div className="landing__reviews__em">
            <img src={avatar} alt="" className="reviews__em" />
            <div className="reviews__em__content">
              <h4>София</h4>
              <div>Интернет-маркетолог</div>
              <p>
                «Уже больше года я исследую свою внутреннюю жизнь с помощью
                социомониторинга. У меня стабильная психика, нет депрессии или
                психических расстройств, да и вообще нет особых проблем. Плюс
                тут есть консультанты, помогающие прокачать себя».
              </p>
            </div>
            <img src={apostrof} alt="" />
          </div>
        </div>

        <div
          className="button__next__landing"
          onClick={() => isVisible(true)}
          style={visible ? { display: "none" } : { display: "true" }}
        >
          <img src={rotate} alt="" />
          Загрузить еще 4 отзыва
        </div>
      </div>
      <footer>
        <div className="footer__content">
          <div className="logo__footer">
            <img src={logo} alt="" />
            <p>
              Персональный <br /> Социомониторинг
            </p>
          </div>
          <div className="portal">
            <div>Информационный портал</div>
            <p
              className="for__bottom"
              style={
                document.body.clientWidth > 640
                  ? { marginBottom: "24px" }
                  : { marginBottom: "16px" }
              }
            >
              <a
                href="http://www.sociomonitoring.ru/"
                style={{ color: "#219653" }}
                target="_blank"
              >
                www.sociomonitoring.ru
              </a>
            </p>
            <div>Сервис</div>
            <p>
              <a
                href="https://pers.sociom.info"
                style={{ color: "#219653" }}
                target="_blank"
              >
                pers.sociom.info
              </a>
            </p>
          </div>
          <div className="footer__links__landing">
            <ul>
              <li>
                <a href="/services" target="_blank">
                  Описание услуги
                </a>
              </li>
              <li>
                <a href="">Тарифы</a>
              </li>
              <li>
                <a href="/contacts" target="_blank">
                  Контакты
                </a>
              </li>
            </ul>
          </div>
          <ul>
            <li>
              <a href="https://t.me/sociomonitioring" target="_blank">
                <img src={tg} alt="" />
              </a>
            </li>
            <li>
              <a href="https://rutube.ru/channel/26516507/" target="_blank">
                <img src={ram} alt="" />
              </a>
            </li>
            <li>
              <a href="https://vk.com/sociomon" target="_blank">
                <img src={vk} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@sociomonitoring"
                target="_blank"
              >
                <img src={yt} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://dzen.ru/id/6391e7732c71257886f6ca5a"
                target="_blank"
              >
                <img src={dz} alt="" />
              </a>
            </li>
          </ul>
        </div>
        <div className="links__down">
          <a href="/agreement" target="_blank">
            Пользовательское соглашение
          </a>
          <a href="" target="_blank">
            Sociomonitoring © 2022
          </a>
          <a href="/politics" target="_blank">
            Политика обработки данных
          </a>
        </div>
      </footer>
    </div>
  );
}
