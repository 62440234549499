import { combineReducers } from "redux"
import {MeasurementStatisticReducer} from "./MeasurementStatisticReducer";

import { VerticalStepBarReducer } from './VerticalStepBarReducer'
import { ArticleNavigationReducer } from './ArticleNavigationReducer'
import { ArticleSidebarNavigationReducer } from './ArticleSidebarNavigationReducer'
import { LoginReducer } from './LoginReducer'
import { RestorePasswordReducer } from './RestorePasswordReducer'
import { userReducer } from './userReducer'
import { SignupReducer } from "./SignupReducer"
import { StepBarReducer } from './StepBarReducer'
import { MeasurementTypeReducer } from './MeasurementTypeReducer'
import { HeaderTitleReducer } from './HeaderTitleReducer'
import { NavbarReducer } from './NavbarReducer'
import { MeasurementReducer } from './MeasurementReducer'
import { PersonStatusesReducer } from './PersonStatusesReducer'
import { FindMeasurementPersonsListReducer } from './FindMeasurementPersonsListReducer'
import { CurrentPersonReducer } from './CurrentPersonReducer'
import { PersonMeasurementListReducer } from './PersonMeasurementListReducer'
import { CurrentRatingPersonReducer } from './CurrentRatingPersonReducer'
import { MeasurementImagesReducer } from './MeasurementImagesReducer'

import { MeasurementsListReducer } from './MeasurementsListReducer'

import { ModalWindowsReducer } from './ModalWindowsReducer'

import { MeasurementConsultationListReducer } from './MeasurementConsultationListReducer'
import { CreatingConsultationReducer } from './CreatingConsultationReducer'
import { ConsultantsListReducer } from './ConsultantsListReducer'

import { ConsultationChatReducer } from './ConsultationChatReducer'
import { CurrentConsultationReducer } from './CurrentConsultationReducer'
import { ConsultantReducer } from './ConsultantReducer'
import { ConsultationCommisionReducer } from './ConsultationCommisionReducer'
import { UserTaskReducer } from './UserTaskReducer'
import { ConsultationsCostReducer } from './ConsultationsCostReducer'
import { ConsultationPayReducer } from './ConsultationPayReducer'
import { CreatingMeasurementStateReducer } from './CreatingMeasurementStateReducer'
import { AddMeasurementToConsultationReducer } from './AddMeasurementToConsultationReducer'

import { AccountNavbarReducer } from './AccountNavbarReducer'
import { AccountMainDataReducer } from './AccountMainDataReducer'
import { AccountFinancesReducer } from './AccountFinancesReducer'
import { AccountServicesReducer } from './AccountServicesReducer'
import { AccountChangePasswordReducer } from './AccountChangePasswordReducer'
import { NotificationsSettingsReducer } from './NotificationsSettingsReducer' 
import { CountriesListReducer } from './CountriesListReducer'
import { ChangeEmailReducer } from './ChangeEmailReducer'

import { ConsultationsListReducer } from './ConsultationsListReducer'
import { ConsultantsFilterReducer } from './ConsultantsFilterReducer'
import { CitiesListReducer } from "./CitiesListReducer"
import { RejectConsultationReducer } from "./RejectConsultationReducer"
import { AcceptPayingConsultationReducer } from "./AcceptPayingConsultationReducer"
import { MeasurementTypeListReducer } from "./MeasurementTypeListReducer"
import { MeasurementListSearchReducer } from "./MeasurementListSearchReducer"
import { PersonsListReducer } from "./PersonsListReducer"
import { PersonsListSearchReducer } from "./PersonsListSearchReducer"
import { ChoosenPersonListReducer } from "./ChoosenPersonListReducer"
import { CanRateNextPersonReducer } from "./CanRateNextPersonReducer"
import { ConsultationReviewReducer } from "./ConsultationReviewReducer";
import { CurrentMeasurementStatisticPersonReducer } from "./CurrentMeasurementStatisticPersonReducer";
import { MainGraphicModeReducer } from "./MainGraphicModeReducer";

const appReducer = combineReducers({

    verticalStepBar: VerticalStepBarReducer,

    articleNavigation: ArticleNavigationReducer,
    articleSideNavigation: ArticleSidebarNavigationReducer,

    user: userReducer,

    login: LoginReducer,
    restorePassword: RestorePasswordReducer,
    signup: SignupReducer,

    stepbar: StepBarReducer,

    measurementType: MeasurementTypeReducer,
    creatingMeasurementState: CreatingMeasurementStateReducer,
    headerTitle: HeaderTitleReducer,
    navbar: NavbarReducer,
    measurement: MeasurementReducer,
    personStatuses: PersonStatusesReducer,
    findMeasurementPersonsList: FindMeasurementPersonsListReducer,
    currentPerson: CurrentPersonReducer,
    personMeasurementList: PersonMeasurementListReducer,
    currentRatingPerson: CurrentRatingPersonReducer,
    measurementImages: MeasurementImagesReducer,
    canRateNextPerson: CanRateNextPersonReducer,

    modals: ModalWindowsReducer,

    measurementsList: MeasurementsListReducer,
    
    measurementConsultationList: MeasurementConsultationListReducer,
    creatingConsultation: CreatingConsultationReducer,
    consultantsList: ConsultantsListReducer,
    consultantFilters: ConsultantsFilterReducer,

    currentConsultation: CurrentConsultationReducer,
    consultationChat: ConsultationChatReducer,
    consultant: ConsultantReducer,
    consultationCommision: ConsultationCommisionReducer,
    userTasks: UserTaskReducer,
    consultationCost: ConsultationsCostReducer,
    consultationPay: ConsultationPayReducer,
    addMeasurementToConsultation: AddMeasurementToConsultationReducer,
    rejectConsultation: RejectConsultationReducer,
    acceptPayingConsultation: AcceptPayingConsultationReducer,
    consultationReview: ConsultationReviewReducer,

    accountNavbar: AccountNavbarReducer,

    measurementStatistic: MeasurementStatisticReducer,

    accountMainData: AccountMainDataReducer,
    accountFinances: AccountFinancesReducer,
    accountServices: AccountServicesReducer,
    changingPassword: AccountChangePasswordReducer,
    notificationsSettings: NotificationsSettingsReducer,
    countries: CountriesListReducer,
    cities: CitiesListReducer,
    changeEmail: ChangeEmailReducer,

    consultationsList: ConsultationsListReducer,
    measurementsTypeList: MeasurementTypeListReducer,
    measurementSearch: MeasurementListSearchReducer,
    
    personList: PersonsListReducer,
    personListSearch: PersonsListSearchReducer,
    choosenPersonList: ChoosenPersonListReducer,
    
    currentMeasurementStatisticPerson: CurrentMeasurementStatisticPersonReducer,
    mainGraphicMode: MainGraphicModeReducer,
    
})

const rootReducer = (state, action) => {
    if (action.type === 'STORE_RESET') {
      return appReducer(undefined, action)
    }
  
    return appReducer(state, action)
  }

export { rootReducer }