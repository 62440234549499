import { call, put, takeEvery } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { NotificationsSettings } from "../interfaces/NotificationsSettings";
import { routes } from "../routes";
import store from "../store";
import { setNotificationsSettings, TRY_TO_DELETE_NOTIFICATIONS_SETTINGS } from "../store/reducers/NotificationsSettingsReducer";

async function asyncTryToDeleteSocialNetworks(type: string) {
    try {

        let currentData = store.getState().notificationsSettings

        currentData.notificationChannels = currentData.notificationChannels.map( (elem) => {
            if (elem.type === type) {
                return {type: type, use: false}
            } else {
                return elem
            }
        })
        const response = await fetch(
            apiUrl + '/user/notification-settings',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    ...currentData
                })
            }
        )
        if (response.status === 200) {
            const result = await response.json()
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(error) {}
} 

function* DeleteSocialNetworksWorker(action) {
    const data: NotificationsSettings | null = yield call(asyncTryToDeleteSocialNetworks, action.payload)
    if (data && data.notificationChannels) {
        yield put( setNotificationsSettings(data) )
    }
}

export function* DeleteSocialNetworksWatcher() {
    yield takeEvery(TRY_TO_DELETE_NOTIFICATIONS_SETTINGS, DeleteSocialNetworksWorker)
}