import { call, put, takeEvery} from "redux-saga/effects";
import { apiUrl } from "../consts";
import { MeasurementInfo } from "../interfaces/MeasurementInfo";
import { routes } from "../routes";
import store from "../store";
import { setCanRateNextPerson } from "../store/reducers/CanRateNextPersonReducer";
import { ASYNC_SET_RATE } from "../store/reducers/CurrentRatingPersonReducer";
import { updateMeasurement } from "../store/reducers/MeasurementReducer";

async function AsyncRatePerson(type) {
    let measId = JSON.parse(localStorage.getItem('measurement') || '');

    try {
        const response = await fetch(
            apiUrl + '/measurement/'+ measId.id + '/update-rate',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': 'Bearer ' + localStorage.token
                },
                body: JSON.stringify({
                    rate: store.getState().currentRatingPerson.rate,
                    position: store.getState().currentRatingPerson.position,
                    type: type
                })
            }
        )

        if (response.status === 200) {
            const result: MeasurementInfo = await response.json()
           
            localStorage.setItem('measurement', JSON.stringify(result));
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(err) {}
}

function* ratePersonWorker(action) {
    const data = yield call(AsyncRatePerson, action.payload)
    
    if (data && data.id) {
        yield put(updateMeasurement(data))
        yield put(setCanRateNextPerson())
    }
}

export function* RatePersonWatcher() {
    yield takeEvery(ASYNC_SET_RATE, ratePersonWorker)
}