import React, {useState, useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {ChevronDownIcon} from '../../icons';
import {ArticleNavigationTypes} from '../../store/types/ArticleNavigation';
import axios from 'axios';
import {apiUrl} from '../../consts';
import {defaultValue} from './article';

import './styles.sass'

export  const ArticlesNavigation = () => {

    const navState = useSelector((state: any) => state.articleNavigation)
    const dispatch = useDispatch()
    const changeCurrentSection = (index: number) => ({type: ArticleNavigationTypes.OPEN_SECTION, payload: index})
    const changeCurrentArticle = (index: Array<any>) => ({type: ArticleNavigationTypes.OPEN_ARTICLE, payload: index})
    const toggleIsArticleSidebarOpened = () => ({type: 'TOGGLE_IS_ARTICLE_SIDEBAR_OPENED'})
 
    const url = new URL(window.location.href);
    

    const [articles, setArticles] = useState(new Array(defaultValue));
  
    function searchArticle(data){
      
        for (let i=0;i<data.length;i++){
            let lengthCh = data[i].children?.length
            if (lengthCh != undefined){
                data[i].children?.map((article)=>{
                    if (article.id == url.searchParams.get("key")){
                     
                        dispatch(changeCurrentSection(i))
                    }
                })
            }
        }
    }
  
  
    useEffect(() => {
        axios.get(apiUrl + "/content/sub-nodes/referenceInformation/1").then(response => {
            
            if (response.status == 200 || response.status == 201){
            setArticles(response.data)
            searchArticle(response.data)
           
            localStorage.setItem('articles', JSON.stringify(response.data))
            } else if(response.status == 401){
                
            }
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
     
     
        
        
        
    
   
    
    return (
        <div className='articles-navigataion-wrapper'>
            {   
                articles.map((article, sectionIndex) => {
                    return (
                        <div key={sectionIndex}>
                            <div
                                className={`articles-navigataion-wrapper__section 
                                    ${(navState.currentSectionNum == sectionIndex) && 'active-section'}
                                `}
                                onClick={
                                    () => {
                                        dispatch(changeCurrentSection(sectionIndex))
                                        const data: Array<any> = [];
                                        data.push(0);
                                        if (article.children) {
                                            data.push(article.children[0]);
                                        }
                                        dispatch(changeCurrentArticle(data))
                                    }
                                }
                            >
                                <span>{article.title}</span>
                                <ChevronDownIcon/>
                            </div>

                            <div
                                className={`articles-navigataion-wrapper__articles-list-wrapper 
                                    ${(navState.currentSectionNum === sectionIndex) ? 'opened-article-list' : ''}
                                `}
                            >
                                {   
                                    article?.children?.map((subArticle, articleIndex) => {
                                  
                                       
                                        return (
                                            <div
                                                key={articleIndex}
                                                onClick={() => {
                                                    
                                                    const data: Array<any> = [];
                                                    data.push(articleIndex);
                                                    data.push(subArticle);
                                                   
                                                    window.history.replaceState(null, '', `?key=${data[1].id}`);
                                                  
                                                    dispatch(changeCurrentArticle(data))
                                                    dispatch(toggleIsArticleSidebarOpened())
                                                }}
                                                className={(subArticle.id === url.searchParams.get("key")) ? 'choosen-article' : ''}
                                            >
                                                <span>{subArticle.title}</span>
                                                {
                                                    (subArticle.id === url.searchParams.get("key"))
                                                    &&
                                                    <div className='choosen-article-block'/>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
