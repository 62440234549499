import { Consultant } from "../../interfaces/Consultant"

export const CHOOSE_CONSULTANT = 'CHOOSE_CONSULTANT'
export const SET_CREATING_CONSULTATION_TEXT = 'SET_CREATING_CONSULTATION_TEXT'
export const TRY_TO_CREATE_CONSULTATION = 'TRY_TO_CREATE_CONSULTATION'
export const CONFIRM_CONSULTATION_CREATION = 'CONFIRM_CONSULTATION_CREATION'
export const CLEAN_CONSULTATION = 'CLEAN_CONSULTATION'

enum DealTypes {
    SAFE_DEAL = 'SAFE_DEAL',
    DIRECT_DEAL = 'DIRECT_DEAL',
}

interface CreatingConsultation {
    adresseeId: string;
    message: string;
    dealType: DealTypes.SAFE_DEAL;
    measurements: string[],
    consultant?: Consultant,
    problemDesc1?: string;
    problemDesc2?: string;
    problemDesc3?: string;
}

let initialState: CreatingConsultation  = {
    adresseeId: '',
    message: '',
    dealType: DealTypes.SAFE_DEAL,
    measurements: [],
}

if (localStorage.createConsultation !== undefined && (typeof localStorage.createConsultation === 'string') && localStorage.createConsultation.length > 0) {
    const preState = JSON.parse(localStorage.createConsultation)
    initialState = preState   
}


export const CreatingConsultationReducer = (state = initialState, action) => {

    switch (action.type) {

        case CHOOSE_CONSULTANT:
            localStorage.setItem('createConsultation', JSON.stringify({...state, consultant: action.payload}))
            return {
                ...state,
                consultant: action.payload
            }

        case SET_CREATING_CONSULTATION_TEXT:
            switch (action.payload.num) {
                case '1':
                    localStorage.setItem('createConsultation', JSON.stringify({...state, problemDesc1: action.payload.value}))
                    return {
                        ...state,
                        problemDesc1: action.payload.value
                    }
                case '2':
                    localStorage.setItem('createConsultation', JSON.stringify({...state, problemDesc2: action.payload.value}))
                    return {
                        ...state,
                        problemDesc2: action.payload.value
                    }
                case '3':
                    localStorage.setItem('createConsultation', JSON.stringify({...state, problemDesc3: action.payload.value}))
                    return {
                        ...state,
                        problemDesc3: action.payload.value
                    }
                default: 
                    return state
            }

        case CONFIRM_CONSULTATION_CREATION:
            localStorage.setItem('createConsultation', '')
            return {
                adresseeId: '',
                message: '',
                dealType: DealTypes.SAFE_DEAL,
                measurements: [],
            }
        
        case CLEAN_CONSULTATION:
            localStorage.setItem('createConsultation', '')
            return {
                adresseeId: '',
                message: '',
                dealType: DealTypes.SAFE_DEAL,
                measurements: [],
            }

        default: 
            return state
    }
}

export const chooseConsultant = (payload: Consultant) => ({type: CHOOSE_CONSULTANT, payload})
export const setTextData = (payload: {value: string, num: string }) =>({type: SET_CREATING_CONSULTATION_TEXT, payload})
export const tryToCreateConsultation = (payload: CreatingConsultation) => ({type: TRY_TO_CREATE_CONSULTATION, payload})
export const confirmConsultationCreation = () => ({type: CONFIRM_CONSULTATION_CREATION})
export const cleanConsultation = () => ({type: CLEAN_CONSULTATION})