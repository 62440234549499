export const TRY_TO_REJECT_CONSULTATION = 'TRY_TO_REJECT_CONSULTATION'
export const SET_IS_REJECTING_CONSULTATION_TRUE = 'SET_IS_REJECTING_CONSULTATION_TRUE'
export const SET_IS_REJECTING_CONSULTATION_FALSE = 'SET_IS_REJECTING_CONSULTATION_FALSE'

const initialState: boolean = false

export const RejectConsultationReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_IS_REJECTING_CONSULTATION_TRUE:
            return true

        case SET_IS_REJECTING_CONSULTATION_FALSE:
            return false

        default:
            return state
    }
}

export const tryToRejectConsultation = () => ({type: TRY_TO_REJECT_CONSULTATION})
export const setIsRejectinConsultationTrue = () => ({type: SET_IS_REJECTING_CONSULTATION_TRUE})
export const setIsRejectinConsultationFalse = () => ({type: SET_IS_REJECTING_CONSULTATION_FALSE})