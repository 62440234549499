import { useEffect, useState } from "react";
import "./style.sass";
export const PaymentFailContainer = () => {
  const [pay, setPay] = useState<any>({});

  const getParam = () => {
    //let query = newUrlSearchParam()
    let url = new URL(document.location.href);
    let key = url.searchParams.get("shp_operation");

    fetch(`${process.env.REACT_APP_API_HOST}/payment/${key} `, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPay(data);
      });
  };

  useEffect(() => {
    getParam();
  }, []);
  return (
    <div className="payment_style">
      <div className="get_cons">
        <h1>Платеж отменен</h1>
        <div>
          <p>Наименование услуги: </p>
          <span>{pay.activatedPackageName}</span>
        </div>
        <div>
          <p>Сумма: </p>
          <span>{pay.amount + " руб."}</span>
        </div>
      </div>
    </div>
  );
};
