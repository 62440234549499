//import { useEffect, useState } from "react"
import {useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { setCurrentPersonComment } from "../../store/reducers/CurrentPersonReducer"

import './styles.sass'

export const CommentaryMeasurementPersonListPage = () => {

    const intl = useIntl()
    const dispatch = useDispatch()

    const currentPerson = useSelector( (state: any) => (state.currentPerson))
    const personStatusesArray = useSelector( (state:any) => (state.personStatuses))
    
    return (
        <div className="commentary-measurement-person-list-wrapper">
            
            <div>
                <input 
                    type='text'
                    value={currentPerson.comment}
                    onChange={(event) => {
                        dispatch(setCurrentPersonComment(event.target.value))
                    
                    }}
                    
                    placeholder={ intl.formatMessage({id: 'measurement_person_list_page_commentary_placeholder'})}
                />
            </div>

            <span>
                {
                    personStatusesArray.map( (elem,index) =>{
                        if (elem.name === currentPerson.member.role) {
                            return personStatusesArray[index].comment
                        }
                    })
                }

            </span>
        </div>
    )
}