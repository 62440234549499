import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  MeasurementFace0,
  MeasurementFace1,
  MeasurementFace2,
  MeasurementFace3,
  MeasurementFace4,
  MeasurementFace5,
  MeasurementFace6,
} from "../../images/measurementIcons";

import { setRate } from "../../store/reducers/CurrentRatingPersonReducer";
import "./styles.sass";
import { setCanRateNextPerson } from "../../store/reducers/CanRateNextPersonReducer";

const colors = [
  {
    color: "#E3655D",
    rate: -3,
  },
  {
    color: "#EF8551",
    rate: -2,
  },
  {
    color: "#FFBA7B",
    rate: -1,
  },
  {
    color: "#FFF171",
    rate: 0,
  },
  {
    color: "#D7FC9A",
    rate: 1,
  },
  {
    color: "#9DE26E",
    rate: 2,
  },
  {
    color: "#53CF5C",
    rate: 3,
  },
];

export const RatingPanel = ({ setMobileButton }) => {
  const dispatch = useDispatch();
  const currentMember = useSelector(
    (state: any) => state.currentRatingPerson.position
  );
  const [choosenRatingPanel, setChoosenRatingPanel] = useState<number>(
    document.body.clientWidth > 640 ? 8 : 3
  );

  const [images, setImages] = useState([]);
  const [offset, setOffset] = useState<number>(0);
  const [currentIcon, setCurrentIcon] = useState<React.ReactElement>(
    <MeasurementFace3 />
  );
  const getImage = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/set-cards/${
          JSON.parse(localStorage.measurementTypesN).setCardsId
        }`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );
      let result = await response.json();
      setImages(result.imagesId);
    } catch (error) {}
  };
  useEffect(() => {
    //dispatch(getMeasurementImages())
    setChoosenRatingPanel(document.body.clientWidth > 640 ? 8 : 3);

    //slider
    const draggingElement: HTMLElement | null =
      document.getElementById("dragging-icon");

    if (draggingElement) {
      let percentage = 48;
      let currentX = offset;
      let off = 0;
      draggingElement.addEventListener("touchmoves", () => {
        // currentX = event.touches[0].clientX;

        if (percentage <= 86) {
          if (currentX > off) {
            draggingElement.style.left = percentage + 0.7 + "%";
            percentage += 0.7;
          }
        }
        if (percentage >= 0) {
          if (currentX < off) {
            draggingElement.style.left = percentage - 0.7 + "%";
            percentage -= 0.7;
          }
        }
        off = currentX;
      });
      draggingElement.addEventListener("touchendd", () => {
        setMobileButton(false);
        if (percentage <= 12) {
          dispatch(setRate(-3));
          setChoosenRatingPanel(0);
          setCurrentIcon(<MeasurementFace0 />);
        } else if (percentage <= 24) {
          dispatch(setRate(-2));
          setChoosenRatingPanel(1);
          setCurrentIcon(<MeasurementFace1 />);
        } else if (percentage <= 36) {
          dispatch(setRate(-1));
          setChoosenRatingPanel(2);
          setCurrentIcon(<MeasurementFace2 />);
        } else if (percentage <= 48) {
          dispatch(setRate(0));
          setChoosenRatingPanel(3);
          setCurrentIcon(<MeasurementFace3 />);
        } else if (percentage <= 60) {
          dispatch(setRate(1));
          setChoosenRatingPanel(4);
          setCurrentIcon(<MeasurementFace4 />);
        } else if (percentage <= 72) {
          dispatch(setRate(2));
          setChoosenRatingPanel(5);
          setCurrentIcon(<MeasurementFace5 />);
        } else {
          dispatch(setRate(3));
          setChoosenRatingPanel(6);
          setCurrentIcon(<MeasurementFace6 />);
        }

        setOffset(currentX);
      });
    }
    //slider

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMember]);
  useEffect(() => {
    getImage();
    const consultantsListWrapper: HTMLElement | null =
      window.document.getElementById("rating_car");
    let x1 = null;
    let y1 = null;

    function handleTouchStart(event) {
      const firstTouch = event.touches[0];
      x1 = firstTouch.clientX;
      y1 = firstTouch.clientY;
    }
    function handleTouchMove(event) {
      if (!x1 || !y1) {
        return false;
      }
      let x2 = event.touches[0].clientX;
      let y2 = event.touches[0].clientY;
      let xDiff = x2 - x1;
      let yDiff = y2 - y1;
      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0 && choosenRatingPanel > 0) {
          setMobileButton(false);
          setChoosenRatingPanel(choosenRatingPanel - 1);
        } else if (xDiff <= 0 && choosenRatingPanel < 6) {
          setMobileButton(false);
          setChoosenRatingPanel(choosenRatingPanel + 1);
        }
      } else if (yDiff > 0) {
        console.log("down");
      } else if (yDiff <= 0) {
        console.log("top");
      }

      x1 = null;
      y1 = null;
    }
    if (consultantsListWrapper) {
      consultantsListWrapper.addEventListener(
        "touchstart",
        handleTouchStart,
        false
      );
      consultantsListWrapper.addEventListener(
        "touchmove",
        handleTouchMove,
        false
      );
    }
    return () => {
      consultantsListWrapper?.removeEventListener(
        "touchstart",
        handleTouchStart
      );
      consultantsListWrapper?.removeEventListener("touchmove", handleTouchMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenRatingPanel]);
  useEffect(() => {
    switch (choosenRatingPanel) {
      case 0:
        setCurrentIcon(<MeasurementFace0 />);
        dispatch(setRate(-3));
        break;
      case 1:
        setCurrentIcon(<MeasurementFace1 />);
        dispatch(setRate(-2));
        break;
      case 2:
        setCurrentIcon(<MeasurementFace2 />);
        dispatch(setRate(-1));
        break;
      case 3:
        setCurrentIcon(<MeasurementFace3 />);
        dispatch(setRate(0));
        break;
      case 4:
        setCurrentIcon(<MeasurementFace4 />);
        dispatch(setRate(1));
        break;
      case 5:
        setCurrentIcon(<MeasurementFace5 />);
        dispatch(setRate(2));
        break;
      case 6:
        setCurrentIcon(<MeasurementFace6 />);
        dispatch(setRate(3));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenRatingPanel]);
  return (
    <div className="rating-panel-wrapper">
      {document.body.clientWidth > 640 ? (
        colors.map((elem, index) => {
          return (
            <div
              key={index}
              className={`rating-panel ${
                choosenRatingPanel === index && "choosen-rating-panel"
              }`}
              style={{ backgroundColor: elem.color }}
              onClick={() => {
                setChoosenRatingPanel(index);

                dispatch(setRate(elem.rate));
                dispatch(setCanRateNextPerson());
              }}
            >
              <img
                src={
                  location.pathname == "/measurement-me-to-person-rating"
                    ? images[index]
                    : images[7 + index]
                }
              />
            </div>
          );
        })
      ) : (
        <>
          <div className="rating_car" id="rating_car">
            <div
              className="rate_train"
              style={
                choosenRatingPanel == 0
                  ? { transform: "translateX(0vw)" }
                  : choosenRatingPanel == 1
                  ? { transform: "translateX(-100vw)" }
                  : choosenRatingPanel == 2
                  ? { transform: "translateX(-200vw)" }
                  : choosenRatingPanel == 3
                  ? { transform: "translateX(-300vw)" }
                  : choosenRatingPanel == 4
                  ? { transform: "translateX(-400vw)" }
                  : choosenRatingPanel == 5
                  ? { transform: "translateX(-500vw)" }
                  : choosenRatingPanel == 6
                  ? { transform: "translateX(-600vw)" }
                  : { transform: "translateX(-300vw)" }
              }
            >
              <div
                onClick={() => {
                  setMobileButton(false);
                  dispatch(setRate(-3));
                }}
                className={`rating-panel`}
                style={{
                  background:
                    colors[choosenRatingPanel] &&
                    colors[choosenRatingPanel].color !== undefined
                      ? colors[choosenRatingPanel].color
                      : "#DBD55E",
                }}
              >
                <img src={images[choosenRatingPanel] || ""} />
              </div>
              <div
                onClick={() => {
                  setMobileButton(false);
                  dispatch(setRate(-2));
                }}
                className={`rating-panel`}
                style={{
                  background:
                    colors[choosenRatingPanel] &&
                    colors[choosenRatingPanel].color !== undefined
                      ? colors[choosenRatingPanel].color
                      : "#DBD55E",
                }}
              >
                <img src={images[choosenRatingPanel] || ""} />
              </div>
              <div
                onClick={() => {
                  setMobileButton(false);
                  dispatch(setRate(-1));
                }}
                className={`rating-panel`}
                style={{
                  background:
                    colors[choosenRatingPanel] &&
                    colors[choosenRatingPanel].color !== undefined
                      ? colors[choosenRatingPanel].color
                      : "#DBD55E",
                }}
              >
                <img src={images[choosenRatingPanel] || ""} />
              </div>
              <div
                onClick={() => {
                  setMobileButton(false);
                  dispatch(setRate(0));
                }}
                className={`rating-panel`}
                style={{
                  background:
                    colors[choosenRatingPanel] &&
                    colors[choosenRatingPanel].color !== undefined
                      ? colors[choosenRatingPanel].color
                      : "#DBD55E",
                }}
              >
                <img src={images[choosenRatingPanel] || ""} />
              </div>
              <div
                onClick={() => {
                  setMobileButton(false);
                  dispatch(setRate(1));
                }}
                className={`rating-panel`}
                style={{
                  background:
                    colors[choosenRatingPanel] &&
                    colors[choosenRatingPanel].color !== undefined
                      ? colors[choosenRatingPanel].color
                      : "#DBD55E",
                }}
              >
                <img src={images[choosenRatingPanel] || ""} />
              </div>
              <div
                onClick={() => {
                  setMobileButton(false);
                  dispatch(setRate(2));
                }}
                className={`rating-panel`}
                style={{
                  background:
                    colors[choosenRatingPanel] &&
                    colors[choosenRatingPanel].color !== undefined
                      ? colors[choosenRatingPanel].color
                      : "#DBD55E",
                }}
              >
                <img src={images[choosenRatingPanel] || ""} />
              </div>
              <div
                onClick={() => {
                  setMobileButton(false);
                  dispatch(setRate(3));
                }}
                className={`rating-panel`}
                style={{
                  background:
                    colors[choosenRatingPanel] &&
                    colors[choosenRatingPanel].color !== undefined
                      ? colors[choosenRatingPanel].color
                      : "#DBD55E",
                }}
              >
                <img src={images[choosenRatingPanel] || ""} />
              </div>
            </div>
          </div>

          <div className="choosing-line" onClick={() => setMobileButton(false)}>
            <span
              id="dragging-icon"
              style={
                choosenRatingPanel == 0
                  ? { left: "-8px" }
                  : choosenRatingPanel == 1
                  ? { left: "41px" }
                  : choosenRatingPanel == 2
                  ? { left: "89px" }
                  : choosenRatingPanel == 3
                  ? { left: "136px" }
                  : choosenRatingPanel == 4
                  ? { left: "183px" }
                  : choosenRatingPanel == 5
                  ? { left: "229px" }
                  : choosenRatingPanel == 6
                  ? { left: "276px" }
                  : { left: "unset" }
              }
            >
              {currentIcon}
            </span>
            <div
              className="choosing-line__panel"
              onClick={() => {
                setChoosenRatingPanel(0);

                dispatch(setRate(-3));
              }}
            >
              <div style={{ backgroundColor: "#AC3838" }} />
            </div>
            <div
              className="choosing-line__panel"
              onClick={() => {
                setChoosenRatingPanel(1);
                dispatch(setRate(-2));
              }}
            >
              <div style={{ backgroundColor: "#BB7248" }} />
            </div>
            <div
              className="choosing-line__panel"
              onClick={() => {
                setChoosenRatingPanel(2);
                dispatch(setRate(-1));
              }}
            >
              <div style={{ backgroundColor: "#DD8C45" }} />
            </div>
            <div
              className="choosing-line__panel"
              onClick={() => {
                setChoosenRatingPanel(3);
                dispatch(setRate(0));
              }}
            >
              <div style={{ backgroundColor: "#DBD55E" }} />
            </div>

            <div
              className="choosing-line__panel"
              onClick={() => {
                setChoosenRatingPanel(4);
                dispatch(setRate(1));
              }}
            >
              <div style={{ backgroundColor: "#92BF48" }} />
            </div>
            <div
              className="choosing-line__panel"
              onClick={() => {
                setChoosenRatingPanel(5);
                dispatch(setRate(2));
              }}
            >
              <div style={{ backgroundColor: "#62B549" }} />
            </div>
            <div
              className="choosing-line__panel"
              onClick={() => {
                setChoosenRatingPanel(6);
                dispatch(setRate(3));
              }}
            >
              <div style={{ backgroundColor: "#38B33D" }} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
