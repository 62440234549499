import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { StepBar } from "../../components/StepBar";
import { routes } from "../../routes";

import { BottomControlPanel } from "../../components/BottomControlPanel/index";

import "./styles.sass";
import { nextStepBar } from "../../store/reducers/StepBarReducer";
import { getConsultantsList } from "../../store/reducers/ConsultantsListReducer";
import { closeModal } from "../../store/reducers/ModalWindowsReducer";
import { ChooseConsultantPanel } from "../../components/ConsultationUserComponents/ChooseConsultantPanel/index";
import { ConsultantModal } from "../../components/ConsultationUserComponents/ConsultantModal/index";
import { SearchFilterConsultation } from "../../components/ConsultationUserComponents/SearchFilterConsultation";

export const ConsultantChoosingConsultationContainer = () => {
  const dispatch = useDispatch();

  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );

  const consultantModal = useSelector((state: any) => state.modals);

  const consultant = useSelector(
    (state: any) => state.creatingConsultation.consultant
  );

  const consultantsList = useSelector((state: any) => state.consultantsList);

  useEffect(() => {
    dispatch(closeModal());
    dispatch(nextStepBar(1));
    dispatch(getConsultantsList(0));

    const consultantsListWrapper: HTMLElement | null =
      window.document.getElementById("consultants-list-wrapper");

    function handleScroll() {
      let scrollTop = consultantsListWrapper?.scrollTop || 0;
      let clientHeight = consultantsListWrapper?.clientHeight || 0;
      let scrollheight = consultantsListWrapper?.scrollHeight || 0;
      if (scrollTop + clientHeight > scrollheight - 5 && consultantsList) {
        dispatch(getConsultantsList(consultantsList.page + 1));
      }
    }
    consultantsListWrapper?.addEventListener("scroll", handleScroll);

    //return () => document?.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateBack = () => {
    return true;
  };

  const navigateNext = () => {
    return "";
  };

  return (
    <>
      <div
        className={`start-consultation-container ${
          isNavbarShowed ? "page-with-opened-navbar" : "page-with-closed-navbar"
        }`}
        id="consultants-list-wrapper"
      >
        <div className="start-consultation-container__title-wrapper">
          <span>
            <FormattedMessage id="consultation_start_page_title" />
          </span>

          {document.body.clientWidth > 640 && <StepBar steps={4} />}
        </div>

        <div className="start-consultation-container__additional-title">
          <FormattedMessage id="consultation_choose_consultant_page_additional_title" />
        </div>

        <SearchFilterConsultation />

        <div className="start-consultation-container__consultants">
          {consultantsList.list &&
            consultantsList.list.length > 0 &&
            consultantsList.list.map((elem, index) => {
              return (
                <div key={index}>
                  <ChooseConsultantPanel
                    data={elem}
                    choosen={false}
                    canBeChoosen={true}
                  />
                </div>
              );
            })}
        </div>

        {consultantModal.modal === "CONSULTANT_MODAL" && <ConsultantModal />}
      </div>

      <BottomControlPanel
        nextPage={routes.consultationInfo}
        previousPage={routes.startMeasurement}
        backFunc={navigateBack}
        nextFunc={navigateNext}
        isNextButtonDisabled={
          !(consultant && consultant.id && consultant.login)
        }
      />
    </>
  );
};
