import { useEffect, useState } from 'react';
import './styles.sass';
import { useSelector } from 'react-redux';
import { routes } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ChoosenCheckMarkIcon } from '../../icons';
import { EmptyCheckMarkIcon } from '../../icons';
export const GroupStartInstructionContainer = () =>{
    const navigate = useNavigate()
    const [isUserAgree, setIsUserAgree] = useState(false)
    const [text, setText] = useState('');
    const isNavbarShowed = useSelector(
        (state: any) => state.navbar.isNavbarShowed
      );
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('id');
    const GroupDetails = () =>{
        fetch(process.env.REACT_APP_API_HOST + '/measurement/details/'+ myParam, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.token,
              },
        }).then((response)=>{
            return response.json()
        }).then((data)=> {
            setText(data.instruction.startText)
        })
      }
      useEffect(()=>{
        GroupDetails()
         // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
    return(<div className={`instruction-measurement-container ${ isNavbarShowed ? 'page-with-opened-navbar' : 'page-with-closed-navbar'}`}>
        <div className='text_format'>
        <h3 className="main-instruction-measurement-container__title-wrapper__title">Инструкция перед проведением группового замера</h3>
        <h4 className="main-instruction-measurement-container__title-wrapper__additional-title">Пожалуйста, внимательно прочтите инструкцию и подтвердите выполнение галочкой</h4>
        <div   dangerouslySetInnerHTML={{__html: text}}></div>
        <div 
                className="main-instruction-measurement-container__agreement-panel"
                onClick={() => {
                    if (isUserAgree) {
                        setIsUserAgree(false)
                    } else {
                        setIsUserAgree(true)
                    }
                }}
                style={{marginBottom: '0px'}}
            >
                {
                    isUserAgree
                    ?
                    <><ChoosenCheckMarkIcon/></>
                    :
                    <><EmptyCheckMarkIcon/></>
                }
                <span>
                    <FormattedMessage id='measurement_instruction_page_agreement_panel'/>
                </span>
            </div>
            </div>
        <div className="bottom_buttons">
        <div className="white-button" onClick={()=>navigate(routes.measurementsList)}>Отмена</div>
            <div className="green-button" onClick={()=>navigate(routes.groupRating + '?id=' + myParam)} style={!isUserAgree ?{pointerEvents: 'none', opacity: '0.6'}: {}}>Далее</div>
         
        </div>
    </div>)
}