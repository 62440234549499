import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../consts";
import { PersonStatus } from "../interfaces/PersonStatus";
import { routes } from "../routes";
import { GET_PERSON_STATUSES, setPersonStatuses } from "../store/reducers/PersonStatusesReducer";

async function asyncGetPersonStatuses () {
    try {
        const response = await fetch(
            apiUrl + '/person-statuses',
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': 'Bearer ' + localStorage.token
                }
            }
        )
        
        if (response.status === 200) {
            const result: PersonStatus[] = await response.json()
            
            return result
        } else if (response.status === 401) {
            location.assign(routes.login)
        } else {
            return null
        }
    } catch(error) {}
}

function* PersonStatusesWorker () {
    const data = yield call(asyncGetPersonStatuses)

    if (data && data.length > 0) {
        yield put(setPersonStatuses(data))
    }
}

export function* PersonStatusesWatcher () {
    yield takeLatest(GET_PERSON_STATUSES, PersonStatusesWorker)
}