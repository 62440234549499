import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  BigGreenArrowIcon,
  RoundedHexagon,
  TriangleCursorIcon,
} from "../../../icons";
import "./styles.sass";
import { Consultation } from "../../../interfaces/Consultation";

import { User } from "../../../interfaces/User";

const gradientLinesArray = [
  "linear-gradient(270deg, #E3655D 0%, #E80E00 100%)",
  "linear-gradient(270deg, #EF8551 0%, #E3655D 100%)",
  "linear-gradient(270deg, #FFBA7B 0%, #EF8551 100%)",
  "linear-gradient(270deg, #FFF171 0%, #FFBA7B 100%)",
  "linear-gradient(270deg, #D7FC9A 0%, #FFF171 100%)",
  "linear-gradient(270deg, #9DE26E 0%, #D7FC9A 100%)",
  "linear-gradient(270deg, #53CF5C 0%, #9DE26E 100%)",
  "linear-gradient(270deg, #00A30C 0%, #53CF5C 100%)",
];

const headerTitles = [
  {
    color: "#E3655D",
    title: "measurement_statistic_group_header_1",
    type: "3T",
  },
  {
    color: "#EF8551",
    title: "measurement_statistic_group_header_2",
    type: "2T",
  },
  {
    color: "#FFBA7B",
    title: "measurement_statistic_group_header_3",
    type: "1T",
  },
  {
    color: "#FFF171",
    title: "measurement_statistic_group_header_4",
    type: "0",
  },
  {
    color: "#D7FC9A",
    title: "measurement_statistic_group_header_5",
    type: "1L",
  },
  {
    color: "#9DE26E",
    title: "measurement_statistic_group_header_6",
    type: "2L",
  },
  {
    color: "#53CF5C",
    title: "measurement_statistic_group_header_7",
    type: "3L",
  },
];

export const GroupStatistic = () => {
  const data = useSelector((state: any) => state.measurementStatistic);
  const isNavbarShowed = useSelector(
    (state: any) => state.navbar.isNavbarShowed
  );
  const consultation: Consultation | null = useSelector(
    (state: any) => state.currentConsultation
  );
  const accountData: User = useSelector((state: any) => state.user);
  const [mode, setMode] = useState(0);
  const [width, setWidth] = useState<number | undefined>(0);
  const [interval, setNewInterval] = useState<any>({});

  useEffect(() => {
    if (data.prevailingGroup) {
      fetch(data.prevailingGroup.descriptionLink, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + localStorage.token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data1) => {
          setNewInterval(data1);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getCountOfPersons = (list, elem) => {
    let count = "-";

    if (list && list.length > 0) {
      list.forEach((element) => {
        if (element.name === elem.type) {
          count = element.count !== 0 ? element.count : "-";
        }
      });
    }
    return count;
  };

  const getGroupList = (curMode, persons: any[], groups) => {
    if (groups.length > 0 && persons.length > 0) {
      let list: any[] = [];
      let type = headerTitles[curMode].type;
      let personsIds: string[] = [];

      for (var i = 0; i < groups.length; i++) {
        if (groups[i].name === type) {
          personsIds = groups[i].members;
        }
      }

      for (var kIn = 1; kIn < persons.length; kIn++) {
        if (
          personsIds.length > 0 &&
          personsIds.includes(persons[kIn].person.id)
        ) {
          list = [...list, persons[kIn]];
        }
      }

      return list;
    } else {
      return [];
    }
  };
  const [titlesGroup, setTitlesGroup] = useState<any>({});
  useEffect(() => {
    if (data && data.titleGroup) {
      fetch(data.titleGroup, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data1) => {
          setTitlesGroup(data1);
        });
    }
  }, [data, data.titleGroup]);
  useEffect(() => {
    setWidth(
      document
        .getElementById("group-statistic-wrapper__header__panel" + mode)
        ?.getBoundingClientRect()?.x
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNavbarShowed, mode]);

  return (
    <div className="group-statistic-wrapper" id="group-statistic-wrapper">
      <div className="group-statistic-wrapper__header">
        <div className="group-statistic-wrapper__header__gradient-line">
          {gradientLinesArray.map((gradientLine, gradientLineIndex) => (
            <div
              key={
                "group-statistic-wrapper__header__gradient-line" +
                gradientLineIndex
              }
              style={{
                background: gradientLine,
              }}
            />
          ))}
        </div>
        <div
          className="group-statistic-wrapper__header__panels"
          style={
            mode == 2
              ? { translate: "-100px" }
              : mode == 3
              ? { translate: "-200px" }
              : mode == 4
              ? { translate: "-300px" }
              : mode == 5
              ? { translate: "-400px" }
              : mode == 6
              ? { translate: "-450px" }
              : { translate: "0" }
          }
        >
          {headerTitles.map((elem, index) => (
            <div
              key={index}
              onClick={() => {
                setMode(index);
              }}
              id={"group-statistic-wrapper__header__panel" + index}
            >
              <span
                className="title"
                style={
                  index == mode && document.body.clientWidth < 640
                    ? { fontWeight: "bold" }
                    : { fontWeight: "normal" }
                }
              >
                <FormattedMessage id={elem.title} />
              </span>
              <RoundedHexagon color={elem.color} />
              <span className="count">
                {getCountOfPersons(data?.groups, elem)}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div
        className="group-statistic-wrapper__cursor"
        style={
          location.pathname.includes("watcher")
            ? {
                marginLeft: width
                  ? width +
                    260 +
                    (isNavbarShowed ? -271 : -10) +
                    (document.body.clientWidth > 640 ? 0 : 18)
                  : 0,
                display: document.body.clientWidth < 640 ? "none" : "block",
              }
            : {
                marginLeft: width
                  ? width +
                    (isNavbarShowed ? -271 : -10) +
                    (document.body.clientWidth > 640 ? 0 : 18)
                  : 0,
                display: document.body.clientWidth < 640 ? "none" : "block",
              }
        }
      >
        <TriangleCursorIcon color={headerTitles[mode].color} />
      </div>

      <div
        className="group-statistic-wrapper__main"
        style={{
          borderColor: headerTitles[mode].color,
        }}
      >
        <span className="group-statistic-wrapper__main__title">
          <div
            className="text_akignnn"
            style={
              document.body.clientWidth > 640
                ? { width: "96%", margin: "0 auto" }
                : { width: "86%", margin: "0 auto" }
            }
            dangerouslySetInnerHTML={{ __html: titlesGroup[mode] }}
          ></div>
        </span>

        <div
          className="group-statistic-wrapper__main__left-button"
          onClick={() => {
            if (mode !== 0) {
              setMode(mode - 1);
            }
          }}
        >
          <BigGreenArrowIcon />
        </div>
        <div
          className="group-statistic-wrapper__main__right-button"
          onClick={() => {
            if (mode < 6) {
              setMode(mode + 1);
            }
          }}
        >
          <BigGreenArrowIcon />
        </div>

        <div className="group-statistic-wrapper__main__list">
          {" "}
          {getGroupList(mode, data.personRates, data.groups).length > 0 && (
            <div className="group-statistic-wrapper__main__list-header">
              <div>№</div>
              <span>3Т</span>
              <span>2Т</span>
              <span>1Т</span>
              <span>0</span>
              <span>1Л</span>
              <span>2Л</span>
              <span>3Л</span>
            </div>
          )}
          {getGroupList(mode, data.personRates, data.groups).length > 0 ? (
            getGroupList(mode, data.personRates, data.groups)?.map(
              (person, personIndex) => (
                <div
                  className="panel"
                  key={"group-statistic-wrapper-person" + personIndex}
                >
                  <div className="panel__name-wrapper">
                    <div
                      className="panel__name-wrapper__image"
                      style={{
                        background:
                          person.person.gender === "Male"
                            ? "rgba(57, 125, 255, 0.8)"
                            : "rgba(255, 143, 143, 0.8)",
                      }}
                    >
                      {person.person.role.slice(0, 1).toUpperCase()}
                    </div>
                    <span className="panel__name-wrapper__name">
                      {location.pathname.includes("measurement-watcher") &&
                      accountData &&
                      accountData.id &&
                      consultation?.consultantId == accountData.id
                        ? person?.person?.customName.slice(0, 3) + "..." || ""
                        : person?.person?.customName || ""}
                    </span>
                    <span className="panel__name-wrapper__number">
                      {personIndex + 1}
                    </span>
                    <div className="panel__name-wrapper__values">
                      <span style={{ marginBottom: "6px" }}>
                        <FormattedMessage id="measurement_statistic_group_me" />
                      </span>
                      <span style={{ marginTop: "6px" }}>
                        {person.person.gender === "Female" ? (
                          <FormattedMessage id="measurement_statistic_group_she" />
                        ) : (
                          <FormattedMessage id="measurement_statistic_group_he" />
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="panel__value-wrapper">
                    <div>
                      <div className="panel__value-wrapper__line">
                        {person.outerRating === "-3" && (
                          <div
                            style={{
                              marginLeft: "0",
                              width: "50%",
                              background: "#E3655D",
                              color: "#FFFFFF",
                            }}
                          >
                            3T
                          </div>
                        )}
                        {person.outerRating === "-2" && (
                          <div
                            style={{
                              marginLeft: "calc(50% / 3)",
                              width: "calc(50% / 3 * 2)",
                              background: "#EF8551",
                              color: "#FFFFFF",
                            }}
                          >
                            2T
                          </div>
                        )}
                        {person.outerRating === "-1" && (
                          <div
                            style={{
                              marginLeft: "calc(50% / 3 * 2)",
                              width: "calc(50% / 3)",
                              background: "#FFBA7B",
                              color: "#FFFFFF",
                            }}
                          >
                            1T
                          </div>
                        )}
                        {person.outerRating === "0" && (
                          <div
                            style={{
                              margin: "0 auto",
                              width: "37px",
                              color: "#729932",
                              background: "#FFF171",
                            }}
                          >
                            0
                          </div>
                        )}
                        {person.outerRating === "+1" && (
                          <div
                            style={{
                              marginLeft: "50%",
                              width: "calc(50% / 3)",
                              background: "#D7FC9A",
                              color: "#729932",
                            }}
                          >
                            1Л
                          </div>
                        )}
                        {person.outerRating === "+2" && (
                          <div
                            style={{
                              marginLeft: "50%",
                              width: "calc(50% / 3 * 2)",
                              background: "#9DE26E",
                              color: "#FFFFFF",
                            }}
                          >
                            2Л
                          </div>
                        )}
                        {person.outerRating === "+3" && (
                          <div
                            style={{
                              marginLeft: "50%",
                              width: "50%",
                              background: "#219653",
                              color: "#FFFFFF",
                            }}
                          >
                            3Л
                          </div>
                        )}
                      </div>
                      <div
                        className="panel__value-wrapper__line"
                        style={{ marginTop: "6px" }}
                      >
                        {person.innerRating === "-3" && (
                          <div
                            style={{
                              marginLeft: "0",
                              width: "50%",
                              background: "#E3655D",
                              color: "#FFFFFF",
                            }}
                          >
                            3T
                          </div>
                        )}
                        {person.innerRating === "-2" && (
                          <div
                            style={{
                              marginLeft: "calc(50% / 3)",
                              width: "calc(50% / 3 * 2)",
                              background: "#EF8551",
                              color: "#FFFFFF",
                            }}
                          >
                            2T
                          </div>
                        )}
                        {person.innerRating === "-1" && (
                          <div
                            style={{
                              marginLeft: "calc(50% / 3 * 2)",
                              width: "calc(50% / 3)",
                              background: "#FFBA7B",
                              color: "#FFFFFF",
                            }}
                          >
                            1T
                          </div>
                        )}
                        {person.innerRating === "0" && (
                          <div
                            style={{
                              margin: "0 auto",
                              width: "37px",
                              color: "#729932",
                              background: "#FFF171",
                            }}
                          >
                            0
                          </div>
                        )}
                        {person.innerRating === "+1" && (
                          <div
                            style={{
                              marginLeft: "50%",
                              width: "calc(50% / 3)",
                              background: "#D7FC9A",
                              color: "#729932",
                            }}
                          >
                            1Л
                          </div>
                        )}
                        {person.innerRating === "+2" && (
                          <div
                            style={{
                              marginLeft: "50%",
                              width: "calc(50% / 3 * 2)",
                              background: "#9DE26E",
                              color: "#FFFFFF",
                            }}
                          >
                            2Л
                          </div>
                        )}
                        {person.innerRating === "+3" && (
                          <div
                            style={{
                              marginLeft: "50%",
                              width: "50%",
                              background: "#219653",
                              color: "#FFFFFF",
                            }}
                          >
                            3Л
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            <div
              style={
                document.body.clientWidth > 640
                  ? {
                      position: "absolute",
                      bottom: "325px",
                      left: "8px",
                      textAlign: "center",
                      fontSize: "20px",
                    }
                  : {
                      position: "absolute",
                      bottom: "24vh",
                      left: "0",
                      textAlign: "center",
                      fontSize: "20px",
                    }
              }
            >
              В этом интервале отношений у вас нет ни одного партнера из данной
              группы
            </div>
          )}
        </div>
      </div>
      {
        <div style={{ maxWidth: "96%", margin: "0 auto" }}>
          <div className="groups__title">
            Преобладающий интервал:{" "}
            {
              <span
                dangerouslySetInnerHTML={{
                  __html: data.prevailingGroup && data.prevailingGroup.name,
                }}
              ></span>
            }
          </div>

          <div
            className="groups__text"
            dangerouslySetInnerHTML={{ __html: interval.description }}
          ></div>
        </div>
      }
    </div>
  );
};
